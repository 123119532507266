import React, {ReactNode, useEffect} from 'react';
import './DefaultPageWrapper.scss';
import {WebCbt} from '../../constants';
import Header from '../Header';
import Footer from '../Footer';
import GdprBanner from '../GdprBanner';
import {Location} from 'history';
import {Routes} from '../../router/Routes';
import {useDispatch, useSelector} from 'react-redux';
import {selectClassName} from '../../store/reducers/page';
import {selectWebCbt} from '../../store/reducers/partner';
import {changeClassName} from '../../store/actions';

type DefaultPageWrapperType = {
    children: ReactNode;
    location: Location,
};

const PageBackgroundType = {
    white: 'page page--white',
    default: 'page',
    twoPart: 'page page--aside'
}

const DefaultPageWrapper = ({children, location}: DefaultPageWrapperType) => {
    const className = useSelector(selectClassName);
    const webcbt = useSelector(selectWebCbt);
    const dispatch = useDispatch();
    useEffect(() => {
        if (className.includes('error')) {
            return;
        }

        switch (location.pathname) {
            case Routes.Login:
            case Routes.Signup:
            case Routes.Forgot:
            case Routes.ResetPassword:
                dispatch(changeClassName(PageBackgroundType.twoPart));
                break;
            case Routes.Download:
                let pageClassName = webcbt === WebCbt.mkClarioMobile ? PageBackgroundType.default : PageBackgroundType.white
                dispatch(changeClassName(pageClassName));
                break;
            default:
                dispatch(changeClassName(PageBackgroundType.default));
        }

    }, [location]);

    return (
        <div className={className}>
            <Header />
            {children}
            <GdprBanner />
            <Footer />
        </div>
    )
};

export default DefaultPageWrapper;
