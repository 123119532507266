import * as React from 'react';
import './Copyright.scss';

const Copyright = () => {
  return (
    <div className='copyright'>
      <p className='copyright__text copyright__text--upper'>
        <span className='copyright__symbol'>©</span>
        <span className='copyright__year'>{` ${new Date().getFullYear()} `}</span>
        <span className='copyright__company-name'> Clario Tech DMCC.</span>
      </p>
      <p className='copyright__text copyright__rights'>{` All rights reserved.`}</p>
    </div>
  );
};

export default Copyright;
