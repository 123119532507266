export const SSOActions = {
  GET_APPLE_AUTH_REDIRECT_LINK_REQUEST: 'GET_APPLE_AUTH_REDIRECT_LINK_REQUEST',
  GET_APPLE_AUTH_REDIRECT_LINK_SUCCESS: 'GET_APPLE_AUTH_REDIRECT_LINK_SUCCESS',
  GET_APPLE_AUTH_REDIRECT_LINK_FAILURE: 'GET_APPLE_AUTH_REDIRECT_LINK_FAILURE',

  SEND_GOOGLE_CREDENTIALS_REQUEST: 'SEND_GOOGLE_CREDENTIALS_REQUEST',
  SEND_GOOGLE_CREDENTIALS_SUCCESS: 'SEND_GOOGLE_CREDENTIALS_SUCCESS',
  SEND_GOOGLE_CREDENTIALS_FAILURE: 'SEND_GOOGLE_CREDENTIALS_FAILURE',

  CLEAR_SSO_ERROR: 'CLEAR_SSO_ERROR'
};

export const GoogleErrors = {
  closePopup: 'popup_closed_by_user',
  iframeInit: 'idpiframe_initialization_failed'
}
