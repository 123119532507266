import React from 'react';
import Title from '../../../../../components/Title';
import Instructions from '../Instructions';
import {IconType} from '../../../../../constants';
import QRCodeComponent from '../../../../../components/QRCode';
import IconsWithBackground from '../../../../../components/Icons/IconsWithBackground';


interface MobileDownloadBlockItemType {
    downloadLink: string
}

const AppStoreIcon = require('../../../../../../resources/images/store/icon-app-store.svg').default;
const GooglePlayIcon = require('../../../../../../resources/images/store/icon-google-play.svg').default;

const MobileDownloadInstructions = [
    'Scan the QR code with your phone\'s camera', 
    'Install the Clario mobile app from App Store or Google Play', 
    'Log in with your email and the password you created'
];

const MobileDownloadBlockItem = ({downloadLink}: MobileDownloadBlockItemType) => {
    return (
        <div className={`download__block`}>
            <aside className='download__aside download__aside--instruction'>
                <div className='download__header'>
                    <IconsWithBackground iconType={IconType.mobile} size='big' className='download__icon download__icon--header'/>
                    <Title text='Connect your smartphone' className='download__title'/>
                </div>
                <Instructions instructions={MobileDownloadInstructions}/>
                <div className='download__source'>
                    <p className='download__text'>Secure your identity and browse privately on your iPhone or Android.</p>
                    <div className='download__source-content'>
                        <p className='download__source-text'>
                            Get it on
                        </p>
                        <div className='download__source-buttons'>
                            <a href={downloadLink} target='_blank' className='download__source-button'>
                                <AppStoreIcon/>
                            </a>
                            <a href={downloadLink} className='download__source-button' target='_blank'>
                                <GooglePlayIcon/>
                            </a>
                        </div>
                    </div>
                </div>
            </aside>
            <aside
                className={`download__aside download__aside--source`}
            >
                <QRCodeComponent className='download__icon download__icon--download' link={downloadLink}/>
                <p className='download__source-text'>
                    Download Clario mobile
                </p>
            </aside>
        </div>
    );
};

export default MobileDownloadBlockItem;
