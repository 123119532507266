import {connect} from 'react-redux';
import AuthError from './AuthError';
import {clearSSOError} from '../../store/actions';


const mapDispatchToProps = (dispatch: any) => ({
  clearSSOError: (): void => {
    dispatch(clearSSOError());
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(AuthError);
