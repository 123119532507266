import {Dispatch} from 'redux';
import {VerifyDownloadTokenFailureReduxAction, VerifyDownloadTokenRequestReduxAction, VerifyDownloadTokenSuccessReduxAction} from './partnerActionsTypes';
import {PartnerIntegrationService} from '../../../services/partner/partnerIntegration';
import {PartnersActions} from '../../../constants';
import {VerifyLinkTokenResponse} from '../../../services/partner/verifySmartLinkToken';

export function verifyDownloadToken(token: string) {
  return (dispatch: Dispatch<VerifyDownloadTokenRequestReduxAction | VerifyDownloadTokenSuccessReduxAction | VerifyDownloadTokenFailureReduxAction>) => {
    dispatch(request(token));

    PartnerIntegrationService.verifyDownloadToken(token)
      .then((response) => {
          dispatch(success(response));
        },
        error => {
          dispatch(failure(error));
        }
      );

    function request(token: string): VerifyDownloadTokenRequestReduxAction {
      return {type: PartnersActions.VERIFY_DOWNLOAD_TOKEN_REQUEST, payload: {token: token}}
    }

    function success(response: VerifyLinkTokenResponse): VerifyDownloadTokenSuccessReduxAction {
      return {type: PartnersActions.VERIFY_DOWNLOAD_TOKEN_SUCCESS, payload: response}
    }

    function failure(error: Error): VerifyDownloadTokenFailureReduxAction {
      return {type: PartnersActions.VERIFY_DOWNLOAD_TOKEN_FAILURE, payload: error}
    }
  }
}
