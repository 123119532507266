import fetch from 'node-fetch';
import {ServicesErrors, UTMKeys, AppEnv, CreateAffidLink, AffidUuid, UserInfo} from '../../constants';
import {determineErrorLevelForSentry, delay, handleResponse} from '../../utils';
import * as Sentry from '@sentry/browser';

declare var APP_ENV: AppEnv;

export default class AffidService {
  private static instance: AffidService;
  // @ts-ignore
  _affid: Promise<string>;
  // @ts-ignore
  _resolve: (affid: string) => void;

  constructor() {
    this.setupPromise();
    this._makeRequest();
  }

  private setupPromise() {
    this._affid = new Promise((resolve: (affid: string) => void) => {
      this._resolve = resolve;
    });
  }

  getAffid = async (): Promise<string> => {
    return await this._affid;
  }

  _makeRequest = async (retryCount = 0): Promise<string> => {
    let LdrUrl = this.formLdrUrl();

    return fetch(LdrUrl)
      .then(async (resp: any) => {

        if (resp.status === 200) {
          let response = await handleResponse(resp);
          if (!this._resolve) {
            this._affid = Promise.resolve(response.affid);
            return Promise.resolve(response);
          }

          this._resolve(response.affid);
          return Promise.resolve(response);
        }

        if (resp.status == 401 || resp.status == 403) {
          await delay(retryCount);
          await this._makeRequest(retryCount)
          return;
        }

        if (resp.status >= 400 && resp.status < 500) {
          return Promise.reject(resp);
        }
      })
      .catch(error => {
        const errorLevel = determineErrorLevelForSentry(error);

        Sentry.configureScope((scope) => {
          scope.setLevel(errorLevel);
        });

        Sentry.addBreadcrumb({
          category: 'user',
          message: 'Endpoint ' + LdrUrl,
          data: {
            error: error,
          },
          level: errorLevel
        });
        Sentry.captureMessage(ServicesErrors.ldr.createAffid, errorLevel);
        this._resolve('');
        return Promise.resolve('');
      });
  }

  formLdrUrl = () => {
    let affidUuidFromStorage = sessionStorage.getItem(UserInfo.affidUuid);
    let affidUuid = AffidUuid[APP_ENV];

    if (affidUuidFromStorage) {
      affidUuid = affidUuidFromStorage;
    }

    let ldrUrl = CreateAffidLink(affidUuid);

    let extraParams = {
      medium: localStorage.getItem(UTMKeys.medium),
      campaign: localStorage.getItem(UTMKeys.campaign)
    };

    let tidExt = '';

    Object.entries(extraParams).forEach(([key, value]) => {
      if (!value) {
        return;
      }

      if (!tidExt.length) {
        tidExt = value;
        return;
      }

      tidExt = `${tidExt};${value}`;
    });

    if (tidExt.length) {
      ldrUrl = `${ldrUrl}&tid_ext=${tidExt}`
    }

    return ldrUrl;
  }

  public static getInstance(): AffidService {
    if (!AffidService.instance) {
      AffidService.instance = new AffidService();
    }

    return AffidService.instance;
  }
}
