export type SignupFormEventsType =
  'privacyPolicy'
  | 'eula'
  | 'consents'
  | 'login'
  | 'signup'
  | 'signupSuccess'
  | 'generalSignup'
  | 'signupError'
  | 'verificationEmailScreen'
  | 'open';

export const SignupEvents: Record<SignupFormEventsType, any> = {
  open: 'page__opened',
  privacyPolicy: 'page__privacy_policy_link_click',
  eula: 'page__eula_link_click',
  consents: 'page__consents_box_click',
  login: 'page__login_link_click',
  signup: 'page__create_account_button_click',
  signupSuccess: 'page__create_account_success',
  generalSignup: 'Signup',
  signupError: 'page__create_account_error',
  verificationEmailScreen: 'page__verification_email_sent_screen_opened',
}
