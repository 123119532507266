import {registration, signupUser} from '../store/actions';
import {RegisterUserBody} from '../services/user/userTypes';
import {History} from 'history';
import {WebCbt} from '../constants';

export function signup(user: RegisterUserBody, history: History, webcbt: string) {
  return (dispatch: any) => {
    switch (webcbt) {
      case WebCbt.waoo:
      case WebCbt.stackCommerce:
      case WebCbt.mkClarioMobile:
        dispatch(signupUser(user, history));
        break;
      default:
        dispatch(registration(user, history));
    }
  }
}
