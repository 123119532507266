import React, {FunctionComponent} from 'react';
import guid from '../../../../utils/guid';

type Props = {
  instructions: Array<string>,
};

const Instructions: FunctionComponent<Props> = (props: Props) => {
  const {instructions} = props;

  return (
    <ul className='download__instructions'>
      {instructions.map((instructionItem: string, index) => (
        <li className='download__instruction-item' key={guid()}>
          <span className='download__instruction-item-count'>{index + 1}.</span>
          {instructionItem}</li>
      ))}
    </ul>
  );
};

export default Instructions;
