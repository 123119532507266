import {ResetPasswordData} from './userTypes';
import {History} from 'history';
import {Routes} from '../../router/Routes';
import {authHeader} from '../../utils';
import fetch from 'node-fetch';
import {UserChangePasswordApi, ServicesErrors} from '../../constants';
import * as Sentry from '@sentry/browser';
import {ErrorType} from '../../store/actions/actionsTypes';
import {Severity} from '@sentry/types/dist/severity';
import {determineErrorLevelForSentry, handleResponse} from '../../utils';
import {AuthHeader} from '../../utils/authHeader';

export function resetPassword(userData: ResetPasswordData, history: History): Promise<string> {
  const {verificationCode, password} = userData;

  if (!verificationCode.length) {
    history.push(Routes.Forgot);
    return Promise.reject();
  }

  const authorizedHeader: AuthHeader = authHeader(verificationCode);
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      ...authorizedHeader,
    },
    credentials: 'include',
    body: JSON.stringify({password})
  };

  return fetch(UserChangePasswordApi, requestOptions)
    .then(handleResponse)
    .catch(error => {
      const errorLevel = determineErrorLevel(error);

      Sentry.configureScope((scope) => {
        scope.setLevel(errorLevel);
      })

      Sentry.addBreadcrumb({
        category: 'auth',
        message: 'Endpoint ' + UserChangePasswordApi,
        data: {
          error: error,
          verificationCode: userData.verificationCode,
        },
        level: errorLevel
      });
      Sentry.captureMessage(ServicesErrors.user.resetPasswordFailed, errorLevel);
      throw new Error(error.message);
    });
}

function determineErrorLevel(error: ErrorType): Severity {
  switch (error.status) {
    case 409:
      return Severity.Error;
    default:
      return determineErrorLevelForSentry(error);
  }
}
