import React, {FunctionComponent, useEffect} from 'react';
import Button from '../../../../components/Buttons/Button';
import {Events} from '../../../../services/events/events';
import {ApiErrors, DownloadLinksEvents, EventContext} from '../../../../constants';
import {RequestStatus} from '../../../../store/reducers/reducerType';
import {DownloadLinkCircleType} from '../../DownloadLinkCircle';

type Props = {
  sendDownloadLinks: (token: string) => void;
  downloadToken: string;
  handleContactSupport: (from: DownloadLinkCircleType) => void;
  sendDownloadLinksStatus: RequestStatus,
}

const LinkExpired: FunctionComponent<Props> = (props: Props) => {
  const handleResendDownloadLink = () => {
    Events.send({
      context: EventContext.downloadLinks,
      event: DownloadLinksEvents.resend_button_click,
    });
    props.sendDownloadLinks(props.downloadToken);
  }

  useEffect(() => {
    handleSendDownloadLinksStatus();
  }, [props.sendDownloadLinksStatus]);

  const handleSendDownloadLinksStatus = () => {
    const {sendDownloadLinksStatus} = props;
    const {status, error} = sendDownloadLinksStatus;

    if (status || error === ApiErrors.sent_download_links_has_max_verify_attempts) {
      startDownloadSendAnimation();
      return;
    }
  }

  const startDownloadSendAnimation = () => {
    const downloadLinkPageElement = document.getElementById('download-link-expired-page');
    if (!downloadLinkPageElement) {
      return;
    }
    downloadLinkPageElement.classList.add('animate-to-sent');
  }

  return (
    <div className='download-link-circle__content-wrapper download-link-circle__content-wrapper--link-expired'>
      <div className='download-link-circle__content'>
        <h3 className='download-link-circle__title download-link-circle__title--expired'>Oops, your link has expired.</h3>
        <div className='download-link-circle__text'>
          <span>For security reasons, your Clario download link works for 24 hours and can be used only once. Get a new one?</span>
        </div>
        <div className='button-wrapper download-link-circle__button-wrapper'>
          <Button className='download-link-circle__button button--primary button--green' action={handleResendDownloadLink} title='Request another link'/>
        </div>
        <footer className='download-link-circle__footer download-link-circle-footer'>
          <div className='download-link-circle__text'>{`Any problems? `}
            <p className='download-link-circle__text download-link-circle__text--bold download-link-circle__text--support'
               onClick={() => props.handleContactSupport(DownloadLinkCircleType.tokenExpired)}>Let us know.</p>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default LinkExpired;
