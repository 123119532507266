import React from 'react';
import ReactDOM from 'react-dom';
import './style.scss';
import {Router} from './router';
import * as serviceWorker from './utils/serviceWorker';
import {Provider} from 'react-redux';
import store from './store';
import * as Sentry from '@sentry/browser';
import {AppEnv, IgnoreErrors, SentryDns} from './constants';
import {GoogleOAuthProvider} from '@react-oauth/google';

declare var APP_ENV: string;
declare var RELEASE_ID: string;
declare const GOOGLE_CLIENT_ID: string;

if (APP_ENV !== AppEnv.testing) {
    try {
        Sentry.init({
            ignoreErrors: IgnoreErrors,
            dsn: SentryDns,
            release: RELEASE_ID,
            maxBreadcrumbs: 50,
            environment: APP_ENV,
            attachStacktrace: true,
            beforeSend(event, hint: { originalException: Error }) {
                const error = hint.originalException;

                // Prevent send error message to Sentry that Zchat is closed
                if (error && error.message && error.message.includes('Cannot read property \'pause\' of null')) {
                    return null;
                }

                return event;
            },
        });
    } catch (error) {
        console.log('Error while sentry init', error);
    }
}

ReactDOM.render(
    <React.StrictMode>
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
            <Provider store={store}>
                <Router />
            </Provider>
        </GoogleOAuthProvider>
    </React.StrictMode>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
serviceWorker.unregister();
