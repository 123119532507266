import {History} from 'history';
import {Dispatch} from 'redux';
import {LogoutFailureReduxAction, LogoutRequestReduxAction, LogoutSuccessReduxAction} from './userActionsTypes';
import {userService} from '../../../services/user/user';
import {Routes} from '../../../router/Routes';
import {Affid, UserConstants, UserInfo, WebCbt} from '../../../constants';
import {removeUserSessionData} from './userActions';

export function logout(history: History) {
  return (dispatch: Dispatch<LogoutRequestReduxAction | LogoutSuccessReduxAction | LogoutFailureReduxAction>) => {
    dispatch(request());

    userService.logout(history)
      .then(
        () => {
          processingUserSessionData();
          dispatch(successLogout());
          const event = new CustomEvent('logoutSuccess', {
            detail: {
              email: '',
              location: Routes.Login,
            }
          });
          document.dispatchEvent(event);
          history.push(Routes.Main);
        },
        error => {
          dispatch(failure(error));
        }
      );

    function request(): LogoutRequestReduxAction {
      return {type: UserConstants.LOGOUT_REQUEST, payload: {}}
    }

    function failure(error: Error): LogoutFailureReduxAction {
      return {type: UserConstants.LOGOUT_FAILURE, payload: error}
    }
  }
}

export function successLogout(): LogoutSuccessReduxAction {
  return {type: UserConstants.LOGOUT_SUCCESS, payload: {}}
}

function processingUserSessionData() {
  let webCbt = sessionStorage.getItem(UserInfo.webcbt) || WebCbt.clario;

  switch (webCbt) {
    case WebCbt.stackCommerce:
      removeUserSessionData();
      break;
    default:
  }
}
