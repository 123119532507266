// Actions names

export const PartnersActions = {
  VERIFY_DOWNLOAD_TOKEN_REQUEST: 'VERIFY_DOWNLOAD_TOKEN_REQUEST',
  VERIFY_DOWNLOAD_TOKEN_SUCCESS: 'VERIFY_DOWNLOAD_TOKEN_SUCCESS',
  VERIFY_DOWNLOAD_TOKEN_FAILURE: 'VERIFY_DOWNLOAD_TOKEN_FAILURE',

  SEND_DOWNLOADS_LINKS_REQUEST: 'SEND_DOWNLOADS_LINKS_REQUEST',
  SEND_DOWNLOADS_LINKS_SUCCESS: 'SEND_DOWNLOADS_LINKS_SUCCESS',
  SEND_DOWNLOADS_LINKS_FAILURE: 'SEND_DOWNLOADS_LINKS_FAILURE',

  VERIFY_SMART_LINK_TOKEN_REQUEST: 'VERIFY_SMART_LINK_TOKEN_REQUEST',
  VERIFY_SMART_LINK_TOKEN_SUCCESS: 'VERIFY_SMART_LINK_TOKEN_SUCCESS',
  VERIFY_SMART_LINK_TOKEN_FAILURE: 'VERIFY_SMART_LINK_TOKEN_FAILURE',

  SET_PARTNER_NAME: 'SET_PARTNER_NAME',
  SET_WEB_CBT: 'SET_WEB_CBT',
};

export const WebCbt = {
  clario: '0',
  waoo: '1',
  stackCommerce: '2',
  mkClarioMobile: '3'
}

export const WebCbtFlowNames: any = {
  [WebCbt.clario]: 'clario',
  [WebCbt.waoo]: 'waoo',
  [WebCbt.stackCommerce]: 'stackCommerce',
  [WebCbt.mkClarioMobile]: 'mkClarioMobile'
}

declare var API_USER_HOST: string;

// Api path
export const VerifyDownloadLinkTokenApi = `${API_USER_HOST}/v1/autologin/check-token`;
export const SendDownloadLinksApi = `${API_USER_HOST}/v1/autologin/email/send-download-links`;
export const VerifySmartLinkTokenApi = `${API_USER_HOST}/v1/partner/smart-link/validate`;
export const SignupUserApi = `${API_USER_HOST}/v1/partner/customer-signup`;
