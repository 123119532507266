import {EmailVerification, UserConstants} from '../../constants';
import {RequestStatus} from './reducerType';

type State = {
  sendVerificationCodeStatus: RequestStatus,
  checkVerificationCodeStatus: RequestStatus,
};

const defaultState: State = {
  sendVerificationCodeStatus: {
    status: false,
    error: '',
  },
  checkVerificationCodeStatus: {
    status: false,
    error: '',
  }
};

export default (state = defaultState, action: any): State => {
  switch (action.type) {
    case EmailVerification.SEND_EMAIL_VERIFICATION_REQUEST:
      return {
        ...state,
        sendVerificationCodeStatus: {
          status: false,
          error: '',
        }
      };
    case EmailVerification.SEND_EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        sendVerificationCodeStatus: {
          status: true,
          error: '',
        }
      };
    case EmailVerification.SEND_EMAIL_VERIFICATION_FAILURE:
      return {
        ...state,
        sendVerificationCodeStatus: {
          status: false,
          error: action.payload.message,
        }
      };
    case EmailVerification.CHECK_VERIFICATION_EMAIL_CODE_REQUEST:
      return {
        ...state,
        checkVerificationCodeStatus: {
          status: false,
          error: '',
        }
      };
    case EmailVerification.CHECK_VERIFICATION_EMAIL_CODE_SUCCESS:
      return {
        ...state,
        checkVerificationCodeStatus: {
          status: true,
          error: '',
        }
      };
    case EmailVerification.CHECK_VERIFICATION_EMAIL_CODE_FAILURE:
      return {
        ...state,
        checkVerificationCodeStatus: {
          status: false,
          error: action.payload.message,
        }
      };
    case UserConstants.LOGOUT_SUCCESS:
      return defaultState;
    default:
      return state;
  }
}
