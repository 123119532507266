import React, {FunctionComponent, useEffect} from 'react';
import './Mobile.scss';
import QRCodeComponent from '../../../components/QRCode/QRCode';
import {
  DesktopAppLink,
  EventContext,
  MKClarioDownload,
} from '../../../constants';
import {Events} from '../../../services/events/events';
import {useDownloadLinks} from '../../../hooks';

const Mobile: FunctionComponent = () => {
  const {mobileDownloadLink} = useDownloadLinks();
  
  useEffect(() => {
    Events.send({
      context: EventContext.downloadLink,
      event: MKClarioDownload.open,
    });
  }, []);

  const handleDownload = () => {
    Events.send({
      context: EventContext.downloadLink,
      event: MKClarioDownload.download,
    });
    window.location.href = mobileDownloadLink;
  }

  return (
    <>
      <div className='download-page__content download-mobile-page'>
        <h2 className='download-page__title download-mobile-page__title'>Thanks and welcome to Clario!</h2>
        <div className='download-mobile__download download-mobile'>
          <div className='download-mobile__content'>
            <h3 className='download-mobile__title'>Download for Mobile</h3>
            <div className='download-mobile__info-wrapper'>
              <aside className='download-mobile__image-wrapper'>
                <img src='./images/image-mobile-screen@2x.png' alt='device image' className='download-mobile__image'/>
              </aside>
              <aside className='download-mobile__qr-info'>
                <p className='download-mobile__description'>
                  <span className='download-mobile__text download-mobile__text--bold'>Scan this QR code <br/> and download Clario</span>
                  <span className='download-mobile__text'> from AppStore or Play Market and install it on your device.</span>
                </p>
                <div className='download-mobile__qr-wrapper'>
                  <QRCodeComponent link={mobileDownloadLink} className='download-mobile__qr' size={108}/>
                </div>
              </aside>
            </div>
          </div>
        </div>
        <div className='download-mobile__button-section'>
          <div className='button-wrapper device-download__button-wrapper'>
            <button className='button button--primary button--green device-download__button' onClick={handleDownload}>
              Download Clario
              <img src='./images/icon-downloads.svg' alt='Download icon' className='device-download__button-icon'/>
            </button>
          </div>
          <div className='download-mobile__description'>
            <p className='download-mobile__text'>{`Already installed it? Just `}
              <a href={DesktopAppLink.mainScreen} className='download-mobile__text download-mobile__text--highlighted'>open Clario</a>
              {` on your device.`}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default (Mobile);
