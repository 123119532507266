import {SSOStateType} from './types';

export const DefaultSSOState: SSOStateType = {
    appleAuthRedirectLink: null,
    appleAuthRedirectLinkStatus: {
        status: false,
        error: '',
    },
    googleAuthStatus: {
        status: false,
        error: '',
    },
}