import {AppEnv} from './envConstants';

declare var APP_ENV: string;

export enum WebToCaseSurveyType {
  deleteAccount = 'deleteAccount',
}

export type WebToCaseSurveyDataType = { [name in WebToCaseSurveyType]: SurveyConfigType };

export type SurveyConfigType = {
  name: WebToCaseSurveyType,
  orgid: string,
  recordType: string,
  type: string,
  formId: string,
  fieldId: string
}

export const WebToCaseSurveyData = (surveyType: WebToCaseSurveyType): SurveyConfigType => {
  return APP_ENV == AppEnv.production ? WebToCaseSurveyDataProduction[surveyType] : WebToCaseSurveyDataStaging[surveyType];
};

export const WebToCaseSurveyDataProduction: WebToCaseSurveyDataType = {
  [WebToCaseSurveyType.deleteAccount]: {
    name: WebToCaseSurveyType.deleteAccount,
    orgid: '00D0Y000000Zhzp',
    recordType: '0121v000000z0uJ',
    type: 'GDPR Survey',
    formId: '00N0Y00000OPbpi',
    fieldId: '00N1v00000T38qw'
  }
};

export const WebToCaseSurveyDataStaging: WebToCaseSurveyDataType = {
  [WebToCaseSurveyType.deleteAccount]: {
    name: WebToCaseSurveyType.deleteAccount,
    orgid: '00D1q0000000VaS',
    recordType: '0121v000000z0uJ',
    type: 'GDPR Survey',
    formId: '00N0Y00000OPbpi',
    fieldId: '00N1v00000T38qw'
  }
};
