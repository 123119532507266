// @ts-nocheck
import {createStore} from 'redux';
import Reducers from './reducers';
import Middleware from './middleware';

const store = createStore(
  Reducers,
  Middleware,
);

//Add access fot Cypress to Store
if (window.Cypress) {
  window.store = store;
}

export default store;
