import {DeviceStateType} from './types';

export const DefaultDeviceState: DeviceStateType = {
    devicesList: null,
    deviceListStatus: {
        status: false,
        error: '',
    },
    devicesStatus: null,
    disconnectDeviceStatus: null,
    currentAssetDevices: null,
}