import React, {FunctionComponent, useEffect} from 'react';
import DeviceItemPopup from '../DeviceItemPopup';
import Button from '../../../../../components/Buttons/Button';
import './DisconnectCongratulation.scss';
import {Events} from '../../../../../services/events/events';
import {DevicesEvents, EventContext, DeviceItemScreenType} from '../../../../../constants';
import {EnrichedDevice} from '../../../../../services/device/deviceTypes';

type Props = {
  hideModal: () => void;
  resetDisconnectDeviceStatus: (deviceId: string) => void;
  setUpdateDataFromApi: (value: boolean) => void;
  currentAssetDevices: Array<EnrichedDevice> | null,
}

const DisconnectCongratulation: FunctionComponent<Props> = (props: Props) => {
  useEffect(() => {
    sendAppearEvent();
  }, []);

  const determineDevice = (): EnrichedDevice | null | undefined => {
    const {currentAssetDevices} = props;

    if (!currentAssetDevices || !currentAssetDevices.length) {
      return null;
    }

    return currentAssetDevices.find((device: EnrichedDevice) => device.screen_type === DeviceItemScreenType.disconnectCongratulation);
  };

  const sendAppearEvent = (): void => {
    const device = determineDevice();

    if (!device) {
      return;
    }

    Events.send({
      context: EventContext.devices,
      event: DevicesEvents.detailed_area__disconnect_success_area_shown,
      data: {deviceId: device.id}
    })
  };

  const hideModal = () => {
    const {resetDisconnectDeviceStatus} = props;
    const device = determineDevice();
    props.hideModal();
    if (!device) {
      return;
    }

    resetDisconnectDeviceStatus(device.id);
  };

  const handleBackToAccount = () => {
    const device = determineDevice();
    props.setUpdateDataFromApi(false);
    hideModal();

    if (!device) {
      return;
    }

    Events.send({
      context: EventContext.devices,
      event: DevicesEvents.detailed_area__disconnect_success_back_button_click,
      data: {deviceId: device.id}
    })
  };

  return (
    <DeviceItemPopup>
      <h3 className='devices__block-title disconnect-congratulation__title'>Device disconnected.</h3>
      <div className='device-item-popup__text-wrapper disconnect-congratulation__text-wrapper'>
        <p className='devices__text'>Just log in to the Clario app on this device to re-connect it.</p>
      </div>
      <div className='button-wrapper disconnect-congratulation__button-wrapper'>
        <Button
          type='button'
          className='button--secondary'
          action={handleBackToAccount}
          title='Back to account'/>
      </div>
    </DeviceItemPopup>
  );
};
export default DisconnectCongratulation;
