import React, {useEffect, useState} from 'react';
import Section from '../../../containers/Section';
import {DashboardEvents, EventContext, IconType, InnerContentPopups} from '../../../constants';
import {Events} from '../../../services/events/events';
import {SectionInfo, SectionStatus} from '../Main'
import {openModal} from '../../../store/actions';
import Loader from '../../../components/Loader';
import {useHistory} from 'react-router-dom';
import {ModalContentType} from '../../../popups/Modal/ModalTypes';
import {SectionNames} from '../../../containers/Section/Section';
import {DeleteAccountRequestStatus} from '../../../store/actions/cde/cdeTypes';
import {useDispatch, useSelector} from 'react-redux';
import {selectUserInfo} from '../../../store/reducers/user';
import {selectDeleteAccountStatus} from '../../../store/reducers/cde';

type ProfileStatus = {
  status: SectionStatus,
  description: string,
  sectionInfo: SectionInfo,
  button: {
    title: string,
    action: () => void;
  }
}

const ProfileSectionStatuses = {
  setUp: 'All set',
  notFullySetUp: 'Not fully set up',
  willBeDeleted: 'Will be deleted.',
}

const ProfileSection = () => {
  const history = useHistory();
  const userInfo = useSelector(selectUserInfo);
  const dispatch = useDispatch();
  const deleteAccountStatus = useSelector(selectDeleteAccountStatus);
  const [profileStatus, setProfileStatus] = useState<ProfileStatus>();

  useEffect(() => {
    handleProfileStatus();
  }, []);

  useEffect(() => {
    handleProfileStatus();
  }, [deleteAccountStatus]);

  // Handle open popup from direct link
  useEffect(() => {
    const locationState: any = history.location.state;

    if (locationState != null && Object.keys(locationState).length && locationState.modalPopup != null) {
      const {contentType, innerContentPopup} = locationState.modalPopup;
      if (contentType === ModalContentType.profile) {
        dispatch(openModal(contentType, innerContentPopup));
        return;
      }
    }

  }, [history.location.state]);


  const handleManageProfile = () => {
    Events.send({context: EventContext.mainPage, event: DashboardEvents.profile_area__manage_button_click_to_popup});
    dispatch(openModal(ModalContentType.profile, InnerContentPopups.profile));
  };

  const handleCancelDeleteAccount = () => {
    dispatch(openModal(ModalContentType.profile, InnerContentPopups.cancelDeleteAccountForm));
  }

  const handleProfileStatus = (): void => {
    // GDPR Delete account has the highest priority
    if (deleteAccountStatus === DeleteAccountRequestStatus.newApplication || deleteAccountStatus === DeleteAccountRequestStatus.inQueue) {
      setProfileStatus({
        status: {name: ProfileSectionStatuses.willBeDeleted, active: false},
        description: 'Not sure? You can still change your mind.',
        sectionInfo: {text: 'Your profile', bold: 'will be deleted'},
        button: {title: 'Get my account back', action: handleCancelDeleteAccount}
      });
      return;
    }

    if (!userInfo?.is_email_verified) {
      setProfileStatus({
        status: {name: ProfileSectionStatuses.notFullySetUp, active: false},
        description: 'Just verify your email address and you’re all done.',
        sectionInfo: {text: 'You just need to verify your email'},
        button: {title: 'Manage', action: handleManageProfile}
      });
      return;
    }

    setProfileStatus({
      status: {name: ProfileSectionStatuses.setUp, active: true},
      description: 'Your account profile is complete. You’re good to go!',
      sectionInfo: {text: 'Your profile is', bold: 'ready'},
      button: {title: 'Manage', action: handleManageProfile}
    })
  };

  if (profileStatus == null) {
    return (
      <section className='section slide__section'>
        <Loader/>
      </section>
    )
  }

  return (
    <Section
      name={SectionNames.profile}
      title='Profile'
      iconType={IconType.profile}
      status={profileStatus?.status}
      description={profileStatus?.description}
      sectionInfo={profileStatus?.sectionInfo}
      button={profileStatus?.button}
    />
  );
};

export default ProfileSection;
