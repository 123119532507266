import * as React from 'react';
import './Subtitle.scss';

interface Props {
  text: string,
  className?: string,
  highlightedText?: string
}

const Subtitle: React.FunctionComponent<Props> = (props: Props) => {
  const {text} = props;
  return (
    <h3 className={`subtitle animate ${props.className ? props.className : ''}`} data-delay={500}>
      {props.highlightedText ? <span className='subtitle--bold'>{props.highlightedText} </span> : null}
      {text}
    </h3>
  );
};


export default Subtitle;
