export type RegisterUserBody = {
  email: string,
  password: string,
  name: string,
  device_id: string,
  consents?: ConsentsType,
};

export type ConsentsType = {
  marketing_name: boolean,
  app_statistics: boolean,
  marketing_emails: boolean,
  marketing_notifications: boolean,
}

export type LoginUserBody = {
  email: string,
  password: string,
  device_id: string,
}

export type LoginInfo = {
  email: string,
  device_id?: string,
}

export type ChangePasswordUserBody = {
  previous_password: string,
  password: string,
}

export type ForgotPasswordUserBody = {
  email: string,
  device_id: string,
}

export type ResetPasswordData = ResetPasswordUserBody & {
  verificationCode: string,
}

export type ResetPasswordUserBody = {
  password: string,
}

type PersonalizationData = {[key: string]: any};

export enum PartnerName {
  clario = 'clario',
  waoo = 'waoo',
  stackCommerce = 'stackCommerce'
}

export type GetUserInfo = {
  id: string,
  name: string,
  email: string,
  affid: string,
  cbt: number | string,
  web_cbt: number,
  partner_name: string,
  partner_slug: PartnerName,
  personalization: PersonalizationData,
  is_email_verified?: boolean,
  consents: {
    marketing_name: boolean,
    app_statistics: boolean,
    marketing_emails: boolean,
  }
}

export type CheckVerificationCodeResponse = {
  access_token: string,
  token_type: string,
  expires_in: number,
}

export type VerificationData = {
  code: string,
  device_id: string,
}

export type DownloadLinksType = {
  ios: string,
  macos: string,
  android: string
}