import React, {FunctionComponent, useCallback, useEffect, useMemo} from 'react';
import {Events} from '../../services/events/events';
import './Download.scss';
import {
  DownloadLinksEvents,
  EventContext,
  UIBreakPoints,
  DownloadSystemLink,
  MobileDownloadLink
} from '../../constants';
import ContentWrapper from '../../containers/ContentWrapper';
import RoundedArea from '../../components/RoundedArea';
import {useLocation} from 'react-router-dom';
import withServerErrorHandlingPage from '../../hoc/ServerErrorHandlingPage';
import {StoreTypes} from '../../components/DeviceDownloadBlock/Mobile/MobileDownloadBlock';
import {determineDownloadLink} from '../../store/actions';
import {useSelector} from 'react-redux';
import {selectAutoLoginParamsData} from '../../store/reducers/user';
import {AutoLoginParamsInterface} from '../../services/user/getAutoLoginParams';

const DefaultDownloadLinks = {
  ios: MobileDownloadLink,
  macos: DownloadSystemLink,
  android: MobileDownloadLink,
  redirect: DownloadSystemLink,
}

const DefaultLocationState = {
  downloadLinks: DefaultDownloadLinks
}

const DownloadInstruction: FunctionComponent = () => {
  const location = useLocation();
  const autoLoginParamsData = useSelector(selectAutoLoginParamsData);
  const mobileDownloadLink = useMemo(() => {
    return determineDownloadLink<AutoLoginParamsInterface | null>(MobileDownloadLink, autoLoginParamsData);
  }, [autoLoginParamsData]);
  const {state = DefaultLocationState} = location;
  const {downloadLinks = DefaultDownloadLinks} = state as any;

  useEffect(() => {
    Events.send({
      context: EventContext.downloadLinks,
      event: DownloadLinksEvents.download_instruction_show,
    });
  }, []);

  const redirectToDownLoadSystem = () => {
    window.open(downloadLinks.redirect, '_blank');
    window.focus();
    return;
  }


  useEffect(() => {
    redirectToDownLoadSystem();
  }, []);

  const handleDownloadMobileClick = useCallback(() => {
    if (window.innerWidth > UIBreakPoints.tablet.max) {
          return;
        }

    window.open(determineDownloadLink(mobileDownloadLink), '_blank');
  }, []);
  
  return (
    <ContentWrapper>
      <div className='download-instruction animate-in'>
        <section className='download-instruction__section'>
          <div className='download-instruction__greeting'>
            <h2 className='download-instruction__title download-instruction__title--download-instruction'>
              <span className='download-instruction__title download-instruction__title--separate'>Awesome!</span> You’re just a few clicks away from a better, safer, digital life.
            </h2>
            <RoundedArea className='download-instruction__manual-download'>
              <p className='download-instruction__text'>Your download should begin automatically. Didn't work?
                <a href={downloadLinks.redirect} className='download-instruction__link text__link'>
                  Download again
                  <span className='download-instruction__text'>.</span>
                </a>
              </p>
            </RoundedArea>
            <div className='download-instruction__separator'/>
          </div>
          <div className='download-instruction__instructions download-instruction'>
            <h3 className='download-instruction__title'>Let's finish installing:</h3>
            <ul className='download-instruction__list'>
              <li className='download-instruction__item'>
                <div className='download-instruction__number'>
                  <div className='download-instruction__number-circle'>01</div>
                </div>
                <div className='instruction-step'>
                  <h5 className='instruction-step__title'>Open the file</h5>
                  <p className='instruction-step__description download-instruction__text'>Click on the downloaded file to open it. (called Clario.pkg).</p>
                </div>
              </li>
              <li className='download-instruction__item'>
                <div className='download-instruction__number'>
                  <div className='download-instruction__number-circle'>02</div>
                </div>
                <div className='instruction-step'>
                  <h5 className='instruction-step__title'>Follow the onscreen instructions</h5>
                  <p className='instruction-step__description download-instruction__text'>Installation will begin, and the Clario will automatically launch once installation is
                    complete.</p>
                </div>
              </li>
              <li className='download-instruction__item'>
                <div className='download-instruction__number'>
                  <div className='download-instruction__number-circle'>03</div>
                </div>
                <div className='instruction-step instruction-step--stores'>
                  <h5 className='instruction-step__title'>Protect your mobile device, too</h5>
                  <div className='instruction-step__description-wrapper'>
                    <p className='instruction-step__description download-instruction__text'>Download Clario for your iOS or Android</p>
                    <div className='instruction-step__store-buttons'>
                      <img src='./images/store/icon-app-store-black.png' className='download-instruction__source-button' data-name={StoreTypes.ios} onClick={handleDownloadMobileClick}/>
                      <img src='./images/store/icon-google-play-black.png' className='download-instruction__source-button' data-name={StoreTypes.android} onClick={handleDownloadMobileClick}/>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </section>
        <section className='download-instruction__section download-benefits'>
          <div className='download-benefits__content'>
            <img src='./images/emotions/happiness-confetti-girl.png' className='download-benefits__image'/>
            <div className='download-benefits__description'>
              <h2 className='download-instruction__title download-benefits__title'>Your digital life just got a major upgrade!</h2>
              <ul className='download-benefits__list'>
                <li className='download-benefits__item download-instruction__text'>
                  600+ trained experts available 24/7 to help you where software can’t.
                </li>
                <li className='download-benefits__item download-instruction__text'>
                  Peace of mind wherever, whenever, or, however you connect.
                </li>
                <li className='download-benefits__item download-instruction__text'>
                  Personalized control of the 6 key aspects of your digital world from one easy to use place.
                </li>
              </ul>
              <p className='download-benefits__conclusion'>Sounds good? Wait till you try it. Open the app and start exploring! Need any help, just give us a shout.</p>
            </div>
          </div>
        </section>
      </div>
    </ContentWrapper>)
};

export default React.memo(withServerErrorHandlingPage(DownloadInstruction) as any);
