import * as React from 'react';
import './Title.scss';

interface Props {
  text: string,
  className?: string,
}

const Title: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <h3 className={`title animate ${props.className || '' }`}>{props.text}</h3>
  );
};

export default Title;
