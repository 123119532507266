import fetch from 'node-fetch';
import {ServicesErrors, FirebaseShortenUrlApi} from '../../constants';
import {handleResponse, determineErrorLevelForSentry} from '../../utils';
import * as Sentry from '@sentry/browser';
import {ShortenUrlResponse} from './firebaseTypes';

export function shortenUrl(link: string): Promise<ShortenUrlResponse> {
  let requestBody = {
    longDynamicLink: link,
    suffix: {
      option: 'SHORT'
    }
  }

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestBody)
  };

  return fetch(FirebaseShortenUrlApi, requestOptions)
    .then(handleResponse)
    .catch(error => {
      const errorLevel = determineErrorLevelForSentry(error);

      Sentry.configureScope((scope) => {
        scope.setLevel(errorLevel);
      });

      Sentry.addBreadcrumb({
        category: 'user',
        message: 'Endpoint ' + FirebaseShortenUrlApi,
        data: {
          error: error,
        },
        level: errorLevel
      });
      Sentry.captureMessage(ServicesErrors.firebase.shortenUrlFailed, errorLevel);
      return Promise.reject('');
    });
}
