import {AppleAuthRedirectLinkResponse, GoogleAuthResponseType} from './ssoTypes';
import {ServicesErrors, GoogleAuthWebRequestApi} from '../../constants';
import {determineErrorLevelForSentry, getAffid, getDeviceId, handleResponse} from '../../utils';
import fetch from 'node-fetch';
import * as Sentry from '@sentry/browser';

export async function sendGoogleCredentials(token: string): Promise<AppleAuthRedirectLinkResponse> {
  let responseToBack = {
    access_token: token,
    device_id: await getDeviceId(),
    affid: await getAffid(),
  }

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(responseToBack)
  };

  return fetch(GoogleAuthWebRequestApi, requestOptions)
    .then(handleResponse)
    .catch(error => {
      const errorLevel = determineErrorLevelForSentry(error);
      Sentry.configureScope((scope) => {
        scope.setLevel(errorLevel);
      });

      Sentry.addBreadcrumb({
        category: 'sso',
        message: 'Endpoint ' + GoogleAuthWebRequestApi,
        data: {
          error: error,
        },
        level: errorLevel,
      });
      Sentry.captureMessage(ServicesErrors.sso.google.webRequest, errorLevel);
      throw new Error(error.message);
    });
}
