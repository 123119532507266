export const UserConstants = {
  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  CHANGE_PASSWORD_REQUEST: 'USERS_CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'USERS_CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'USERS_CHANGE_PASSWORD_FAILURE',

  CHANGE_USER_NAME_REQUEST: 'CHANGE_USER_NAME_REQUEST',
  CHANGE_USER_NAME_SUCCESS: 'CHANGE_USER_NAME_SUCCESS',
  CHANGE_USER_NAME_FAILURE: 'CHANGE_USER_NAME_FAILURE',

  CHANGE_CONSENTS_REQUEST: 'CHANGE_CONSENTS_REQUEST',
  CHANGE_CONSENTS_SUCCESS: 'CHANGE_CONSENTS_SUCCESS',
  CHANGE_CONSENTS_FAILURE: 'CHANGE_CONSENTS_FAILURE',

  FORGOT_REQUEST: 'USERS_FORGOT_REQUEST',
  FORGOT_SUCCESS: 'USERS_FORGOT_SUCCESS',
  FORGOT_FAILURE: 'USERS_FORGOT_FAILURE',

  GET_USER_INFO_REQUEST: 'GET_USER_INFO_REQUEST',
  GET_USER_INFO_SUCCESS: 'GET_USER_INFO_SUCCESS',
  GET_USER_INFO_FAILURE: 'GET_USER_INFO_FAILURE',

  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

  CHECK_VERIFICATION_CODE_REQUEST: 'CHECK_VERIFICATION_CODE_REQUEST',
  CHECK_VERIFICATION_CODE_SUCCESS: 'CHECK_VERIFICATION_CODE_SUCCESS',
  CHECK_VERIFICATION_CODE_FAILURE: 'CHECK_VERIFICATION_CODE_FAILURE',

  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',

  GENERATE_DOWNLOAD_LINKS_REQUEST: 'GENERATE_DOWNLOAD_LINKS_REQUEST',
  GENERATE_DOWNLOAD_LINKS_SUCCESS: 'GENERATE_DOWNLOAD_LINKS_SUCCESS',
  GENERATE_DOWNLOAD_LINKS_FAILURE: 'GENERATE_DOWNLOAD_LINKS_FAILURE',

  SET_LOGIN_EMAIL: 'SET_LOGIN_EMAIL',
  CHANGE_LOGOUT_IN_PROCESS_STATE: 'CHANGE_LOGOUT_IN_PROCESS_STATE',
};

export const TestingAffid = 'c62d3380-7e61-11ea-ac26-0623145bc800-mzb';
