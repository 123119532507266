import React, {ChangeEvent, FocusEvent, ReactElement} from 'react';
import ErrorIcons from '../Icons/ErrorIcons';
import SucceededIcons from '../Icons/SucceededIcons';
import {InputProps} from './Input';

type Props = InputProps & {
  value: string;
  onChange: (ev: ChangeEvent<HTMLInputElement>, name: string) => void;
  onBlur: (ev: FocusEvent<HTMLInputElement>) => void;
}

const InputDefault = React.forwardRef((props: Props, ref: React.Ref<HTMLInputElement>):
  ReactElement => {
    const {name, type, placeholder, errors, succeeded, value, onChange, onBlur} = props;

    return (
      <>
        <input
          className={`form__input ${props.errors ? 'form__input--error' : props.succeeded ? 'form__input--succeeded' : ''}`}
          id={name}
          name={name}
          value={value}
          type={type}
          placeholder={placeholder}
          ref={ref}
          autoComplete='off'
          spellCheck={false}
          onChange={(ev) => onChange(ev, name)}
          onBlur={(ev) => onBlur(ev)}
          data-hj-suppress
          tabIndex={2}
        />
        {errors && <ErrorIcons/>}
        {succeeded && <SucceededIcons/>}
      </>
    )
  }
  )
;


export default InputDefault;
