export const AssetsConstants = {
  GET_ASSETS_LIST_REQUEST: 'GET_ASSETS_LIST_REQUEST',
  GET_ASSETS_LIST_SUCCESS: 'GET_ASSETS_LIST_SUCCESS',
  GET_ASSETS_LIST_FAILURE: 'GET_ASSETS_LIST_FAILURE',

  CANCEL_SUBSCRIPTION_REQUEST: 'CANCEL_SUBSCRIPTION_REQUEST',
  CANCEL_SUBSCRIPTION_SUCCESS: 'CANCEL_SUBSCRIPTION_SUCCESS',
  CANCEL_SUBSCRIPTION_FAILURE: 'CANCEL_SUBSCRIPTION_FAILURE',

  RENEW_SUBSCRIPTION_REQUEST: 'RENEW_SUBSCRIPTION_REQUEST',
  RENEW_SUBSCRIPTION_SUCCESS: 'RENEW_SUBSCRIPTION_SUCCESS',
  RENEW_SUBSCRIPTION_FAILURE: 'RENEW_SUBSCRIPTION_FAILURE',

  CHECK_TRIAL_REQUEST: 'CHECK_TRIAL_REQUEST',
  CHECK_TRIAL_SUCCESS: 'CHECK_TRIAL_SUCCESS',
  CHECK_TRIAL_FAILURE: 'CHECK_TRIAL_FAILURE',

  SET_CURRENT_ASSET: 'SET_CURRENT_ASSET',
};

export const ActiveStatusFromApi = 'active';
export const DeactivatedStatusFromApi = 'deactivated';
export const HoldStatusFromApi = 'hold';
export const FinishedStatusFromApi = 'finished';

export const PlanTypes = {
  trial: 'Free Trial',
  month: 'Monthly',
  year: 'Yearly',
  freeTrial: 'Clario free trial'
};

export enum AssetsStatusesMap {
  active = 'active',
  deactivated = 'active',
  hold = 'active',
  finished = 'canceled',
  no_subscription = 'missing',
}

export const AssetRefreshRequiredError = 'asset_refresh_required';
export const EmptyAssetId = 'empty_asset_id';
export const CanceledStatus = 'canceled';

export const AssetDuration = {
  monthly: 1,
  yearly: 12,
};
