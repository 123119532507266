export * from './appleMobileDeviceTypes';

export const DeviceConstants = {
  GET_DEVICES_LIST_REQUEST: 'GET_DEVICES_LIST_REQUEST',
  GET_DEVICES_LIST_SUCCESS: 'GET_DEVICES_LIST_SUCCESS',
  GET_DEVICES_LIST_FAILURE: 'GET_DEVICES_LIST_FAILURE',

  DISCONNECT_DEVICE_REQUEST: 'DISCONNECT_DEVICE_REQUEST',
  DISCONNECT_DEVICE_SUCCESS: 'DISCONNECT_DEVICE_SUCCESS',
  DISCONNECT_DEVICE_FAILURE: 'DISCONNECT_DEVICE_FAILURE',

  SET_CURRENT_ASSET_DEVICES: 'SET_CURRENT_ASSET_DEVICES',

  SET_DEVICES_STATUS: 'SET_DEVICES_STATUS',
  SET_DEVICE_ITEM_SCREEN_TYPE: 'SET_DEVICE_ITEM_SCREEN_TYPE',
};

export const SystemTypeName = {
  ios: 'ios',
  android: 'android',
  macos: 'macos',
  unknown: ''
};

export enum DeviceItemScreenType {
  deviceInfo = 'deviceInfo',
  disconnectConfirm = 'disconnectConfirm',
  disconnectCongratulation = 'disconnectCongratulation',
}

export enum SystemType {
  desktop = 'desktop',
  mobile = 'mobile',
  unknown = '',
}

export const DesktopDeviceModels = {
  macbookair: 'MacBook Air',
  macbookpro: 'MacBook Pro',
  imacpro: 'iMac Pro',
  macmini: 'Mac mini',
  macpro: 'Mac Pro',
  macbook: 'MacBook',
  imac: 'iMac',
};
