import {connect} from 'react-redux';
import Forgot from './Forgot';
import {forgotPassword, setLoginEmail} from '../../../store/actions';
import {ForgotPasswordUserBody} from '../../../services/user/userTypes';
import {AppState, RequestStatus} from '../../../store/reducers/reducerType';
import {History} from 'history';

type StateType = {
  forgotPasswordStatus: RequestStatus,
  checkCodeStatus: RequestStatus,
}

const mapStateToProps = (state: AppState): StateType => ({
  forgotPasswordStatus: state.user.forgotPasswordStatus,
  checkCodeStatus: state.user.checkCodeStatus,
});

const mapDispatchToProps = (dispatch: any) => ({
  forgot: (userData: ForgotPasswordUserBody, history: History): void => {
    dispatch(forgotPassword(userData, history));
  },
  setLoginEmail: (email: string) => {
    dispatch(setLoginEmail(email));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Forgot);
