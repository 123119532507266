import {Routes} from '../../router/Routes';
import {ServicesErrors} from '../../constants';

interface ServerErrorPageDataType {
  [key: string]: {
    title: string,
    description: string,
    button?: {
      name: string,
      route: Routes,
    },
    additionalButton?: {
      name: string,
      route: Routes,
    },
  }
}

export const ServerErrorPageData: ServerErrorPageDataType = {
  request_is_already_processing: {
    title: 'Still no reset email from us?',
    description: 'Just start a chat and we\'ll help you out.',
    additionalButton: {
      name: 'Back to login',
      route: Routes.Login,
    }
  },
  email_verification_invalid_code: {
    title: 'Uh-oh, your verification link expired...',
    description: 'Don\'t worry, we can resend a new one to your email. Just log in to your Clario account and hit Resend email.',
    button: {
      name: 'Go to your account',
      route: Routes.ProfilePopup,
    },
  },
  default: {
    title: 'Something went wrong.',
    description: 'Just get in touch and we\'ll be happy to help you out.',
    button: undefined,
  }
};

export const ErrorPageMessageMap = {
  sendVerificationEmail: 'Send verification email failed',
  emailVerificationInvalidCode: 'email_verification_invalid_code',
  checkVerificationEmailCodeFailed: ServicesErrors.emailVerification.checkVerificationEmailCodeFailed,
  invalidDownloadToken: 'invalid_download_token',
}
