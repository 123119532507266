import {useSelector} from 'react-redux';
import {Redirect, Route, useHistory} from 'react-router-dom';
import {Routes} from './Routes';
import React, {ReactNode, useEffect} from 'react';
import {AppState} from '../store/reducers/reducerType';
import Url from '../utils/url'
import {Affid} from '../constants';

type PublicRoutePropsType = {
  children: ReactNode,
}

const PublicRoute = ({children, ...rest}: PublicRoutePropsType) => {
  const isAuthenticated = useSelector((state: AppState) => state.user.isAuthenticated);
  const history = useHistory();

  useEffect(() => {
    getQueryParamsFromUrl();
  }, []);
  
  const getQueryParamsFromUrl = () => {
    getAffidFormUrl();
  }

  const getAffidFormUrl = () => {
    const affid = Url.getQueryParamByName(window.location.href, 'affid');

    if (!affid) {
      return;
    }

    localStorage.setItem(Affid, affid);
    history.push(Url.deleteQueryParams(window.location.href));
  }
  return (
    <Route
      {...rest}
      render={() =>
        !isAuthenticated ? (
          children
        ) : (
          <Redirect to={{pathname: Routes.Main}}/>
        )
      }
    />
  );
};

export default PublicRoute;
