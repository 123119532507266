import {DeviceApi, ServicesErrors} from '../../constants';
import {
  handleResponse,
  generateDeviceId,
  getPlatform,
  getBrowserInfo,
  determineErrorLevelForSentry
} from '../../utils';
import {RegisterDeviceBody} from './deviceTypes';
import fetch from 'node-fetch';
import * as Sentry from '@sentry/browser';


export async function registration(deviceId?: string): Promise<RegisterDeviceBody> {
  const {browserName, browserVersion} = getBrowserInfo();
  const deviceInfo: RegisterDeviceBody = {
    device_id: deviceId ? deviceId : await generateDeviceId(),
    device_model: getPlatform(),
    system_name: browserName,
    system_version: browserVersion,
    language_code: navigator.language.substr(0, 2).toLowerCase(),
  };

  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(deviceInfo)
  };

  return fetch(DeviceApi, requestOptions)
    .then(handleResponse)
    .catch(error => {
      const errorLevel = determineErrorLevelForSentry(error);

      Sentry.configureScope((scope) => {
        scope.setLevel(errorLevel);
      })

      Sentry.addBreadcrumb({
        category: 'device',
        message: 'Endpoint ' + DeviceApi,
        data: {
          error: error,
          requestData: requestOptions,
        },
        level: errorLevel
      });

      Sentry.captureMessage(ServicesErrors.device.deviceRegistrationFailed, errorLevel);
      throw new Error(error.message);
    });
}
