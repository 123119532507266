import {History} from 'history';
import {Dispatch} from 'redux';
import {userService} from '../../../services/user/user';
import {ActionType} from '../actionsTypes';
import {AutoLoginParamsInterface} from '../../../services/user/getAutoLoginParams';

export enum AutoLoginParams {
    GET_AUTO_LOGIN_PARAMS_REQUEST= 'GET_AUTO_LOGIN_PARAMS_REQUEST',
    GET_AUTO_LOGIN_PARAMS_SUCCESS= 'GET_AUTO_LOGIN_PARAMS_SUCCESS',
    GET_AUTO_LOGIN_PARAMS_FAILURE= 'GET_AUTO_LOGIN_PARAMS_FAILURE',
}

export type GetAutoLoginParamsRequestReduxAction = ActionType<AutoLoginParams.GET_AUTO_LOGIN_PARAMS_REQUEST, {}>;
export type GetAutoLoginParamsSuccessReduxAction = ActionType<AutoLoginParams.GET_AUTO_LOGIN_PARAMS_SUCCESS, AutoLoginParamsInterface>;
export type GetAutoLoginParamsFailureReduxAction = ActionType<AutoLoginParams.GET_AUTO_LOGIN_PARAMS_FAILURE, Error>;

export function getAutoLoginParams(history: History) {
    return (dispatch: Dispatch<GetAutoLoginParamsRequestReduxAction | GetAutoLoginParamsSuccessReduxAction | GetAutoLoginParamsFailureReduxAction>) => {
        dispatch(request());
        userService.getAutoLoginParams(history)
            .then(
                (resp: AutoLoginParamsInterface) => {
                    dispatch(success(resp));
                },
                error => {
                    dispatch(failure(error));
                }
            );

        function request(): GetAutoLoginParamsRequestReduxAction {
            return {type: AutoLoginParams.GET_AUTO_LOGIN_PARAMS_REQUEST, payload: {}}
        }

        function success(autoLoginParams: AutoLoginParamsInterface): GetAutoLoginParamsSuccessReduxAction {
            return {type: AutoLoginParams.GET_AUTO_LOGIN_PARAMS_SUCCESS, payload: autoLoginParams}
        }

        function failure(error: Error): GetAutoLoginParamsFailureReduxAction {
            return {type: AutoLoginParams.GET_AUTO_LOGIN_PARAMS_FAILURE, payload: error}
        }
    }
}