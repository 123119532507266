import {AppState, RequestStatus, UserInfo} from '../reducerType';
import {AutoLoginParamsType} from './types';
import {AutoLoginParamsInterface} from '../../../services/user/getAutoLoginParams';
import {ConsentsType} from '../../../services/user/userTypes';

export const selectAutoLoginParams = (state: AppState): AutoLoginParamsType => state.user.autoLoginParams;
export const selectAutoLoginParamsData = (state: AppState): AutoLoginParamsInterface | null => state.user.autoLoginParams.data;
export const selectUserInfo = (state: AppState): UserInfo | null => state.user.userInfo;
export const selectLogoutStatus = (state: AppState): RequestStatus => state.user.logoutStatus;
export const selectUserConsents = (state: AppState): ConsentsType => state.user.consents;
export const selectChangePasswordStatus = (state: AppState): RequestStatus => state.user.changePasswordStatus;
export const selectChangeUserNameStatus = (state: AppState): RequestStatus => state.user.changeUserNameStatus;
export const selectIsAuthenticated = (state: AppState): boolean | null => state.user.isAuthenticated;