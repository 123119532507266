import {getAssetsList} from './getAssetsList';
import {renewSubscription} from './renewSubscription';
import {cancelSubscription} from './cancelSubscription';
import {checkTrial} from './checkTrial';

export const assetsService = {
  getAssetsList,
  renewSubscription,
  cancelSubscription,
  checkTrial,
};


