import React, {FunctionComponent, useEffect, useState} from 'react';
import './FooterWaoo.scss'
import OnlyCopyright from '../OnlyCopyright';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {Routes} from '../../../../router/Routes';
import {FooterTypes} from '../../Footer';
import FooterDefault from '../FooterDefault';
import {useSelector} from 'react-redux';
import {AppState} from '../../../../store/reducers/reducerType';

const FooterWaoo: FunctionComponent<RouteComponentProps> = (props: RouteComponentProps) => {
  const webcbt: string = useSelector((state: AppState) => state.partner.webcbt);
  const {location} = props;
  const [footerType, setFooterType] = useState<FooterTypes>(FooterTypes.default);

  useEffect(() => {
    switch (location.pathname) {
      case Routes.Login:
      case Routes.Signup:
      case Routes.VerifySmartLink:
      case Routes.Download:
        setFooterType(FooterTypes.onlyCopy);
        break;
      default:
        setFooterType(FooterTypes.default);
    }
  }, [location]);

  const determineFooterType = () => {
    switch (footerType) {
      case  FooterTypes.onlyCopy:
        return <OnlyCopyright webcbt={webcbt} />
      default:
        return <FooterDefault/>;
    }
  }

  return (determineFooterType())
};

export default withRouter(FooterWaoo);
