import React, {FunctionComponent, useEffect} from 'react';
import withServerErrorHandlingPage from '../../../hoc/ServerErrorHandlingPage';
import DownloadLinkCircle, {DownloadLinkCircleType} from '../../../containers/DownloadLinkCircle';
import {Events} from '../../../services/events/events';
import {DownloadLinksEvents, EventContext} from '../../../constants';
import DownloadLinksWrapper from '../DownloadLinksWrapper';

const TokenInvalid: FunctionComponent = () => {
  useEffect(() => {
    Events.send({
      context: EventContext.downloadLinks,
      event: DownloadLinksEvents.link_broken_shown,
    });
  }, []);

  return (
    <DownloadLinksWrapper type={DownloadLinkCircleType.invalidToken}>
      <DownloadLinkCircle type={DownloadLinkCircleType.invalidToken}/>
    </DownloadLinksWrapper>
  );
};

export default (withServerErrorHandlingPage(TokenInvalid) as any);
