import React, {FunctionComponent, useEffect, useState} from 'react';
import './HeaderWaoo.scss';
import {Routes} from '../../../../router/Routes';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import OnlyLogo from '../OnlyLogo';
import {HeaderTypes} from '../../Header';
import HeaderDefault from '../HeaderDefault';
import {HeaderNavLinksName} from '../../HeaderNavList/NavListItemsConfig';
import {useSelector} from 'react-redux';
import {AppState} from '../../../../store/reducers/reducerType';

const NavListItems = [HeaderNavLinksName.product, HeaderNavLinksName.about, HeaderNavLinksName.blog, HeaderNavLinksName.account, HeaderNavLinksName.logout];

const HeaderWaoo: FunctionComponent<RouteComponentProps> = (props: RouteComponentProps) => {
  const {location} = props;
  const [headerType, setHeaderType] = useState<HeaderTypes>(HeaderTypes.onlyLogo);
  const webcbt: string | number = useSelector((state: AppState) => state.partner.webcbt);

  useEffect(() => {
    determineLocation();
  }, [location]);

  const determineLocation = () => {
    switch (location.pathname) {
      case Routes.Login:
      case Routes.Signup:
      case Routes.VerifySmartLink:
      case Routes.VerifyDownloadLinks:
      case Routes.Download:
        setHeaderType(HeaderTypes.onlyLogo);
        break;
      default:
        setHeaderType(HeaderTypes.withoutPricing);
    }
  }

  const determineHeaderType = () => {
    switch (headerType) {
      case  HeaderTypes.withoutPricing:
        return <HeaderDefault webcbt={webcbt} navListItems={NavListItems}/>
      case HeaderTypes.onlyLogo:
        return <OnlyLogo webcbt={webcbt}/>
      default:
        setHeaderType(HeaderTypes.withoutPricing);
        return <HeaderDefault webcbt={webcbt} navListItems={NavListItems}/>;
    }
  }

  return determineHeaderType()
};

export default withRouter(HeaderWaoo);
