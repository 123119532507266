import React, {FunctionComponent, useEffect, useState} from 'react';
import Button from '../../../../../components/Buttons/Button';
import {determineDesktopModel, determineIOSModel, formatAMPM} from '../../../../../utils';
import {Device, DevicesStatus} from '../../../../../services/device/deviceTypes';
import {DeviceItemScreenType, DevicesEvents, EventContext, IconType, SystemType, SystemTypeName} from '../../../../../constants';
import {Events} from '../../../../../services/events/events';
import IconsWithBackground from '../../../../../components/Icons/IconsWithBackground';

type Props = {
  device: Device;
  devicesStatus: DevicesStatus | null;
  listNumber: number,
  setDeviceItemScreenType: (deviceId: string, screenType: DeviceItemScreenType) => void;
}

const DefaultDevice: FunctionComponent<Props> = (props: Props) => {
  const {device, devicesStatus, listNumber, setDeviceItemScreenType} = props;
  const [systemType, setSystemType] = useState<SystemType>();

  useEffect(() => {
    determineSystemType();
  }, [device]);

  const determineSystemType = (): void => {
    switch (device.system_name.toLowerCase()) {
      case SystemTypeName.macos:
        setSystemType(SystemType.desktop);
        break;
      case SystemTypeName.ios:
      case SystemTypeName.android:
        setSystemType(SystemType.mobile);
        break;
      default:
        setSystemType(SystemType.desktop);
    }
  };

  const determineLastLoginDate = (): string => {
    const date = formatAMPM(device.last_login);

    if (date == null) {
      return '';
    }

    return date;
  };

  const determineDeviceModel = (): string => {
    const {system_name, device_model} = device;

    switch (system_name.toLowerCase()) {
      case SystemTypeName.macos:
        return determineDesktopModel(device_model);
      case SystemTypeName.ios:
        return determineIOSModel(device_model);
      default:
        return device_model;
    }
  };

  const handleDisconnect = () => {
    setDeviceItemScreenType(device.id, DeviceItemScreenType.disconnectConfirm);
    Events.send({
      context: EventContext.devices,
      event: DevicesEvents.detailed_area__disconnect_button_click,
      data: {deviceId: device.id}
    });
  };


  return (
    <div className='device-item'>
      <div className='device-item__header'>
        {systemType === SystemType.mobile
          ? <IconsWithBackground iconType={IconType.mobile} size='big' className='device-item__icon'/>
          : <IconsWithBackground iconType={IconType.desktop} size='big' className='device-item__icon'/>}
        <p className='device-item__type'>{systemType}</p>
      </div>
      <aside className='device-item__aside'>
        <div className='device-item__device-info-block'>
          <div className='device-item__device-info'>
            <p className='device-item__device-info-item device-item__device-info-name'>Model</p>
            <p className='device-item__device-info-item device-item__device-info-value'>{determineDeviceModel()}</p>
          </div>
          <div className='device-item__device-info'>
            <p className='device-item__device-info-item device-item__device-info-name'>Last login</p>
            <p className='device-item__device-info-item device-item__device-info-value'>{determineLastLoginDate()}</p>
          </div>
        </div>
      </aside>
      <aside className='device-item__aside device-item__aside--right'>
        <div className='device-item__info'>
          <p className='device-item__number'>{`Device ${listNumber + 1}`}</p>
          <p className='device-item__status'>{devicesStatus?.name}</p>
        </div>
        <div className='button-wrapper device-item__button-wrapper'>
          <Button
            type='button'
            className='button--secondary button--dark assets__button'
            action={handleDisconnect}
            title='Disconnect'/>
        </div>
      </aside>
    </div>
  );
};

export default DefaultDevice;
