import React, {FunctionComponent} from 'react';
import {NavLink} from 'react-router-dom';
import {Routes} from '../../../router/Routes';
import {useHeaderContext} from '../Header';
import {HeaderNavLinksName} from '../HeaderNavList/NavListItemsConfig';

const InnerPageLinkItem: FunctionComponent = () => {
  const {hideBurgerMenu, isAuthenticated} = useHeaderContext();
  return (
    <li className='header__menu-item'>
      <NavLink
        data-name={HeaderNavLinksName.account}
        className='header__menu-link' to={Routes.Main}
        exact
        activeClassName='header__menu-link--active'
        onClick={hideBurgerMenu}>
        {isAuthenticated ? 'Account.' : 'Sign in.'}
      </NavLink>
    </li>
  );
}

export default InnerPageLinkItem;
