import React, {FunctionComponent, useEffect, useRef} from 'react';
import Lottie from 'react-lottie';
import './Loader.scss';

const preloadJSONAnimation = require('../../../resources/images/lottie-animations/loader.json');

type Props = {
  style?: {
    [key: string]: string | number;
  }
  className?: string,
}

const Loader: FunctionComponent<Props> = ({className, style = undefined}) => {
  const loaderRef: any = useRef();

  useEffect(() => {
    if (className && loaderRef.current != null) {
      loaderRef.current.classList.add(className);
    }
  }, [className]);


  return (<div className='loader' ref={loaderRef} style={style}>
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: preloadJSONAnimation,
      }}
      isClickToPauseDisabled
      isStopped={false}
      isPaused={false}
    />
  </div>);
};

export default Loader;
