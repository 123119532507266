import {WebCbt, WebCbtFlowNames} from '../../constants';

export const SignupPageConfig: Record<any, any> = {
  [WebCbtFlowNames[WebCbt.clario]]: {
    title: {
      text: 'Hey there! Let’s create your account!',
    },
    subtitle: 'Now we’ll get you set up. It’ll only take a few minutes and then you’ll be good to go.',
  },
  [WebCbtFlowNames[WebCbt.waoo]]: {
    title: {
      text: '%partner_name% brings you a Premium Clario subscription at no cost.',
    },
    subtitle: 'Now we’ll get you set up. It’ll only take a few minutes and then you’ll be good to go.',
  },
  [WebCbtFlowNames[WebCbt.stackCommerce]]: {
    title: {
      text: 'Thanks for choosing Clario!',
    },
    subtitle: 'Now let’s get you a Clario account so you can download your fancy new software. It’ll only take a sec!',
  },
}
