import {connect} from 'react-redux';
import LogoutItem from './LogoutItem';
import {logout} from '../../../../store/actions';
import {History} from 'history';
import {AppState, RequestStatus} from '../../../../store/reducers/reducerType';

type StateType = {
  isAuthenticated: boolean | null,
  logoutStatus: RequestStatus,
}

const mapStateToProps = (state: AppState): StateType => ({
  isAuthenticated: state.user.isAuthenticated,
  logoutStatus: state.user.logoutStatus,
});

const mapDispatchToProps = (dispatch: any) => ({
  logout: (history: History): void => {
    dispatch(logout(history));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)((LogoutItem as any));
