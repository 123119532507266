import * as React from 'react';
import './RoundedArea.scss';
import {ReactNode} from 'react';

type Props = {
  className?: string,
  children: ReactNode;
}

const RoundedArea: React.FunctionComponent<Props> = (props: Props) => {


  return (
    <div className={`rounded-area ${props.className || ''}`}>
      {props.children}
    </div>
  );
};

export default RoundedArea;
