import React, {useEffect, useLayoutEffect, useState} from 'react';
import './Main.scss';
import {useHistory} from 'react-router-dom';
import {
  ApiErrors,
  EventContext,
  UIBreakPoints,
  DashboardEvents,
} from '../../constants';
import withServerErrorHandlingPage from '../../hoc/ServerErrorHandlingPage';
import GreetingData from '../../containers/GreetingData';
import ContentWrapper from '../../containers/ContentWrapper';
import SubscriptionSection from './Subscription';
import ProfileSection from './Profile';
import DevicesSection from './Devices';
import ModalConductor from '../../popups/Modal/ModalConductor';
import {Events} from '../../services/events/events';
import Loader from '../../components/Loader';
import {vh} from '../../utils';
import {useDispatch, useSelector} from 'react-redux';
import {getAssetsList, getDevicesList, getUserInfo, successLogout} from '../../store/actions';
import {getDeleteAccountStatus} from '../../store/actions/cde/getDeleteAccountStatusAction';
import {selectLogoutStatus, selectUserInfo} from '../../store/reducers/user';
import {selectAssetsList} from '../../store/reducers/assets';
import {selectDisconnectDeviceStatus} from '../../store/reducers/device';
import {selectUpdateDataFromApi} from '../../store/reducers/page';
import {selectModalPopupSize, selectNeedToShow} from '../../store/reducers/popups';
import {selectDeleteAccountStatus} from '../../store/reducers/cde';

export type SectionStatus = {
  name: string,
  active: boolean,
};

export type SectionInfo = {
  text: string,
  bold?: string,
  boldClassName?: string,
}

type ContentSize = {
  minHeight: string,
  maxHeight?: string,
  height?: string,
}

const Main = () => {
  const history = useHistory();
  const userInfo = useSelector(selectUserInfo);
  const logoutStatus = useSelector(selectLogoutStatus);
  const assetsList = useSelector(selectAssetsList);
  const updateDataFromApi = useSelector(selectUpdateDataFromApi);
  const disconnectDeviceStatus = useSelector(selectDisconnectDeviceStatus);
  const modalPopupSize = useSelector(selectModalPopupSize);
  const needToShow = useSelector(selectNeedToShow);
  const deleteAccountStatus = useSelector(selectDeleteAccountStatus);
  const dispatch = useDispatch();
  const [contentSize, setContentSize] = useState<ContentSize | undefined>();

  useEffect(() => {
    dispatch(getAssetsList(history));
    dispatch(getDevicesList(history));
    if (!userInfo?.email.length) {
      dispatch(getUserInfo());
    }
  }, []);

  // Handle zeroing deleteAccountStatus
  useEffect(() => {
    if (!deleteAccountStatus) {
      dispatch(getDeleteAccountStatus(history));
    }
  }, [deleteAccountStatus]);

  useEffect(() => {
    if (!needToShow && userInfo?.id.length) {
      Events.send({
        context: EventContext.mainPage,
        event: DashboardEvents.open,
      })
    }
  }, [needToShow]);

  useEffect(() => {
    determineApiErrors();
  }, [logoutStatus]);

  const determineApiErrors = () => {
    if (!logoutStatus.error.length) {
      return;
    }

    if (logoutStatus.error === ApiErrors.invalid_token || logoutStatus.error === ApiErrors.token_expired) {
      dispatch(successLogout());
    }

    throw new Error(logoutStatus.error);
  };

  // Handle 500 upi errors for disconnect device
  useEffect(() => {
    if (!disconnectDeviceStatus) {
      return;
    }
    Object.values(disconnectDeviceStatus).forEach((disconnectStatus) => {
      if (disconnectStatus.error === ApiErrors.undefined_error) {
        throw new Error(disconnectStatus.error);
      }
    })
  }, [disconnectDeviceStatus]);

  // Whether it is necessary to change the height of the content depending on the height of pseudo popups.
  // Since pseudo popups can both increase page height (desktop) and decrease (mobile)
  useLayoutEffect(() => {
    resizeContent();
  }, [modalPopupSize, needToShow]);

  const resizeContent = () => {
    const orientationLandscape = window.matchMedia('(orientation: landscape)').matches;

    // offsetHeight
    if (needToShow) {
      // Handle mobile landscape adaptive
      if (window.innerWidth <= UIBreakPoints.tablet.max && orientationLandscape) {
        setContentSize({height: `${modalPopupSize.height + 192}px`, minHeight: `${vh(100) - 192}px`});
        return;
      }

      // Handle mobile adaptive
      if (window.innerWidth <= UIBreakPoints.mobile.landscape.min) {
        setContentSize({height: `${modalPopupSize.height}px`, minHeight: `${vh(100) - 192}px`});
        return;
      }

      if (window.innerWidth >= UIBreakPoints.mobile.landscape.min && window.innerWidth <= UIBreakPoints.tablet.max) {
        setContentSize({height: `${modalPopupSize.height + 192 + 48}px`, minHeight: '0px'});
        return;
      }

      setContentSize({height: `${modalPopupSize.height + 192 + 48}px`, minHeight: `${vh(100)}px`});
      return;
    }

    setContentSize(undefined);
  };

  // Show the loader before receiving all the necessary data to display the view
  if (assetsList == null && !updateDataFromApi) {
    return <Loader className='loader--page'/>;
  }

  return (
    <ContentWrapper>
      <div className={`membership ${contentSize != null ? 'membership--animate' : ''}`} style={contentSize}>
        {!needToShow ?
          <>
            <GreetingData/>
            <div className='membership__section-wrapper'>
              <div className='membership__section'>
                <SubscriptionSection/>
                <ProfileSection/>
                <DevicesSection/>
              </div>
            </div>
          </> : null
        }
      </div>
      <ModalConductor/>
    </ContentWrapper>
  )
};

export default withServerErrorHandlingPage(React.memo(Main));
