import {SSOActions} from '../../../constants/sso';
import {DefaultSSOState} from './constants';
import {SSOStateType} from './types';

export default (state = DefaultSSOState, action: any): SSOStateType => {
  switch (action.type) {
    case SSOActions.GET_APPLE_AUTH_REDIRECT_LINK_REQUEST:
      return {
        ...state,
        appleAuthRedirectLinkStatus: {
          status: false,
          error: '',
        },
      };
    case SSOActions.GET_APPLE_AUTH_REDIRECT_LINK_SUCCESS:
      return {
        ...state,
        appleAuthRedirectLink: action.payload,
        appleAuthRedirectLinkStatus: {
          status: true,
          error: '',
        },
      };
    case SSOActions.GET_APPLE_AUTH_REDIRECT_LINK_FAILURE:
      return {
        ...state,
        appleAuthRedirectLink: null,
        appleAuthRedirectLinkStatus: {
          status: false,
          error: action.payload.message,
        },
      };
    case SSOActions.SEND_GOOGLE_CREDENTIALS_REQUEST:
      return {
        ...state,
        googleAuthStatus: {
          status: false,
          error: '',
        },
      };
    case SSOActions.SEND_GOOGLE_CREDENTIALS_SUCCESS:
      return {
        ...state,
        googleAuthStatus: {
          status: true,
          error: '',
        },
      };
    case SSOActions.SEND_GOOGLE_CREDENTIALS_FAILURE:
      return {
        ...state,
        googleAuthStatus: {
          status: false,
          error: action.payload.message,
        },
      };
    case SSOActions.CLEAR_SSO_ERROR:
      return {
        ...state,
        googleAuthStatus: {
          status: false,
          error: '',
        },
        appleAuthRedirectLinkStatus: {
          status: false,
          error: '',
        }
      };
    default:
      return state;
  }
}
