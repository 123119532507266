import * as React from 'react';
import './MobileDownloadBlock.scss';
import QRCodeComponent from '../../QRCode';

interface MobileDownloadBlockType {
  downloadLink: string;
  className?: string,
}

const AppStoreIcon = require('../../../../resources/images/store/icon-app-store-white.svg').default;
const GooglePlayIcon = require('../../../../resources/images/store/icon-google-play-dark-blue.svg').default;

export enum StoreTypes {
  ios = 'ios',
  android = 'android',
}

const MobileDownloadBlock= ({className = '', downloadLink}: MobileDownloadBlockType) => {
  const handleStoreButtonClick = () => {
    if (!downloadLink) {
      return;
    }

    window.open(downloadLink, '_blank');
  }

  if (!downloadLink) {
    return null;
  }

  return (
    <div className={`device-download ${className}`}>
      <h3 className='device-download__device-type'>Mobile</h3>
      <img src='./images/image-mobile.png' alt='device image' className='device-download__image device-download__image--mobile'/>
      <div className='device-download__content'>
        <h4 className='device-download__title'>Scan this QR code and download Clario </h4>
        <p className='device-download__text device-download__text--subtitle'>Follow the link and install it on your Android or IOS device.</p>
        <QRCodeComponent link={downloadLink} className='device-download__qr' size={165}/>
        <div className='device-download__source-buttons'>
          <GooglePlayIcon className='device-download__source-button' data-name={StoreTypes.android} onClick={handleStoreButtonClick}/>
          <AppStoreIcon className='device-download__source-button' data-name={StoreTypes.ios} onClick={handleStoreButtonClick}/>
        </div>
      </div>
    </div>
  );
};

export default MobileDownloadBlock;
