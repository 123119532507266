import React, {FunctionComponent} from 'react';
import './DownloadLinkCircle.scss';
import Circle from '../../components/Circle';
import LinkExpired from './CircleState/LinkExpired';
import {Events} from '../../services/events/events';
import {DownloadLinksEvents, EventContext, ClarioSiteLinks, ContactSupportFromEventParamsMap} from '../../constants';
import InvalidToken from './CircleState/InvalidToken';
import LinkSend from './CircleState/LinkSend';
import RequestTimout from './CircleState/RequestTimout';

type Props = {
  type: DownloadLinkCircleType,
  resendLinkFailed?: boolean
}

export enum DownloadLinkCircleType {
  invalidToken = 'invalidToken',
  requestTimout = 'requestTimout',
  tokenExpired = 'tokenExpired',
  linkSend = 'linkSend',
}

const DownloadLinkCircle: FunctionComponent<Props> = (props: Props) => {
  const handleContactSupport = (from: DownloadLinkCircleType) => {
    Events.send({
      context: EventContext.downloadLinks,
      event: DownloadLinksEvents.contact_chat_click,
      data: {
        from: ContactSupportFromEventParamsMap[from] || '',
      }
    });

    window.open(ClarioSiteLinks.contacts, '_blank');
  }


  const determineCircleView = () => {
    const {type = DownloadLinkCircleType.tokenExpired} = props;

    switch (type) {
      case DownloadLinkCircleType.invalidToken:
        return <InvalidToken handleContactSupport={handleContactSupport}/>;
      default:
        return (
          <>
            <LinkExpired handleContactSupport={handleContactSupport}/>
            {props.resendLinkFailed
              ? <RequestTimout handleContactSupport={handleContactSupport}/>
              : <LinkSend handleContactSupport={handleContactSupport}/>}
          </>
        );
    }
  }

  return (
    <Circle className={`download-link-expired__circle download-link-circle download-link-circle--${props.type}`}>
      {determineCircleView()}
    </Circle>
  );
};

export default DownloadLinkCircle;
