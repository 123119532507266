import {TestingAffid, ServicesErrors, SignupUserApi} from '../../constants';
import fetch from 'node-fetch';
import {handleResponse, determineErrorLevelForSentry, getAffid} from '../../utils';
import * as Sentry from '@sentry/browser';
import {SignupUserBodyType} from '../../store/actions/partner/partnerActionsTypes';

export async function signupUser(user: SignupUserBodyType, token: string): Promise<string> {
  const affid = await getAffid();
  const requestBody = {
    ...user,
    affid: affid || TestingAffid,
  };

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    credentials: 'include',
    body: JSON.stringify(requestBody)
  };

  return fetch(SignupUserApi, requestOptions)
    .then(handleResponse)
    .catch(error => {
      const errorLevel = determineErrorLevelForSentry(error);

      Sentry.configureScope((scope) => {
        scope.setLevel(errorLevel);
      })

      Sentry.addBreadcrumb({
        category: 'auth',
        message: 'Endpoint ' + SignupUserApi,
        data: {
          error: error,
        },
        level: errorLevel
      });
      Sentry.captureMessage(ServicesErrors.waoo.signupUserFailed, errorLevel);
      throw new Error(error.message);
    });
}
