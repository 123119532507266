import {Dispatch} from 'redux';
import {ClearSSOErrorReduxAction} from './ssoActionsTypes';
import {SSOActions} from '../../../constants/sso';

export {getAppleAuthRedirectLink} from './apple/getAppleAuthRedirectLink';
export {sendGoogleCredentials} from './google/sendGoogleCredentials';

export function clearSSOError() {
  return (dispatch: Dispatch<ClearSSOErrorReduxAction>) => {
    dispatch({type: SSOActions.CLEAR_SSO_ERROR, payload: {}});
  };
}
