import fetch from 'node-fetch';
import {UserApi, ServicesErrors} from '../../constants';
import {handleResponse, determineErrorLevelForSentry} from '../../utils';
import * as Sentry from '@sentry/browser';
import {GetUserInfo} from './userTypes';

export function getUserInfo(): Promise<GetUserInfo> {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  };

  return fetch(UserApi, requestOptions)
    .then(handleResponse)
    .catch(error => {
      // Prevent send getUserInfo with 401 - because we use this request to detect if user logged in
      if (error.status !== 401) {
        const errorLevel = determineErrorLevelForSentry(error);

        Sentry.configureScope((scope) => {
          scope.setLevel(errorLevel);
        });


        Sentry.addBreadcrumb({
          category: 'user',
          message: 'Endpoint ' + UserApi,
          data: {
            error: error,
          },
          level: errorLevel,
        });
        Sentry.captureMessage(ServicesErrors.user.getUserInfoFailed, errorLevel);
      }
      throw new Error(error.message);
    });
}
