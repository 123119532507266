import fetch from 'node-fetch';
import {ServicesErrors, AppleAuthRedirectLink} from '../../constants';
import {handleResponse, determineErrorLevelForSentry, getDeviceId, getAffid, objectOmitNull} from '../../utils';
import * as Sentry from '@sentry/browser';
import {AppleAuthRedirectLinkResponse} from './ssoTypes';

export async function getAppleAuthRedirectLink(): Promise<AppleAuthRedirectLinkResponse> {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  };

  let AppleAuthRedirectLinkApi = new URL(AppleAuthRedirectLink);
  const queryParams = objectOmitNull({deviceID: await getDeviceId(), affid: await getAffid()});

  AppleAuthRedirectLinkApi.search = new URLSearchParams(queryParams).toString();

  return fetch(AppleAuthRedirectLinkApi, requestOptions)
    .then(handleResponse)
    .catch(error => {
      const errorLevel = determineErrorLevelForSentry(error);
      Sentry.configureScope((scope) => {
        scope.setLevel(errorLevel);
      });

      Sentry.addBreadcrumb({
        category: 'sso',
        message: 'Endpoint ' + AppleAuthRedirectLinkApi,
        data: {
          error: error,
        },
        level: errorLevel,
      });
      Sentry.captureMessage(ServicesErrors.sso.appleAuthRedirectLink, errorLevel);
      throw new Error(error.message);
    });
}
