import {YEAR} from '../constants';

export default class Cookies {
  static setCookie(name: string, value: boolean | string, cookiesOptions: Record<string, any> = {}) {
    const date = new Date();

    const options: Record<string, any> = {
      path: '/',
      domain: '.clario.co',
      sameSite: 'Lax',
      secure: 'secure',
      expires: date.getTime() + YEAR,
      ...cookiesOptions
    };

    if (options.expires instanceof Date) {
      options.expires = options.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);

    for (const optionKey in options) {
      updatedCookie += '; ' + optionKey;
      const optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += '=' + optionValue;
      }
    }

    document.cookie = updatedCookie;
  }

  static getCookie(name: string) {
    const matches = document.cookie.match(new RegExp(
      '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  static deleteCookie(name: string) {
    Cookies.setCookie(name, '', {
      'max-age': -1
    })
  }

  static calculateCookiesHours(hours: number): string {
    let date: Date = new Date();

    // Get milliseconds at current time plus number of hours*60 minutes*60 seconds* 1000 milliseconds
    date.setTime(+date + (hours * 3600000)); //60 * 60 * 1000

    return date.toUTCString();
  }
}
