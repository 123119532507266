import React from 'react';
import {WebCbt} from '../../constants';
import './Download.scss';
import withServerErrorHandlingPage from '../../hoc/ServerErrorHandlingPage';
import ContentWrapper from '../../containers/ContentWrapper';
import {DesktopAndMobile} from './DesktopAndMobile';
import {Mobile} from './Mobile';
import {useSelector} from 'react-redux';
import {selectWebCbt} from '../../store/reducers/partner';


const Download = () => {
  const webCbt = useSelector(selectWebCbt);

  return (
    <ContentWrapper
    className='main--mobile'>
      <div className='download-page animate-in'>
        {webCbt  === WebCbt.mkClarioMobile ? <Mobile/> : <DesktopAndMobile/>}
      </div>
    </ContentWrapper>
  );
};

export default (withServerErrorHandlingPage(Download));
