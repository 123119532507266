import React, {FunctionComponent} from 'react';
import './Footer.scss';
import FooterDefault from './Types/FooterDefault';
import FooterWaoo from './Types/FooterWaoo';
import {useSelector} from 'react-redux';
import {AppState} from '../../store/reducers/reducerType';
import {WebCbt} from '../../constants';

export enum FooterTypes {
  onlyCopy = 'onlyCopy',
  default = 'default'
}

const Footer: FunctionComponent = () => {
  const webcbt: string = useSelector((state: AppState) => state.partner.webcbt);

  const determineFooterType = () => {
    switch (webcbt) {
      case WebCbt.waoo:
      case WebCbt.stackCommerce:
        return <FooterWaoo/>
      default:
        return <FooterDefault/>
    }
  }

  return (
    determineFooterType()
  )
};

export default Footer;
