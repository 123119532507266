import React, {FunctionComponent} from 'react';
import {useSubscriptionSectionContext} from '../Subscription';
import {FinishedStatusFromApi} from '../../../../constants';
import FinishedSubscription from '../DefaultSubscription/Types/FinishedSubscription';
import ActiveSubscription from './Types';

const StackCommerceSubscription: FunctionComponent = () => {
  const {currentAsset} = useSubscriptionSectionContext();

  const determineSubscriptionStatus = () => {
    if (currentAsset?.status === FinishedStatusFromApi) {
      return <FinishedSubscription/>;
    }

    return <ActiveSubscription/>
  }

  return (determineSubscriptionStatus())
}

export default StackCommerceSubscription;
