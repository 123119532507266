import {Routes} from '../../router/Routes';
import {LoginEvents} from './pages/login';
import {SignupEvents} from './pages/signup';
import {ForgotPasswordEvents} from './pages/forgotPassword';
import {ResetPasswordEvents} from './pages/resetPassword';
import {DashboardEvents} from './pages/main';

export enum EventContext {
  mainPage = 'ClarioAccountDashboard',
  subscriptions = 'ClarioAccountSubscription',
  profile = 'ClarioAccountProfile',
  devices = 'ClarioAccountDevices',
  emailVerification = 'ClarioAccountEmailVerification',
  header = 'ClarioAccountHeader',
  footer = 'ClarioAccountFooter',
  cookiesBanner = 'ClarioAccountCookieBanner',
  signup = 'ClarioAccountSignup',
  login = 'ClarioAccountLogin',
  forgotPassword = 'ClarioAccountForgotPassword',
  resetPassword = 'ClarioAccountRestorePassword',
  downloadLinks = 'ClarioAccountDownloadLink',
  general = 'General',
  downloadLink = 'ClarioAccountDownloadLink'
}

export const PathEventContextMap: Record<Routes, EventContext> | any = {
  [Routes.Main]: EventContext.mainPage,
  [Routes.Login]: EventContext.login,
  [Routes.Signup]: EventContext.signup,
  [Routes.Forgot]: EventContext.forgotPassword,
  [Routes.ResetPassword]: EventContext.resetPassword,
}

export const PathEventNameMap: any = {
  [Routes.Main]: DashboardEvents,
  [Routes.Login]: LoginEvents,
  [Routes.Signup]: SignupEvents,
  [Routes.Forgot]: ForgotPasswordEvents,
  [Routes.ResetPassword]: ResetPasswordEvents,
}
