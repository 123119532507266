import {Dispatch} from 'redux';
import {SendDownloadLinksFailureReduxAction, SendDownloadLinksRequestReduxAction, SendDownloadLinksSuccessReduxAction} from './partnerActionsTypes';
import {PartnerIntegrationService} from '../../../services/partner/partnerIntegration';
import {PartnersActions} from '../../../constants';

export function sendDownloadLinks(token: string) {
  return (dispatch: Dispatch<SendDownloadLinksRequestReduxAction | SendDownloadLinksSuccessReduxAction | SendDownloadLinksFailureReduxAction>) => {
    dispatch(request());

    PartnerIntegrationService.sendDownloadLinks(token)
      .then(
        () => {
          dispatch(success());
        },
        error => {
          dispatch(failure(error));
        }
      );

    function request(): SendDownloadLinksRequestReduxAction {
      return {type: PartnersActions.SEND_DOWNLOADS_LINKS_REQUEST, payload: {}}
    }

    function success(): SendDownloadLinksSuccessReduxAction {
      return {type: PartnersActions.SEND_DOWNLOADS_LINKS_SUCCESS, payload: {}}
    }

    function failure(error: Error): SendDownloadLinksFailureReduxAction {
      return {type: PartnersActions.SEND_DOWNLOADS_LINKS_REQUEST, payload: error}
    }
  }
}
