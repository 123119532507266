import {connect} from 'react-redux';
import Forgot from './Forgot';
import {AppState, RequestStatus} from '../../store/reducers/reducerType';
import {ForgotPasswordUserBody} from '../../services/user/userTypes';
import {forgotPassword, forgotPasswordRequest} from '../../store/actions';
import {History} from 'history';

type StateType = {
  forgotPasswordStatus: RequestStatus,
  forgotPasswordInfo: ForgotPasswordUserBody | null,
}

const mapStateToProps = (state: AppState): StateType => ({
  forgotPasswordStatus: state.user.forgotPasswordStatus,
  forgotPasswordInfo: state.user.forgotPasswordInfo,
});

const mapDispatchToProps = (dispatch: any) => ({
  forgot: (userData: ForgotPasswordUserBody, history: History, resend?: boolean): void => {
    dispatch(forgotPassword(userData, history, resend));
  },
  resetForgotPasswordErrors: () => {
    dispatch(forgotPasswordRequest());
  }
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)((Forgot as any));
