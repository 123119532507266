import React, {ReactElement, MouseEvent} from 'react';
import './Button.scss'

type ButtonProps = {
  className: string,
  action?: (ev: MouseEvent<HTMLButtonElement>) => void,
  title: string,
  disabled?: boolean,
  type?: 'submit' | 'button',
}

const Button = (props: ButtonProps): ReactElement => {
  const disabledState = props.disabled != null && props.disabled || false;

  return (
    <button
      className={`button ${props.className}`}
      onClick={props.action}
      type={props.type || 'button'}
      tabIndex={2}
      disabled={disabledState}
    >
      {props.title}
      <div className='button__arrow'/>
    </button>
  )
};

export default Button;
