import React, {FunctionComponent, ReactElement, useCallback, useEffect} from 'react';
import './GoogleSignIn.scss';
import {Events} from '../../services/events/events';
import {EventContext, SSOEvents} from '../../constants';
import {useDispatch, useSelector} from 'react-redux';
import {getUserInfo} from '../../store/actions/user';
import {sendGoogleCredentials} from '../../store/actions/sso';
import {selectGoogleAuthStatus} from '../../store/reducers/sso';
import {Routes} from '../../router/Routes';
import {useHistory} from 'react-router-dom';
import {TokenResponse, useGoogleLogin} from '@react-oauth/google';

const GoogleSignIn: FunctionComponent = (): ReactElement | null => {
    const history = useHistory();
    const googleAuthStatus = useSelector(selectGoogleAuthStatus);
    const dispatch = useDispatch();

    const handleCredentialResponse = (tokenResponse: TokenResponse) => {
        dispatch(sendGoogleCredentials(tokenResponse.access_token));
    };

    const login = useGoogleLogin({
        onSuccess: handleCredentialResponse,
    });

    useEffect(() => {
        const {status, error} = googleAuthStatus;

        if (status && !error.length) {
            Events.send({
                context: EventContext.login,
                event: SSOEvents.google.success,
            });
            dispatch(getUserInfo());
            return;
        }

        if (!status && error.length) {
            Events.send({
                context: EventContext.login,
                event: SSOEvents.google.error,
            });
            history.push(Routes.AuthError);
            return;
        }

    }, [googleAuthStatus]);

    const handleLogin = useCallback(() => {
        Events.send({
            context: EventContext.login,
            event: SSOEvents.google.click,
        });
        login();
    }, []);

    return (
        <button className='google-signin button' onClick={handleLogin}>
            <img src='./images/google-logo.svg' className='google-signin__logo' alt='google logo' />
            Continue with Google
        </button>
    )
}

export default GoogleSignIn;
