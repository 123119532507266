import React, {FunctionComponent, useEffect} from 'react';
import withServerErrorHandlingPage from '../../hoc/ServerErrorHandlingPage';
import {Events} from '../../services/events/events';
import {EventContext, SignupEvents} from '../../constants/events';
import {SignupPageConfig} from './SignupConfig';
import ContentWrapper from '../../containers/ContentWrapper';
import SignupForm from '../../containers/Forms/Signup';
import {UserInfo, WebCbt, WebCbtFlowNames} from '../../constants';
import {capitalizeFirstLetter, fullFillStringWithPlaceholder} from '../../utils';
import {getUserSessionData} from '../../store/actions/user';
import {PartnerName} from '../../services/user/userTypes';
import SSOWrapper from '../../containers/SSOWrapper';

type Props = {
  onError: (error: string) => void;
}

const Signup: FunctionComponent<Props> = ({onError}) => {
  let webcbt: string = getUserSessionData(UserInfo.webcbt) || WebCbt.clario;
  let partnerName: string = getUserSessionData(UserInfo.partnerName) || PartnerName.clario;
  let signupPageConfig = SignupPageConfig[WebCbtFlowNames[webcbt]] || SignupPageConfig[WebCbtFlowNames[WebCbt.clario]];

  useEffect(() => {
    Events.send({
      context: EventContext.signup,
      event: SignupEvents.open,
    })
  }, []);

  const setErrorMessage = (error: string) => {
    if (error.length) {
      onError(error);
    }
  }

  return (
    <ContentWrapper
      aside={{
        title: fullFillStringWithPlaceholder(signupPageConfig.title.text, capitalizeFirstLetter(partnerName)),
        subtitle: signupPageConfig.subtitle,
        fullHeight: true,
      }}>
      <div className='content-form__wrapper'>
        <SSOWrapper/>
        <SignupForm setErrorMessage={setErrorMessage}/>
      </div>
    </ContentWrapper>
  )
}

export default withServerErrorHandlingPage(Signup);
