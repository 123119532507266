import React, {FunctionComponent, MouseEvent} from 'react';
import Title from '../../components/Title';
import Subtitle from '../../components/Subtitle';
import Button from '../../components/Buttons/Button';
import Circle from '../../components/Circle';
import IconsWithBackground from '../../components/Icons/IconsWithBackground';
import './Section.scss';
import {IconType} from '../../constants';
import {SectionInfo} from '../../pages/Main/Main';
import {SubscriptionSectionStatus} from '../../pages/Main/Subscription/Subscription';

export enum SectionNames {
  subscription = 'subscription',
  profile = 'profile',
  devices = 'devices'
}

type Props = {
  name: SectionNames,
  iconType: IconType,
  status: SubscriptionSectionStatus,
  description: string,
  title: string,
  sectionInfo: SectionInfo,
  disabled?: boolean;
  button: {
    title: string,
    action: (ev: MouseEvent<HTMLButtonElement>) => void,
  }
};

const Section: FunctionComponent<Props> = (props: Props) => {
  const {disabled = false} = props;

  return (
    <section
      className={`section slide__section section--${props.name} ${props.status.active ? 'section--active' : 'section--inactive'} ${disabled ? 'section--disabled' : ''}`}
      onClick={props.button.action}>
      <div className='section__content'>
        <div className='section__header'>
          <IconsWithBackground className='section__icon' iconType={props.iconType}/>
          <div className='section__title'>
            <Title text={props.title} className='section__title'/>
            <Subtitle text={props.status.name} className='section__status'/>
          </div>
        </div>
        <p className='section__description'>{props.description}</p>
        <Circle
          className='section__circle-wrapper'
          active={props.status.active}
        >
          <p className={`${props.title.toLowerCase() === 'profile' ? 'section__text--profile' : 'section__copy'}`}>
            {props.sectionInfo.text}
          </p>
          {props.sectionInfo.bold && <p className={`section__text section__text--bold ${props.sectionInfo.boldClassName || ''}`}>{` ${props.sectionInfo.bold}`}</p>}
        </Circle>
      </div>
      <div className='button-wrapper section__button-wrapper'>
        <Button
          className={`button--primary ${props.status.active ? 'button--green' : 'button--purple'} section__button`}
          disabled={disabled}
          title={props.button.title}/>
      </div>
    </section>
  )
};

export default Section;
