import fetch from 'node-fetch';
import {History} from 'history';
import {ServicesErrors, DeleteAccountApi} from '../../constants';
import {handleResponse} from '../../utils';
import * as Sentry from '@sentry/browser';
import {ApiMethod} from '../../../cypress/support/constants';
import handleCommonCdeErrorResponse, {determineErrorLevel} from '.';

export function deleteAccount(password: string, history: History): Promise<string> {
  const requestOptions = {
    method: ApiMethod.POST,
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({password})
  };

  return fetch(DeleteAccountApi, requestOptions)
    .then(handleResponse)
    .catch(error => {
      const errorLevel = determineErrorLevel(error);

      Sentry.configureScope((scope) => {
        scope.setLevel(errorLevel);
      })

      Sentry.addBreadcrumb({
        category: 'auth',
        message: 'Endpoint ' + DeleteAccountApi,
        data: {
          error: error,
        },
        level: Sentry.Severity.Error
      });
      Sentry.captureMessage(`${ServicesErrors.cde.deleteAccount} ${error.message}`, Sentry.Severity.Error);
      return handleCommonCdeErrorResponse(error, history);
    });
}

