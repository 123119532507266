export type ForgotPasswordFormEventsName = 'resetLink' | 'login' | 'resetSuccess' | 'resetError' | 'resetLinkScreen' | 'open';

export const ForgotPasswordEvents: Record<ForgotPasswordFormEventsName, any> = {
  open: 'page__opened',
  resetLink: 'page__reset_button_click',
  login: 'page__back_to_login_link_click',
  resetSuccess: 'page__reset_success',
  resetError: 'page__reset_error',
  resetLinkScreen: {
    open: 'page__reset_link_sent_screen_opened',
    resend: 'page__reset_link_sent_screen_resend_link_click',
    resendSuccess: 'page__reset_link_sent_screen_resend_success',
    resendError: 'page__reset_link_sent_screen_resend_error',
    login: 'page__reset_link_sent_screen_back_to_login_link_click',
    linkExpired: 'page__opened_reset_link_expired'
  }
}
