import React, {FunctionComponent} from 'react';
import Section from '../../../../../../containers/Section';
import {AssetsStatusesMap, DashboardEvents, IconType} from '../../../../../../constants';
import {useSubscriptionSectionContext} from '../../../Subscription';
import {SectionNames} from '../../../../../../containers/Section/Section';

const InactiveSubscription: FunctionComponent = () => {
  const {openAssetModal, sendManageSubscriptionEvent} = useSubscriptionSectionContext();

  if (!openAssetModal || !sendManageSubscriptionEvent) {
    return null;
  }

  const handleManageSubscriptions = () => {
    sendManageSubscriptionEvent(DashboardEvents.subscription_area__manage_button_click_to_popup);
    openAssetModal();
  };
  return (
    <Section
      name={SectionNames.subscription}
      title='Subscription'
      iconType={IconType.subscription}
      status={{name: AssetsStatusesMap.finished, active: false}}
      description='Head here to manage your billing and subscription details.'
      sectionInfo={{text: 'Renew subscription to keep your digital life secure.'}}
      button={{title: 'Renew subscription', action: handleManageSubscriptions}}
    />
  );
}

export default InactiveSubscription;
