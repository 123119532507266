import React from 'react';
import '../Icons.scss';
import './SucceededIcons.scss'

const CheckMarkIcon = require('../../../../resources/images/icon-checkmark.svg').default;

const SucceededIcons: React.FunctionComponent = () => {
  return (
    <div className='icon__wrapper icon__wrapper--succeeded'>
      <div className='icon icon--succeeded'><CheckMarkIcon/></div>
    </div>
  );
};

export default SucceededIcons;
