import React, {FunctionComponent} from 'react';
import {EventContext, HeaderEvents, WebCbt, WebCbtFlowNames} from '../../../../constants';
import HeaderNavList from '../../HeaderNavList/HeaderNavList';
import NavLogo from '../../../../components/NavLogo';
import {useHeaderContext} from '../../Header';
import BurgerMenu from '../../BurgerMenu/BurgerMenu';

type Props = {
  webcbt?: string | number,
  navListItems?: Array<string>,
}

const HeaderDefault: FunctionComponent<Props> = ({webcbt = WebCbt.clario, navListItems}) => {
  const {openMenuState, toggleOpenMenuState} = useHeaderContext();

  return (
    <header className={`header ${openMenuState ? 'header--open' : ''} header--${WebCbtFlowNames[webcbt]}`}>
      <div className='header__container container'>
        <NavLogo eventContext={EventContext.header} eventName={HeaderEvents.logo} className='header__logo'/>
        <BurgerMenu/>
        <div className='header__nav-content'>
          <aside className='header__nav header__aside'>
            <HeaderNavList navListItems={navListItems}/>
          </aside>
          <aside className='header__nav-close header__aside' onClick={toggleOpenMenuState}/>
        </div>
      </div>
    </header>)
};

export default HeaderDefault;
