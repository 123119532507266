import * as React from 'react';
import './VerifyEmailSignupForm.scss';

const MailIcon = require('../../../resources/images/icon-mail.svg').default;

const VerifyEmailSignupForm: React.FunctionComponent = () => {
  return (
    <div className='verify-email' id='verify-email'>
      <div className='verify-email__message'>
        <div className='verify-email__icon-wrapper'>
          <MailIcon className='verify-email__icon'/>
        </div>
        <h3 className='verify-email__title'>We'll send you a verification email.</h3>
        <p className='verify-email__text'>Check your mailbox (and the Spam folder too) to verify your email address so we know it's the real you.</p>
      </div>
    </div>
  );
};

export default VerifyEmailSignupForm;
