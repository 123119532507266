import React, {FunctionComponent} from 'react';
import {ListItemType} from '../HeaderNavList/HeaderNavList';
import InnerPageLinkItem from './InnerPageLinkItem';
import DefaultNavItem from './DefaultNavItem';
import LogoutItem from './LogoutItem';
import WithSubMenu from './WithSubMenu';
import {HeaderNavLinksName} from '../HeaderNavList/NavListItemsConfig';

const HeaderNavItem: FunctionComponent<ListItemType> = (props) => {
  const determineNavItemType = () => {
    const {name, subMenu} = props;

    if (subMenu) {
      return <WithSubMenu {...props}/>
    }

    switch (name) {
      case HeaderNavLinksName.account:
        return <InnerPageLinkItem/>;
      case HeaderNavLinksName.logout:
        return <LogoutItem />;
      default:
        return <DefaultNavItem {...props}/>
    }
  }

  return determineNavItemType()
}

export default React.memo(HeaderNavItem);
