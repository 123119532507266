import {Asset, AssetsList} from '../../../services/assets/assetsTypes';
import {ActiveStatusFromApi, AssetsStatusesMap} from '../../../constants';
import {determineCurrentAssetName} from './determineCurrentAssetName';

export function determineCurrentAsset(assetsList: AssetsList): Asset | {} {
  if (!assetsList.length) {
    return {};
  }

  // @ts-ignore
  const [activeAssets, notActiveAssets]: [AssetsList, AssetsList] = assetsList.reduce(([activeAssets, notActiveAssets]: any, asset: Asset) => {
    let enrichedAsset = {
      ...asset,
      device_quantity: asset.licenses.length,
      name: determineCurrentAssetName(asset),
    }
    return AssetsStatusesMap[enrichedAsset.status] === ActiveStatusFromApi
      ? [[...activeAssets, enrichedAsset], notActiveAssets]
      : [activeAssets, [...notActiveAssets, enrichedAsset]]
  }, [[], []]);

  if (activeAssets.length) {
    return activeAssets[0];
  }

  if (notActiveAssets.length) {
    return notActiveAssets[0];
  }

  return {};
}
