import {History} from 'history';
import {Dispatch} from 'redux';
import {CancelSubscriptionFailureReduxAction, CancelSubscriptionRequestReduxAction, CancelSubscriptionSuccessReduxAction} from './assetsActionsTypes';
import {assetsService} from '../../../services/assets/assets';
import {AssetsConstants} from '../../../constants';
import {getAssetsList} from './assetsActions';

export function cancelSubscription(assetId: string, history: History) {
  return (dispatch: Dispatch<CancelSubscriptionRequestReduxAction | CancelSubscriptionSuccessReduxAction | CancelSubscriptionFailureReduxAction | any>) => {
    dispatch(request());
    return assetsService.cancelSubscription(assetId, history)
      .then(
        () => {
          dispatch(success());
          dispatch(getAssetsList(history));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request(): CancelSubscriptionRequestReduxAction {
    return {type: AssetsConstants.CANCEL_SUBSCRIPTION_REQUEST, payload: {}}
  }

  function success(): CancelSubscriptionSuccessReduxAction {
    return {type: AssetsConstants.CANCEL_SUBSCRIPTION_SUCCESS, payload: {}}
  }

  function failure(error: Error): CancelSubscriptionFailureReduxAction {
    return {type: AssetsConstants.CANCEL_SUBSCRIPTION_FAILURE, payload: error}
  }
}

export function resetCancelSubscriptionError() {
  return (dispatch: Dispatch<CancelSubscriptionRequestReduxAction>) => {
    dispatch({type: AssetsConstants.CANCEL_SUBSCRIPTION_REQUEST, payload: {}});
  }
}
