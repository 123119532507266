import React, {FunctionComponent} from 'react';
import {EventContext, FooterEvents} from '../../../../constants';
import Copyright from '../../../../components/Copyright';
import NavLogo from '../../../../components/NavLogo';
import FooterNavList from '../../FooterNavList';

const FooterDefault: FunctionComponent = () => {
  return (
    <footer className='footer'>
      <div className='footer__container container'>
        <NavLogo
          eventContext={EventContext.footer}
          eventName={FooterEvents.logo}
          className='footer__logo'
          logoClassName='logo--white'
        />
        <FooterNavList/>
        <Copyright/>
      </div>
    </footer>
  )
};

export default React.memo(FooterDefault);
