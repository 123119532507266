import React, {FunctionComponent, MouseEvent, useEffect, useState} from 'react';
import {NavListItemsConfig} from './NavListItemsConfig';
import FooterNavItem from '../FooterNavItem';
import {EventContext, FooterEvents} from '../../../constants/events';
import {Events} from '../../../services/events/events';
import {FooterNavLinksName} from '../../../constants';

type Props = {
  navListItems?: Array<string>,
}

export type ListItemType = {
  name: string,
  link?: string,
  ariaLabel?: string,
  text?: string,
  subMenu?: Array<ListItemType>
}

const DefaultNavListItems = [FooterNavLinksName.privacyPolicy, FooterNavLinksName.cookie, FooterNavLinksName.eula, FooterNavLinksName.privacyNotice, FooterNavLinksName.terms, FooterNavLinksName.refund];

export type FooterMenuItems = 'logo' | 'privacyPolicy' | 'cookie' | 'eula' | 'privacyNotice' | 'terms' | 'refund';

const FooterNavList: FunctionComponent<Props> = ({navListItems = DefaultNavListItems}) => {
  const [navItems, changeNavItems] = useState<Array<ListItemType>>([]);

  useEffect(() => {
    formNavItems();
  }, [navListItems]);

  const handleMenuItemClick = (ev: MouseEvent<HTMLElement>) => {
    const menuItemElement: any = ev.target;

    if (!menuItemElement) {
      return;
    }

    const itemName: FooterMenuItems = menuItemElement.dataset.name;

    if (!itemName) {
      return;
    }

    if (FooterEvents[itemName])
      Events.send({
        context: EventContext.footer,
        event: FooterEvents[itemName],
      });
  }

  const formNavItems = () => {
    let navItemsConfig: Array<ListItemType> = [];
    navListItems.map(navListItem => {
      let navItem = NavListItemsConfig.find(listItem => listItem.name === navListItem);
      if (navItem) {
        navItemsConfig.push(navItem);
      }
    });
    changeNavItems((navItemsConfig as Array<ListItemType>));
  }

  return (
    <>
      {!!navItems.length && (
        <ul className='footer__menu' onClick={handleMenuItemClick}>
          {navItems.map(navListItem => (
            <FooterNavItem
              key={navListItem.name}
              {...navListItem}
            />
          ))}
        </ul>
      )}
    </>
  );
}

export default FooterNavList;
