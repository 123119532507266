export enum ChatRoom {
  unAuthorized = 'ClarioWeb',
  authorized = 'AccountClario',
}

export enum PageName {
  Main = 'Main',
  Login = 'Login',
  Signup = 'Signup',
  Forgot = 'Forgot',
  ResetPassword = 'Reset Password',
}

export const NotificationChatName = 'clario_account';
export const ChatName = 'zchat';

