import * as React from 'react';
import {useHeaderContext} from '../../Header';

const BurgerMenu: React.FunctionComponent = () => {
  const {openMenuState, toggleOpenMenuState} = useHeaderContext();

  return (
    <div className={`header__btn-wrapper ${openMenuState ? 'header__btn-wrapper--open' : ''}`} onClick={toggleOpenMenuState}>
      <span className='header__btn-line'/>
      <span className='header__btn-line'/>
      <span className='header__btn-line'/>
    </div>
  )
};

export default BurgerMenu;
