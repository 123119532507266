import {SignupUserBodyType} from './partnerActionsTypes';
import {History} from 'history';
import {Dispatch} from 'redux';
import {RegisterUserFailureReduxAction, RegisterUserRequestReduxAction, RegisterUserSuccessReduxAction} from '../user/userActionsTypes';
import {deviceService} from '../../../services/device/device';
import {PartnerIntegrationService} from '../../../services/partner/partnerIntegration';
import {sendGtmCustomEvent} from '../../../utils';
import {Routes} from '../../../router/Routes';
import {Events} from '../../../services/events/events';
import {EventContext, SignupEvents} from '../../../constants/events';
import {RegisterUserBody} from '../../../services/user/userTypes';
import {Tokens, UserConstants} from '../../../constants';

export function signupUser(user: SignupUserBodyType, history: History) {
  return (dispatch: Dispatch<RegisterUserRequestReduxAction | RegisterUserSuccessReduxAction | RegisterUserFailureReduxAction>, getState: () => any) => {
    const smartLinkToken = getState().partner.smartLinkToken || sessionStorage.getItem(Tokens.smartLink);

    dispatch(signupUserRequest());
    Promise.all([deviceService.registration(), PartnerIntegrationService.signupUser(user, smartLinkToken)])
      .then(() => {
        sendGtmCustomEvent('registrationComplete');
        sessionStorage.removeItem(Tokens.smartLink);
        dispatch(success(user));
        history.push(Routes.Download);
        Events.send({
          context: EventContext.general,
          event: SignupEvents.generalSignup,
        });
        Events.send({
          context: EventContext.signup,
          event: SignupEvents.signupSuccess,
        });
      })
      .catch((error: Error) => {
        Events.send({
          context: EventContext.signup,
          event: SignupEvents.signupError,
        });
        dispatch(failure(error));
      });
  };

  function success(user: RegisterUserBody): RegisterUserSuccessReduxAction {
    return {type: UserConstants.REGISTER_SUCCESS, payload: user}
  }

  function failure(error: Error): RegisterUserFailureReduxAction {
    return {type: UserConstants.REGISTER_FAILURE, payload: error}
  }
}

export function signupUserRequest(): RegisterUserRequestReduxAction {
  return {type: UserConstants.REGISTER_REQUEST, payload: {}}
}
