type ConvertedTime = {
  year: number,
  month: string,
  day: string,
  hour: string,
  minute: string,
  seconds: string,
}

export const dateFormat = {
  MDDYYYY: {month: 'short', day: 'numeric', year: 'numeric'},
  MDDhha: {month: 'short', day: 'numeric', hour: 'numeric', hour12: true}
}

export function localTimeConverter(timestamp = 0, format: Intl.DateTimeFormatOptions = dateFormat.MDDYYYY) {
  return new Date(timestamp * 1000).toLocaleString([], format);
}

export function timeConverter(timestamp = 0, monthType?: 'string'): ConvertedTime {
  const fullDate = new Date(timestamp * 1000);
  const monthsTextShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const monthsNumber = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  const year = fullDate.getUTCFullYear();
  const monthNumber = monthsNumber[fullDate.getUTCMonth()];
  const monthText = monthsTextShort[fullDate.getUTCMonth()];
  const day = fullDate.getUTCDate();
  const formattedDay = (day > 9 ? '' : '0') + day;
  const hour = fullDate.getUTCDay();
  const formattedHour = (hour > 9 ? '' : '0') + hour;
  const minute = fullDate.getUTCMinutes();
  const formattedMinute = (minute > 9 ? '' : '0') + minute;
  const seconds = fullDate.getUTCSeconds();
  const formattedSeconds = (seconds > 9 ? '' : '0') + seconds;

  return {
    year,
    month: monthType ? monthText : monthNumber,
    day: formattedDay,
    hour: formattedHour,
    minute: formattedMinute,
    seconds: formattedSeconds,
  }
}

export function formatAMPM(timestamp: number): string {
  const date = localTimeConverter(timestamp, dateFormat.MDDhha);
  const formattedDate = date.replace('AM', 'am').replace('PM', 'pm');

  return formattedDate;
}
