export const ApiAliases = {
  user: {
    resetPassword: 'resetPassword',
    checkVerificationCode: 'checkVerificationCode',
    login: 'login',
    getUserInfo: 'getUserInfo',
    signup: 'signup',
    forgot: 'sendForgotPasswordLink',
    downloadLinks: 'getDownloadLinks',
    logout: 'logout',
    changeConsents: 'changeConsents'
  },
  nexus: {
    getAssetsList: 'getAssetsList'
  },
  devices: {
    getDevicesList: 'getDevisesList'
  },
  partner: {
    signup: 'partnerSignup',
  },
  cde: {
    getDeleteAccountStatus: 'getDeleteAccountStatus'
  }
}

export const ApiMethod = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  DELETE: 'DELETE',
  PATCH: 'PATCH'
}

export const SubscriptionTypes = {
  without: 'without',
  noCC: {
    monthly: {
      active: 'noCC_monthly_active',
      finished: 'noCC_monthly_finished'
    }
  },
  trial: {
    monthly: {
      active: 'trial_monthly_active',
      deactivated: 'trial_monthly_deactivated',
      finished: 'trial_monthly_finished',
    },
    yearly: {
      active: 'trial_yearly_active',
      deactivated: 'trial_yearly_deactivated',
      finished: 'trial_yearly_finished',
    }
  },
  gifted: {
    yearly: {
      active: 'gifted_yearly_active',
      finished: 'gifted_yearly_finished'
    }
  },
  CCFsc: {
    monthly: {
      active: 'CCFsc_monthly_active',
      deactivated: 'CCFsc_monthly_deactivated',
      finished: 'CCFsc_monthly_finished',
    },
    yearly: {
      active: 'CCFsc_yearly_active',
      deactivated: 'CCFsc_yearly_deactivated',
      finished: 'CCFsc_yearly_finished',
    }
  },
  aps: {
    monthly: {
      active: 'aps_monthly_active',
      deactivated: 'aps_monthly_deactivated',
      finished: 'aps_monthly_finished',
    },
    yearly: {
      active: 'aps_yearly_active',
      deactivated: 'aps_yearly_deactivated',
      finished: 'aps_yearly_finished',
    }
  },
  gpm: {
    monthly: {
      active: 'gpm_monthly_active',
      deactivated: 'gpm_monthly_deactivated',
      finished: 'gpm_monthly_finished',
    },
    yearly: {
      active: 'gpm_yearly_active',
      deactivated: 'gpm_yearly_deactivated',
      finished: 'gpm_yearly_finished',
    }
  },
  ppg: {
    monthly: {
      active: 'ppg_monthly_active',
      deactivated: 'ppg_monthly_deactivated',
      finished: 'ppg_monthly_finished',
    },
    yearly: {
      active: 'ppg_yearly_active',
      deactivated: 'ppg_yearly_deactivated',
      finished: 'ppg_yearly_finished',
    }
  }
}

export const Subscriptions = [SubscriptionTypes.without];

export const DevicesOptionsName = {
  without: 'without',
}

export const GreetingTypes =  {
  unverifiedEmail: 'unverifiedEmail',
  withoutSubscription: 'withoutSubscription',
  noDevicesConnected: 'noDevicesConnected',
  finished: 'finished',
  active: 'active',
  finishedTrial: 'finishedTrial',
  deleteRequested: 'deleteRequested',
  canceled: 'canceled'
}

export const Consents = {
  marketingEmails: 'marketing_emails',
  marketingNotifications: 'marketing_notifications',
  appsStatistics: 'app_statistics',
  marketingName: 'marketing_name'
}

