import {useSelector} from 'react-redux';
import {Redirect, Route} from 'react-router-dom';
import {Routes} from './Routes';
import React, {ReactNode} from 'react';
import {AppState} from '../store/reducers/reducerType';

type PrivateRoutePropsType = {
  children: ReactNode,
}

const PrivateRoute = ({children, ...rest}: PrivateRoutePropsType) => {
  const isAuthenticated = useSelector((state: AppState) => state.user.isAuthenticated);
  return (
    <Route
      {...rest}
      render={() =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect to={{pathname: Routes.Login}}/>
        )
      }
    />
  );
};

export default PrivateRoute;
