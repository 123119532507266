import {History} from 'history';
import {Dispatch} from 'redux';
import {CdeConstants} from '../../../constants';
import {
  GetDeleteAccountStatusRequestReduxAction,
  GetDeleteAccountStatusSuccessReduxAction, GetDeleteAccountStatusFailureReduxAction, DeleteAccountRequestStatus
} from './cdeTypes';
import {cdeService} from '../../../services/cde';

export function getDeleteAccountStatus(history: History) {
  return (dispatch: Dispatch<GetDeleteAccountStatusRequestReduxAction | GetDeleteAccountStatusSuccessReduxAction | GetDeleteAccountStatusFailureReduxAction>) => {
    dispatch(getDeleteAccountStatusRequest());
    cdeService.getDeleteAccountStatus(history)
      .then((resp) => {
        dispatch(success(resp.status));
      })
      .catch((error: Error) => {
        dispatch(failure(error));
      });
  };

  function success(status: DeleteAccountRequestStatus): GetDeleteAccountStatusSuccessReduxAction {
    return {type: CdeConstants.GET_DELETE_ACCOUNT_STATUS_SUCCESS, payload: {status}}
  }

  function failure(error: Error): GetDeleteAccountStatusFailureReduxAction {
    return {type: CdeConstants.GET_DELETE_ACCOUNT_STATUS_FAILURE, payload: error}
  }
}

export function getDeleteAccountStatusRequest(): GetDeleteAccountStatusRequestReduxAction {
  return {type: CdeConstants.GET_DELETE_ACCOUNT_STATUS_REQUEST, payload: {}}
}
