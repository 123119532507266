import React, {FunctionComponent} from 'react';
import ActiveSubscription from './Types/ActiveSubscription';
import {FinishedStatusFromApi, DeactivatedStatusFromApi} from '../../../../constants';
import {useSubscriptionSectionContext} from '../Subscription';
import InactiveSubscription from './Types/InactiveSubscription/InactiveSubscription';
import AutoRenewalDisabledSubscription from './Types/AutoRenewalDisabledSubscription';

const WaooSubscription: FunctionComponent = () => {
  const {currentAsset} = useSubscriptionSectionContext();

  const determineSubscriptionStatus = () => {
    switch (currentAsset?.status) {
      case FinishedStatusFromApi:
        return <InactiveSubscription/>
      case DeactivatedStatusFromApi:
        return <AutoRenewalDisabledSubscription/>
      default:
        return <ActiveSubscription/>
    }
  }
  return (determineSubscriptionStatus())
}

export default WaooSubscription;
