import {History} from 'history';
import {GetAssetsListResponse} from './assetsTypes';
import fetch from 'node-fetch';
import {NexusAssetsApi, ServicesErrors} from '../../constants';
import {handleResponse, handleCommonErrorResponse, determineErrorLevelForSentry} from '../../utils';
import * as Sentry from '@sentry/browser';

export async function getAssetsList(history: History): Promise<GetAssetsListResponse> {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  };

  return fetch(NexusAssetsApi, requestOptions)
    .then(handleResponse)
    .catch(error => {
      const errorLevel = determineErrorLevelForSentry(error);

      Sentry.configureScope((scope) => {
        scope.setLevel(errorLevel);
      })

      Sentry.addBreadcrumb({
        category: 'assets',
        message: 'Endpoint ' + NexusAssetsApi,
        data: {
          error: error,
        },
        level: errorLevel
      });
      Sentry.captureMessage(ServicesErrors.assets.getAssetsListFailed, errorLevel);
      return handleCommonErrorResponse(error, history);
    });
}
