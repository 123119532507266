import {History} from 'history';
import {Dispatch} from 'redux';
import {GetAssetsListFailureReduxAction, GetAssetsListRequestReduxAction, GetAssetsListSuccessReduxAction, SetCurrentAssetReduxAction} from './assetsActionsTypes';
import {assetsService} from '../../../services/assets/assets';
import {AssetsConstants} from '../../../constants';
import {Asset, AssetsList} from '../../../services/assets/assetsTypes';
import {determineCurrentAsset} from './determineCurrentAsset';

export function getAssetsList(history: History) {
  return (dispatch: Dispatch<GetAssetsListRequestReduxAction | GetAssetsListSuccessReduxAction | GetAssetsListFailureReduxAction>) => {
    dispatch(request());

    return assetsService.getAssetsList(history)
      .then(
        (response) => {
          dispatch(setCurrentAsset(determineCurrentAsset(response.assets)));
          dispatch(success(response.assets));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request(): GetAssetsListRequestReduxAction {
    return {type: AssetsConstants.GET_ASSETS_LIST_REQUEST, payload: {}}
  }

  function success(assets: AssetsList): GetAssetsListSuccessReduxAction {
    return {type: AssetsConstants.GET_ASSETS_LIST_SUCCESS, payload: {assetsList: assets}}
  }

  function failure(error: Error): GetAssetsListFailureReduxAction {
    return {type: AssetsConstants.GET_ASSETS_LIST_FAILURE, payload: error}
  }

  function setCurrentAsset(currentAsset: Asset | {}): SetCurrentAssetReduxAction {
    return {type: AssetsConstants.SET_CURRENT_ASSET, payload: {assetInfo: currentAsset}}
  }
}

export function resetAssetsList() {
  return (dispatch: Dispatch<GetAssetsListRequestReduxAction>) => {
    dispatch({type: AssetsConstants.GET_ASSETS_LIST_REQUEST, payload: {}});
  }
}
