import React, {useCallback} from 'react';
import Title from '../../../../../components/Title';
import Instructions from '../Instructions';
import {DevicesEvents, EventContext, IconType} from '../../../../../constants';
import IconsWithBackground from '../../../../../components/Icons/IconsWithBackground';
import {useSelector} from 'react-redux';
import {selectCurrentAssetDevices} from '../../../../../store/reducers/device';
import {Events} from '../../../../../services/events/events';
import {formDeviceIdArray} from '../../../../../utils';

type Props = {
    downloadLink: string;
}
const DownloadInstructions = [
    'Click Download Clario',
    'Install the Clario app on your computer',
    'Log in with the email and password you\'ve created'
];

const DownloadIcon = require('../../../../../../resources/images/icon-download.svg').default;

const DesktopDownloadBlockItem = ({downloadLink}: Props) => {
    const currentAssetDevices = useSelector(selectCurrentAssetDevices);

    const handleDownloadClick = useCallback(() => {
        Events.send({
            context: EventContext.devices,
            event: DevicesEvents.detailed_area__download_clario_desktop_link_click,
            data: formDeviceIdArray(currentAssetDevices)
        });
        window.open(downloadLink, '_blank');
    }, [downloadLink])

    return (
        <div className={`download__block download__block--desktop`}>
            <aside className='download__aside download__aside--instruction'>
                <div className='download__header'>
                    <IconsWithBackground iconType={IconType.desktop} size='big' className='download__icon download__icon--header'/>
                    <Title text='Connect your computer' className='download__title'/>
                </div>
                <Instructions instructions={DownloadInstructions}/>
                <div className='download__source'>
                    <p className='download__text'>Want Clario on your computer? Click below and we\'ll email you a download link.</p>
                    <div className='download__source-content'>
                        <p className='download__source-link'>Send me a download link</p>
                    </div>
                </div>
            </aside>
            <aside
                className={`download__aside download__aside--source download__aside--source-desktop`}
                onClick={handleDownloadClick}
            >
                <DownloadIcon
                    className='download__icon download__icon--download  download__icon--download-desktop'
                />
                <p className='download__source-link'>
                    Download Clario
                </p>
            </aside>
        </div>
    );
};

export default DesktopDownloadBlockItem;
