import React from 'react';
import Notification from '../../../components/Notification';
import {DeviceDownloadBlock} from '../../../components/DeviceDownloadBlock';

const DesktopAndMobile = () => {
    return (
        <>
            <Notification className='download-page__notification'>
                We have sent you an email to verify your email address. Make sure to check your inbox and spam folder and click the verification link.
            </Notification>
            <div className='download-page__content'>
                <section className='download-page__section download-page__text-block'>
                    <h2 className='download-page__title'>Now download the Clario app.</h2>
                    <p className='download-page__text download-page__text--subtitle'>
                        Hit Download to install Clario for your PC. We also sent you an email with download link.
                    </p>
                </section>
                <DeviceDownloadBlock className='download-page__section download-page__download'/>
            </div>
        </>
    );
}

export default DesktopAndMobile;
