import * as React from 'react';
import './Aside.scss';
import Title from '../../components/Title';
import Subtitle from '../../components/Subtitle';

interface Props {
  title: string,
  titleClassName?: string,
  subtitle?: string,
}

const Aside: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <section className='content content--aside-left'>
      <div className='content__wrapper'>
        <Title text={props.title} className={`title--aside ${props.titleClassName || ''}`}/>
        {props.subtitle && <Subtitle text={props.subtitle}/>}
      </div>
    </section>
  );
};

export default Aside;
