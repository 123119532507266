import React, {useMemo} from 'react';
import './Download.scss';
import {MobileDownloadBlockItem, DesktopDownloadBlockItem} from './DownloadBlock';
import {WebCbt} from '../../../../constants';
import {useSelector} from 'react-redux';
import {selectWebCbt} from '../../../../store/reducers/partner';
import {useDownloadLinks} from '../../../../hooks';

const Download = () => {
  const webCbt = useSelector(selectWebCbt);
  const {desktopDownloadLink, mobileDownloadLink} = useDownloadLinks();
  const showDesktopDownload = useMemo(() => {
    return webCbt !== WebCbt.mkClarioMobile
  }, [webCbt]);

  return (
      <div className='devices__download download'>
        {showDesktopDownload ? <DesktopDownloadBlockItem downloadLink={desktopDownloadLink}/> : null}
        <MobileDownloadBlockItem downloadLink={mobileDownloadLink} />
      </div>
  )
};

export default Download;
