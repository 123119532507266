import React, {FunctionComponent} from 'react';

import ContentWrapper from '../../containers/ContentWrapper';
import Title from '../Title';
import Subtitle from '../Subtitle';
import Button from '../Buttons/Button';
import {Routes} from '../../router/Routes';
import {useHistory} from 'react-router-dom';

type Props = {
  clearSSOError: () => void;
}

const AuthError: FunctionComponent<Props> = ({clearSSOError}) => {
  const history = useHistory();

  const handleBackToLogin = () => {
    clearSSOError();
    history.push(Routes.Login);
  }

  return (
    <ContentWrapper className='container--error-page'>
      <div className='error-page'>
        <Title text='Something went wrong.' className='title--form'/>
        <Subtitle text='Try using your email to log back in.' className='subtitle--error subtitle--error-visible'/>
        <div className='button-wrapper button-wrapper--error button-wrapper--error-visible'>
          <Button
            type='button'
            className='button--primary button--green'
            action={handleBackToLogin}
            title='Back to login'/>
        </div>
      </div>
    </ContentWrapper>
  );
}

export default AuthError;
