import {Asset, PaymentGatewayType} from '../../../services/assets/assetsTypes';
import {AppEnv, BuyNowLinks} from '../../../constants';
import Url from '../../../utils/url';
import objectOmitNull from '../../../utils/objectOmitNull';
import isEmpty from '../../../utils/isEmpty';

declare var APP_ENV: string;

export function determineBuyNowLink(currentAsset: Asset | null): string {
  let buyNowLink = BuyNowLinks.default;
  let paymentGateway: PaymentGatewayType = !isEmpty(currentAsset) && currentAsset?.payment_gateway.length ? (currentAsset.payment_gateway as PaymentGatewayType) : PaymentGatewayType.Ppg;

  let buyNowLinkParams = {
    affid: localStorage.getItem('affid') || '',
    trexp: currentAsset && isEmpty(currentAsset) ? null : true,
  }

  if (APP_ENV === AppEnv.testing) {
    // @ts-ignore
    buyNowLink = BuyNowLinks.testingEnv[paymentGateway];
    return buyNowLink;
  }

  if (APP_ENV === AppEnv.staging) {
    // @ts-ignore
    buyNowLink = BuyNowLinks.stagingEnv[paymentGateway];
    return buyNowLink;
  }

  return Url.updateQueryStringParams(buyNowLink, objectOmitNull(buyNowLinkParams));
}
