import {UserStateType} from './types';
import {DefaultInitialData} from '../../actions/actionsTypes';

// @ts-ignore
export const DefaultUserState: UserStateType = (window.Cypress && window.initialState) || {
    loginStatus: {
        status: false,
        error: '',
    },
    changePasswordStatus: {
        status: false,
        error: ''
    },
    changeUserNameStatus: {
        status: false,
        error: ''
    },
    forgotPasswordStatus: {
        status: false,
        error: '',
    },
    registrationStatus: {
        status: false,
        error: '',
    },
    resetPasswordStatus: {
        status: false,
        error: ''
    },
    generateDownloadLinksStatus: {
        status: false,
        error: ''
    },
    consents: {
        marketing_name: false,
        app_statistics: true,
        marketing_emails: false,
        marketing_notifications: false,
    },
    userInfo: {
        id: '',
        name: '',
        email: '',
        is_email_verified: false,
    },
    userInfoStatus: {
        status: false,
        error: '',
    },
    checkCodeStatus: {
        status: false,
        error: '',
    },
    logoutStatus: {
        status: false,
        error: '',
    },
    verificationCode: '',
    loginInfo: null,
    registrationInfo: null,
    forgotPasswordInfo: null,
    isAuthenticated: null,
    downloadLinks: null,
    autoLoginParams: DefaultInitialData,
};