import {
  SetPartnerNameReduxAction,
  VerifySmartLinkTokenFailureReduxAction,
  VerifySmartLinkTokenRequestReduxAction,
  VerifySmartLinkTokenSuccessReduxAction,
} from './partnerActionsTypes';
import {PartnerIntegrationService} from '../../../services/partner/partnerIntegration';
import {ApiErrors, PartnersActions} from '../../../constants';
import {setPartnerName} from './setPartnerName';
import {ThunkDispatch} from 'redux-thunk';
import {AppState} from '../../reducers/reducerType';
import {setAffidUuid} from './setAffidUuid';

export function verifySmartLinkToken(token: string) {
  return (dispatch: ThunkDispatch<AppState, void, VerifySmartLinkTokenRequestReduxAction | VerifySmartLinkTokenSuccessReduxAction | VerifySmartLinkTokenFailureReduxAction | SetPartnerNameReduxAction>) => {
    dispatch(request());

    PartnerIntegrationService.verifySmartLinkToken(token)
      .then((response) => {
        const {affid_uuid, partner_name} = response;
        setAffidUuid(affid_uuid);
        dispatch(success(token));
        dispatch(setPartnerName(partner_name));
      }).catch(error => {

        if (error.message === ApiErrors.smart_link_used) {
          dispatch(setPartnerName(error.partner_name));
          setAffidUuid(error.affid_uuid);
        }
        dispatch(failure(error));
      }
    );

    function request(): VerifySmartLinkTokenRequestReduxAction {
      return {type: PartnersActions.VERIFY_SMART_LINK_TOKEN_REQUEST, payload: {}}
    }

    function success(token: string): VerifySmartLinkTokenSuccessReduxAction {
      return {type: PartnersActions.VERIFY_SMART_LINK_TOKEN_SUCCESS, payload: {token: token}}
    }

    function failure(error: Error): VerifySmartLinkTokenFailureReduxAction {
      return {type: PartnersActions.VERIFY_SMART_LINK_TOKEN_FAILURE, payload: error}
    }
  }
}
