import React, {useEffect} from 'react';
import {BrowserRouter, useLocation} from 'react-router-dom';
import SwitchWrapper from './SwitchWrapper';
import {useSelector, useDispatch} from 'react-redux';
import {AppState} from '../store/reducers/reducerType';
import Loader from '../components/Loader';
import TagManager from 'react-gtm-module';
import {hotjarTriggerPoll, getDeviceId} from '../utils';
import {UserInfo, WebCbt, PageActions, AppEnv} from '../constants';
import {PartnerName} from '../services/user/userTypes';
import {getUserInfo, setWebCbt, setPartnerName} from '../store/actions';

declare var APP_ENV: string;

const tagManagerArgs = {
  gtmId: 'GTM-KDQV4H2',
};

export function ScrollToTop() {
  const scrollTop = useSelector((state: AppState) => state.page.scrollTop);
  const {pathname} = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    startScroll();
  }, [pathname]);

  useEffect(() => {
    if (scrollTop) {
      startScroll();
      dispatch({type: PageActions.SCROLL_TOP_PAGE})
    }

  }, [scrollTop]);

  const startScroll = () => {
    window.scrollTo(0, 0);
  }

  return null;
}

const Router = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state: AppState) => state.user.isAuthenticated);

  const determinePartnerData = () => {
    let webCbt = sessionStorage.getItem(UserInfo.webcbt) || WebCbt.clario;
    let partnerName = sessionStorage.getItem(UserInfo.partnerName) || PartnerName.clario;

    dispatch(setPartnerName(partnerName));
    dispatch(setWebCbt(webCbt));
  }

  useEffect(() => {
    getDeviceId();
    determinePartnerData();
    dispatch(getUserInfo());

    if (APP_ENV !== AppEnv.testing) {
      TagManager.initialize(tagManagerArgs);
      hotjarTriggerPoll();
    }
  }, []);


  if (isAuthenticated == null) {
    return <Loader className="loader--main"/>;
  }

  return (
    <BrowserRouter>
        <ScrollToTop/>
      <SwitchWrapper/>
    </BrowserRouter>
  );
};

export default Router;
