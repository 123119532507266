import {DefaultPagesState} from './constants';
import {PagesStateType} from './types';
import {PageActions, UserConstants} from '../../../constants';


export default (state = DefaultPagesState, action: any): PagesStateType => {
  switch (action.type) {
    case PageActions.changeClassName:
      return {
        ...state,
        className: action.payload.className,
      };
    case PageActions.chatInitialized:
      return {
        ...state,
        chatInitialized: !state.chatInitialized,
      };
    case UserConstants.LOGOUT_SUCCESS:
      return {...DefaultPagesState, className: state.className, zchatLibLoaded: state.zchatLibLoaded};
    case PageActions.updateDataFromApi:
      return {
        ...state,
        updateDataFromApi: action.payload.value,
      };
    case PageActions.SET_ZCHAT_LIB_LOADED:
      return {
        ...state,
        zchatLibLoaded: action.payload.loaded,
      };
    case PageActions.SET_THANK_YOU_PAGE_CONTENT:
      return {
        ...state,
        thankYouPageContent: action.payload.content,
      };
    case PageActions.SCROLL_TOP_PAGE:
      return {
        ...state,
        scrollTop: !state.scrollTop,
      };
    default:
      return state;
  }
}
