import {History} from 'history';
import fetch from 'node-fetch';
import {DeviceApi, ServicesErrors} from '../../constants';
import * as Sentry from '@sentry/browser';
import {handleCommonErrorResponse, handleResponse, determineErrorLevelForSentry} from '../../utils';
import {DevicesListResponseFromApi} from './deviceTypes';

export async function getDevicesList(history: History): Promise<DevicesListResponseFromApi> {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  };

  return fetch(DeviceApi, requestOptions)
    .then(handleResponse)
    .catch(error => {
      const errorLevel = determineErrorLevelForSentry(error);

      Sentry.configureScope((scope) => {
        scope.setLevel(errorLevel);
      })

      Sentry.addBreadcrumb({
        category: 'device',
        message: 'Endpoint ' + DeviceApi,
        data: {
          error: error,
          requestData: requestOptions,
        },
        level: errorLevel,
      });
      Sentry.captureMessage(ServicesErrors.device.getDeviceListFailed, errorLevel);
      return handleCommonErrorResponse(error, history);
    });
}
