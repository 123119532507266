import {DeviceConstants, UserConstants} from '../../../constants';
import {EnrichedDevice} from '../../../services/device/deviceTypes';
import {DeviceStateType} from './types';
import {DefaultDeviceState} from './constants';


export default (state = DefaultDeviceState, action: any): DeviceStateType => {
  switch (action.type) {
    case DeviceConstants.GET_DEVICES_LIST_REQUEST:
      return {
        ...state,
        devicesList: null,
        deviceListStatus: {
          status: false,
          error: '', // flash error before request
        }
      };
    case DeviceConstants.GET_DEVICES_LIST_SUCCESS:
      return {
        ...state,
        devicesList: action.payload.devicesList,
        deviceListStatus: {
          status: true,
          error: '',
        }
      };
    case DeviceConstants.GET_DEVICES_LIST_FAILURE:
      return {
        ...state,
        deviceListStatus: {
          status: false,
          error: action.payload.message,
        },
      };
    case DeviceConstants.SET_DEVICES_STATUS:
      return {
        ...state,
        devicesStatus: action.payload.status,
      };
    case DeviceConstants.DISCONNECT_DEVICE_REQUEST:
      return {
        ...state,
        disconnectDeviceStatus: {
          ...state.disconnectDeviceStatus,
          [action.payload.deviceId]: {
            status: false,
            error: '', // flash error before request
          },
        },
      };
    case DeviceConstants.DISCONNECT_DEVICE_SUCCESS:
      return {
        ...state,
        disconnectDeviceStatus: {
          ...state.disconnectDeviceStatus,
          [action.payload.deviceId]: {
            status: true,
            error: ''
          },
        },
      };
    case DeviceConstants.DISCONNECT_DEVICE_FAILURE:
      return {
        ...state,
        disconnectDeviceStatus: {
          ...state.disconnectDeviceStatus,
          [action.payload.deviceId]: {
            status: false,
            error: action.payload.error.message,
          },
        },
      };
    case DeviceConstants.SET_CURRENT_ASSET_DEVICES:
      return {
        ...state,
        currentAssetDevices: action.payload.devicesList,
      };
    case DeviceConstants.SET_DEVICE_ITEM_SCREEN_TYPE:
      let updatedAssetsInfo: Array<EnrichedDevice> | null = state.currentAssetDevices;

      // Update screen Type for device by id
      if (state.currentAssetDevices != null && state.currentAssetDevices.length) {
        updatedAssetsInfo = state.currentAssetDevices.map((device: EnrichedDevice) => {
          if (device.id === action.payload.deviceId) {
            return {...device, screen_type: action.payload.screenType};
          }

          return {...device};
        });
      }

      return {
        ...state,
        currentAssetDevices: updatedAssetsInfo,
      };
    case UserConstants.LOGOUT_SUCCESS:
      return DefaultDeviceState;
    default:
      return state;
  }
}
