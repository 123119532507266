export type LoginFormEventsName = 'forgot' | 'login' | 'loginSuccess' | 'loginError' | 'signup' | 'open';

export const LoginEvents: Record<LoginFormEventsName, string> = {
  open: 'page__opened',
  forgot: 'page__forgot_password_link_click',
  login: 'page__login_button_click',
  loginSuccess: 'page__login_success',
  loginError: 'page__login_error',
  signup: 'page__create_account_link_click',
}

