import {Severity} from '@sentry/types/dist/severity';
import {ErrorType} from '../store/actions/actionsTypes';

export default function determineErrorLevelForSentry(error: ErrorType): Severity {
  switch (error.status) {
    case 400:
    case 500:
      return Severity.Error;
    default:
      return Severity.Info;
  }
}
