import React, {ReactNode, useEffect} from 'react';
import CircleButton, {CircleButtonTypes} from '../../../components/Buttons/CircleButton/CircleButton';
import {useModalPopupContext} from '../ModalWrapper/ModalPopupWrapper';
import {withResizeDetector} from 'react-resize-detector';
import {sendChangeSizeEvent} from '../../../utils/sendCustomEvent';
import {AnimationState} from '../../../constants';

type Props = {
  children: ReactNode;
  height: number,
  scroll?: boolean;
}

const hideModalAnimationStartEvent = new Event(AnimationState.hideModalStart);

const ModalContentWrapper = ({children, height, scroll = true}: Props) => {
  const {hideModal} = useModalPopupContext();

  useEffect(() => {
    sendChangeSizeEvent(height, scroll);
  }, [height]);

  if (!hideModal) {
    return null;
  }

  return (
    <div className='popup__wrapper'>
      <CircleButton
        type={CircleButtonTypes.close}
        className='popup__close-button'
        text='Back'
        action={() => {
          window.dispatchEvent(hideModalAnimationStartEvent);
        }}/>
      {children}
    </div>
  )
}

export default withResizeDetector(ModalContentWrapper);
