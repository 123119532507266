import {EnrichedDevice} from '../services/device/deviceTypes';

export default function formDeviceIdArray(currentAssetDevices: Array<EnrichedDevice> | null): Array<string> {
  let devicesIdArray: Array<string> = [];

  if (!currentAssetDevices) {
    return devicesIdArray;
  }

  currentAssetDevices.map((device) => {
    if (!device.id) {
      return;
    }
    devicesIdArray.push(device.id)
  });

  return devicesIdArray;
}
