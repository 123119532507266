import {WebCbt, WebCbtFlowNames} from '../../../constants';

export const signupApiErrors: Record<any, Record<string, string>> = {
  [WebCbtFlowNames[WebCbt.clario]]: {
    email_already_exists: 'Looks like this email\'s taken. Try logging in.',
    invalid_email: 'Uh-oh. Email doesn\'t look valid.',
    invalid_password: 'This password doesn\'t look valid.',
    password_contains_space: 'This password doesn\'t look valid.',
    invalid_user_name: 'Use only letters, digits, or spaces in your name.',
    user_name_too_long: 'Keep it under %maxNumber% characters, please.',
    password_is_less_6_symbols: 'Has to be at least 6 characters.',
    password_does_not_contain_a_letter: 'Your password needs a letter.',
    password_does_not_contain_a_number: 'Add a digit to make your password more secure.',
    invalid_request_body: 'invalid_request_body',
    invalid_device_id: 'invalid_device_id',
    undefined_error: 'undefined_error',
    invalid_empty_consents: 'invalid_empty_consents',
    invalid_app_statistic_consent: 'invalid_app_statistic_consent',
    invalid_marketing_name_consent: 'invalid_marketing_name_consent',
    invalid_marketing_emails_consent: 'invalid_marketing_emails_consent',
    invalid_affid: 'invalid_affid',
  },
  [WebCbtFlowNames[WebCbt.waoo]]: {
    email_already_exists: 'This email address is taken. Use another one for %partner_name%.',
  },
  [WebCbtFlowNames[WebCbt.stackCommerce]]: {
    email_already_exists: 'This email address is taken. Use another one.',
  },
  [WebCbtFlowNames[WebCbt.mkClarioMobile]]: {
    email_already_exists: 'This email address is taken. Use another one.',
  },
};

export const buttonName: any = {
  [WebCbtFlowNames[WebCbt.clario]]: 'Create an account',
  [WebCbtFlowNames[WebCbt.waoo]]: 'Create your account'
}
