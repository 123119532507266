import {PartnersActions, UserConstants} from '../../../constants';
import {PartnerStateType} from './types';
import {DefaultPartnerState} from './constants';

export default (state = DefaultPartnerState, action: any): PartnerStateType => {
  switch (action.type) {
    case PartnersActions.VERIFY_DOWNLOAD_TOKEN_REQUEST:
      return {
        ...state,
        verifyDownloadLinkStatus: {
          status: false,
          error: '',
        },
        downloadToken: action.payload.token,
      };
    case PartnersActions.VERIFY_DOWNLOAD_TOKEN_SUCCESS:
      return {
        ...state,
        verifyDownloadLinkStatus: {
          status: true,
          error: '',
        },
        downloadToken: '',
      };
    case PartnersActions.VERIFY_DOWNLOAD_TOKEN_FAILURE:
      return {
        ...state,
        verifyDownloadLinkStatus: {
          status: false,
          error: action.payload.message,
        },
      };
    case PartnersActions.SEND_DOWNLOADS_LINKS_REQUEST:
      return {
        ...state,
        sendDownloadLinksStatus: {
          status: false,
          error: '',
        },
      };
    case PartnersActions.SEND_DOWNLOADS_LINKS_SUCCESS:
      return {
        ...state,
        sendDownloadLinksStatus: {
          status: true,
          error: '',
        },
      };
    case PartnersActions.SEND_DOWNLOADS_LINKS_FAILURE:
      return {
        ...state,
        sendDownloadLinksStatus: {
          status: false,
          error: action.payload.message,
        },
      };
    case PartnersActions.VERIFY_SMART_LINK_TOKEN_REQUEST:
      return {
        ...state,
        verifySmartLinkTokenStatus: {
          status: false,
          error: '',
        },
        smartLinkToken: '',
      }
    case PartnersActions.VERIFY_SMART_LINK_TOKEN_SUCCESS:
      return {
        ...state,
        verifySmartLinkTokenStatus: {
          status: true,
          error: '',
        },
        smartLinkToken: action.payload.token,
      }
    case PartnersActions.VERIFY_SMART_LINK_TOKEN_FAILURE:
      return {
        ...state,
        verifySmartLinkTokenStatus: {
          status: false,
          error: action.payload.message,
        },
      }
    case PartnersActions.SET_PARTNER_NAME:
      return {
        ...state,
        partnerName: action.payload.name,
      }
    case PartnersActions.SET_WEB_CBT:
      return {
        ...state,
        webcbt: action.payload.webcbt,
      }
    case UserConstants.LOGOUT_SUCCESS:
      return DefaultPartnerState;
    default:
      return state;
  }
}
