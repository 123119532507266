import fetch from 'node-fetch';
import {GetDesktopDownloadLinkApi, ServicesErrors} from '../../constants';
import {determineErrorLevelForSentry, handleResponse, isEmpty, objectOmitNull} from '../../utils';
import * as Sentry from '@sentry/browser';
import Url from '../../utils/url';
import {Severity} from '@sentry/types/dist/severity';

export interface DesktopDownloadLinkParamsType {
    affid?: string | null;
    bundleId?: string | null;
    ga_cid?: string | null;
}

export interface DesktopDownloadLinksType {
    macos: string | null,
    windows: string | null
}

export function getDesktopDownloadLinks(params: DesktopDownloadLinkParamsType): Promise<DesktopDownloadLinksType> {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    };
    const downloadLinkParams = objectOmitNull(
        {
            affid: params?.affid,
            bundle_id: params?.bundleId
        }
    );
    
    if (!downloadLinkParams || isEmpty(downloadLinkParams)) {
        Sentry.captureMessage(ServicesErrors.download.getDesktopDownloadLinkEmptyParams, Severity.Error);
        return Promise.reject({message: ServicesErrors.download.getDesktopDownloadLinkEmptyParams});
    }
    
    const DownloadLink = Url.updateQueryStringParams(GetDesktopDownloadLinkApi, downloadLinkParams);

    return fetch(DownloadLink, requestOptions)
        .then(handleResponse)
        .catch(error => {
            const errorLevel = determineErrorLevelForSentry(error);
            Sentry.configureScope((scope) => {
                scope.setLevel(errorLevel);
            });

            Sentry.addBreadcrumb({
                category: 'user',
                message: 'Endpoint ' + GetDesktopDownloadLinkApi,
                data: {
                    error: error,
                },
                level: errorLevel,
            });
            Sentry.captureMessage(ServicesErrors.download.getDesktopDownloadLink, errorLevel);
            return Promise.reject({message: error.error_message ? error.error_message : error.message});
        });
}