import {combineReducers} from 'redux';
import {user} from './user';
import {assets} from './assets';
import {device} from './device';
import {popups} from './popups';
import emailVerification from './emailVerification';
import {partner} from './partner';
import {download} from './download';
import {cde} from './cde';
import {sso} from './sso';
import {page} from './page';

const reducers = combineReducers({
  user,
  assets,
  device,
  popups,
  page,
  emailVerification,
  partner,
  cde,
  sso,
  download,
});

export default reducers;
