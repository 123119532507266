import {connect} from 'react-redux';
import DisconnectCongratulation from './DisconnectCongratulation';
import {hideModal, setUpdateDataFromApi, disconnectDeviceRequest} from '../../../../../store/actions';
import {AppState} from '../../../../../store/reducers/reducerType';
import {EnrichedDevice} from '../../../../../services/device/deviceTypes';

type StateType = {
  currentAssetDevices: Array<EnrichedDevice> | null,
}

const mapStateToProps = (state: AppState): StateType => ({
  currentAssetDevices: state.device.currentAssetDevices,
});



const mapDispatchToProps = (dispatch: any) => ({
  hideModal: (): void => {
    dispatch(hideModal());
  },
  setUpdateDataFromApi: (value: boolean): void => {
    dispatch(setUpdateDataFromApi(value));
  },
  resetDisconnectDeviceStatus: (deviceId: string): void => {
    dispatch(disconnectDeviceRequest(deviceId));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)((DisconnectCongratulation as any));
