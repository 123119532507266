import {DownloadStateType} from './types';
import {DefaultDownloadState} from './constants';
import {DesktopDownloadLinks} from './actions/desktopDownloadLinks';
import {RequestsStatus} from '../../actions/actionsTypes';

export default (state = DefaultDownloadState, action: any): DownloadStateType => {
    switch (action.type) {
        case DesktopDownloadLinks.GET_DESKTOP_DOWNLOAD_LINKS_REQUEST: {
            return {
                ...state,
                desktopDownloadLinks: {
                    status: RequestsStatus.Loading,
                    data: null,
                    error: null,
                },
            }
        }
        case DesktopDownloadLinks.GET_DESKTOP_DOWNLOAD_LINKS_SUCCESS: {
            return {
                ...state,
                desktopDownloadLinks: {
                    status: RequestsStatus.Success,
                    data: action.payload,
                    error: null,
                },
            }
        }
        case DesktopDownloadLinks.GET_DESKTOP_DOWNLOAD_LINKS_FAILURE: {
            return {
                ...state,
                desktopDownloadLinks: {
                    status: RequestsStatus.Failed,
                    data: null,
                    error: action.payload.error,
                },
            }
        }
        default:
            return state
    }
}