import React from 'react';
import {fullFillStringWithPlaceholder} from './fullFillStringWithPlaceholder';

export const errorRules = {
  name: {
    required: 'Let us know what you\'d like us to call you.',
    tooLong: 'Keep it under %maxNumber% characters, please.',
  },
  email: {
    required: 'Let\'s type your email address in here.',
    validationFailed: 'Hmm... email doesn\'t look right.',
    tooLong: 'Make sure your email fits %maxNumber% characters.',
  },
  password: {
    required: 'Let\'s set your password and we\'ll move on.',
    existPasswordRequired: 'Enter your password and we’ll move on.',
    previousRequired: 'Enter your current password and we’ll move on.',
    oldRequired: 'Enter your old password and we’ll move on.',
    matchOld: 'New password has to be different from your old one.',
    oldWrong: 'Uh-oh...your old password doesn’t look right.',
    length: 'Has to be at least 6 characters.',
    number: 'Add a digit to make your password more secure.',
    upperCaseLetter: 'Your password needs an uppercase letter.',
    lowerCaseLetter: 'Your password needs a lowercase letter.',
    notEqual: 'Hmmm, looks like the passwords don’t match.',
    tooLong: 'Your password should be a max of %maxNumber% characters.',
  },
};

export type ErrorItem = {
  message: string,
  type: string,
  ref: React.Ref<HTMLInputElement>,
}

export const passwordIncludeNumberRegexp = /(?=.*\d)/;
export const passwordIncludeUpperCaseLetterRegexp = (/(?=.*[A-Z])/);
export const passwordIncludeLowerCaseLetterRegexp = (/(?=.*[a-z])/);

export const emailRegexp = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const maxInputValueLength = 255;
export const maxEmailValueLength = 80;
export const maxNameValueLength = 20;
export const minPasswordValueLength = 6;

export type FormTypes = 'signup' | 'login' | 'forgot' | 'resetPassword' | 'changePassword' | 'changeUserName' | 'delete';

export const inputPlaceholders: Record<FormTypes, any> = {
  signup: {
    name: 'What can we call you?',
    email: 'What\'s your email address?',
    password: 'Now create a strong password',
  },
  login: {
    email: 'Email',
    password: 'Password',
  },
  delete: {
    password: 'Enter your password',
  },
  forgot: {
    email: 'What\'s your email address?',
  },
  resetPassword: {
    password: 'Your new password',
    confirmPassword: 'Please confirm it',
  },
  changePassword: {
    oldPassword: 'Your old password',
    password: 'Your new password',
    confirmPassword: 'Please confirm',
  },
  changeUserName: {
    name: 'Enter your name',
  }
};

export function generateErrorMessageMaxLength(inputType: 'name' | 'email' | 'password'): string {
  let errorMessage = errorRules[inputType].tooLong;
  let maxNumber = maxInputValueLength;

  if (!errorMessage) {
    return '';
  }

  switch (inputType) {
    case 'email':
      maxNumber = maxEmailValueLength;
      break;
    case 'name':
      maxNumber = maxNameValueLength;
      break;
    default:
  }

  return fullFillStringWithPlaceholder(errorMessage, maxNumber)
}
