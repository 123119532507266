export const GdprBannerKeys = {
  hideLocally: 'consentBanner',
}

export const Affid = 'affid';

export const Ltk = 'ltk';

export const UTMKeys = {
  campaign: 'utm_campaign',
  medium: 'utm_medium',
  source: 'utm_source',
}

export const UserInfo = {
  partnerName: 'partnerName',
  affidUuid: 'uuid',
  webcbt: 'webcbt'
};

export const Tokens = {
  smartLink: 'ltk'
}
