import {AppEnv} from './envConstants';

declare var API_USER_HOST: string;
declare var API_DEVICE_HOST: string;
declare var DOMAIN: string;
declare var APP_ENV: string;

// User Api
export const UserApi = `${API_USER_HOST}/v1/user`;
export const UserLoginApi = `${API_USER_HOST}/v1/user/login`;
export const UserChangePasswordApi = `${API_USER_HOST}/v1/user/password`;
export const UserCheckCodeApi = `${API_USER_HOST}/v1/verification-code/auth`;
export const UserChangeConsentsApi = `${API_USER_HOST}/v1/user/consents`;
export const UserSendVerificationEmail = `${API_USER_HOST}/v1/verification-code/email`;
export const UserVerifyVerificationEmailCode = `${API_USER_HOST}/v1/verification-code/email/verify`;
export const UserLogoutApi = `${API_USER_HOST}/v1/user/logout`;
export const GenerateAppDownloadLinksApi = `${API_USER_HOST}/v1/autologin/download-link`;
export const GetAutoLoginParamsApi = `${API_USER_HOST}/v1/autologin/params`;

export const DeviceApi = `${API_DEVICE_HOST}/v1/device`;
export const DeviceForgotPasswordApi = `${API_DEVICE_HOST}/v1/verification-code/forgot-password`;

// Nexus Api
export const NexusApi = `${API_DEVICE_HOST}/v1/nexus`;
export const NexusAssetsApi = `${NexusApi}/asset`;
export const NexusTrialCheckerApi = `${NexusApi}/asset/trial`;
export const LicenseApi = `${NexusApi}/license`;

// CDE Api
export const CDEApi = `${API_DEVICE_HOST}/v1/cde`;
export const DeleteAccountApi = `${CDEApi}/forget`;
export const GetDeleteAccountStatusApi = `${CDEApi}/forget/status`;

// SSO Api
export const AppleAuthRedirectLink = `${API_USER_HOST}/v1/auth/apple/url`;
export const GoogleAuthWebRequestApi = `${API_USER_HOST}/v1/auth/google-request/web`;

// Sales Force Api
export const SalesForceDomain = () => {
  return APP_ENV == AppEnv.production ? 'https://webto.salesforce.com' : 'https://zoomsupport--sabihasb2.my.salesforce.com';
};

export const SalesForceApi = `${SalesForceDomain()}/servlet/servlet`;
export const WebToCaseApi = `${SalesForceApi}.WebToCase?encoding=UTF-8`;

export const AnalyticsApi = APP_ENV == AppEnv.testing ? 'https://event.sz.clario.co/event' : `https://event.${DOMAIN}/event`;

// LDR
export const AffidUuid = {
  [AppEnv.production]: 'a5554042-6a92-11ed-8b20-126d24bfc983',
  [AppEnv.staging]: 'f52c59b4-6ca1-11ed-93ae-a6ca4219b0c0',
  [AppEnv.testing]: 'f52c59b4-6ca1-11ed-93ae-a6ca4219b0c0'
}

// Download System
export const GetDesktopDownloadLinkApi = APP_ENV == AppEnv.production ? 'https://dl.clario.co/link' : 'https://dl.sz.clario.co/link';

export const CreateAffidLink = (uuid: string) => {
  return APP_ENV == AppEnv.production ? `https://clario.co/link/${uuid}?noguid&getaffid` : `https://ldr.sz.kromtech.net/link/${uuid}?noguid&getaffid`;
}

// Firebase
export const FirebaseShortenUrlApi = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyAw-SpLHVTIP3IFEIkckCuEmIhnUrY9OrQ`;
export const ShortUrlDomain = 'https://lnk.clario.co';

//ZChat
export const ZChatUrl = APP_ENV === AppEnv.production ? 'https://zchat.account.clario.co/js/zchat.js' : 'https://zchat.account.sz.clario.co/js/zchat.js';
