import {LoginUserBody} from './userTypes';
import fetch from 'node-fetch';
import {UserLoginApi, ServicesErrors} from '../../constants';
import {handleResponse} from '../../utils';
import * as Sentry from '@sentry/browser';
import {Severity} from '@sentry/browser';
import {ErrorType} from '../../store/actions/actionsTypes';

export function login(userData: LoginUserBody): Promise<string> {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(userData)
  };

  return fetch(UserLoginApi, requestOptions)
    .then(handleResponse)
    .catch(error => {
      const errorLevel = determineErrorLevel(error);

      Sentry.configureScope((scope) => {
        scope.setLevel(errorLevel);
      })

      Sentry.addBreadcrumb({
        category: 'auth',
        message: 'Endpoint ' + UserLoginApi,
        data: {
          error: error,
          device_id: userData.device_id,
        },
        level: Sentry.Severity.Error
      });
      Sentry.captureMessage(`${ServicesErrors.user.loginUserFailed} with ${error.message}`, Sentry.Severity.Error);
      throw new Error(error.message);
    });
}

function determineErrorLevel(error: ErrorType): Severity {
  switch (error.status) {
    case 400:
    case 500:
      return Severity.Error;
    case 401:
      return Severity.Info;
    case 403:
      return Severity.Warning;
    default:
      return Severity.Error;
  }
}
