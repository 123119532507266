import {connect} from 'react-redux';
import VerifySmartLink from './VerifySmartLink';
import {AppState, RequestStatus} from '../../store/reducers/reducerType';
import {verifySmartLinkToken, setWebCbt} from '../../store/actions';

type StateType = {
  verifySmartLinkTokenStatus: RequestStatus,
}

const mapStateToProps = (state: AppState): StateType => ({
  verifySmartLinkTokenStatus: state.partner.verifySmartLinkTokenStatus,
});

const mapDispatchToProps = (dispatch: any) => ({
  verifySmartLinkToken: (token: string): void => {
    dispatch(verifySmartLinkToken(token));
  },
  setWebCbt: (webcbt: number | string): void => {
    dispatch(setWebCbt(webcbt));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)((VerifySmartLink as any));
