import {History} from 'history';
import fetch from 'node-fetch';
import {UserSendVerificationEmail, ServicesErrors} from '../../constants';
import {handleResponse, handleCommonErrorResponse, determineErrorLevelForSentry} from '../../utils';
import * as Sentry from '@sentry/browser';

export function sendVerificationEmail(history: History): Promise<string> {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  };

  return fetch(UserSendVerificationEmail, requestOptions)
    .then(handleResponse)
    .catch(error => {
      const errorLevel = determineErrorLevelForSentry(error);

      Sentry.configureScope((scope) => {
        scope.setLevel(errorLevel);
      })

      Sentry.addBreadcrumb({
        category: 'auth',
        message: 'Endpoint ' + UserSendVerificationEmail,
        data: {
          error: error,
          requestOptions,
        },
        level: errorLevel
      });
      Sentry.captureMessage(ServicesErrors.emailVerification.sendEmailVerificationFailed, errorLevel);
      return handleCommonErrorResponse(error, history)
    });
}
