import React, {ChangeEvent, FocusEvent, ReactElement, useState} from 'react';
import './Input.scss';
import InputDefault from './InputDefault';
import InputPassword from './InputPassword';
import {ErrorItem} from '../../utils/formValidationRules';
import ErrorMessage from '../ErrorMessage';

export type InputProps = {
  name: string,
  placeholder: string,
  type: string,
  errors?: ErrorItem | null,
  succeeded?: boolean,
  inputValue?: string,
  className?: string,
}

const Input = React.forwardRef((props: InputProps, ref: React.Ref<HTMLInputElement>): ReactElement => {
  const {type, errors, inputValue} = props;
  const initialValueState = inputValue != null ? inputValue : '';
  const [value, setValue] = useState(initialValueState);

  const onChange = (ev: ChangeEvent<HTMLInputElement>, name: string) => {
    if (name !== 'name') {
      setValue(ev.target.value.replace(/\s/g, ''));
      return;
    }

    setValue(ev.target.value);
  };

  const onBlur = (ev: FocusEvent<HTMLInputElement>) => {
    setValue(ev.target.value.trim());
  };

  return (
    <div className='form__group'>
      {type !== 'password'
        ? <InputDefault {...props} ref={ref} onChange={onChange} value={value} onBlur={onBlur}/>
        : <InputPassword {...props} ref={ref} onChange={onChange} value={value}/>}
      {errors != null
        ? <ErrorMessage message={(errors as ErrorItem).message}/>
        : null
      }
    </div>
  )
});


export default Input;
