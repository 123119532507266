import React, {FunctionComponent, useEffect, useState} from 'react';
import withServerErrorHandlingPage from '../../hoc/ServerErrorHandlingPage';
import Loader from '../../components/Loader';
import {useParams, Redirect} from 'react-router-dom';
import {getDeviceId} from '../../utils';
import {VerificationData} from '../../services/user/userTypes';
import {RequestStatus} from '../../store/reducers/reducerType';
import {Routes} from '../../router/Routes';
import {ApiErrors} from '../../constants';

type Props = {
  checkVerificationCode: (verificationData: VerificationData) => void;
  checkCodeStatus: RequestStatus;
}

const VerificationCode: FunctionComponent<Props> = (props: Props) => {
  const {code} = useParams();
  const {checkCodeStatus} = props;
  const [invalidCode, setInvalidCodeStatus] = useState(false);

  async function checkCodeFromLink() {
    if (code == null) {
      return;
    }
    props.checkVerificationCode({code: code, device_id: await getDeviceId()});
  }

  useEffect(() => {
    checkCodeFromLink();
  }, []);

  useEffect(() => {
    determineApiErrors();
  }, [checkCodeStatus]);


  const determineApiErrors = () => {
    const {error} = checkCodeStatus;

    if (!error.length) {
      return;
    }

    switch (error) {
      case ApiErrors.invalid_verification_code:
        setInvalidCodeStatus(true);
        break;
      default:
        throw new Error(error);
    }
  };

  if (invalidCode) {
    return <Redirect to={Routes.Forgot}/>
  }

  if (checkCodeStatus.status) {
    return <Redirect to={Routes.ResetPassword}/>
  }

  return (
    <Loader className='loader--page'/>
  );
};

export default withServerErrorHandlingPage(VerificationCode);
