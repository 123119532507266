import React, {FunctionComponent, useEffect, useState} from 'react';
import withServerErrorHandlingPage from '../../hoc/ServerErrorHandlingPage';
import Loader from '../../components/Loader';
import {Redirect, useHistory, useParams} from 'react-router-dom';
import {RequestStatus} from '../../store/reducers/reducerType';
import {Routes} from '../../router/Routes';
import {History} from 'history';
import {
  EmailVerificationEvents,
  EmailVerificationInvalidCodeMessage,
  EventContext,
  ThankYouPageContext,
  ApiErrors, ServicesErrors
} from '../../constants';
import {Events} from '../../services/events/events';
import {ThankYouPageContentType} from '../../store/actions/page/pageActionsTypes';

type Props = {
  checkEmailVerificationCode: (verificationCode: string, history: History) => void;
  checkVerificationCodeStatus: RequestStatus;
  setThankYouPageContent: (content: ThankYouPageContentType) => void;
  logout: (history: History) => void;
  getUserInfo: () => void;
}

const VerifyEmailCode: FunctionComponent<Props> = (props: Props) => {
  const {code} = useParams();
  const history = useHistory();
  const {checkVerificationCodeStatus} = props;
  const [redirect, setNeedToRedirect] = useState(false);

  async function checkCodeFromLink() {
    if (code == null) {
      return;
    }
    props.checkEmailVerificationCode(code, history);
  }

  useEffect(() => {
    Events.send({
      context: EventContext.emailVerification,
      event: EmailVerificationEvents.page_open__email_verification,
    });

    checkCodeFromLink();
  }, []);

  useEffect(() => {
    const {status} = checkVerificationCodeStatus;

    if (status) {
      setNeedToRedirect(true);
      props.setThankYouPageContent({
        title: 'Your email\'s verified. Sweet!',
        text: 'Now we know it\'s the real you! Great to have you with us.',
        context: ThankYouPageContext.emailVerification,
      });
      props.getUserInfo();
      return;
    }
    determineApiErrors();
  }, [checkVerificationCodeStatus]);


  const determineApiErrors = () => {
    const {error, status} = checkVerificationCodeStatus;

    if (!status && !error.length) {
      return;
    }

    switch (error) {
      case ApiErrors.invalid_verification_code:
        throw new Error(EmailVerificationInvalidCodeMessage);
      case ApiErrors.invalid_token:
      case ApiErrors.token_expired:
        props.logout(history);
        break;
      default:
        throw new Error(ServicesErrors.emailVerification.checkVerificationEmailCodeFailed);
    }
  };

  if (redirect) {
    return <Redirect to={Routes.ThankYouPage}/>
  }

  return (
    <Loader className='loader--page'/>
  );
};

export default withServerErrorHandlingPage(VerifyEmailCode);
