import React, {FunctionComponent, useEffect, useState} from 'react';
import withServerErrorHandlingPage from '../../hoc/ServerErrorHandlingPage';
import {Redirect} from 'react-router-dom';
import {Routes} from '../../router/Routes';
import {useStore} from 'react-redux';
import ContentWrapper from '../../containers/ContentWrapper';
import ResetPasswordForm from '../../containers/Forms/ResetPassword';
import {Events} from '../../services/events/events';
import {EventContext, ResetPasswordEvents} from '../../constants/events';

type Props = {
  onError: (error: string) => void;
}

const ResetPassword: FunctionComponent<Props> = ({onError}) => {
  const store = useStore();
  const [verificationCode, setVerificationCode] = useState<string>();

  useEffect(() => {
    Events.send({
      context: EventContext.resetPassword,
      event: ResetPasswordEvents.open,
    });
    const state = store.getState();
    setTimeout(() => setVerificationCode(state.user.verificationCode), 100)
  }, []);

  const setErrorMessage = (error: string) => {
    if (error.length) {
      onError(error);
    }
  }

  if (verificationCode != null && !verificationCode.length) {
    return <Redirect to={Routes.Forgot}/>
  }

  return (
    <ContentWrapper
      aside={{
        title: 'OK, let’s get you a new password',
        subtitle: 'Your password is the key to your account so let’s make sure it’s something that only you know.',
        fullHeight: true,
      }}>
      <ResetPasswordForm setErrorMessage={setErrorMessage}/>
    </ContentWrapper>
  );
};

export default withServerErrorHandlingPage(ResetPassword);
