import {AssetsConstants, UserConstants} from '../../../constants';
import {DefaultAssetsState} from './constants';
import {AssetsStateType} from './types';

export default (state = DefaultAssetsState, action: any): AssetsStateType => {
  switch (action.type) {
    case AssetsConstants.GET_ASSETS_LIST_REQUEST:
      return {
        ...state,
        getAssetsListStatus: {
          status: false,
          error: '',
        },
        assetsList: null,
      };
    case AssetsConstants.GET_ASSETS_LIST_SUCCESS:
      return {
        ...state,
        assetsList: action.payload.assetsList,
        getAssetsListStatus: {
          status: true,
          error: '',
        }
      };
    case AssetsConstants.GET_ASSETS_LIST_FAILURE:
      return {
        ...state,
        getAssetsListStatus: {
          status: false,
          error: action.payload.message,
        }
      };
    case AssetsConstants.SET_CURRENT_ASSET:
      return {
        ...state,
        currentAsset: action.payload.assetInfo,
      };
    case AssetsConstants.CANCEL_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        cancelStatus: {
          status: false,
          error: '',
        }
      };
    case AssetsConstants.CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        cancelStatus: {
          status: true,
          error: '',
        }
      };
    case AssetsConstants.CANCEL_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        cancelStatus: {
          status: false,
          error: action.payload.message,
        }
      };
    case AssetsConstants.RENEW_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        renewStatus: {
          status: false,
          error: '',
        }
      };
    case AssetsConstants.RENEW_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        renewStatus: {
          status: true,
          error: '',
        }
      };
    case AssetsConstants.RENEW_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        renewStatus: {
          status: false,
          error: action.payload.message,
        }
      };
    case AssetsConstants.CHECK_TRIAL_REQUEST:
      return {
        ...state,
        isTrial: false,
        checkTrialStatus: {
          status: false,
          error: '',
        },
      };
    case AssetsConstants.CHECK_TRIAL_SUCCESS:
      return {
        ...state,
        isTrial: true,
      };
    case AssetsConstants.CHECK_TRIAL_FAILURE:
      return {
        ...state,
        checkTrialStatus: {
          status: false,
          error: action.payload.message,
        },
      };
    case UserConstants.LOGOUT_SUCCESS:
      return DefaultAssetsState;
    default:
      return state;
  }
}
