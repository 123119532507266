import {AssetsStateType} from './types';

export const DefaultAssetsState: AssetsStateType = {
    assetsList: null,
    currentAsset: null,
    cancelStatus: {
        status: false,
        error: '',
    },
    renewStatus: {
        status: false,
        error: '',
    },
    getAssetsListStatus: {
        status: false,
        error: '',
    },
    isTrial: false,
    checkTrialStatus: {
        status: false,
        error: '',
    },
}