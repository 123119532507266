import React, {FunctionComponent} from 'react';
import './DeviceItem.scss';
import {EnrichedDevice, DevicesStatus} from '../../../../services/device/deviceTypes';
import DeviceInfo from './DeviceInfo';
import DisconnectConfirm from './DisconnectConfirm';
import DisconnectCongratulation from './DisconnectCongratulation';
import {DeviceItemScreenType} from '../../../../constants';

type Props = {
  device: EnrichedDevice;
  devicesStatus: DevicesStatus | null;
  listNumber: number,
}

const DeviceItem: FunctionComponent<Props> = (props: Props) => {
  const determineDeviceItemScreen = () => {
    const {device} = props;

    switch (device.screen_type) {
      case DeviceItemScreenType.deviceInfo:
        return <DeviceInfo listNumber={props.listNumber} device={props.device}/>;
      case  DeviceItemScreenType.disconnectConfirm:
        // @ts-ignore
        return <DisconnectConfirm deviceId={props.device.id}/>;
      case DeviceItemScreenType.disconnectCongratulation:
        return <DisconnectCongratulation/>;
      default:
        return null;
    }
  };

  return (
    <div className='devices__device-item'>
      {determineDeviceItemScreen()}
    </div>
  );
};

export default DeviceItem;
