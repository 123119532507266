export type GetAssetsListResponse = {
  user_id: string,
  assets: Array<Asset>;
};

export type AssetsList = Array<Asset>;

export type Licenses = Array<License>;

export type License = {
  license_id: string,
  device_id: string,
  expires_at: number,
  created_at: number,
}

export type Asset = {
  asset_id: string,
  pg_subscription_id: string,
  payment_gateway: PaymentGatewayType | '',
  duration: number,
  price: string,
  currency: string,
  next_billing_date: number,
  name: string,
  quantity: number,
  is_trial: boolean,
  is_test: boolean,
  created_at: number,
  device_quantity: number,
  is_manageable: boolean,
  is_family: boolean,
  status: 'active' | 'deactivated' | 'hold' | 'finished',
  licenses: Array<License> | []
};

export enum PaymentGatewayType {
  FastSpring = 'fsc',
  Ppg = 'ppg',
  GooglePlayMarket = 'gpm',
  AppleStore = 'aps',
}


