import {Dispatch} from 'redux';
import {InnerContentPopups, PopupsActions} from '../../../constants';
import {HideModalReduxAction, OpenModalReduxAction, SetModalPopupSizeReduxAction} from './popupsActionsTypes';
import {ModalContentType, ModalPopupSize} from '../../../popups/Modal/ModalTypes';

export function hideModal() {
  return (dispatch: Dispatch<HideModalReduxAction>) => {
    dispatch({type: PopupsActions.hideModal, payload: {}});
  };
}

export function openModal(contentType: ModalContentType, innerContentPopup: InnerContentPopups) {
  return (dispatch: Dispatch<OpenModalReduxAction>) => {
    dispatch({type: PopupsActions.openModal, payload: {contentType, innerContentPopup}});
  };
}

export function setModalPopupSize(size: ModalPopupSize) {
  return (dispatch: Dispatch<SetModalPopupSizeReduxAction>) => {
    dispatch({type: PopupsActions.setModalSize, payload: size});
  };
}
