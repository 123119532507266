import {connect} from 'react-redux';
import VerifyEmailCode from './VerifyEmailCode';
import {AppState, RequestStatus} from '../../store/reducers/reducerType';
import {checkEmailVerificationCode} from '../../store/actions';
import {History} from 'history';
import {getUserInfo, logout, setThankYouPageContent} from '../../store/actions';
import {ThankYouPageContentType} from '../../store/actions/page/pageActionsTypes';

type StateType = {
  checkVerificationCodeStatus: RequestStatus,
}

const mapStateToProps = (state: AppState): StateType => ({
  checkVerificationCodeStatus: state.emailVerification.checkVerificationCodeStatus,
});

const mapDispatchToProps = (dispatch: any) => ({
  checkEmailVerificationCode: (verificationCode: string, history: History): void => {
    dispatch(checkEmailVerificationCode(verificationCode, history));
  },
  setThankYouPageContent: (content: ThankYouPageContentType): void => {
    dispatch(setThankYouPageContent(content));
  },
  logout: (history: History): void => {
    dispatch(logout(history));
  },
  getUserInfo: (): void => {
    dispatch(getUserInfo());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)((VerifyEmailCode as any));
