import React, {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {AppState} from '../../store/reducers/reducerType';
import {WebCbt} from '../../constants';
import SSO from '../SSO';
import Separator from '../../components/Separator';

const SSOWrapper = (): ReactElement | null => {
  const webcbt: string | number = useSelector((state: AppState) => state.partner.webcbt);

  if (webcbt !== WebCbt.clario) {
    return null;
  }

  return (
    <div className='sso__wrapper'>
      <SSO/>
      <Separator/>
    </div>
  );
};

export default SSOWrapper;
