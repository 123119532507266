import React, {FunctionComponent, SyntheticEvent, useEffect, useState} from 'react';
import './Signup.scss';
import Input from '../../../components/Input';
import CheckBox from '../../../components/CheckBox';
import Button from '../../../components/Buttons/Button';
import {PartnerName, RegisterUserBody} from '../../../services/user/userTypes';
import {RequestStatus} from '../../../store/reducers/reducerType';
import {
  emailRegexp,
  ErrorItem,
  errorRules,
  generateErrorMessageMaxLength,
  inputPlaceholders,
  maxEmailValueLength,
  maxInputValueLength,
  maxNameValueLength,
  minPasswordValueLength,
  passwordIncludeLowerCaseLetterRegexp,
  passwordIncludeNumberRegexp,
  passwordIncludeUpperCaseLetterRegexp
} from '../../../utils/formValidationRules';
import {Controller, useForm} from 'react-hook-form';
import {Link, useHistory} from 'react-router-dom';
import {Routes} from '../../../router/Routes';
import {History} from 'history';
import {buttonName, signupApiErrors} from './SignupFormConfigs';
import '../Form.scss';
import VerifyEmailSignupForm from '../../../components/VerifyEmailSignupForm';
import {fullFillStringWithPlaceholder, getDeviceId, capitalizeFirstLetter} from '../../../utils';
import {Events} from '../../../services/events/events';
import {ApiErrors, EventContext, SignupEvents, SignupFormEventsType, WebCbt, WebCbtFlowNames} from '../../../constants';

type Props = {
  signupUser: (user: RegisterUserBody, history: History, webcbt: string) => void;
  registrationStatus: RequestStatus,
  resetRegistrationErrors: () => void;
  webcbt: string,
  partnerName: PartnerName | string,
  setErrorMessage: (error: string) => void;
};

type SignupFormSubmitData = Record<string, any> | {
  email: string,
  password: string,
  name: string,
  emailConsent: boolean,
}

const Signup: FunctionComponent<Props> = ({signupUser, registrationStatus, resetRegistrationErrors, webcbt, partnerName, setErrorMessage}) => {
  const history = useHistory();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [marketingConsents, setMarketingConsents] = useState(false);
  const {register, handleSubmit, errors, formState: {touched}, setError, control} = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur'
  });

  useEffect(() => {
    // Reset isSubmitted state after get response from api - to enable button submit
    if (registrationStatus.status || registrationStatus.error.length) {
      setIsSubmitted(false);
    }

    determineFormErrors();
  }, [registrationStatus]);

  useEffect(() => {
    return () => {
      resetRegistrationErrors();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      sendSignupFormEvents(SignupEvents.signupError);
    }
  }, [errors])

  const setApiError = (inputName: string, message: string): void => {
    setError([
      {
        type: 'custom',
        name: inputName,
        message: message,
      },
    ]);
  };

  const determineFormErrors = () => {
    const apiError = registrationStatus.error;

    if (!apiError.length) {
      return errors;
    }

    switch (apiError) {
      case ApiErrors.invalid_user_name:
        setApiError('name', signupApiErrors[WebCbtFlowNames[webcbt]][apiError] || signupApiErrors[WebCbtFlowNames[WebCbt.clario]][apiError]);
        break;
      case ApiErrors.user_name_too_long:
        setApiError('name', fullFillStringWithPlaceholder(signupApiErrors[webcbt][apiError] || signupApiErrors[WebCbtFlowNames[WebCbt.clario]][apiError], maxNameValueLength));
        break;
      case ApiErrors.invalid_email:
        setApiError('email', signupApiErrors[WebCbtFlowNames[webcbt]][apiError] || signupApiErrors[WebCbtFlowNames[WebCbt.clario]][apiError]);
        break;
      case ApiErrors.email_already_exists:
        setApiError('email', fullFillStringWithPlaceholder(signupApiErrors[WebCbtFlowNames[webcbt]][apiError] || signupApiErrors[WebCbtFlowNames[WebCbt.clario]][apiError], capitalizeFirstLetter(partnerName)));
        break;
      case ApiErrors.invalid_password:
      case ApiErrors.password_is_less_6_symbols:
      case ApiErrors.password_does_not_contain_a_letter:
      case ApiErrors.password_does_not_contain_a_number:
      case ApiErrors.password_contains_space:
        setApiError('password', signupApiErrors[WebCbtFlowNames[webcbt]][apiError] || signupApiErrors[WebCbtFlowNames[WebCbt.clario]][apiError]);
        break;
      default:
        setErrorMessage(signupApiErrors[WebCbtFlowNames[webcbt]][apiError] || signupApiErrors[WebCbtFlowNames[WebCbt.clario]][apiError]);
        return errors;
    }
  };

  const handleSignUp = async (data: SignupFormSubmitData): Promise<void> => {
    const {email, password, name} = data;

    if (isSubmitted) {
      return;
    }

    setIsSubmitted(true);
    signupUser({
        email,
        password,
        name,
        device_id: await getDeviceId(),
        consents: {
          marketing_notifications: marketingConsents,
          marketing_emails: marketingConsents,
          marketing_name: false,
          app_statistics: true,
        }
      }, history,
      webcbt);
  };

  const determineSignupFormEventType = (ev: SyntheticEvent) => {
    const element = (ev.target as HTMLElement);

    if (!element) {
      return;
    }

    const eventsType: string | undefined = element?.dataset.name;

    if (!eventsType) {
      return;
    }

    sendSignupFormEvents((eventsType as SignupFormEventsType));
  }


  const sendSignupFormEvents = (eventName: SignupFormEventsType) => {
    Events.send({
      context: EventContext.signup,
      event: eventName,
    });
  }

  const handleCheckBoxClick = () => {
    setMarketingConsents(!marketingConsents);
    sendSignupFormEvents(SignupEvents.consents);
  }

  const handleSubmitButtonClick = () => {
    sendSignupFormEvents(SignupEvents.signup);
    handleSubmit(handleSignUp);
  }

  return (
    <form className={`form form-signup form-signup--${WebCbtFlowNames[webcbt]}`} id='signup-form' onSubmit={handleSubmit(handleSignUp)}>
      <Input
        name='name'
        placeholder={inputPlaceholders.signup.name}
        type='text'
        errors={errors != null ? (errors.name as ErrorItem) : null}
        succeeded={(errors.name == null && touched.name) as boolean}
        ref={register({
          required: errorRules.name.required,
          maxLength: {
            value: maxNameValueLength,
            message: generateErrorMessageMaxLength('name'),
          },
        })}
      />
      <Input
        name='email'
        placeholder={inputPlaceholders.signup.email}
        type='text'
        errors={errors != null ? (errors.email as ErrorItem) : null}
        succeeded={(errors.email == null && touched.email) as boolean}
        ref={register({
          required: errorRules.email.required,
          pattern: {
            value: emailRegexp,
            message: errorRules.email.validationFailed
          },
          maxLength: {
            value: maxEmailValueLength,
            message: generateErrorMessageMaxLength('email'),
          },
        })}
      />
      <Input
        name='password'
        placeholder={inputPlaceholders.signup.password}
        type='password'
        errors={errors != null ? (errors.password as ErrorItem) : null}
        succeeded={(errors.password == null && touched.password) as boolean}
        ref={register({
          required: errorRules.password.required,
          minLength: {
            value: minPasswordValueLength,
            message: errorRules.password.length
          },
          validate: {
            includeLowerCaseLetter: value => passwordIncludeLowerCaseLetterRegexp.test(value) || errorRules.password.lowerCaseLetter,
            includeUpperCaseLetter: value => passwordIncludeUpperCaseLetterRegexp.test(value) || errorRules.password.upperCaseLetter,
            includeNumber: value => passwordIncludeNumberRegexp.test(value) || errorRules.password.number,
          },
          maxLength: {
            value: maxInputValueLength,
            message: generateErrorMessageMaxLength('password')
          },
        })}
      />
      <Controller
        as={
          <CheckBox
            name='emailConsent'
            errors={errors != null ? (errors.emailConsent as ErrorItem) : null}
            clickAction={handleCheckBoxClick}
            label='I agree to receive news, updates, and marketing offers.'/>}
        control={control}
        name='emailConsent'
      />
      <p className='consent__desc'>
        By creating an account, you agree to the
        <a data-name={SignupEvents.privacyPolicy}
           href='https://clario.co/privacy-policy/'
           className='text__link text__link--privacy-policy'
           target='_blank'
           aria-label='Privacy Policy'
           rel='noopener'
           onClick={determineSignupFormEventType}> Privacy Policy </a>
        <span className='text__link'>and</span>
        <a data-name={SignupEvents.eula}
           href='https://clario.co/eula/'
           className='text__link text__link--eula'
           target='_blank'
           aria-label='EULA'
           rel='noopener'
           onClick={determineSignupFormEventType}> EULA</a>.
        We take your privacy very seriously. All the data you share with us is encrypted and stays private.
      </p>
      <div className='button-wrapper'>
        <Button
          type='submit'
          disabled={isSubmitted}
          className='button--primary button--green'
          action={handleSubmitButtonClick}
          title={buttonName[WebCbtFlowNames[webcbt]] || buttonName[WebCbtFlowNames[WebCbt.clario]]}/>
      </div>
      <div className='form__link-wrapper'>
        <div className='form__link-description'>{`Already have an account? `}
          <Link className='form__link' tabIndex={2} to={Routes.Login} onClick={() => sendSignupFormEvents(SignupEvents.login)}>Log in</Link>
          .
        </div>
      </div>
      <VerifyEmailSignupForm/>
    </form>
  );
};

export default Signup;


