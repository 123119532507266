import {Asset} from '../../../services/assets/assetsTypes';
import {AssetDuration, PlanTypes} from '../../../constants';

export function determineCurrentAssetName(asset: Asset): string {
  let {duration, is_trial, payment_gateway} = asset;

  if (is_trial && !payment_gateway.length) {
    return PlanTypes.freeTrial;
  }

  if (duration < AssetDuration.yearly) {
    return PlanTypes.month;
  }

  if (duration === AssetDuration.yearly) {
    return PlanTypes.year
  }

  return asset.name;
}
