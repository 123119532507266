import {DesktopDeviceModels} from '../constants';

export default function determineDesktopModel(defaultValue: string): string {
  const models = Object.entries(DesktopDeviceModels).find((modelName) => defaultValue.toLowerCase().includes(modelName[0]));

  if (models == null) {
    return defaultValue;
  }

  return models[1];
};
