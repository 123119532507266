import {verifyDownloadToken} from './verifyDownloadToken';
import {sendDownloadLinks} from './sendDownloadLinks';
import {verifySmartLinkToken} from './verifySmartLinkToken';
import {signupUser} from './signupUser';

export const PartnerIntegrationService = {
  verifyDownloadToken,
  sendDownloadLinks,
  verifySmartLinkToken,
  signupUser,
};

