import React, {ChangeEvent, ReactElement, useEffect, useState} from 'react';
import {InputProps} from './Input';
import PasswordIcons from '../Icons/PasswordIcons';


type Props = InputProps & {
  value: string;
  onChange: (ev: ChangeEvent<HTMLInputElement>, name: string) => void;
}

const InputPassword = React.forwardRef((props: Props, ref: React.Ref<HTMLInputElement>): ReactElement => {
  const {name, type, placeholder, value, onChange} = props;
  const [shownPassword, changeShownPassword] = useState<boolean>(false);

  function toggleShowPassword() {
    changeShownPassword(!shownPassword);
  }

  return (
    <>
      <input
        className={`form__input ${props.errors ? 'form__input--error' : ''}
        ${props.succeeded ? 'form__input--succeeded' : ''}
        ${value.length && !shownPassword ? 'form__input--increased-circle' : ''}`}
        id={name}
        name={name}
        value={value}
        type={shownPassword ? 'text' : type}
        placeholder={placeholder}
        ref={ref}
        autoComplete='off'
        spellCheck={false}
        onChange={(ev) => onChange(ev, name)}
        data-hj-suppress
        tabIndex={2}
      />
      <PasswordIcons shownPassword={shownPassword} toggleShowPassword={toggleShowPassword}/>
    </>
  )
});


export default InputPassword;

