import {Dispatch} from 'redux';
import {GetAppleAuthRedirectLinkRequestReduxAction, GetAppleAuthRedirectLinkSuccessReduxAction, GetAppleAuthRedirectLinkFailureReduxAction} from '../ssoActionsTypes';
import {SSOService} from '../../../../services/sso/sso';
import {SSOActions} from '../../../../constants/sso';
import {AppleAuthRedirectLinkResponse} from '../../../../services/sso/ssoTypes';

export function getAppleAuthRedirectLink() {
  return (dispatch: Dispatch<GetAppleAuthRedirectLinkRequestReduxAction | GetAppleAuthRedirectLinkSuccessReduxAction | GetAppleAuthRedirectLinkFailureReduxAction>) => {
    dispatch(request());

    SSOService.getAppleAuthRedirectLink()
      .then((response) => {
          dispatch(success(response));
        },
        error => {
          dispatch(failure(error));
        }
      );

    function request(): GetAppleAuthRedirectLinkRequestReduxAction {
      return {type: SSOActions.GET_APPLE_AUTH_REDIRECT_LINK_REQUEST, payload: {}}
    }

    function success(response: AppleAuthRedirectLinkResponse): GetAppleAuthRedirectLinkSuccessReduxAction {
      return {type: SSOActions.GET_APPLE_AUTH_REDIRECT_LINK_SUCCESS, payload: response}
    }

    function failure(error: Error): GetAppleAuthRedirectLinkFailureReduxAction {
      return {type: SSOActions.GET_APPLE_AUTH_REDIRECT_LINK_FAILURE, payload: error}
    }
  }
}
