import React, {FunctionComponent, useEffect, useState} from 'react';
import {Events} from '../../../../services/events/events';
import {EventContext, HeaderEvents} from '../../../../constants/events';
import {History} from 'history';
import {useHistory} from 'react-router-dom';
import {useHeaderContext} from '../../Header';
import {RequestStatus} from '../../../../store/reducers/reducerType';

const ExitIcon = require('../../../../../resources/images/icon-exit.svg').default;

type Props = {
  logout: (history: History) => void;
  isAuthenticated: boolean | null,
  logoutStatus: RequestStatus,
}

const LogoutItem: FunctionComponent<Props> = ({logout, isAuthenticated, logoutStatus}) => {
  const history = useHistory();
  const {hideBurgerMenu} = useHeaderContext();
  const [isSubmittedLogout, setIsSubmittedLogout] = useState(false);

  if (!hideBurgerMenu) {
    return null;
  }

  useEffect(() => {
    const {status} = logoutStatus;
    if (status) {
      setIsSubmittedLogout(false);
    }

  }, [logoutStatus])

  const handleLogout = () => {
    if (isSubmittedLogout) {
      return;
    }

    setIsSubmittedLogout(true);
    logout(history);
    hideBurgerMenu();

    Events.send({
      context: EventContext.header,
      event: HeaderEvents.logout,
    });
  };

  return (isAuthenticated ?
      <li className='header__menu-item header__menu-item--logout' onClick={handleLogout}>
        <ExitIcon className='header__menu-icon header__menu-icon--logout'/>
        <p className='header__menu-link header__menu-link--logout'>Log out.</p>
      </li> : null
  );
}

export default React.memo(LogoutItem);
