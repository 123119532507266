import {userService} from '../services/user/user';
import Url from './url';
import {Affid, CookiesKeys} from '../constants';
import AffidService from '../services/ldr/createAffid';
import Cookies from './cookies';

export default async function getAffid(): Promise<string | null> {
  let affid = determineAffid();

  if (affid && affid.length) {
    return affid;
  } else {
    try {
      const getUserInfo = await userService.getUserInfo();
      return getUserInfo.affid;
    } catch (err) {
      let AffidServ = AffidService.getInstance();
      const affid = await AffidServ.getAffid();
      if (affid) {
        localStorage.setItem(Affid, affid);
        return affid;
      }
      return null;
    }
  }
}

export function determineAffid(): string | null {
  let affidFromUrl = Url.getQueryParamByName(window.location.href, Affid);

  if (affidFromUrl) {
    localStorage.setItem(Affid, affidFromUrl);
    return affidFromUrl;
  }

  let affidFromStorage = localStorage.getItem(Affid) || '';

  if (affidFromStorage.length) {
    return affidFromStorage;
  }
  const landingAffid = getAffidFromLandingsParams();

  if (landingAffid) {
    localStorage.setItem(Affid, landingAffid);
    return landingAffid;
  }

  return null;
}

const getAffidFromLandingsParams = (): string | null => {
  let landingParams = null;
  let paramsFromCookie = Cookies.getCookie(CookiesKeys.landingsParams);
  if (!paramsFromCookie) {
    return landingParams;
  }

  try {
    landingParams = JSON.parse(paramsFromCookie);
  } catch (error) {
    landingParams = null
  }

  return landingParams?.affid || null;
}



