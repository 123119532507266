import {FooterMenuItems} from '../../containers/Footer/FooterNavList/FooterNavList';

export const HeaderEvents: Record<string, string> = {
  logo: 'clario_logo_click',
  about_us: 'about_link_click',
  product_overview: 'product_link_click',
  pricing: 'pricing_link_click',
  faq: 'faq_link_click',
  blog: 'blog_link_click',
  account: 'account_link_click',
  logout: 'account_logout_click',
  mobile_security: 'mobile_security_click',
  secure_browsing: 'secure_browsing_click',
  contact: 'contact_link_click'
}

export const FooterEvents: Record<FooterMenuItems, string> = {
  logo: 'clario_logo_click',
  privacyPolicy: 'privacy_policy_link_click',
  cookie: 'cookie_policy_link_click',
  eula: 'eula_link_click',
  privacyNotice: 'privacy_notice_link_click',
  terms: 'terms_link_click',
  refund: 'refund_policy_link_click'
}

export const CookiesBannerEvents = {
  cookiePolicy: 'cookie_policy_click',
  close: 'close_icon_link_click',
  agree: 'agree_button_click',
  disagree: 'disagree_button_click',
}

export const ChatEvents = {
  open: 'page__chat_button_click',
}

export type CookiesBannerEventsTypes = 'cookiePolicy' | 'close' | 'agree' | 'disagree';
