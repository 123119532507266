import Cookies from '../utils/cookies';
import {determineAffid} from '../utils/getAffid';
import {Affid, CookiesKeys, TrackingParams} from '../constants';
import {useMemo} from 'react';
import {useLocation} from 'react-router-dom';

interface ReadOnlyURLSearchParams extends URLSearchParams {
    append: never;
    set: never;
    delete: never;
    sort: never;
}

export const useTrackingParams = () => {
    const {search} = useLocation();

    const searchParams = useMemo(
        () => new URLSearchParams(search) as ReadOnlyURLSearchParams,
        [search]
    );

    const getAffid = (): string | null => {
        const landingsParams = getLandingsParams();
        if (landingsParams?.affid) {
            const affid = (landingsParams.affid as string);
            localStorage.setItem(Affid, affid);
            return affid;
        }
        return determineAffid();
    }
    const getBundleId = (): string | null => {
        const landingsParams = getLandingsParams();
        if (landingsParams?.trt) {
            return (landingsParams?.trt as string);
        }

        return null;
    }

    const getQueries = () => {
        const params: { [key: string]: string } = {};

        for (let [key, value] of searchParams.entries()) {
            params[key] = value;
        }

        return params;
    }


    const getGaCid = (): string | null => {
        const landingsParams = getLandingsParams();
        const queryParams = getQueries();

        const gaCid =
            queryParams?.ga_cid ||
            landingsParams?.ga_cid ||
            Cookies.getCookie(TrackingParams.Ga) ||
            Cookies.getCookie(TrackingParams.GaId) ||
            searchParams.get(TrackingParams.GaCid) ||
            null;

        return gaCid;
    }

    const getLandingsParams = (): { [key: string]: string } | null => {
        let landingParams = null;
        let paramsFromCookie = Cookies.getCookie(CookiesKeys.landingsParams);
        if (!paramsFromCookie) {
            return landingParams;
        }

        try {
            landingParams = JSON.parse(paramsFromCookie);
        } catch (error) {
            landingParams = null
        }

        return landingParams;
    }

    return {
        getLandingsParams,
        getAffid,
        getBundleId,
        getGaCid
    }
}