import React, {FunctionComponent, useEffect, useState} from 'react';
import withServerErrorHandlingPage from '../../hoc/ServerErrorHandlingPage';
import Loader from '../../components/Loader';
import {RequestStatus} from '../../store/reducers/reducerType';
import {ApiErrors, ServicesErrors, Ltk, UTMKeys, Tokens, Affid} from '../../constants';
import Url from '../../utils/url';
import TokenInvalid from '../DownloadLinks/TokenInvalid/TokenInvalid';
import {Redirect} from 'react-router-dom';
import {Routes} from '../../router/Routes';
import {JWT} from '../../utils/jwtParser';
import Cookies from '../../utils/cookies';

type Props = {
  verifySmartLinkTokenStatus: RequestStatus,
  verifySmartLinkToken: (token: string) => void,
  setWebCbt: (webcbt: number | string) => void;
}

const LinkState: { [key in LinkStateType]: string } = {
  valid: 'valid',
  invalid: 'invalid',
  used: 'used'
}

type LinkStateType = 'valid' | 'invalid' | 'used';

const VerifySmartLink: FunctionComponent<Props> = (props: Props) => {
  const [linkState, setLinkState] = useState<LinkStateType | null>(null);
  const [token, setToken] = useState('');
  useEffect(() => {
    Cookies.deleteCookie('sessionAffid');
    parseUrl();
    validateSmartLinkToken();
  }, []);

  function validateSmartLinkToken() {
    const smartLinkToken = parseSmartLinkToken();

    if (!smartLinkToken || !smartLinkToken.length) {
      setLinkState((LinkState.used as LinkStateType));
      return;
    }
    props.verifySmartLinkToken(smartLinkToken);
  }

  function parseUrl(): void {
    const queryParams = Url.getArrayOfParams(window.location.href);

    if (!queryParams.length) {
      return;
    }

    queryParams.forEach(([key, value]) => {
      if (Object.values(UTMKeys).indexOf(key) > -1 || key === Affid) {
        localStorage.setItem(key, value);
      }
    })
  }

  // Add to add possibility to signup user after he reload the page
  function saveTokenForSession(): void {
    sessionStorage.setItem(Tokens.smartLink, token);
  }

  function parseSmartLinkToken(): string {
    const token = Url.getQueryParamByName(window.location.href, Ltk);

    if (!token) {
      return '';
    }

    setToken(token);
    const decodeToken = decodeURIComponent(token);
    const parseToken = JWT.parseJwt(decodeToken);
    const {web_cbt, cbt} = parseToken;

    if (web_cbt) {
      props.setWebCbt(web_cbt);
      return decodeToken;
    }

    // Add for the back compatibility - first links has cbt params
    props.setWebCbt(cbt);
    return decodeToken;
  }

  useEffect(() => {
    const {verifySmartLinkTokenStatus} = props;
    const {status} = verifySmartLinkTokenStatus;


    if (status) {
      setLinkState((LinkState.valid as LinkStateType));
      return;
    }
    determineApiErrors();
  }, [props.verifySmartLinkTokenStatus]);

  const determineApiErrors = () => {
    const {error, status} = props.verifySmartLinkTokenStatus;

    if (!status && !error.length) {
      return;
    }

    switch (error) {
      case ApiErrors.invalid_token:
        setLinkState((LinkState.invalid as LinkStateType));
        break;
      case ApiErrors.smart_link_used:
        setLinkState((LinkState.used as LinkStateType));
        break;
      default:
        throw new Error(ServicesErrors.waoo.verifySmartLinkToken);
    }
  };

  const determineView = () => {
    switch (linkState) {
      case LinkState.valid:
        saveTokenForSession();
        return <Redirect to={Routes.Signup}/>;
      case LinkState.used:
        return <Redirect to={Routes.Login}/>;
      case LinkState.invalid:
        return <TokenInvalid/>;
      default:
        return <Loader className='loader--page'/>;
    }
  }

  return (determineView());
};

export default withServerErrorHandlingPage(VerifySmartLink);
