import * as React from 'react';
import './Logo.scss';

const LogoImage = require('../../../resources/images/logo.svg').default;

interface Props {
  className?: string
}

const Logo: React.FunctionComponent<Props> = (props: Props) => {
  return <LogoImage className={`logo ${props.className}`}/>;
};

export default Logo;
