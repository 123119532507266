export const EmailVerification = {
  SEND_EMAIL_VERIFICATION_REQUEST: 'SEND_EMAIL_VERIFICATION_REQUEST',
  SEND_EMAIL_VERIFICATION_SUCCESS: 'SEND_EMAIL_VERIFICATION_SUCCESS',
  SEND_EMAIL_VERIFICATION_FAILURE: 'SEND_EMAIL_VERIFICATION_FAILURE',

  CHECK_VERIFICATION_EMAIL_CODE_REQUEST: 'CHECK_VERIFICATION_EMAIL_CODE_REQUEST',
  CHECK_VERIFICATION_EMAIL_CODE_SUCCESS: 'CHECK_VERIFICATION_EMAIL_CODE_SUCCESS',
  CHECK_VERIFICATION_EMAIL_CODE_FAILURE: 'CHECK_VERIFICATION_EMAIL_CODE_FAILURE',
};

export const EmailVerificationInvalidCodeMessage = 'email_verification_invalid_code';
