import React, {FunctionComponent, ReactNode, useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import {ModalContentType, ModalPopup, ModalPopupSize} from '../ModalTypes';
import {InnerContentPopups, AnimationState, UIBreakPoints, EventContext, ProfileEvents} from '../../../constants';
import {CSSTransition} from 'react-transition-group';
import './ModalPopup.scss';
import {useHistory} from 'react-router-dom';
import {Asset} from '../../../services/assets/assetsTypes';
import {Events} from '../../../services/events/events';

interface Props {
  children: ReactNode;
  setModalPopupSize: (size: ModalPopupSize) => void;
  needToShow: boolean;
  hideModal: () => void;
  openModal: (contentType: ModalContentType, innerContentPopup: InnerContentPopups) => void;
  currentAsset: Asset | null,
  modalPopup: ModalPopup | null;
}

export const ModalPopupContext = React.createContext<Partial<ModalPopupConsumerContext>>({});

export type ModalPopupConsumerContext = {
  openModal: (contentType: ModalContentType, innerContentPopup: InnerContentPopups) => void;
  hideModal: () => void;
  currentAsset: Asset | null;
  modalPopup: ModalPopup | null;
}

export function useModalPopupContext() {
  const context = React.useContext(ModalPopupContext);

  if (!context) {
    throw new Error(
      'Modal Popups Compound Components must be rendered within the Modal Wrapper',
    )
  }
  return context;
}

const modalRoot = document.getElementById('modal-root');

const ModalPopupWrapper: FunctionComponent<Props> = ({children, setModalPopupSize, needToShow, hideModal, openModal, currentAsset, modalPopup}) => {
  const history = useHistory();
  const popupRef: any = useRef();
  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    window.addEventListener(AnimationState.hideModalStart, handleHideModalPopup);

    // Clear location state
    // It is necessary as the state of display the popups from a direct link is transferred through location state
    history.replace({...history.location, state: {}});
    document.addEventListener('changeSize', handleChangeModalPopupSize);

    return () => {
      window.removeEventListener(AnimationState.hideModalStart, handleHideModalPopup);
      window.removeEventListener('changeSize', handleChangeModalPopupSize);
    }
  }, []);

  const handleHideModalPopup = () => {
    Events.send({context: EventContext.profile, event: ProfileEvents.buttons.close});
    setShowModal(false);
  }

  const handleChangeModalPopupSize = (ev: any) => {
    const {height, scroll} = ev.detail;
    if (height > 0) {
      recalculatePopupSize(scroll);
    }
  }

  const recalculatePopupSize = (scroll: boolean) => {
    if (popupRef.current == null) {
      return;
    }

    const height = popupRef.current.clientHeight;

    if (window.innerWidth <= UIBreakPoints.tablet.max && scroll) {
      window.scrollTo(0, 0);
    }

    setModalPopupSize({height});
  };

  if (modalRoot == null || !needToShow) {
    return null;
  }

  return ReactDOM.createPortal(
    <ModalPopupContext.Provider value={{
      openModal: openModal,
      hideModal: hideModal,
      currentAsset: currentAsset,
      modalPopup: modalPopup,
    }}>
      <CSSTransition
        appear
        in={showModal}
        classNames='popup-transition'
        unmountOnExit
        timeout={500}
        nodeRef={popupRef}
        onExited={() => {
          setShowModal(true);
          hideModal();
        }}>
        <div className='popup' ref={popupRef}>
          {children}
        </div>
      </CSSTransition>
    </ModalPopupContext.Provider>
    , modalRoot);
};
export default ModalPopupWrapper;
