import React, {ReactElement} from 'react';
import './ErrorMessage.scss';

type ErrorMessageProps = {
  message: string,
  className?: string,
}

const ErrorMessage = (props: ErrorMessageProps): ReactElement => {
  return (
    <div className={`error-message__wrapper ${props.className ? `error-message__wrapper--${props.className}` : ''}`}>
      <p className='error-message'>{props.message}</p>
    </div>
  )
};
export default ErrorMessage;
