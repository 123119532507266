import React, {FunctionComponent} from 'react';
import {ListItemType} from '../HeaderNavList/HeaderNavList';

const DefaultNavItem: FunctionComponent<ListItemType> = ({name, link, ariaLabel, text}) => {
  return (
    <li className='header__menu-item'>
      <a target='_blank'
         data-name={name}
         href={link}
         className='header__menu-link'
         aria-label={ariaLabel}
         rel='noopener'>
        {text}
      </a>
    </li>
  );
}

export default DefaultNavItem;
