import React, {FunctionComponent} from 'react';
import Title from '../../../../components/Title';
import FAQList from './FAQList';
import './FAQ.scss';
import {formDeviceIdArray} from '../../../../utils';
import {Events} from '../../../../services/events/events';
import {ClarioSiteLinks, DevicesEvents, EventContext} from '../../../../constants';
import {useSelector} from 'react-redux';
import {AppState} from '../../../../store/reducers/reducerType';

const FAQ: FunctionComponent = () => {
  const currentAssetDevices = useSelector((state: AppState) => state.device.currentAssetDevices);

  const handleStartChat = () => {
    window.open(ClarioSiteLinks.contacts, '_blank');
    Events.send({
      context: EventContext.devices,
      event: DevicesEvents.detailed_area__start_chat_link_click,
      data: {deviceId: formDeviceIdArray(currentAssetDevices)}
    });
  };

  return (
    <div className='devices__faq faq'>
      <Title text='FAQ' className='devices__title faq__title'/>
      <FAQList/>
      <div className='faq__footer'>
        <p className='faq__footer-text'>Still got questions?</p>
        <div className='faq__footer-link' onClick={handleStartChat}>Contact us</div>
      </div>
    </div>
  );
};

export default React.memo(FAQ);
