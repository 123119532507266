import {GetUserInfoFailureReduxAction, GetUserInfoRequestReduxAction, GetUserInfoSuccessReduxAction} from './userActionsTypes';
import {userService} from '../../../services/user/user';
import {GetUserInfo, PartnerName} from '../../../services/user/userTypes';
import {Affid, UserConstants, WebCbt} from '../../../constants';
import {setPartnerName, setWebCbt} from '../partner';
import {SetWebCbtReduxAction} from '../partner/partnerActionsTypes';
import {ThunkDispatch} from 'redux-thunk';
import {AppState} from '../../reducers/reducerType';

export function getUserInfo() {
  return (dispatch: ThunkDispatch<AppState, void, GetUserInfoRequestReduxAction | GetUserInfoSuccessReduxAction | GetUserInfoFailureReduxAction | SetWebCbtReduxAction>) => {
    dispatch(request());
    return userService.getUserInfo()
      .then(
        (userResp) => {
          localStorage.setItem(Affid, userResp.affid);
          dispatch(success(userResp));
          let partnerName = userResp.partner_name && userResp.partner_name.length ? userResp.partner_name : PartnerName.clario;
          let webCbt = userResp.web_cbt ? userResp.web_cbt : WebCbt.clario;
          dispatch(setPartnerName(partnerName));
          dispatch(setWebCbt(webCbt));
        },
        error => {
          dispatch(failure(error));
        }
      );

    function request(): GetUserInfoRequestReduxAction {
      return {type: UserConstants.GET_USER_INFO_REQUEST, payload: {}}
    }

    function success(userData: GetUserInfo): GetUserInfoSuccessReduxAction {
      return {type: UserConstants.GET_USER_INFO_SUCCESS, payload: userData}
    }

    function failure(error: Error): GetUserInfoFailureReduxAction {
      return {type: UserConstants.GET_USER_INFO_FAILURE, payload: error}
    }
  }
}
