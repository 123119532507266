import {CheckVerificationCodeResponse, VerificationData} from './userTypes';
import fetch from 'node-fetch';
import {UserCheckCodeApi, ServicesErrors} from '../../constants';
import {handleResponse, determineErrorLevelForSentry} from '../../utils';
import * as Sentry from '@sentry/browser';

export function checkVerificationCode(verificationData: VerificationData): Promise<CheckVerificationCodeResponse> {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(verificationData)
  };

  return fetch(UserCheckCodeApi, requestOptions)
    .then(handleResponse)
    .catch(error => {
      const errorLevel = determineErrorLevelForSentry(error);

      Sentry.configureScope((scope) => {
        scope.setLevel(errorLevel);
      });

      Sentry.addBreadcrumb({
        category: 'verification',
        message: 'Endpoint ' + UserCheckCodeApi,
        data: {
          error: error,
          requestOptions,
        },
        level: errorLevel,
      });
      Sentry.captureMessage(ServicesErrors.user.checkForgotPasswordVerificationCodeFailed, errorLevel);
      throw new Error(error.message);
    });
}
