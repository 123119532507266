import {PartnerName} from '../../../services/user/userTypes';
import {WebCbt} from '../../../constants';
import {PartnerStateType} from './types';

export const DefaultPartnerState: PartnerStateType = {
    verifyDownloadLinkStatus: {
        status: false,
        error: '',
    },
    sendDownloadLinksStatus: {
        status: false,
        error: '',
    },
    downloadToken: '',
    verifySmartLinkTokenStatus: {
        status: false,
        error: '',
    },
    smartLinkToken: '',
    partnerName: PartnerName.clario,
    webcbt: WebCbt.clario,
};