//@ts-nocheck
import React, {FunctionComponent} from 'react';
import DefaultPageWrapper from '../containers/DefaultPageWrapper';
import {Routes} from './Routes';
import {Redirect, Route, RouteComponentProps, Switch, withRouter} from 'react-router-dom';
import Login from '../pages/Login';
import Forgot from '../pages/Forgot';
import ResetPassword from '../pages/ResetPassword';
import Signup from '../pages/Signup';
import VerificationCode from '../pages/VerificationCode';
import VerifyDownloadLinks from '../pages/VerifyDownloadLinks';
import {InnerContentPopups, WebToCaseSurveyType} from '../constants';
import Main from '../pages/Main';
import VerifyEmailCode from '../pages/VerifyEmailCode';
import VerifySmartLink from '../pages/VerifySmartLink';
import ThankYouPage from '../pages/ThankYouPage';
import {Download} from '../pages/Download';
import DownloadInstruction from '../pages/DownloadInstruction';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import RedirectRoute from './RedirectRoute';
import {ModalContentType} from '../popups/Modal/ModalTypes';
import SalesForceForm from '../components/SalesForceForm';
import AuthError from '../components/AuthError';

const SwitchWrapper: FunctionComponent<RouteComponentProps> = (props: RouteComponentProps) => {
  const {location} = props;

  return (<DefaultPageWrapper location={location}>
      <Switch location={location}>
        <Redirect from='/:url*(/+)' to={location.pathname.slice(0, -1).concat(location.search)}/>
        <Route path={Routes.Login} exact>
          <PublicRoute>
            <Login/>
          </PublicRoute>
        </Route>
        <Route path={Routes.Forgot} exact>
          <PublicRoute>
            <Forgot/>
          </PublicRoute>
        </Route>
        <Route path={Routes.ResetPassword} exact>
          <ResetPassword/>
        </Route>
        <Route path={Routes.Signup} exact>
          <PublicRoute>
            <Signup/>
          </PublicRoute>
        </Route>
        <Route path={Routes.AuthError}>
          <PublicRoute>
            <AuthError/>
          </PublicRoute>
        </Route>
        <Route path={`${Routes.ThankYouPage}`} exact>
          <ThankYouPage/>
        </Route>
        <Route path={`${Routes.ResetPassword}/:code`}>
          <VerificationCode/>
        </Route>
        <Route path={`${Routes.VerifyEmail}/:code`}>
          <VerifyEmailCode/>
        </Route>
        <Route path={Routes.VerifyDownloadLinks}>
          <VerifyDownloadLinks/>
        </Route>
        <Route path={`${Routes.VerifySmartLink}`}>
          <VerifySmartLink/>
        </Route>
        <Route path={Routes.SubscriptionPopup} exact>
          <RedirectRoute path={Routes.Main} state={{
            modalPopup: {
              contentType: ModalContentType.subscription,
              innerContentPopup: InnerContentPopups.asset
            }
          }}/>
        </Route>
        <Route path={Routes.DevicesPopup} exact>
          <RedirectRoute path={Routes.Main} state={{
            modalPopup: {
              contentType: ModalContentType.devices,
              innerContentPopup: InnerContentPopups.devices
            }
          }}/>
        </Route>
        <Route path={Routes.ProfilePopup} exact>
          <RedirectRoute path={Routes.Main} state={{
            modalPopup: {
              contentType: ModalContentType.profile,
              innerContentPopup: InnerContentPopups.profile
            }
          }}/>
        </Route>
        <Route path={Routes.DeleteAccountPopup} exact>
          <RedirectRoute path={Routes.Main} state={{
            modalPopup: {
              contentType: ModalContentType.profile,
              innerContentPopup: InnerContentPopups.deleteAccountForm
            }
          }}/>
        </Route>
        <Route path={Routes.Download} exact>
          <Download/>
        </Route>
        <Route path={Routes.DownloadInstruction} exact>
          <DownloadInstruction/>
        </Route>
        <Route path={Routes.SalesForceDeleteFlow} exact>
          <SalesForceForm surveyType={WebToCaseSurveyType.deleteAccount}/>
        </Route>
        <Route path={Routes.AppleSignIn} exact>
          <Redirect to={Routes.Main}/>
        </Route>
        <Route path={Routes.Main}>
          <PrivateRoute>
            <Main/>
          </PrivateRoute>
        </Route>
      </Switch>
    </DefaultPageWrapper>
  )
};

export default withRouter(SwitchWrapper);
