import {AppEnv, HOUR, ServicesErrors} from '../../constants';
import {Event, RetryingFetchConfig} from './eventsTypes';
import * as Sentry from '@sentry/browser';

declare var APP_ENV: string;

const ErrorMaxRetryDelay = 'Max retry delay excess';

function exponential(delay: number) {
  return (attempts: number) => (attempts * attempts) * delay;
}

function sleep(timeout: number, maxRetryDelay: number): Promise<void> {
  if (timeout < 1) {
    return Promise.resolve();
  }

  if (timeout >= maxRetryDelay) {
    return Promise.reject(new Error(ErrorMaxRetryDelay));
  }

  return new Promise(resolve => setTimeout(resolve, timeout));
}

function makeRequest(url: string, body: Event, method = 'POST') {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.open(method, url);
    xhr.setRequestHeader('Content-Type', 'application/json');

    xhr.onload = () => {
      resolve(xhr);
    };

    xhr.onerror = () => {
      reject(new Error(ServicesErrors.events.sendEventFailed));
    };

    xhr.send(JSON.stringify(body));
  })
}

function retryingFetch(url: string, config: RetryingFetchConfig, event: Event): Promise<boolean> {
  return new Promise((resolve) => {
    const {defaultRetryDelay, maxRetryDelay} = config;
    const timeout = exponential(defaultRetryDelay);

    function execute(attempts = 0, retryDelay?: number): any {
      const wait = retryDelay || timeout(attempts);

      return sleep(wait, maxRetryDelay)
        .then(() => makeRequest(url, event)).then((resp: any) => {
          if (resp.status === 200) {
            resolve(true);
            return;
          }

          if (resp.status === 429) {
            execute(attempts + 1, Number(resp.getResponseHeader('Retry-After')));
            return;
          }

          if (resp.status >= 400 && resp.status < 500) {
            resolve(false);
            return;
          }

          execute(attempts + 1);
        })
        .catch((error: Error) => {
          if (error.message === ErrorMaxRetryDelay) {
            resolve(false);
            return;
          }

          Sentry.addBreadcrumb({
            category: 'events',
            data: {
              error: error,
              eventBody: event,
              url: url,
              attempt: attempts,
            },
            level: Sentry.Severity.Error,
          });
          Sentry.captureMessage(`${ServicesErrors.events.sendEventFailed} with ${error.message}`, Sentry.Severity.Error);

          execute(attempts + 1);
        });
    }

    execute();
  });
}

export default function send(url: string, event: Event): Promise<boolean> {
  const defaultRetryingFetchConfig = {
    defaultRetryDelay: 1000,
    maxRetryDelay: HOUR,
  };

  // Add make request - because we need to send request - for cy testing
  // Inside cy tests - we will stub request and cover events by tests
  if (APP_ENV == AppEnv.testing) {
    return new Promise((resolve) => {
      console.log('Had to send event:', event);
      return resolve(true);
    })
  }
  return retryingFetch(url, defaultRetryingFetchConfig, event);
}
