export enum IconType {
  subscription = 'subscription',
  profile = 'profile',
  changePassword = 'changePassword',
  notifications = 'notifications',
  privacy = 'privacy',
  desktop = 'desktop',
  mobile = 'mobile',
  delete = 'delete',
  revert = 'revert',
}

export enum InnerContentPopups {
  asset = 'asset',
  profile = 'profile',
  changePasswordForm = 'changePasswordForm',
  changeUserNameForm = 'changeUserNameForm',
  confirmCancel = 'confirmCancel',
  congratulationCancel = 'congratulationCancel',
  congratulationChangePassword = 'congratulationChangePassword',
  congratulationChangeUserName = 'congratulationChangeUserName',
  congratulationRenew = 'congratulationRenew',
  devices = 'devices',
  finishedTrial = 'finishedTrial',
  deleteAccountForm = 'deleteAccountForm',
  deleteAccountSuccessful = 'deleteAccountSuccessful',
  cancelDeleteAccountForm = 'cancelDeleteAccountForm',
  congratulationCancelDeleteAccount = 'congratulationCancelDeleteAccount',
}

export const PopupsActions = {
  hideModal: 'HIDE_MODAL',
  openModal: 'OPEN_MODAl',
  setModalSize: 'SET_MODAL_SIZE',
};

export const MinPopupHeight = 754;
export const MinHeightIndent = 250;
export const MinMobileHeightIndent = 50;
export const MaxHeightIndent = 140;

export enum AnimationState {
  hideModalFinished = 'hideModalFinished',
  hideModalStart = 'hideModalStart',
}
