import {connect} from 'react-redux';
import Signup from './Signup';
import {registrationRequest} from '../../../store/actions';
import {PartnerName, RegisterUserBody} from '../../../services/user/userTypes';
import {AppState, RequestStatus} from '../../../store/reducers/reducerType';
import {History} from 'history';
import {signup} from '../../../middleware';

type StateType = {
  registrationStatus: RequestStatus,
  webcbt: string,
  partnerName: PartnerName | string,
}

const mapStateToProps = (state: AppState): StateType => ({
  registrationStatus: state.user.registrationStatus,
  webcbt: state.partner.webcbt,
  partnerName: state.partner.partnerName,
});

const mapDispatchToProps = (dispatch: any) => ({
  signupUser: (user: RegisterUserBody, history: History, webcbt: string): void => {
    dispatch(signup(user, history, webcbt));
  },
  resetRegistrationErrors: () => {
    dispatch(registrationRequest());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Signup);
