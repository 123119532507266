import React, {FunctionComponent, SyntheticEvent, useEffect, useState} from 'react';
import '../Form.scss';
import './Login.scss';
import Input from '../../../components/Input';
import Button from '../../../components/Buttons/Button';
import {LoginInfo, LoginUserBody} from '../../../services/user/userTypes';
import {getDeviceId, isEmpty} from '../../../utils';
import {Link} from 'react-router-dom';
import {Routes} from '../../../router/Routes';
import {
  emailRegexp,
  ErrorItem,
  errorRules,
  maxInputValueLength,
  inputPlaceholders,
  maxEmailValueLength,
  generateErrorMessageMaxLength,
} from '../../../utils/formValidationRules';
import {useForm} from 'react-hook-form';
import {RequestStatus} from '../../../store/reducers/reducerType';
import {History} from 'history';
import {useHistory} from 'react-router-dom';
import {LoginApiErrors} from './LoginApiErrors';
import {ApiErrors, UserInfo, WebCbt, WebCbtFlowNames} from '../../../constants';
import {Events} from '../../../services/events/events';
import {EventContext, LoginEvents, LoginFormEventsName} from '../../../constants/events';
import {getUserSessionData} from '../../../store/actions/user';

type Props = {
  login: (userData: LoginUserBody, history: History) => void;
  loginStatus: RequestStatus,
  resetLoginErrors: () => void;
  loginInfo: LoginInfo | null,
  clearLoginEmail: () => void;
  webcbt: string;
  setErrorMessage: (error: string) => void;
};

type LoginFormSubmitData = Record<string, any> | {
  email: string,
  password: string
}

const Login: FunctionComponent<Props> = (props: Props) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const history = useHistory();
  const {register, handleSubmit, errors, formState: {touched}, setError} = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur'
  });
  let {webcbt} = props;

  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      sendLoginFormEvents((LoginEvents.loginError as LoginFormEventsName));
    }
  }, [errors])

  const handleLogin = async (data: LoginFormSubmitData): Promise<void> => {
    const {email, password} = data;
    const {login} = props;

    if (isSubmitted) {
      return;
    }

    setIsSubmitted(true);

    login({email, password, device_id: await getDeviceId()}, history)
  };

  useEffect(() => {
    return () => {
      props.resetLoginErrors();
    }
  }, []);

  const setApiError = (inputName: string, message: string): void => {
    setError([
      {
        type: 'custom',
        name: inputName,
        message: message,
      },
    ]);
  };

  useEffect(() => {
    // Reset isSubmitted state after get response from api - to enable button submit
    if (props.loginStatus.status || props.loginStatus.error.length) {
      setIsSubmitted(false);
    }

    determineFormErrors();
  }, [props.loginStatus]);

  const determineFormErrors = () => {
    const {loginStatus} = props;
    const apiError = loginStatus.error;

    if (!apiError.length) {
      return errors;
    }

    switch (apiError) {
      case ApiErrors.invalid_email_or_password:
        setApiError('email', LoginApiErrors[apiError]);
        setApiError('password', LoginApiErrors[apiError]);
        break;
      default:
        props.setErrorMessage(LoginApiErrors[apiError]);
        return errors;
    }
  };

  const clearLoginEmail = (ev: SyntheticEvent) => {
    const element = (ev.target as HTMLElement);
    if (!element) {
      return;
    }

    const eventName: string | undefined = element.dataset.name;

    switch (eventName) {
      case LoginEvents.forgot:
        sendLoginFormEvents((LoginEvents.forgot as LoginFormEventsName));
        break;
      case LoginEvents.signup:
        sendLoginFormEvents((LoginEvents.signup as LoginFormEventsName));
        break;
      default:
    }

    props.clearLoginEmail();
  };

  const sendLoginFormEvents = (eventsName: LoginFormEventsName) => {
    Events.send({
      context: EventContext.login,
      event: eventsName,
    });
  }

  const handleLoginButtonClick = () => {
    sendLoginFormEvents((LoginEvents.login as LoginFormEventsName));

    handleSubmit(handleLogin)
  }

  return (
    <form
      className={`form form-login form-login--${getUserSessionData(UserInfo.partnerName) || WebCbtFlowNames[webcbt] || WebCbtFlowNames[WebCbt.clario]}`}
      onSubmit={handleSubmit(handleLogin)}>
      <Input name='email'
             placeholder={inputPlaceholders.login.email}
             type='text'
             inputValue={props.loginInfo?.email}
             errors={errors != null ? (errors.email as ErrorItem) : null}
             succeeded={(errors.email == null && touched.email) as boolean}
             ref={register({
               required: errorRules.email.required,
               pattern: {
                 value: emailRegexp,
                 message: errorRules.email.validationFailed
               },
               maxLength: {
                 value: maxEmailValueLength,
                 message: generateErrorMessageMaxLength('email')
               },
             })}
      />
      <Input
        name='password'
        placeholder={inputPlaceholders.login.password}
        type='password'
        errors={errors != null ? (errors.password as ErrorItem) : null}
        succeeded={(errors.password == null && touched.password) as boolean}
        ref={register({
          required: errorRules.password.existPasswordRequired,
          maxLength: {
            value: maxInputValueLength,
            message: generateErrorMessageMaxLength('password')
          },
        })}
      />
      <div className='form__link-wrapper form__link-wrapper--login'>
        <Link
          data-name={LoginEvents.forgot}
          tabIndex={2}
          className='form__link form__link--login'
          to={Routes.Forgot}
          onClick={clearLoginEmail}>
          Forgot password?
        </Link>
      </div>
      <div className='button-wrapper'>
        <Button
          type='submit'
          disabled={isSubmitted}
          className='button--primary button--half-width button--green'
          action={handleLoginButtonClick}
          title='Log in'/>
      </div>
      <div className='form__link-wrapper form__link-wrapper--signup'>
        <div className='form__link-description'>{`New to Clario? `}
          <Link
            data-name={LoginEvents.signup}
            className='form__link'
            to={Routes.Signup}
            tabIndex={2}
            onClick={clearLoginEmail}>
            Create your account
          </Link>
          .
        </div>
      </div>
    </form>
  );
};

export default Login;
