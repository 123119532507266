import React, {FunctionComponent, useEffect, useState} from 'react';
import HeaderNavItem from '../HeaderNavItem';
import {useHeaderContext} from '../Header';
import {DefaultNavListItems, NavListItemsConfig} from './NavListItemsConfig';

type Props = {
  navListItems?: Array<string>,
}

export type ListItemType = {
  name: string,
  link?: string,
  ariaLabel?: string,
  text?: string,
  subMenu?: Array<ListItemType>
}

export type MenuItemsType = 'logo' | 'about' | 'product' | 'pricing' | 'blog' | 'account' | 'logout';
export type SubMenuItems = 'about_us' | 'product_overview' | 'faq' | 'contact' | 'mobile_security'

const HeaderNavList: FunctionComponent<Props> = ({navListItems = DefaultNavListItems}) => {
  const [navItems, changeNavItems] = useState<Array<ListItemType>>([]);
  const {handleMenuItemClick} = useHeaderContext();

  useEffect(() => {
    formNavItems();
  }, [navListItems])

  const formNavItems = () => {
    let navItemsConfig: Array<ListItemType> = [];
    navListItems.map(navListItem => {
      let navItem = NavListItemsConfig.find(listItem => listItem.name === navListItem);
      if (navItem) {
        navItemsConfig.push((navItem as ListItemType));
      }
    });
    changeNavItems((navItemsConfig as Array<ListItemType>));
  }

  return (
    <>
      {!!navItems.length && (
        <ul className='header__menu' onClick={handleMenuItemClick}>
          {navItems.map(navListItem => (
            <HeaderNavItem
              key={navListItem.name}
              {...navListItem}
            />
          ))}
        </ul>
      )}
    </>
  );
}

export default HeaderNavList;
