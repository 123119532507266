import {Dispatch} from 'redux';
import {
  SendGoogleCredentialsRequestReduxAction,
  SendGoogleCredentialsSuccessReduxAction,
  SendGoogleCredentialsFailureReduxAction
} from '../ssoActionsTypes';
import {SSOService} from '../../../../services/sso/sso';
import {SSOActions} from '../../../../constants/sso';
import {GoogleAuthResponseType} from '../../../../services/sso/ssoTypes';

export function sendGoogleCredentials(token: string) {
  return (dispatch: Dispatch<SendGoogleCredentialsRequestReduxAction | SendGoogleCredentialsSuccessReduxAction | SendGoogleCredentialsFailureReduxAction>) => {
    dispatch(request({token}));

    SSOService.sendGoogleCredentials(token)
      .then(() => {
          dispatch(success());
        },
        error => {
          dispatch(failure(error));
        }
      );

    function request(googleAuthResponse: GoogleAuthResponseType): SendGoogleCredentialsRequestReduxAction {
      return {type: SSOActions.SEND_GOOGLE_CREDENTIALS_REQUEST, payload: googleAuthResponse}
    }

    function success(): SendGoogleCredentialsSuccessReduxAction {
      return {type: SSOActions.SEND_GOOGLE_CREDENTIALS_SUCCESS, payload: {}}
    }

    function failure(error: Error): SendGoogleCredentialsFailureReduxAction {
      return {type: SSOActions.SEND_GOOGLE_CREDENTIALS_FAILURE, payload: error}
    }
  }
}
