import * as React from 'react';
import './PasswordIcons.scss';

const ShowPasswordIcon = require('../../../../resources/images/password/icon_show-password.svg').default;
const HidePasswordIcon = require('../../../../resources/images/password/icon_hide-password.svg').default;

type Props = {
  shownPassword: boolean,
  toggleShowPassword: () => void;
}

const PasswordIcons: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <>
      {props.shownPassword
        ?
        <div className='icon__wrapper icon__wrapper--password'>
          <HidePasswordIcon
            className='icon'
            onClick={props.toggleShowPassword}/>
        </div>
        :
        <div className='icon__wrapper icon__wrapper--password'>
          <ShowPasswordIcon
            className='icon'
            onClick={props.toggleShowPassword}/>
        </div>
      }
    </>
  );
};

export default PasswordIcons;
