export enum DashboardEvents {
  open = 'page__opened',
  subscription_area__manage_button_click_to_popup = 'subscription_area__manage_button_click_to_popup',
  subscription_area__manage_button_click_to_buynow = 'subscription_area__manage_button_click_to_buynow',
  subscription_area__subscribe_button_click_to_buynow = 'subscription_area__subscribe_button_click_to_buynow',
  profile_area__manage_button_click_to_popup = 'profile_area__manage_button_click_to_popup',
}

export enum SubscriptionsEvents {
  detailed_area__popup_expanded = 'detailed_area__popup_expanded',
  detailed_area__cancel_button_click = 'detailed_area__cancel_button_click',
  detailed_area__cancel_confirmation_popup_shown = 'detailed_area__cancel_confirmation_popup_shown',
  detailed_area__cancel_confirmation_popup_no_button_click = 'detailed_area__cancel_confirmation_popup_no_button_click',
  detailed_area__cancel_confirmation_popup_yes_button_click = 'detailed_area__cancel_confirmation_popup_yes_button_click',
  detailed_area__cancel_success_popup_shown = 'detailed_area__cancel_success_popup_shown',
  detailed_area__cancel_success_popup_back_button_click = 'detailed_area__cancel_success_popup_back_button_click',
  detailed_area__renew_button_click = 'detailed_area__renew_button_click',
  detailed_area__renew_success_popup_shown = 'detailed_area__renew_success_popup_shown',
  detailed_area__renew_success_popup_back_button_click = 'detailed_area__renew_success_popup_back_button_click',
}

export const ErrorEvents = {
  changePassword: {
    open: 'change_password_page_error_shown',
    startChat: 'change_password_page_error_chat_clicked',
  },
  changeUserName: {
    open: 'change_user_name_page_error_shown',
    startChat: 'change_user_name_page_error_chat_clicked',
  },
  deleteAccount: {
    open: 'delete_account_page_error_shown',
    startChat: 'delete_account_page_error_chat_clicked',
  },
  cancelDeleteAccount: {
    open: 'retrieve_account_page_error_shown',
    startChat: 'retrieve_account_page_error_chat_clicked',
  },
  detailed_area__cancel_unsuccess_error_shown: 'detailed_area__cancel_unsuccess_error_shown',
  detailed_area__cancel_unsuccess_error_chat_button_click: 'detailed_area__cancel_unsuccess_error_chat_button_click',
  detailed_area__renew_unsuccess_error_shown: 'detailed_area__renew_unsuccess_error_shown',
  detailed_area__renew_unsuccess_error_chat_button_click: 'detailed_area__renew_unsuccess_error_chat_button_click',
}

export const ProfileEvents = {
  open: 'page_loaded',
  buttons: {
    close: 'close_button_clicked',
    changePassword: 'change_password_clicked',
    changeUserName: 'change_user_name_clicked',
    deleteAccount: 'delete_account_clicked'
  },
  changePasswordForm: {
    open: 'change_password_page_shown',
    changePasswordButtonClick: 'change_password_page_submit_clicked'
  },
  changeUserNameForm: {
    open: 'change_user_name_page_shown',
    changeUserNameButtonClick: 'change_user_name_page_submit_clicked'
  },
  congratulationChangePassword: {
    open: 'change_password_page_confirmation_shown',
    backToAccountButtonClick: 'change_password_page_back_to_account_clicked',
  },
  congratulationChangeUserName: {
    open: 'change_user_name_page_confirmation_shown',
    backToAccountButtonClick: 'change_user_name_page_back_to_account_clicked',
  },
  deleteAccountForm: {
    open: 'delete_account_page_shown',
    deleteButtonClick: 'delete_account_page_submit_clicked',
    feedback: {
      sent: 'delete_account_page_feedback_sent',
      notSent: 'delete_account_page_feedback_not_sent'
    }
  },
  deleteAccountSuccessful: {
    open: 'delete_account_page_confirmation_shown',
    cancelDeleteButtonClick: 'delete_account_page_confirmation_clicked',
  },
  cancelDeleteAccountForm: {
    open: 'retrieve_account_page_shown',
    buttonClick: 'retrieve_account_page_submit_clicked',
  },
  cancelDeleteAccountSuccessful: {
    open: 'retrieve_account_page_account_retrieved_shown',
    buttonClick: 'retrieve_account_page_back_to_account_clicked',
  },
}

export enum DevicesEvents {
  detailed_area__no_devices_popup_expanded = 'detailed_area__no_devices_popup_expanded',
  detailed_area__devices_popup_expanded = 'detailed_area__devices_popup_expanded',
  detailed_area__disconnect_button_click = 'detailed_area__disconnect_button_click',
  detailed_area__disconnect_confirmation_area_shown = 'detailed_area__disconnect_confirmation_area_shown',
  detailed_area__disconnect_confirmation_area_no_click = 'detailed_area__disconnect_confirmation_area_no_click',
  detailed_area__disconnect_confirmation_area_yes_click = 'detailed_area__disconnect_confirmation_area_yes_click',
  detailed_area__disconnect_success_area_shown = 'detailed_area__disconnect_success_area_shown',
  detailed_area__disconnect_success_back_button_click = 'detailed_area__disconnect_success_back_button_click',
  detailed_area__download_clario_desktop_link_click = 'detailed_area__download_clario_desktop_link_click',
  detailed_area__how_many_devices_can_connect_tab_click = 'detailed_area__how_many_devices_can_connect_tab_click',
  detailed_area__which_devices_can_connect_tab_click = 'detailed_area__which_devices_can_connect_tab_click',
  detailed_area__how_connect_devices_tab_click = 'detailed_area__how_connect_devices_tab_click',
  detailed_area__device_protected_tab_click = 'detailed_area__device_protected_tab_click',
  detailed_area__connect_more_devices_tab_click = 'detailed_area__connect_more_devices_tab_click',
  detailed_area__install_clario_new_devices_tab_click = 'detailed_area__install_clario_new_devices_tab_click',
  detailed_area__start_chat_link_click = 'detailed_area__start_chat_link_click',
  detailed_area__disconnect_unsuccess_error_shown = 'detailed_area__disconnect_unsuccess_error_shown',
  detailed_area__disconnect_unsuccess_error_chat_button_click = 'detailed_area__disconnect_unsuccess_error_chat_button_click',
  detailed_area__unknown_device_present = 'detailed_area__unknown_device_present',
  detailed_area__unknown_device_disconnect_button_click = 'detailed_area__unknown_device_disconnect_button_click',
}

export enum EmailVerificationResendEvents {
  detailed_area__verify_email_resend_button_click = 'detailed_area__verify_email_resend_button_click',
  detailed_area__verify_email_resend_unsuccess_error_shown_exceeded = 'detailed_area__verify_email_resend_unsuccess_error_shown_exceeded',
  detailed_area__verify_email_resend_unsuccess_error_shown_unexpected = 'detailed_area__verify_email_resend_unsuccess_error_shown_unexpected'
}

export enum EmailVerificationEvents {
  page_open__email_verification = 'page_open__email_verification',
  page_opened__email_verification_success = 'page_opened__email_verification_success',
  page_opened__email_verification_unsuccess_error_shown_expired = 'page_opened__email_verification_unsuccess_error_shown_expired',
  page_opened__email_verification_unsuccess_error_shown_unexpected = 'page_opened__email_verification_unsuccess_error_shown_unexpected',
  page_opened__email_verification_unsuccess_login_button_click = 'page_opened__email_verification_unsuccess_login_button_click',
}

export enum DownloadEvents {
  desktop_download_button_click = 'desktop_download_button_click',
}
