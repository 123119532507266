import {History} from 'history';
import fetch from 'node-fetch';
import {LicenseApi, ServicesErrors} from '../../constants';
import * as Sentry from '@sentry/browser';
import {handleCommonErrorResponse, handleResponse, determineErrorLevelForSentry} from '../../utils';
import {ErrorType} from '../../store/actions/actionsTypes';
import {Severity} from '@sentry/types/dist/severity';

export async function disconnect(licenseID: string, history: History): Promise<{}> {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  };

  return fetch(`${LicenseApi}/${licenseID}/device`, requestOptions)
    .then(handleResponse)
    .catch(error => {
      const errorLevel = determineErrorLevel(error);

      Sentry.configureScope((scope) => {
        scope.setLevel(errorLevel);
      })

      Sentry.addBreadcrumb({
        category: 'device',
        message: 'Endpoint ' + `${LicenseApi}/${licenseID}/device`,
        data: {
          error: error,
          requestData: requestOptions,
        },
        level: errorLevel,
      });
      Sentry.captureMessage(ServicesErrors.device.disconnectDeviceFailed, errorLevel);
      return handleCommonErrorResponse(error, history);
    });
}

function determineErrorLevel(error: ErrorType): Severity {
  switch (error.status) {
    case 404:
      return Severity.Error;
    default:
      return determineErrorLevelForSentry(error);
  }
}
