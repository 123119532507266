import {AppEnv} from './envConstants';

declare var APP_ENV: string;

export const BuyNowLinks = {
  default: 'https://clario.co/pricing-acc',
  trialSubscription: 'https://clario.co/pricing-acc',
  monthlySubscription: 'https://clario.co/pricing-acc',
  yearlySubscription: 'https://clario.co/pricing-acc',
  stagingEnv: {
    fsc: 'https://buynow.account.sz.clario.co/clario/lumis_fastspring_bn_emulator_with_login.html',
    ppg: 'https://buynow.account.sz.clario.co/clario/ppg_login.html'
  },
  testingEnv: {
    fsc: 'https://buynow.account.tz.clario.co/clario/lumis_fastspring_bn_emulator_with_login.html',
    ppg: 'https://buynow.account.tz.clario.co/clario/ppg_login.html'
  },
};

export const DownloadSystemLink = 'https://clario.co/link/11be6a84-b491-11ea-bd6f-127369ec21d1';

export const MobileDownloadLink = APP_ENV == AppEnv.production ?
    'https://clario.onelink.me/a7yn/waoo' :
    'https://clario-test.onelink.me/UfQo/81f1b40c';

export const ClarioSiteLinks = {
  contacts: 'https://clario.co/contacts',
  about: 'https://clario.co/about/',
  product: ' https://clario.co/cybersecurity-software/',
  pricing: 'https://clario.co/pricing-web',
  faq: 'https://clario.co/faq/',
  blog: 'https://clario.co/blog/',
  mainPage: 'https://clario.co/',
  privacyPolicy: 'https://clario.co/privacy-policy/',
  cookiePolicy: 'https://clario.co/cookie-policy/',
  eula: 'https://clario.co/eula/',
  privacyNotice: 'https://clario.co/privacy-notice/',
  terms: 'https://clario.co/terms-and-conditions/',
  refundPolicy: 'https://clario.co/refund-policy/',
  mobileSecurity: 'https://clario.co/mobile-security/',
  secureBrowsing: 'https://clario.co/secure-browsing/'
}

export const AccountLinks = {
  [AppEnv.testing]: 'https://account.tz.clario.co/',
  [AppEnv.staging]: 'https://account.sz.clario.co/',
  [AppEnv.production]: 'https://account.clario.co',
}

export const DesktopAppLink = {
  main: 'co-clario://open',
  mainScreen: 'co-clario://main_screen'
}

export const MobileAppLink = {
  thankYou: 'https://clario.onelink.me/a7yn/f1cec81d'
}
