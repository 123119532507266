export enum PlatformNames {
  MacOS= 'MacOS',
  iOS= 'iOS',
  Windows= 'Windows',
  Android= 'Android',
  Linux= 'Linux',
  Other= 'Other'
}

export default function getPlatform(): PlatformNames {
  let userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod'],
    os = PlatformNames.Other;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = PlatformNames.MacOS;
  } else if (iosPlatforms.indexOf(platform) !== -1 || navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1 && !window.MSStream) {
    os = PlatformNames.iOS;
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = PlatformNames.Windows;
  } else if (/Android/.test(userAgent)) {
    os = PlatformNames.Android;
  } else if (!os && /Linux/.test(platform)) {
    os = PlatformNames.Android;
  }

  return os;
}
