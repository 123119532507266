import {connect} from 'react-redux';
import AppleSignIn from './AppleSignIn';
import {AppState, RequestStatus} from '../../store/reducers/reducerType';
import {AppleAuthRedirectLinkResponse} from '../../services/sso/ssoTypes';
import {getAppleAuthRedirectLink} from '../../store/actions/sso';

type StateType = {
  appleAuthRedirectLink: AppleAuthRedirectLinkResponse | null,
  appleAuthRedirectLinkStatus: RequestStatus,
}

const mapStateToProps = (state: AppState): StateType => ({
  appleAuthRedirectLink: state.sso.appleAuthRedirectLink,
  appleAuthRedirectLinkStatus: state.sso.appleAuthRedirectLinkStatus,
});

const mapDispatchToProps = (dispatch: any) => ({
  getAppleAuthRedirectLink: (): void => {
    dispatch(getAppleAuthRedirectLink());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppleSignIn);
