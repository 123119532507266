import React, {FunctionComponent, MouseEvent, useState} from 'react';
import './Header.scss';
import {EventContext, HeaderEvents, WebCbt} from '../../constants';
import HeaderDefault from './Types/HeaderDefault';
import HeaderWaoo from './Types/HeaderWaoo';
import {Events} from '../../services/events/events';
import {MenuItemsType, SubMenuItems} from './HeaderNavList/HeaderNavList';
import {useSelector} from 'react-redux';
import {selectIsAuthenticated} from '../../store/reducers/user';
import {selectWebCbt} from '../../store/reducers/partner';

export enum HeaderTypes {
  withoutPricing = 'withoutPricing',
  onlyLogo = 'onlyLogo',
  default = 'default'
}

type HeaderEventsName = MenuItemsType & SubMenuItems;

export const HeaderContext = React.createContext<Partial<HeaderConsumerContext>>({});

export type HeaderConsumerContext = {
  openMenuState: boolean,
  isAuthenticated: boolean | null,
  hideBurgerMenu: () => void,
  toggleOpenMenuState: () => void,
  handleMenuItemClick: (ev: MouseEvent<HTMLElement>) => void,
}

export function useHeaderContext() {
  const context = React.useContext(HeaderContext);

  if (!context) {
    throw new Error(
      'Header Compound Components must be rendered within the Header Component',
    )
  }
  return context;
}

const Header = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const webcbt = useSelector(selectWebCbt);
  const [openMenuState, setOpenMenuState] = useState(false);

  const toggleOpenMenuState = (): void => {
    openMenuState ? document.body.style.overflow = 'visible' : document.body.style.overflow = 'hidden';
    setOpenMenuState(!openMenuState);
  };

  const hideBurgerMenu = () => {
    document.body.style.overflow = 'visible';
    setOpenMenuState(false);
  }

  const handleMenuItemClick = (ev: MouseEvent<HTMLElement>) => {
    const menuItemElement: any = ev.target;

    if (!menuItemElement) {
      return;
    }

    const itemName: HeaderEventsName = (menuItemElement.dataset.name as HeaderEventsName);

    if (!itemName) {
      return;
    }

    if (HeaderEvents[itemName])
      Events.send({
        context: EventContext.header,
        event: HeaderEvents[itemName],
      });
  }

  function determineHeaderType() {
    switch (webcbt) {
      case WebCbt.waoo:
      case WebCbt.stackCommerce:
      case WebCbt.mkClarioMobile:
        return <HeaderWaoo/>
      default:
        return <HeaderDefault/>
    }
  }

  return (
    <HeaderContext.Provider value={{
      openMenuState: openMenuState,
      isAuthenticated,
      hideBurgerMenu: hideBurgerMenu,
      toggleOpenMenuState: toggleOpenMenuState,
      handleMenuItemClick: handleMenuItemClick,
    }}>
      {determineHeaderType()}
    </HeaderContext.Provider>
  )
};

export default React.memo(Header);
