import React, {FunctionComponent, Suspense} from 'react';
import {InnerContentPopups,} from '../../../constants';
import {useModalPopupContext} from '../ModalWrapper/ModalPopupWrapper';
import ModalContentWrapper from '../ModalContentWrapper';
import './SubscriptionConductor.scss';

const Assets = React.lazy(() => import('./Assets/Assets'));
const FinishedTrial = React.lazy(() => import('./FinishedTrial'));
const Congratulation = React.lazy(() => import('../Shared/Congratulation'));
const ConfirmCancel = React.lazy(() => import('../ConfirmCancel'));

const SubscriptionConductor: FunctionComponent = () => {
    const {modalPopup} = useModalPopupContext();
    const determinePopup = () => {
        switch (modalPopup?.innerContentPopup) {
            case InnerContentPopups.asset:
                return <Assets />;
            case InnerContentPopups.confirmCancel:
                return <ConfirmCancel />;
            case InnerContentPopups.congratulationCancel:
                return <Congratulation type={InnerContentPopups.congratulationCancel} />;
            case InnerContentPopups.congratulationRenew:
                return <Congratulation type={InnerContentPopups.congratulationRenew} />;
            case InnerContentPopups.finishedTrial:
                return <FinishedTrial />;
            default:
                return null;
        }
    }

    return (
        <ModalContentWrapper>
            <div className='subscription-popup__content'>
                <Suspense fallback={null}>
                    {determinePopup()}
                </Suspense>
            </div>
        </ModalContentWrapper>
    )
}

export default SubscriptionConductor;
