import {CdeStateType} from './types';

export const DefaultCdeState: CdeStateType = {
    deleteAccountStatus: null,
    deleteAccountRequestStatus: {
        status: false,
        error: ''
    },
    cancelDeleteAccountRequestStatus: {
        status: false,
        error: ''
    },
    getDeleteAccountStatusRequestStatus: {
        status: false,
        error: ''
    }
};