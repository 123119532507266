export enum GoogleParams {
    UtmCampaign = 'utm_campaign',
    UtmMedium = 'utm_medium',
    UtmSource = 'utm_source',
    Ga = '_ga',
    GaId = 'gid',
    GaCid = 'ga_cid',
}

export const TrackingParams = {
    ...GoogleParams,
}