import {ClarioSiteLinks} from '../../../constants/links';
import {FooterNavLinksName} from '../../../constants/general';

export const NavListItemsConfig = [
  {
    name: FooterNavLinksName.privacyPolicy,
    link: ClarioSiteLinks.privacyPolicy,
    ariaLabel: 'Privacy Policy',
    text: 'Privacy Policy',
  },
  {
    name: FooterNavLinksName.cookie,
    link: ClarioSiteLinks.cookiePolicy,
    ariaLabel: 'Cookie Policy',
    text: 'Cookie Policy',
  },
  {
    name: FooterNavLinksName.eula,
    link: ClarioSiteLinks.eula,
    ariaLabel: 'EULA',
    text: 'EULA',
  },
  {
    name: FooterNavLinksName.privacyNotice,
    link: ClarioSiteLinks.privacyNotice,
    ariaLabel: 'Privacy Notice',
    text: 'Privacy Notice',
  },
  {
    name: FooterNavLinksName.terms,
    link: ClarioSiteLinks.terms,
    ariaLabel: 'Terms and Conditions',
    text: 'Terms and Conditions',
  },
  {
    name: FooterNavLinksName.refund,
    link: ClarioSiteLinks.refundPolicy,
    ariaLabel: 'Refund Policy',
    text: 'Refund Policy',
  }
]
