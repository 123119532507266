import {deleteAccount} from './deleteAccount';
import {cancelDeleteAccount} from './cancelDeleteAccount';
import {getDeleteAccountStatus} from './getDeleteAccountStatus';
import {ErrorType} from '../../store/actions/actionsTypes';
import {Severity} from '@sentry/browser';
import {ApiErrors} from '../../constants';
import {History} from 'history';
import {Routes} from '../../router/Routes';

export const cdeService = {
  deleteAccount,
  cancelDeleteAccount,
  getDeleteAccountStatus,
};

export function determineErrorLevel(error: ErrorType): Severity {
  if (error.message === ApiErrors.invalid_user_password) {
    return Severity.Info;
  }

  switch (error.status) {
    case 400:
    case 500:
      return Severity.Error;
    case 409:
      return Severity.Info;
    default:
      return Severity.Info;
  }
}

type Error = {
  message: string,
  status: number,
}

export default function handleCommonCdeErrorResponse(error: Error, history: History): Promise<string> {
  switch (error.status) {
    case 401:
      history.push(Routes.Login);
      return Promise.reject({message: error.message});
    default:
      return Promise.reject({message: error.message, status: error.status});
  }
}

