import {History} from 'history';
import fetch from 'node-fetch';
import {UserVerifyVerificationEmailCode, ServicesErrors} from '../../constants';
import {handleResponse, determineErrorLevelForSentry} from '../../utils';
import * as Sentry from '@sentry/browser';

export function checkVerificationEmailCode(verificationCode: string, history: History): Promise<string> {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({code: verificationCode})
  };

  return fetch(UserVerifyVerificationEmailCode, requestOptions)
    .then(handleResponse)
    .catch(error => {
      const errorLevel = determineErrorLevelForSentry(error);

      Sentry.configureScope((scope) => {
        scope.setLevel(errorLevel);
      })


      Sentry.addBreadcrumb({
        category: 'auth',
        message: 'Endpoint ' + UserVerifyVerificationEmailCode,
        data: {
          error: error,
          requestOptions,
        },
        level: Sentry.Severity.Error
      });
      Sentry.captureMessage(`${ServicesErrors.emailVerification.checkVerificationEmailCodeFailed} ${error.message}`, Sentry.Severity.Error);
      throw new Error(error.message);
    });
}
