import React, {FunctionComponent, useEffect, useRef} from 'react';
import {Events} from '../../../../services/events/events';
import {DownloadLinksEvents, EventContext} from '../../../../constants';
import {DownloadLinkCircleType} from '../../DownloadLinkCircle';

type Props = {
  handleContactSupport: (from: DownloadLinkCircleType) => void;
}

const LinkSend: FunctionComponent<Props> = (props: Props) => {
  const linkSendRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    sendLinkSendShowEvent();
  }, []);

  function sendLinkSendShowEvent() {
    if (linkSendRef && linkSendRef.current) {
      linkSendRef.current.addEventListener('animationend', () => {
        Events.send({
          context: EventContext.downloadLinks,
          event: DownloadLinksEvents.email_sent_shown,
        });
      });
    }
  }

  return (
    <div ref={linkSendRef} className='download-link-circle__content-wrapper download-link-circle__content-wrapper--link-send'>
      <div className='download-link-circle__content'>
        <h3 className='download-link-circle__title'>Alright, we've emailed you a new link.</h3>
        <p className='download-link-circle__text'>Please check your inbox and follow this link to download Clario.</p>
        <footer className='download-link-circle__footer download-link-circle-footer'>
          <div className='download-link-circle__text'>{`Any problems?`}
            <p className='download-link-circle__text download-link-circle__text--bold download-link-circle__text--support'
               onClick={() => props.handleContactSupport(DownloadLinkCircleType.linkSend)}>Let us know.</p>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default LinkSend;
