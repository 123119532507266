import React, {MouseEvent, ReactElement} from 'react';
import './CircleButton.scss';

export enum CircleButtonTypes {
  close = 'close',
  back = 'back'
}

type CircleButtonProps = {
  type: CircleButtonTypes
  className?: string;
  action: (ev: MouseEvent<HTMLDivElement>) => void,
  text?: string;
}

const CircleButton = (props: CircleButtonProps): ReactElement => {
  const {className = '', text, type = CircleButtonTypes.close} = props;
  return (
    <div
      className={`circle-button circle-button--${type} ${className}`}
      onClick={props.action}>
      {text && <span className='circle-button__text'>{text}</span>}
    </div>
  )
};

export default CircleButton;
