import {Dispatch} from 'redux';
import {
  ChangeUserNameRequestReduxAction,
  ChangeUserNameSuccessReduxAction,
  ChangeUserNameFailureReduxAction,
} from './userActionsTypes';
import {userService} from '../../../services/user/user';
import {UserConstants} from '../../../constants';
import {History} from 'history';

export function changeUserName(userName: string, history: History) {
  return (dispatch: Dispatch<ChangeUserNameRequestReduxAction | ChangeUserNameSuccessReduxAction | ChangeUserNameFailureReduxAction>) => {
    dispatch(changeUserNameRequest());

    userService.changeUserName(userName, history)
      .then(
        () => {
          dispatch(success());
        },
        error => {
          dispatch(failure(error));
        }
      );

    function success(): ChangeUserNameSuccessReduxAction {
      return {type: UserConstants.CHANGE_USER_NAME_SUCCESS, payload: {}}
    }

    function failure(error: Error): ChangeUserNameFailureReduxAction {
      return {type: UserConstants.CHANGE_USER_NAME_FAILURE, payload: error}
    }
  }
}

export function changeUserNameRequest(): ChangeUserNameRequestReduxAction {
  return {type: UserConstants.CHANGE_USER_NAME_REQUEST, payload: {}}
}
