import React, {FunctionComponent, useEffect} from 'react';
import withServerErrorHandlingPage from '../../../hoc/ServerErrorHandlingPage';
import DownloadLinkCircle, {DownloadLinkCircleType} from '../../../containers/DownloadLinkCircle';
import {Events} from '../../../services/events/events';
import {DownloadLinksEvents, EventContext} from '../../../constants';
import DownloadLinksWrapper from '../DownloadLinksWrapper';

type Props = {
  resendLinkFailed: boolean,
}

const TokenExpired: FunctionComponent<Props> = (props: Props) => {
  useEffect(() => {
    Events.send({
      context: EventContext.downloadLinks,
      event: DownloadLinksEvents.page_opened,
    });
  }, []);

  return (
    <DownloadLinksWrapper type={DownloadLinkCircleType.tokenExpired}>
      <img src='./images/waoo/emotion-disappointment.png' alt='disappointment emotion' className='download-link-expired__image download-link-expired__aside'/>
      <DownloadLinkCircle type={DownloadLinkCircleType.tokenExpired} resendLinkFailed={props.resendLinkFailed} />
    </DownloadLinksWrapper>
  );
};

export default (withServerErrorHandlingPage(TokenExpired) as any);
