export default function handleResponse(response: any): Promise<any> {
  return response.text().then((text: string) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      return Promise.reject({...data, status: response.status});
    }

    return data;
  });
}
