import React, {useEffect, useState} from 'react';
import Input from '../../../components/Input';
import Button from '../../../components/Buttons/Button';
import {
  ErrorItem,
  errorRules,
  inputPlaceholders,
  minPasswordValueLength,
  maxInputValueLength,
  passwordIncludeNumberRegexp,
  passwordIncludeLowerCaseLetterRegexp,
  passwordIncludeUpperCaseLetterRegexp, generateErrorMessageMaxLength
} from '../../../utils/formValidationRules';
import {useForm} from 'react-hook-form';
import {RequestStatus} from '../../../store/reducers/reducerType';
import {ResetPasswordData} from '../../../services/user/userTypes';
import {NavLink} from 'react-router-dom';
import {Routes} from '../../../router/Routes';
import {History} from 'history';
import {useHistory} from 'react-router-dom';
import {ResetPasswordApiErrors} from './ResetPasswordApiErrors';
import '../Form.scss';
import {EventContext, ApiErrors} from '../../../constants';
import {Events} from '../../../services/events/events';
import {ResetPasswordEvents, ResetPasswordFormEventsName} from '../../../constants/events';

type Props = {
  reset: (userData: ResetPasswordData, history: History) => void;
  resetPasswordStatus: RequestStatus;
  verificationCode: string;
  resetResetPasswordErrors: () => void;
  setErrorMessage: (error: string) => void;
};

type ResetPasswordSubmitData = Record<string, any> | {
  password: string,
}
const ResetPassword: React.FunctionComponent<Props> = (props: Props) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const history = useHistory();
  const {register, handleSubmit, errors, formState: {touched}, getValues, setError} = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur'
  });

  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      sendResetPasswordFormEvents(ResetPasswordEvents.resetError);
    }
  }, [errors])


  const handleResetPassword = (data: ResetPasswordSubmitData): void => {
    const {password} = data;
    const {reset, verificationCode} = props;

    if (isSubmitted) {
      return;
    }

    setIsSubmitted(true);
    reset({password, verificationCode}, history)
  };

  useEffect(() => {
    return () => {
      props.resetResetPasswordErrors();
    }
  }, []);

  useEffect(() => {
    // Reset isSubmitted state after get response from api - to enable button submit
    if (props.resetPasswordStatus.status || props.resetPasswordStatus.error.length) {
      setIsSubmitted(false);
    }

    determineFormErrors();
  }, [props.resetPasswordStatus]);

  const setApiError = (inputName: string, message: string): void => {
    setError([
      {
        type: 'custom',
        name: inputName,
        message: message,
      },
    ]);
  };

  const determineFormErrors = () => {
    const {resetPasswordStatus} = props;
    const apiError = resetPasswordStatus.error;

    if (!apiError.length) {
      return errors;
    }

    switch (apiError) {
      case ApiErrors.invalid_password:
      case ApiErrors.password_is_less_6_symbols:
      case ApiErrors.password_does_not_contain_a_letter:
      case ApiErrors.password_contains_space:
      case ApiErrors.password_does_not_contain_a_number:
        setApiError('password', ResetPasswordApiErrors[apiError]);
        break;
      default:
        props.setErrorMessage(ResetPasswordApiErrors[apiError]);
        return errors;
    }
  };

  const sendResetPasswordFormEvents = (eventsName: ResetPasswordFormEventsName) => {
    Events.send({
      context: EventContext.resetPassword,
      event: eventsName,
    });
  }

  const handleResetPasswordButtonClick = () => {
    sendResetPasswordFormEvents(ResetPasswordEvents.reset);

    handleSubmit(handleResetPassword)
  }

  const handleBackToLogin = () => {
    sendResetPasswordFormEvents(ResetPasswordEvents.backToLogin);
  }

  return (
    <form className='form' onSubmit={handleSubmit(handleResetPassword)}>
      <Input
        name='password'
        placeholder={inputPlaceholders.resetPassword.password}
        type='password'
        errors={errors != null ? (errors.password as ErrorItem) : null}
        succeeded={(errors.password == null && touched.password) as boolean}
        ref={register({
          required: errorRules.password.required,
          minLength: {
            value: minPasswordValueLength,
            message: errorRules.password.length
          },
          validate: {
            includeLowerCaseLetter: value => passwordIncludeLowerCaseLetterRegexp.test(value) || errorRules.password.lowerCaseLetter,
            includeUpperCaseLetter: value => passwordIncludeUpperCaseLetterRegexp.test(value) || errorRules.password.upperCaseLetter,
            includeNumber: value => passwordIncludeNumberRegexp.test(value) || errorRules.password.number,
          },
          maxLength: {
            value: maxInputValueLength,
            message: generateErrorMessageMaxLength('password')
          },
        })}
      />
      <Input name='confirmPassword'
             type='password'
             placeholder={inputPlaceholders.resetPassword.confirmPassword}
             errors={errors != null ? (errors.confirmPassword as ErrorItem) : null}
             succeeded={(errors.confirmPassword == null && touched.confirmPassword) as boolean}
             ref={register({
               required: errorRules.password.required,
               validate: value => value === getValues().password || errorRules.password.notEqual
             })}/>
      <div className='button-wrapper'>
        <Button
          type='submit'
          disabled={isSubmitted}
          className='button--primary button--half-width button--green'
          action={handleResetPasswordButtonClick}
          title='Log in'/>
      </div>
      <div className='form__link-wrapper'>
        <NavLink className='form__link' tabIndex={2} to={Routes.Login} onClick={handleBackToLogin}>Back to Login</NavLink>
      </div>
    </form>
  );
};

export default ResetPassword;

