import React, {FunctionComponent, useEffect} from 'react';
import Button from '../../../../../components/Buttons/Button';
import {Device, DevicesStatus} from '../../../../../services/device/deviceTypes';
import {DeviceItemScreenType, DevicesEvents, EventContext, IconType} from '../../../../../constants';
import {Events} from '../../../../../services/events/events';
import IconsWithBackground from '../../../../../components/Icons/IconsWithBackground';

type Props = {
  device: Device;
  devicesStatus: DevicesStatus | null;
  listNumber: number,
  setDeviceItemScreenType: (deviceId: string, screenType: DeviceItemScreenType) => void;
}

const UnknownDevice: FunctionComponent<Props> = (props: Props) => {
  const {device, devicesStatus, listNumber, setDeviceItemScreenType} = props;

  useEffect(() => {
    Events.send({
      context: EventContext.devices,
      event: DevicesEvents.detailed_area__unknown_device_present,
    })
  }, []);


  const handleDisconnect = () => {
    setDeviceItemScreenType(device.id, DeviceItemScreenType.disconnectConfirm);

    Events.send({
      context: EventContext.devices,
      event: DevicesEvents.detailed_area__unknown_device_disconnect_button_click,
      data: {deviceId: device.id}
    });
  };


  return (
    <div className='device-item'>
      <div className='device-item__header'>
        <IconsWithBackground iconType={IconType.desktop} className='device-item__icon' size='big'/>
        <p className='device-item__type'>Unknown Device</p>
      </div>
      <aside className='device-item__aside'>
        <div className='device-item__device-info-block'>
          <div className='device-item__device-info'>
            <p className='device-item__device-info-item device-item__device-info-name'>Model</p>
            <p className='device-item__device-info-item device-item__device-info-value'> Unknown</p>
          </div>
          <div className='device-item__device-info'>
            <p className='device-item__device-info-item device-item__device-info-name'>Last login</p>
            <p className='device-item__device-info-item device-item__device-info-value'>Unknown</p>
          </div>
        </div>
      </aside>
      <aside className='device-item__aside device-item__aside--right'>
        <div className='device-item__info'>
          <p className='device-item__number'>{`Device ${listNumber + 1}`}</p>
          <p className='device-item__status'>{devicesStatus?.name}</p>
        </div>
        <div className='button-wrapper device-item__button-wrapper'>
          <Button
            type='button'
            className='button--secondary button--dark assets__button'
            action={handleDisconnect}
            title='Disconnect'/>
        </div>
      </aside>
    </div>
  );
};

export default UnknownDevice;
