import {Dispatch} from 'redux';
import {PartnersActions, UserInfo} from '../../../constants';
import {SetPartnerNameReduxAction} from './partnerActionsTypes';
import {PartnerName} from '../../../services/user/userTypes';

export function setPartnerName(name: PartnerName | string) {
  return (dispatch: Dispatch<SetPartnerNameReduxAction>) => {
    if (!name) {
      return;
    }
    sessionStorage.setItem(UserInfo.partnerName, name);
    dispatch({type: PartnersActions.SET_PARTNER_NAME, payload: {name: name}});
  };
}
