import {connect} from 'react-redux';
import {UserInfo, AppState} from '../../../../store/reducers/reducerType';
import GreetingDefault from './GreetingDefault';
import {Asset} from '../../../../services/assets/assetsTypes';
import {DeleteAccountRequestStatus} from '../../../../store/actions/cde/cdeTypes';
import {EnrichedDevice} from '../../../../services/device/deviceTypes';

type StateType = {
  userInfo: UserInfo,
  currentAsset: Asset | null,
  deleteAccountStatus: null | DeleteAccountRequestStatus;
  currentAssetDevices: Array<EnrichedDevice> | null;
}

const mapStateToProps = (state: AppState): StateType => ({
  userInfo: state.user.userInfo,
  currentAsset: state.assets.currentAsset,
  deleteAccountStatus: state.cde.deleteAccountStatus,
  currentAssetDevices: state.device.currentAssetDevices,
});

export default connect(
  mapStateToProps,
  null,
)(GreetingDefault as any);
