import React, {FunctionComponent, ReactElement} from 'react';
import './Separator.scss'

type Props = {
  className?: string;
}

const Separator: FunctionComponent<Props> = ({className = ''}): ReactElement => {
  return (
    <div className='separator'>
      <span className='separator__text'>or</span>
    </div>
  )
};

export default Separator;
