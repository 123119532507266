import React, {FunctionComponent} from 'react';
import {ModalContentType} from '../ModalTypes';
import ModalPopupWrapper from '../ModalWrapper';
import SubscriptionConductor from '../Subscription';
import ProfileConductor from '../Profile';
import {useSelector} from 'react-redux';
import {AppState} from '../../../store/reducers/reducerType';
import {Devices} from '../Devices';

const ModalConductor: FunctionComponent = () => {
  const modalPopup = useSelector((state: AppState) => state.popups.modalPopup);

  const determinePopupType = () => {
    switch (modalPopup?.contentType) {
      case ModalContentType.subscription:
        return <SubscriptionConductor/>;
      case ModalContentType.profile:
        return <ProfileConductor/>;
      case ModalContentType.devices:
        // @ts-ignore
        return <Devices/>;
      default:
        return null;
    }
  }

  return (
    <ModalPopupWrapper>
      {determinePopupType()}
    </ModalPopupWrapper>
  );
};

export default ModalConductor;
