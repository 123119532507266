import React, {FunctionComponent, useEffect, Suspense} from 'react';
import {
  ActiveStatusFromApi,
  AssetsStatusesMap,
  FinishedStatusFromApi,
  DeactivatedStatusFromApi,
} from '../../../../constants';
import Loader from '../../../../components/Loader';
import {useSubscriptionSectionContext} from '../Subscription';
import {useSelector} from 'react-redux';
import {AppState} from '../../../../store/reducers/reducerType';

const GiftedSubscription = React.lazy(() => import('./Types/GiftedSubscription'));
const ActiveSubscription = React.lazy(() => import('./Types/ActiveSubscription'));
const DeactivatedSubscription = React.lazy(() => import('./Types/DeactivatedSubscription'));
const FinishedSubscription = React.lazy(() => import('./Types/FinishedSubscription'));
const NoCCFinished = React.lazy(() => import('./Types/NoCCTrial/NoCCFinished'));
const WithoutSubscription = React.lazy(() => import('./Types/WithoutSubscription'));

const DefaultSubscription: FunctionComponent = () => {
  const currentAsset = useSelector((state: AppState) => state.assets.currentAsset);
  const {isSubscriptionGifted} = useSubscriptionSectionContext();

  if (!isSubscriptionGifted || !currentAsset) {
    return null;
  }

  // Handle change current asset status
  useEffect(() => {
    determineSubscriptionType();
  }, [currentAsset]);


  const determineSubscriptionType = () => {
    // Without subscription
    if (!Object.keys(currentAsset).length) {
      return <WithoutSubscription/>;
    }

    // Handle finished no cc trial subscription
    if (currentAsset.is_trial && currentAsset.status === FinishedStatusFromApi) {
      return <NoCCFinished/>;
    }

    // Handle gifted subscriptions
    if (isSubscriptionGifted()) {
      return <GiftedSubscription/>
    }

    // Handle Deactivated subscription (old name - AutoRenewal Disabled)
    if (currentAsset.status === DeactivatedStatusFromApi) {
      return <DeactivatedSubscription/>
    }

    // Handle Active subscription
    if (AssetsStatusesMap[currentAsset.status] === ActiveStatusFromApi) {
      return <ActiveSubscription/>
    }

    // handle inactive subscription
    return <FinishedSubscription/>
  }

  return (
    <Suspense fallback={
      <section className='section slide__section'>
        <Loader/>
      </section>
    }>
      {determineSubscriptionType()}
    </Suspense>
  );
}

export default DefaultSubscription;
