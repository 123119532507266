import React, {FunctionComponent, ReactNode} from 'react';
import './GreetingData.scss';
import {AppState} from '../../store/reducers/reducerType';
import GreetingDefault from './Types/GreetingDefault';
import {useSelector} from 'react-redux';

type TitleProps = {
  appeal?: string,
  callToAction?: string
}

type SubtitleProps = {
  children: ReactNode
}

// data-hj-suppress - data attribute for hotjar - to hide user sensitive data
export const GreetingTitle: FunctionComponent<TitleProps> = ({appeal, callToAction}) => {
  const userInfo = useSelector((state: AppState) => state.user.userInfo);

  return (
    <h3 className='membership__title slide'>
      {appeal && appeal}
      <span data-hj-suppress>{` ${userInfo.name}`},</span>
      {callToAction && <p>{callToAction}</p>}
    </h3>
  )
}

export const GreetingSubtitle: FunctionComponent<SubtitleProps> = ({children}) => {
  return (
    <div className='subtitle--form membership__subtitle slide'>
      {children}
    </div>
  )
}

const GreetingData: FunctionComponent = () => {
  return (
    <div className='greeting'>
      <GreetingDefault/>
    </div>
  )
};

export default GreetingData;
