import React, {FunctionComponent} from 'react';
import Logo from '../../../../components/Logo';
import {WebCbtFlowNames} from '../../../../constants';

type Props = {
  webcbt: string,
}

const OnlyLogo: FunctionComponent<Props> = ({webcbt}) => {
  return (
    <header className={`header header--logo-${WebCbtFlowNames[webcbt]}`}>
      <div className='header__container container'>
        <div className='header__logo-block'>
          <Logo/>
        </div>
      </div>
    </header>
  )
};

export default OnlyLogo;
