export const CdeConstants = {
  DELETE_ACCOUNT_REQUEST: 'DELETE_ACCOUNT_REQUEST',
  DELETE_ACCOUNT_SUCCESS: 'DELETE_ACCOUNT_SUCCESS',
  DELETE_ACCOUNT_FAILURE: 'DELETE_ACCOUNT_FAILURE',

  CANCEL_DELETE_ACCOUNT_REQUEST: 'CANCEL_DELETE_ACCOUNT_REQUEST',
  CANCEL_DELETE_ACCOUNT_SUCCESS: 'CANCEL_DELETE_ACCOUNT_SUCCESS',
  CANCEL_DELETE_ACCOUNT_FAILURE: 'CANCEL_DELETE_ACCOUNT_FAILURE',

  GET_DELETE_ACCOUNT_STATUS_REQUEST: 'GET_DELETE_ACCOUNT_STATUS_REQUEST',
  GET_DELETE_ACCOUNT_STATUS_SUCCESS: 'GET_DELETE_ACCOUNT_STATUS_SUCCESS',
  GET_DELETE_ACCOUNT_STATUS_FAILURE: 'GET_DELETE_ACCOUNT_STATUS_FAILURE',
}
