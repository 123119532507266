import {connect} from 'react-redux';
import DisconnectConfirm from './DisconnectConfirm';
import {
  disconnectDevice, setDeviceItemScreenType, getLicenseIdByDevice,
  setUpdateDataFromApi, reduceConnectedDevicesQuantity
} from '../../../../../store/actions';
import {History} from 'history';
import {AppState} from '../../../../../store/reducers/reducerType';
import {DeviceItemScreenType} from '../../../../../constants';
import {DevicesStatus} from '../../../../../services/device/deviceTypes';
import {DisconnectDeviceStatusType} from '../../../../../store/actions/device/deviceActionsTypes';

type StateType = {
  disconnectDeviceStatus: DisconnectDeviceStatusType | null,
  devicesStatus: DevicesStatus | null,
}

const mapStateToProps = (state: AppState): StateType => ({
  disconnectDeviceStatus: state.device.disconnectDeviceStatus,
  devicesStatus: state.device.devicesStatus,
});

const mapDispatchToProps = (dispatch: any) => ({
  setDeviceItemScreenType: (deviceId: string, screenType: DeviceItemScreenType): void => {
    dispatch(setDeviceItemScreenType(deviceId, screenType));
  },
  disconnectDevice: (licenseID: string, deviceId: string, history: History): void => {
    dispatch(disconnectDevice(licenseID, deviceId, history));
  },
  setUpdateDataFromApi: (value: boolean): void => {
    dispatch(setUpdateDataFromApi(value));
  },
  reduceConnectedDevicesQuantity: (devicesStatus: DevicesStatus) => {
    dispatch(reduceConnectedDevicesQuantity(devicesStatus));
  },
  getLicenseIdByDevice,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)((DisconnectConfirm as any));
