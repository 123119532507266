import {UserConstants} from '../../../constants';
import {UserStateType} from './types';
import {DefaultUserState} from './constants';
import {AutoLoginParams} from '../../actions/user/getAutoLoginParams';
import {RequestsStatus} from '../../actions/actionsTypes';

export default (state = DefaultUserState, action: any): UserStateType => {
  switch (action.type) {
    case UserConstants.REGISTER_REQUEST:
      return {
        ...state,
        registrationStatus: {
          status: false,
          error: '', // flash error before request
        }
      };
    case UserConstants.LOGIN_REQUEST:
      return {
        ...state,
        loginInfo: null,
        loginStatus: {
          status: false,
          error: '',
        }
      };
    case UserConstants.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        changePasswordStatus: {
          status: false,
          error: '',
        }
      };
    case UserConstants.FORGOT_REQUEST:
      return {
        ...state,
        forgotPasswordStatus: {
          status: action.payload.resend || false,
          error: '',
        }
      };
    case UserConstants.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        resetPasswordStatus: {
          status: false,
          error: '',
        },
        verificationCode: '',
      };
    case UserConstants.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        resetPasswordStatus: {
          status: true,
          error: '',
        }
      };
    case UserConstants.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetPasswordStatus: {
          status: false,
          error: action.payload.message,
        },
      };
    case UserConstants.REGISTER_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        registrationStatus: {
          status: true,
          error: '',
        },
        registrationInfo: {
          email: action.payload.email,
          password: action.payload.password,
          name: action.payload.name,
        }
      };
    case UserConstants.LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        loginStatus: {
          status: true,
          error: '',
        },
        loginInfo: {
          email: action.payload.email,
          device_id: action.payload.device_id,
        }
      };
    case UserConstants.FORGOT_SUCCESS:
      return {
        ...state,
        forgotPasswordStatus: {
          status: true,
          error: '',
        },
        forgotPasswordInfo: action.payload,
      };
    case UserConstants.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordStatus: {
          status: true,
          error: '',
        }
      };
    case UserConstants.REGISTER_FAILURE:
      return {
        ...state,
        registrationStatus: {
          status: false,
          error: action.payload.message,
        },
      };
    case UserConstants.LOGIN_FAILURE:
      return {
        ...state,
        loginStatus: {
          status: false,
          error: action.payload.message,
        }
      };
    case UserConstants.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        changePasswordStatus: {
          status: false,
          error: action.payload.message,
        }
      };
    case UserConstants.FORGOT_FAILURE:
      return {
        ...state,
        forgotPasswordStatus: {
          status: false,
          error: action.payload.message,
        }
      };
    case UserConstants.GET_USER_INFO_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        userInfoStatus: {
          status: true,
          error: '',
        },
        userInfo: {
          name: action.payload.name,
          id: action.payload.id,
          email: action.payload.email,
          is_email_verified: action.payload.is_email_verified || false,
        },
        consents: {...state.consents, ...action.payload.consents}
      };
    case UserConstants.GET_USER_INFO_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        userInfoStatus: {
          status: false,
          error: action.payload.message,
        },
      };
    case UserConstants.CHECK_VERIFICATION_CODE_REQUEST:
      return {
        ...state,
        checkCodeStatus: {
          status: false,
          error: '',
        },
      };
    case UserConstants.CHECK_VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        checkCodeStatus: {
          status: true,
          error: '',
        },
        verificationCode: action.payload.access_token,
      };
    case UserConstants.CHECK_VERIFICATION_CODE_FAILURE:
      return {
        ...state,
        checkCodeStatus: {
          status: false,
          error: action.payload.message,
        },
      };
    case UserConstants.LOGOUT_REQUEST:
      return {
        ...state,
        logoutStatus: {
          status: false,
          error: '',
        },
      };
    case UserConstants.LOGOUT_SUCCESS:
      return {
        ...DefaultUserState,
        logoutStatus: {
          status: true,
          error: '',
        },
        isAuthenticated: false,
      };
    case UserConstants.LOGOUT_FAILURE:
      return {
        ...state,
        logoutStatus: {
          status: false,
          error: action.payload.message,
        },
      };
    case UserConstants.SET_LOGIN_EMAIL:
      return {
        ...state,
        loginInfo: {
          ...state.loginInfo,
          email: action.payload.email,
        },
      };
    case UserConstants.GENERATE_DOWNLOAD_LINKS_REQUEST:
      return {
        ...state,
        downloadLinks: null,
        generateDownloadLinksStatus: {
          status: false,
          error: '',
        },
      };
    case UserConstants.GENERATE_DOWNLOAD_LINKS_SUCCESS:
      return {
        ...state,
        downloadLinks: action.payload,
        generateDownloadLinksStatus: {
          status: true,
          error: '',
        },
      };
    case UserConstants.GENERATE_DOWNLOAD_LINKS_FAILURE:
      return {
        ...state,
        downloadLinks: null,
        generateDownloadLinksStatus: {
          status: false,
          error: action.payload.message,
        },
      };
    case UserConstants.CHANGE_CONSENTS_REQUEST:
      return {
        ...state,
        changeConsentsStatus: {
          status: false,
          error: '',
        },
      };
    case UserConstants.CHANGE_CONSENTS_SUCCESS:
      return {
        ...state,
        changeConsentsStatus: {
          status: true,
          error: '',
        },
        consents: {...state.consents, ...action.payload}
      };
    case UserConstants.CHANGE_CONSENTS_FAILURE:
      return {
        ...state,
        changeConsentsStatus: {
          status: false,
          error: action.payload.message,
        },
      };
    case UserConstants.CHANGE_USER_NAME_REQUEST:
      return {
        ...state,
        changeUserNameStatus: {
          status: false,
          error: '',
        },
      };
    case UserConstants.CHANGE_USER_NAME_SUCCESS:
      return {
        ...state,
        changeUserNameStatus: {
          status: true,
          error: '',
        },
      };
    case UserConstants.CHANGE_USER_NAME_FAILURE:
      return {
        ...state,
        changeUserNameStatus: {
          status: false,
          error: action.payload.message,
        },
      };
    case AutoLoginParams.GET_AUTO_LOGIN_PARAMS_REQUEST:
      return {
        ...state,
        autoLoginParams: {
          status: RequestsStatus.Loading,
          data: null,
          error: null,
        },
      };
    case AutoLoginParams.GET_AUTO_LOGIN_PARAMS_SUCCESS:
      return {
        ...state,
        autoLoginParams: {
          status: RequestsStatus.Success,
          data: action.payload,
          error: null,
        },
      };
    case AutoLoginParams.GET_AUTO_LOGIN_PARAMS_FAILURE:
      return {
        ...state,
        autoLoginParams: {
          status: RequestsStatus.Failed,
          data: null,
          error: action.payload,
        },
      };
    default:
      return state
  }
}
