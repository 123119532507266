import React, {FunctionComponent, ReactElement, useEffect, useState} from 'react';
import './AppleSignIn.scss'
import {AppleAuthRedirectLinkResponse} from '../../services/sso/ssoTypes';
import {RequestStatus} from '../../store/reducers/reducerType';
import {Events} from '../../services/events/events';
import {EventContext, SSOEvents} from '../../constants/events';

type Props = {
  appleAuthRedirectLinkStatus: RequestStatus,
  getAppleAuthRedirectLink: () => void;
  appleAuthRedirectLink: AppleAuthRedirectLinkResponse | null;
}

const AppleSignIn: FunctionComponent<Props> = ({getAppleAuthRedirectLink, appleAuthRedirectLink, appleAuthRedirectLinkStatus}): ReactElement => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    // Reset isSubmitted state after get response from api - to enable button submit
    if (appleAuthRedirectLinkStatus.status || appleAuthRedirectLinkStatus.error.length) {
      setIsSubmitted(false);
    }

  }, [appleAuthRedirectLinkStatus]);

  useEffect(() => {
    if (!appleAuthRedirectLink) {
      return;
    }

    window.location.href = appleAuthRedirectLink.redirect_url;
  }, [appleAuthRedirectLink]);

  const handleSignIn = () => {
    if (isSubmitted) {
      return;
    }

    setIsSubmitted(true);
    Events.send({
      context: EventContext.login,
      event: SSOEvents.appleLogin,
    });
    getAppleAuthRedirectLink();
  }

  return (
    <button
      className='button apple-signin__button'
      onClick={handleSignIn}
      type='button'
      disabled={isSubmitted}
      tabIndex={2}>
      <img src='./images/apple-logo.svg' className='apple-signin__logo' alt='apple logo'/>
      Continue with Apple
    </button>
  )
};

export default AppleSignIn;
