class JWTParser {
  get token(): string | null {
    return localStorage.getItem('jwt');
  }

  set token(token: string | null) {
    if (!token) {
      return;
    }
    localStorage.setItem('jwt', token);
  }

  get claims() {
    if (!this.token) {
      return null;
    }
    return this.parseJwt(this.token);
  }
  
  parseJwt(token: string) {
    if (!token) {
      return null;
    }
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }

  isExpired() {
    let now = (new Date()).getTime();
    let tokenExp = this.claims.exp * 1000;

    return now >= tokenExp;
  }
}

export const JWT = new JWTParser();
