export default function sendCustomEvent(eventName: string, data: any): void {
  const event = new CustomEvent(eventName, {
    detail: data,
  });
  document.dispatchEvent(event);
}

export function sendChangeSizeEvent(height: number, scroll = true): void {
  const event = new CustomEvent('changeSize', {
    detail: {
      height: height,
      scroll: scroll
    }
  });
  document.dispatchEvent(event);
}
