import React from 'react';
import '../Icons.scss';
import './ErrorIcons.scss';

const ExclamationIcon = require('../../../../resources/images/icon-exclamation.svg').default;

const ErrorIcons: React.FunctionComponent = () => {
  return (
    <div className='icon__wrapper icon__wrapper--error'>
      <div className='icon icon--error'>
        <ExclamationIcon/>
      </div>
    </div>
  );
};

export default ErrorIcons;
