import {History} from 'history';
import fetch from 'node-fetch';
import {EmptyAssetId, NexusAssetsApi, ServicesErrors} from '../../constants';
import {handleCommonErrorResponse, handleResponse, determineErrorLevelForSentry} from '../../utils';
import * as Sentry from '@sentry/browser';
import {ErrorType} from '../../store/actions/actionsTypes';
import {Severity} from '@sentry/types/dist/severity';

export async function cancelSubscription(assetId: string, history: History): Promise<string> {
  if (!assetId || !assetId.length) {
    return Promise.reject(EmptyAssetId);
  }

  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  };

  return fetch(`${NexusAssetsApi}/${assetId}/auto-renewal`, requestOptions)
    .then(handleResponse)
    .catch(error => {
      const errorLevel = determineErrorLevel(error);

      Sentry.configureScope((scope) => {
        scope.setLevel(errorLevel);
      })

      Sentry.addBreadcrumb({
        category: 'assets',
        message: 'Endpoint ' + `${NexusAssetsApi}/${assetId}/auto-renewal`,
        data: {
          error: error,
          assetId: assetId,
        },
        level: errorLevel,
      });

      Sentry.captureMessage(ServicesErrors.assets.cancelSubscriptionFailed, errorLevel);
      return handleCommonErrorResponse(error, history)
    });
}

function determineErrorLevel(error: ErrorType): Severity {
  switch (error.status) {
    case 404:
    case 409:
      return Severity.Error;
    default:
      return determineErrorLevelForSentry(error);
  }
}
