import React, {FunctionComponent, useRef, useState} from 'react';
import SubMenuList from './SubMenuList';
import {isAdaptiveMarkup} from '../../../../utils';
import {useOnClickOutside} from '../../../../hooks';
import {ListItemType} from '../../HeaderNavList/HeaderNavList';

const WithSubMenu: FunctionComponent<ListItemType> = ({name, text, subMenu = []}) => {
  const [subMenuState, toggleSubMenu] = useState(false);
  const subMenuRef = useRef<HTMLLIElement>(null);

  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(subMenuRef, () => toggleSubMenu(false));

  // onMouseEnter and onMouseLeave - Hover events should work only on desktop UI.
  // For adaptive markup - need to handle click
  const onMouseEnter = () => {
    if (isAdaptiveMarkup()) {
      return;
    }
    toggleSubMenu(true);
  }

  const onMouseLeave = () => {
    if (isAdaptiveMarkup()) {
      return;
    }

    toggleSubMenu(false);
  }

  const handleToggleMenu = () => {
    toggleSubMenu(!subMenuState);
  }

  const handleClick = () => {
    if (!isAdaptiveMarkup()) {
      return;
    }

    toggleSubMenu(!subMenuState);
  }

  return (
    <li ref={subMenuRef} className={`header__menu-item sub-menu ${subMenuState ? 'sub-menu__open' : ''}`}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={handleClick}
    >
      <p data-name={name} className='header__menu-link sub-menu__title'>
        {text}
        <span className={`header__menu-arrow sub-menu__arrow ${subMenuState ? 'sub-menu__arrow--up' : ''}`} onClick={handleToggleMenu}
        />
      </p>
      <SubMenuList subMenuListItems={subMenu} subMenuState={subMenuState}/>
    </li>
  );
}

export default WithSubMenu;
