export type AuthHeader = {[key: string]: string} | null;

export default function authHeader(authToken?: string): AuthHeader {
  if (authToken) {
    return {'Authorization': 'Bearer ' + authToken};
  } else {
    return null;
  }
}

