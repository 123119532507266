export {};
declare global {
  interface Window {
    hj: any;
  }
}

declare var hj: any;

export default function hotjarTriggerPoll(): void {
  window.hj = window.hj || function () {
    (hj.q = hj.q || []).push(arguments);
  };
}

