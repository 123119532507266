import React, {FunctionComponent, useEffect, useState} from 'react';
import Input from '../../../components/Input';
import Button from '../../../components/Buttons/Button';
import {ForgotPasswordUserBody} from '../../../services/user/userTypes';
import {
  emailRegexp,
  ErrorItem,
  errorRules, generateErrorMessageMaxLength,
  inputPlaceholders,
  maxEmailValueLength
} from '../../../utils/formValidationRules';
import {getDeviceId} from '../../../utils';
import {useForm} from 'react-hook-form';
import {RequestStatus} from '../../../store/reducers/reducerType'
import {useHistory, Link} from 'react-router-dom';
import {Routes} from '../../../router/Routes';
import {forgotApiErrors} from './ForgotApiErrors';
import {ApiErrors} from '../../../constants';
import {History} from 'history';
import '../Form.scss';
import {EventContext, ForgotPasswordEvents, ForgotPasswordFormEventsName} from '../../../constants/events';
import {Events} from '../../../services/events/events';
import withServerErrorHandlingPage from '../../../hoc/ServerErrorHandlingPage';

type Props = {
  forgot: (userData: ForgotPasswordUserBody, history: History) => void;
  forgotPasswordStatus: RequestStatus;
  checkCodeStatus: RequestStatus,
  setLoginEmail: (email: string) => void;
  setErrorMessage: (error: string) => void;
};

type ForgotPasswordSubmitData = Record<string, any> | {
  email: string,
}

const Forgot: FunctionComponent<Props> = (props: Props) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const history = useHistory();
  const {register, handleSubmit, errors, formState: {touched, isValid}, setError, getValues} = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur'
  });

  useEffect(() => {
    const {checkCodeStatus} = props;

    // Set error for email after redirect with error from validation of verification code
    if (checkCodeStatus.error === ApiErrors.invalid_verification_code) {
      sendForgotPasswordFormEvents(ForgotPasswordEvents.resetLinkScreen.linkExpired);
      setApiError('email', forgotApiErrors[checkCodeStatus.error]);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      sendForgotPasswordFormEvents(ForgotPasswordEvents.resetError);
    }
  }, [errors])


  const handleForgot = async (data: ForgotPasswordSubmitData): Promise<void> => {
    const {email} = data;
    const {forgot} = props;

    if (isSubmitted) {
      return;
    }

    setIsSubmitted(true);

    forgot({email, device_id: await getDeviceId()}, history)
  };

  const setApiError = (inputName: string, message: string): void => {
    setError([
      {
        type: 'custom',
        name: inputName,
        message: message,
      },
    ]);
  };

  useEffect(() => {
    // Reset isSubmitted state after get response from api - to enable button submit
    if (props.forgotPasswordStatus.status || props.forgotPasswordStatus.error.length) {
      setIsSubmitted(false);
    }

    determineFormErrors();
  }, [props.forgotPasswordStatus]);

  const determineFormErrors = () => {
    const {forgotPasswordStatus} = props;
    const apiError = forgotPasswordStatus.error;

    if (!apiError.length) {
      return errors;
    }

    switch (apiError) {
      case ApiErrors.invalid_email:
        setApiError('email', forgotApiErrors[apiError]);
        break;
      case ApiErrors.request_is_already_processing:
        props.setErrorMessage(forgotApiErrors[apiError]);
        break;
      default:
        props.setErrorMessage(forgotApiErrors[apiError]);
        return errors;
    }
  };

  const handleRedirectToLogin = () => {
    const emailValue = getValues().email;
    sendForgotPasswordFormEvents(ForgotPasswordEvents.login);
    if (errors.email == null && emailValue.length) {
      props.setLoginEmail(emailValue);
    }
  };

  const sendForgotPasswordFormEvents = (eventsName: ForgotPasswordFormEventsName) => {
    Events.send({
      context: EventContext.forgotPassword,
      event: eventsName,
    });
  }

  const handleGetResetLinkButtonClick = () => {
    sendForgotPasswordFormEvents(ForgotPasswordEvents.resetLink);
    handleSubmit(handleForgot)
  }

  return (
    <form className='form form--forgot' onSubmit={handleSubmit(handleForgot)}>
      <Input name='email'
             placeholder={inputPlaceholders.forgot.email}
             type='text'
             errors={errors != null ? (errors.email as ErrorItem) : null}
             succeeded={(errors.email == null && touched.email) as boolean}
             ref={register({
               required: errorRules.email.required,
               pattern: {
                 value: emailRegexp,
                 message: errorRules.email.validationFailed
               },
               maxLength: {
                 value: maxEmailValueLength,
                 message: generateErrorMessageMaxLength('email')
               },
             })}
      />
      <div className='button-wrapper button-wrapper--forgot'>
        <Button
          type='submit'
          disabled={isSubmitted}
          className='button--primary button--green'
          action={handleGetResetLinkButtonClick}
          title='Get reset link'/>
      </div>
      <div className='form__link-wrapper'>
        <Link className='form__link' tabIndex={2} to={Routes.Login} onClick={handleRedirectToLogin}>Back to Login</Link>
      </div>
    </form>
  );
};

export default Forgot;
