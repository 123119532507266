export const ResetPasswordApiErrors: Record<string, string> = {
  invalid_password: 'This password doesn\'t look valid.',
  password_is_less_6_symbols: 'Has to be at least 6 characters.',
  password_does_not_contain_a_letter: 'Your password needs a letter.',
  password_does_not_contain_a_number: 'Add a digit to make your password more secure.',
  invalid_request_body: 'invalid_request_body',
  undefined_error: 'undefined_error',
  password_contains_space: 'This password doesn\'t look valid.',
  invalid_token: 'invalid_token',
};
