import * as React from 'react';
import {useCallback, useState} from 'react';
import './DesktopDownloadBlock.scss';
import {Redirect} from 'react-router-dom';
import {Routes} from '../../../router/Routes';
import {DesktopPlatforms} from './DesktopPlatforms';
import {DownloadEvents, EventContext} from '../../../constants';
import {Events} from '../../../services/events/events';

interface DesktopDownloadBlockType {
    downloadLink: string,
    className?: string,
}

const DesktopDownloadBlock = ({className = '', downloadLink}: DesktopDownloadBlockType) => {
    const [needToRedirect, setNeedToRedirect] = useState(false);


    const handleDownload = useCallback(() => {
        Events.send({
            context: EventContext.downloadLink,
            event: DownloadEvents.desktop_download_button_click,
            data: {
                downloadLink,
            }
        });
        setNeedToRedirect(true);
    }, [needToRedirect]);

    if (!downloadLink) {
        return null;
    }

    if (needToRedirect) {
        // @ts-ignore
        return <Redirect
            to={{
                pathname: Routes.DownloadInstruction,
                state: {
                    downloadLinks: {
                        redirect: downloadLink
                    }
                }
            }} />
    }

    return (
        <div className={`device-download ${className}`}>
            <h3 className='device-download__device-type'>Desktop</h3>
            <img src='./images/image-desktop.png' alt='device image'
                 className='device-download__image device-download__image--desktop' />
            <div className='device-download__content'>
                <h4 className='device-download__title'>Get the Clario for your Computer</h4>
                <p className='device-download__text device-download__text--subtitle'>
                    Click on Download and launch the app to be protected.
                </p>
                <div className='button-wrapper device-download__button-wrapper'>
                    <button
                        className='button button--primary button--green device-download__button'
                        onClick={handleDownload}>
                        Download
                        <img src='./images/icon-downloads.svg' alt='Download icon'
                             className='device-download__button-icon' />
                    </button>
                </div>
                <DesktopPlatforms />
            </div>
        </div>
    );
};

export default DesktopDownloadBlock;
