import {connect} from 'react-redux';
import DeviceInfo from './DeviceInfo';
import {AppState} from '../../../../../store/reducers/reducerType';
import {DevicesStatus} from '../../../../../services/device/deviceTypes';
import {setDeviceItemScreenType} from '../../../../../store/actions';
import {DeviceItemScreenType} from '../../../../../constants';

type StateType = {
  devicesStatus: DevicesStatus | null,
}

const mapStateToProps = (state: AppState): StateType => ({
  devicesStatus: state.device.devicesStatus,
});

const mapDispatchToProps = (dispatch: any) => ({
  setDeviceItemScreenType: (deviceId: string, screenType: DeviceItemScreenType): void => {
    dispatch(setDeviceItemScreenType(deviceId, screenType));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeviceInfo);
