import {InnerContentPopups} from '../../constants';

export enum ModalContentType {
  subscription = 'subscription',
  profile = 'profile',
  devices = 'devices',
}

export type ModalPopup = {contentType: ModalContentType, innerContentPopup: InnerContentPopups};

export type ModalPopupSize = {height: number};
