import React, {FunctionComponent} from 'react';
import {WebToCaseApi, WebToCaseSurveyData, WebToCaseSurveyType, AccountLinks, AppEnv} from '../../constants';
import {ApiMethod} from '../../../cypress/support/constants';

declare var APP_ENV: AppEnv;

type Props = {
  surveyType: WebToCaseSurveyType
}

const SalesForceForm: FunctionComponent<Props> = (props) => {
  let {surveyType} = props;
  let surveyConfig: any = WebToCaseSurveyData(surveyType);

  if (!surveyConfig) {
    return null;
  }

  return (
    <form action={WebToCaseApi} method={ApiMethod.POST}>
      <input type='hidden' name='orgid' value={surveyConfig.orgid}/>
      <input type='hidden' name='retURL' value={AccountLinks[APP_ENV]}/>
      <input type='hidden' name='recordType' value={surveyConfig.recordType}/>
      <input type='hidden' name='type' value={surveyConfig.type}/>
      <input type='hidden' name={surveyConfig.formId} value={surveyConfig.type}/>
      <textarea id={surveyConfig.fieldId} name={surveyConfig.fieldId} wrap='soft'/>
      <input type='submit' name='submit' id={`${surveyConfig.name}_submit`}/>
    </form>
  );
};

export default SalesForceForm;
