import {AnalyticsApi, UTMKeys} from '../../constants';
import send from './sendToEventSystem';
import {Event, EventsInterface, EventsMessage, PayloadData} from './eventsTypes';
import {getAffid, objectOmitNull, timeConverter} from '../../utils';
import Cookies from '../../utils/cookies';
import Url from '../../utils/url';
import watch from 'redux-watch'
import store from '../../store';
import {CustomObjectType} from '../../common/types';

export class EventsService implements EventsInterface {
  eventsQueue: Array<Event>;

  sendingMessage: boolean;
  sendEventsConsent: boolean;
  isAuthenticated: boolean;

  constructor() {
    this.sendingMessage = false;
    this.isAuthenticated = store.getState().user.isAuthenticated || false;
    this.sendEventsConsent = store.getState().user.consents.app_statistics;
    const eventsMessageQueueStorage = localStorage.getItem('eventsMessageQueue');
    this.eventsQueue = eventsMessageQueueStorage ? JSON.parse(eventsMessageQueueStorage) : [];
    this.updateSendEventsConsent();
    this.updateIsAuthenticatedState();

    if (this.eventsQueue.length) {
      this.sendEventFromQueue();
    }
  }

  send(message: EventsMessage) {
    if (this.isAuthenticated && !this.sendEventsConsent) {
      return;
    }

    this._sendEventToService(message);
  }

  updateSendEventsConsent() {
    let sendEventsConsent = watch(store.getState, 'user.consents.app_statistics')
    store.subscribe(sendEventsConsent((newVal, oldVal, objectPath) => {
      this.sendEventsConsent = newVal;
    }))
  };

  updateIsAuthenticatedState() {
    let sendEventsConsent = watch(store.getState, 'user.isAuthenticated')
    store.subscribe(sendEventsConsent((newVal, oldVal, objectPath) => {
      this.isAuthenticated = newVal;
    }))
  }

  updateEventsQueue() {
    localStorage.setItem('eventsMessageQueue', JSON.stringify(this.eventsQueue));
  }

  async sendEventFromQueue() {
    if (this.sendingMessage) {
      return;
    }

    this.sendingMessage = true;
    await send(AnalyticsApi, this.eventsQueue.shift() as Event);
    this.updateEventsQueue();
    this.sendingMessage = false;
    if (this.eventsQueue.length > 0) {
      this.sendEventFromQueue();
    }
  }

  _formPayloadData(data?: CustomObjectType): PayloadData {
    const convertedTime = timeConverter(Date.now() / 1000);
    const {year, month, day, hour, minute, seconds} = convertedTime;

    let payloadData = {
      datetime: `${year}${month}${day}${hour}${minute}${seconds}`,
      source_type: Url.getQueryParamByName(window.location.href, 'source_type') || Cookies.getCookie('source_type'),
      utm_campaign: Url.getQueryParamByName(window.location.href, UTMKeys.campaign) || Cookies.getCookie(UTMKeys.campaign) || localStorage.getItem(UTMKeys.campaign),
      utm_medium: Url.getQueryParamByName(window.location.href, UTMKeys.medium) || Cookies.getCookie(UTMKeys.medium) || localStorage.getItem(UTMKeys.medium),
      utm_source: Url.getQueryParamByName(window.location.href, UTMKeys.source) || Cookies.getCookie(UTMKeys.source) || localStorage.getItem(UTMKeys.source),
      ...data,
    };

    return (objectOmitNull(payloadData) as PayloadData);
  }

  async _sendEventToService(message: EventsMessage) {
    const payloadData: PayloadData = this._formPayloadData(message.data);

    const eventsBody: Event = objectOmitNull<Event>({
      context: message.context,
      event: message.event,
      affid: await getAffid(),
      prodid: '300',
      payload: payloadData,
    });

    this.eventsQueue.push(eventsBody);
    this.updateEventsQueue();

    this.sendEventFromQueue();
  }
}

export const Events = new EventsService();
