import {History} from 'history';
import {Dispatch} from 'redux';
import {CdeConstants} from '../../../constants';
import {DeleteAccountRequestReduxAction, DeleteAccountSuccessReduxAction, DeleteAccountFailureReduxAction} from './cdeTypes';
import {cdeService} from '../../../services/cde';

export function deleteAccount(password: string, history: History) {
  return (dispatch: Dispatch<DeleteAccountRequestReduxAction | DeleteAccountSuccessReduxAction | DeleteAccountFailureReduxAction>) => {
    dispatch(deleteAccountRequest());
    cdeService.deleteAccount(password, history)
      .then(() => {
        dispatch(success());
      })
      .catch((error: Error) => {
        dispatch(failure(error));
      });
  };

  function success(): DeleteAccountSuccessReduxAction {
    return {type: CdeConstants.DELETE_ACCOUNT_SUCCESS, payload: {}}
  }

  function failure(error: Error): DeleteAccountFailureReduxAction {
    return {type: CdeConstants.DELETE_ACCOUNT_FAILURE, payload: error}
  }
}

export function deleteAccountRequest(): DeleteAccountRequestReduxAction {
  return {type: CdeConstants.DELETE_ACCOUNT_REQUEST, payload: {}}
}
