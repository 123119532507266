import * as React from 'react';
import {ClarioSiteLinks, EventContext, FooterEvents, HeaderEvents, LogoNameAttribute} from '../../constants';
import Logo from '../Logo';
import {Events} from '../../services/events/events';

interface Props {
  className?: string,
  eventContext: EventContext.header | EventContext.footer,
  eventName: typeof HeaderEvents.logo | typeof FooterEvents.logo,
  logoClassName?: string,
}

const NavLogo: React.FunctionComponent<Props> = ({className = '', eventContext, eventName, logoClassName = ''}) => {
  const handleLogoClick = () => {
    Events.send({
      context: eventContext,
      event: eventName,
    });
  }

  return (<div className={`nav__logo-block ${className}`} onClick={handleLogoClick}>
    <a target='_blank'
       data-name={LogoNameAttribute}
       href={ClarioSiteLinks.mainPage}
       className='nav__logo'
       aria-label={LogoNameAttribute}
       rel='noopener'
    >
      <Logo className={logoClassName}/>
    </a>
  </div>)
};

export default NavLogo;
