import React, {FunctionComponent} from 'react';
import {ListItemType} from '../FooterNavList/FooterNavList';

const FooterNavItem: FunctionComponent<ListItemType> = ({name, link, ariaLabel, text}) => {
  return (
    <li className='footer__menu-item'>
      <a target='_blank'
         data-name={name}
         href={link}
         className='footer__menu-link'
         aria-label={ariaLabel}
         rel='noopener'>
        {text}
      </a>
    </li>
  );
}

export default React.memo(FooterNavItem);
