import React, {FunctionComponent} from 'react';
import Section from '../../../../../../containers/Section';
import {AssetsStatusesMap, DashboardEvents, IconType} from '../../../../../../constants';
import {useSubscriptionSectionContext} from '../../../Subscription';
import {SectionNames} from '../../../../../../containers/Section/Section';

const FinishedSubscription: FunctionComponent = () => {
  const {currentAsset, openAssetModal, sendManageSubscriptionEvent} = useSubscriptionSectionContext();

  if (!currentAsset || !openAssetModal || !sendManageSubscriptionEvent) {
    return null;
  }
  const handleManageSubscription = () => {
    sendManageSubscriptionEvent(DashboardEvents.subscription_area__manage_button_click_to_buynow);
    openAssetModal();
  }

  return (
    <Section
      name={SectionNames.subscription}
      title='Subscription'
      iconType={IconType.subscription}
      status={{name: AssetsStatusesMap[currentAsset.status], active: false}}
      description='Head here to manage your billing and subscription details.'
      sectionInfo={{text: 'Renew subscription to keep your digital life secure.'}}
      button={
        {
          title: 'Manage',
          action: handleManageSubscription,
        }
      }
    />
  );
}

export default FinishedSubscription;
