import {Dispatch} from 'redux';
import {
  ChangeClassNameReduxAction,
  ChangeChatInitializedStateReduxAction,
  SetUpdateDataFromApi,
  SetThankYouPageContentReduxAction,
  ThankYouPageContentType,
  SetZchatLibLoadedReduxAction,
  ScrollTopPage,
} from './pageActionsTypes';
import {PageActions} from '../../../constants';

export function changeClassName(className: string) {
  return (dispatch: Dispatch<ChangeClassNameReduxAction>) => {
    dispatch({type: PageActions.changeClassName, payload: {className}});
  };
}

export function changeChatInitializedState() {
  return (dispatch: Dispatch<ChangeChatInitializedStateReduxAction>) => {
    dispatch({type: PageActions.chatInitialized, payload: {}});
  };
}

export function setUpdateDataFromApi(value: boolean) {
  return (dispatch: Dispatch<SetUpdateDataFromApi>) => {
    dispatch({type: PageActions.updateDataFromApi, payload: {value}});
  };
}

export function setThankYouPageContent(content: ThankYouPageContentType) {
  return (dispatch: Dispatch<SetThankYouPageContentReduxAction>) => {
    dispatch({type: PageActions.SET_THANK_YOU_PAGE_CONTENT, payload: {content: content}});
  };
}

export function setZchatLibLoaded(loaded: boolean) {
  return (dispatch: Dispatch<SetZchatLibLoadedReduxAction>) => {
    dispatch({type: PageActions.SET_ZCHAT_LIB_LOADED, payload: {loaded}});
  };
}

export function scrollTopPage() {
  return (dispatch: Dispatch<ScrollTopPage>) => {
    dispatch({type: PageActions.SCROLL_TOP_PAGE, payload: {}});
  };
}
