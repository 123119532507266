import {History} from 'history';
import {Dispatch} from 'redux';
import {CancelDeleteAccountFailureReduxAction, CancelDeleteAccountRequestReduxAction, CancelDeleteAccountSuccessReduxAction} from './cdeTypes';
import {cdeService} from '../../../services/cde';
import {CdeConstants} from '../../../constants';

export function cancelDeleteAccount(password: string, history: History) {
  return (dispatch: Dispatch<CancelDeleteAccountRequestReduxAction | CancelDeleteAccountSuccessReduxAction | CancelDeleteAccountFailureReduxAction>) => {
    dispatch(cancelDeleteAccountRequest());
    cdeService.cancelDeleteAccount(password, history)
      .then(() => {
        dispatch(success());
      })
      .catch((error: Error) => {
        dispatch(failure(error));
      });
  };

  function success(): CancelDeleteAccountSuccessReduxAction {
    return {type: CdeConstants.CANCEL_DELETE_ACCOUNT_SUCCESS, payload: {}}
  }

  function failure(error: Error): CancelDeleteAccountFailureReduxAction {
    return {type: CdeConstants.CANCEL_DELETE_ACCOUNT_FAILURE, payload: error}
  }
}

export function cancelDeleteAccountRequest(): CancelDeleteAccountRequestReduxAction {
  return {type: CdeConstants.CANCEL_DELETE_ACCOUNT_REQUEST, payload: {}}
}
