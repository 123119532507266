import {ConsentsType} from '../../../services/user/userTypes';
import {Dispatch} from 'redux';
import {ChangeConsentsRequestReduxAction, ChangeConsentsSuccessReduxAction, ChangeConsentsFailureReduxAction} from './userActionsTypes';
import {userService} from '../../../services/user/user';
import {UserConstants} from '../../../constants';
import {History} from 'history';

export function changeConsents(consents: ConsentsType, history: History) {
  return (dispatch: Dispatch<ChangeConsentsRequestReduxAction | ChangeConsentsSuccessReduxAction | ChangeConsentsFailureReduxAction>) => {
    dispatch(request());

    userService.changeConsents(consents, history)
      .then(
        () => {
          dispatch(success(consents));
        },
        error => {
          dispatch(failure(error));
        }
      );

    function request(): ChangeConsentsRequestReduxAction {
      return {type: UserConstants.CHANGE_CONSENTS_REQUEST, payload: {}}
    }

    function success(consents: ConsentsType): ChangeConsentsSuccessReduxAction {
      return {type: UserConstants.CHANGE_CONSENTS_SUCCESS, payload: consents}
    }

    function failure(error: Error): ChangeConsentsFailureReduxAction {
      return {type: UserConstants.CHANGE_CONSENTS_FAILURE, payload: error}
    }
  }
}
