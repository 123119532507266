import * as React from 'react';
import './QRCode.scss';
import QRCode from 'qrcode.react';
import {useEffect} from 'react';
import {firebaseService} from '../../services/firebase/firebase';
import {ShortUrlDomain} from '../../constants';
import {ShortenUrlResponse} from '../../services/firebase/firebaseTypes';
import Loader from '../Loader';

type Props = {
  link: string,
  className?: string,
  size?: number,
}

const DownloadQR = require('../../../resources/images/qr-download.svg').default;

const QRCodeComponent: React.FunctionComponent<Props> = ({link, className = '', size = 100}) => {
  const [shortUrl, setShortUrl] = React.useState<string | null>(null);

  useEffect(() => {
    async function getShortUrl() {
      try {
        const encodeLink = encodeURIComponent(link);
        const response: ShortenUrlResponse = await firebaseService.shortenUrl(`${ShortUrlDomain}/?link=${encodeLink}`);
        setShortUrl(response.shortLink);
      } catch (err) {
        setShortUrl('');
      }
    }

    getShortUrl();
  }, []);

  if (shortUrl == null) {
    return <Loader/>
  }

  return (
    <div className={`qr-code ${className}`} id='qr-code'>
      {shortUrl.length ? <QRCode
        value={shortUrl}
        size={size}
        level={'H'}
        fgColor='#151D51'
        bgColor='transparent'
        includeMargin={false}
      /> : <DownloadQR/>}
    </div>
  );
};

export default React.memo(QRCodeComponent);
