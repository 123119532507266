import fetch from 'node-fetch';
import {ServicesErrors, GenerateAppDownloadLinksApi} from '../../constants';
import {handleResponse, determineErrorLevelForSentry, handleCommonErrorResponse} from '../../utils';
import * as Sentry from '@sentry/browser';
import {History} from 'history';
import {DownloadLinksType} from './userTypes';

export function generateAppDownloadLinks(history: History): Promise<DownloadLinksType> {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  };
  return fetch(GenerateAppDownloadLinksApi, requestOptions)
    .then(handleResponse)
    .catch(error => {
      const errorLevel = determineErrorLevelForSentry(error);
      Sentry.configureScope((scope) => {
        scope.setLevel(errorLevel);
      });

      Sentry.addBreadcrumb({
        category: 'user',
        message: 'Endpoint ' + GenerateAppDownloadLinksApi,
        data: {
          error: error,
        },
        level: errorLevel,
      });
      Sentry.captureMessage(ServicesErrors.user.generateAppDownloadLinks, errorLevel);
      return handleCommonErrorResponse(error, history);
    });
}
