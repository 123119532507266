import React, {ReactElement} from 'react';
import './SSO.scss';
import AppleSignIn from '../../components/AppleSignIn';
import {GoogleSignIn} from '../../components/GoogleSignIn';

const SSO = (): ReactElement => {
  return (
    <div className='sso'>
      <AppleSignIn/>
      <GoogleSignIn/>
    </div>
  );
};

export default SSO;
