import {Dispatch} from 'redux';
import {downloadService} from '../../../../services/download/download';
import {
    DesktopDownloadLinkParamsType,
    DesktopDownloadLinksType
} from '../../../../services/download/getDesktopDownloadLinks';
import {ActionType} from '../../../../store/actions/actionsTypes';

export enum DesktopDownloadLinks {
    GET_DESKTOP_DOWNLOAD_LINKS_REQUEST= 'GET_DESKTOP_DOWNLOAD_LINKS_REQUEST',
    GET_DESKTOP_DOWNLOAD_LINKS_SUCCESS= 'GET_DESKTOP_DOWNLOAD_LINKS_SUCCESS',
    GET_DESKTOP_DOWNLOAD_LINKS_FAILURE= 'GET_DESKTOP_DOWNLOAD_LINKS_FAILURE',
}

export type GetDesktopDownloadLinksRequestReduxAction = ActionType<DesktopDownloadLinks.GET_DESKTOP_DOWNLOAD_LINKS_REQUEST, {}>;
export type GetDesktopDownloadLinksSuccessReduxAction = ActionType<DesktopDownloadLinks.GET_DESKTOP_DOWNLOAD_LINKS_SUCCESS, DesktopDownloadLinksType>;
export type GetDesktopDownloadLinksFailureReduxAction = ActionType<DesktopDownloadLinks.GET_DESKTOP_DOWNLOAD_LINKS_FAILURE, Error>;

export function getDesktopDownloadLinks(params: DesktopDownloadLinkParamsType) {
    return (dispatch: Dispatch<GetDesktopDownloadLinksRequestReduxAction | GetDesktopDownloadLinksSuccessReduxAction | GetDesktopDownloadLinksFailureReduxAction>) => {
        dispatch(request());
        downloadService.getDesktopDownloadLinks(params)
            .then(
                (resp: DesktopDownloadLinksType) => {
                    dispatch(success(resp));
                },
                error => {
                    dispatch(failure(error));
                }
            );

        function request(): GetDesktopDownloadLinksRequestReduxAction {
            return {type: DesktopDownloadLinks.GET_DESKTOP_DOWNLOAD_LINKS_REQUEST, payload: {}}
        }

        function success(downloadLinks: DesktopDownloadLinksType): GetDesktopDownloadLinksSuccessReduxAction {
            return {type: DesktopDownloadLinks.GET_DESKTOP_DOWNLOAD_LINKS_SUCCESS, payload: downloadLinks}
        }

        function failure(error: Error): GetDesktopDownloadLinksFailureReduxAction {
            return {type: DesktopDownloadLinks.GET_DESKTOP_DOWNLOAD_LINKS_FAILURE, payload: error}
        }
    }
}