import {ForgotPasswordUserBody} from './userTypes';
import {History} from 'history';
import fetch from 'node-fetch';
import {DeviceForgotPasswordApi, ServicesErrors} from '../../constants';
import {handleResponse, handleCommonErrorResponse, determineErrorLevelForSentry} from '../../utils';
import * as Sentry from '@sentry/browser';

export function forgot(userData: ForgotPasswordUserBody, history: History): Promise<string> {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(userData)
  };

  return fetch(DeviceForgotPasswordApi, requestOptions)
    .then(handleResponse)
    .catch(error => {
      const errorLevel = determineErrorLevelForSentry(error);

      Sentry.configureScope((scope) => {
        scope.setLevel(errorLevel);
      });

      Sentry.addBreadcrumb({
        category: 'auth',
        message: 'Endpoint ' + DeviceForgotPasswordApi,
        data: {
          error: error,
          deviceId: userData.device_id,
        },
        level: errorLevel
      });

      Sentry.captureMessage(`${ServicesErrors.user.forgotPasswordFailed} with ${error.message}`, errorLevel);
      return handleCommonErrorResponse(error, history)
    });
}
