import {userService} from '../../../services/user/user';
import {deviceService} from '../../../services/device/device';
import {
  EventContext,
  ForgotPasswordEvents,
  LoginEvents,
  ResetPasswordEvents,
  SignupEvents,
  UserConstants,
  UserInfo,
} from '../../../constants';
import {
  LoginUserBody,
  RegisterUserBody,
  ChangePasswordUserBody,
  ForgotPasswordUserBody,
  ResetPasswordUserBody,
  VerificationData,
  CheckVerificationCodeResponse,
  ResetPasswordData, DownloadLinksType,
} from '../../../services/user/userTypes';
import {Routes} from '../../../router/Routes';
import {
  LoginUserRequestReduxAction,
  RegisterUserRequestReduxAction,
  RegisterUserSuccessReduxAction,
  LoginUserSuccessReduxAction,
  LoginUserFailureReduxAction,
  ChangePasswordRequestReduxAction,
  ChangePasswordSuccessReduxAction,
  ChangePasswordFailureReduxAction,
  RegisterUserFailureReduxAction,
  ForgotPasswordRequestReduxAction,
  ForgotPasswordSuccessReduxAction,
  ForgotPasswordFailureReduxAction,
  ResetPasswordRequestReduxAction,
  ResetPasswordSuccessReduxAction,
  ResetPasswordFailureReduxAction,
  CheckVerificationCodeRequestReduxAction,
  CheckVerificationCodeSuccessReduxAction,
  CheckVerificationCodeFailureReduxAction,
  SetLoginEmailReduxAction,
  GenerateDownloadLinksFailureReduxAction,
  GenerateDownloadLinksSuccessReduxAction,
  GenerateDownloadLinksRequestReduxAction,
} from './userActionsTypes';
import {Dispatch} from 'redux';
import {History} from 'history';
import {sendGtmCustomEvent} from '../../../utils';
import {Events} from '../../../services/events/events';

export function registration(user: RegisterUserBody, history: History) {
  return (dispatch: Dispatch<RegisterUserRequestReduxAction | RegisterUserSuccessReduxAction | RegisterUserFailureReduxAction>) => {
    dispatch(registrationRequest());
    Promise.all([deviceService.registration(), userService.registration(user)])
      .then(() => {
        sendGtmCustomEvent('registrationComplete');
        Events.send({
          context: EventContext.general,
          event: SignupEvents.generalSignup,
        });
        Events.send({
          context: EventContext.signup,
          event: SignupEvents.signupSuccess,
        });
        const event = new CustomEvent('loginSuccess', {
          detail: {
            email: user.email,
            deviceId: user.device_id,
            location: Routes.Main,
          }
        });
        document.dispatchEvent(event);
        // Need to show message about verification email and hide it after 4 sec
        showSendVerificationEmailMessage();
        setTimeout(() => {
          dispatch(success(user));
          history.push(Routes.Main);
        }, 4000);
      })
      .catch((error: Error) => {
        Events.send({
          context: EventContext.signup,
          event: SignupEvents.signupError,
        });
        dispatch(failure(error));
      });
  };

  function success(user: RegisterUserBody): RegisterUserSuccessReduxAction {
    return {type: UserConstants.REGISTER_SUCCESS, payload: user}
  }

  function failure(error: Error): RegisterUserFailureReduxAction {
    return {type: UserConstants.REGISTER_FAILURE, payload: error}
  }
}

export function registrationRequest(): RegisterUserRequestReduxAction {
  return {type: UserConstants.REGISTER_REQUEST, payload: {}}
}

export function login(userData: LoginUserBody, history: History) {
  return (dispatch: Dispatch<LoginUserRequestReduxAction | LoginUserSuccessReduxAction | LoginUserFailureReduxAction>) => {

    dispatch(loginRequest());
    return userService.login(userData)
      .then(
        () => {
          Events.send({
            context: EventContext.login,
            event: LoginEvents.loginSuccess,
          });
          const event = new CustomEvent('loginSuccess', {
            detail: {
              email: userData.email,
              deviceId: userData.device_id,
              location: Routes.Main,
            }
          });
          document.dispatchEvent(event);
          dispatch(loginSuccess(userData));
          history.push(Routes.Main);
        },
        error => {
          Events.send({
            context: EventContext.login,
            event: LoginEvents.loginError,
          });
          dispatch(failure(error));
        }
      );

    function failure(error: Error): LoginUserFailureReduxAction {
      return {type: UserConstants.LOGIN_FAILURE, payload: error}
    }
  }
}

export function loginRequest(): LoginUserRequestReduxAction {
  return {type: UserConstants.LOGIN_REQUEST, payload: {}}
}

export function loginSuccess(user: LoginUserBody): LoginUserSuccessReduxAction {
  return {type: UserConstants.LOGIN_SUCCESS, payload: user}
}

export function changePassword(userData: ChangePasswordUserBody, history: History) {
  return (dispatch: Dispatch<ChangePasswordRequestReduxAction | ChangePasswordSuccessReduxAction | ChangePasswordFailureReduxAction>) => {
    dispatch(changePasswordRequest());
    return userService.changePassword(userData, history)
      .then(
        () => {
          dispatch(success(userData));
        },
        (error) => {
          dispatch(failure(error));
        }
      );

    function success(userData: ChangePasswordUserBody): ChangePasswordSuccessReduxAction {
      return {type: UserConstants.CHANGE_PASSWORD_SUCCESS, payload: userData}
    }

    function failure(error: Error): ChangePasswordFailureReduxAction {
      return {type: UserConstants.CHANGE_PASSWORD_FAILURE, payload: error}
    }
  }
}

export function changePasswordRequest(): ChangePasswordRequestReduxAction {
  return {type: UserConstants.CHANGE_PASSWORD_REQUEST, payload: {}}
}

export function forgotPassword(userData: ForgotPasswordUserBody, history: History, resend = false) {
  return (dispatch: Dispatch<ForgotPasswordRequestReduxAction | ForgotPasswordSuccessReduxAction | ForgotPasswordFailureReduxAction>) => {
    dispatch(forgotPasswordRequest(resend));

    userService.forgot(userData, history)
      .then(() => {
          Events.send({
            context: EventContext.forgotPassword,
            event: resend ? ForgotPasswordEvents.resetLinkScreen.resendSuccess : ForgotPasswordEvents.resetSuccess,
          });
          dispatch(success(userData));
        },
        (error) => {
          Events.send({
            context: EventContext.forgotPassword,
            event: resend ? ForgotPasswordEvents.resetLinkScreen.resendError : ForgotPasswordEvents.resetError,
          });
          dispatch(failure(error));
        }
      );

    function success(userData: ForgotPasswordUserBody): ForgotPasswordSuccessReduxAction {
      return {type: UserConstants.FORGOT_SUCCESS, payload: userData}
    }

    function failure(error: Error): ForgotPasswordFailureReduxAction {
      return {type: UserConstants.FORGOT_FAILURE, payload: error}
    }
  }
}

export function forgotPasswordRequest(resend = false): ForgotPasswordRequestReduxAction {
  return {type: UserConstants.FORGOT_REQUEST, payload: {resend: resend}}
}

export function checkVerificationCode(verificationData: VerificationData) {
  return (dispatch: Dispatch<CheckVerificationCodeRequestReduxAction | CheckVerificationCodeSuccessReduxAction | CheckVerificationCodeFailureReduxAction>) => {
    dispatch(request());

    userService.checkVerificationCode(verificationData)
      .then(
        (apiResp) => {
          dispatch(success(apiResp));
        },
        error => {
          dispatch(failure(error));
        }
      );

    function request(): CheckVerificationCodeRequestReduxAction {
      return {type: UserConstants.CHECK_VERIFICATION_CODE_REQUEST, payload: {}}
    }

    function success(apiResp: CheckVerificationCodeResponse): CheckVerificationCodeSuccessReduxAction {
      return {type: UserConstants.CHECK_VERIFICATION_CODE_SUCCESS, payload: apiResp}
    }

    function failure(error: Error): CheckVerificationCodeFailureReduxAction {
      return {type: UserConstants.CHECK_VERIFICATION_CODE_FAILURE, payload: error}
    }
  }
}

export function resetPassword(userData: ResetPasswordData, history: History) {
  return (dispatch: Dispatch<ResetPasswordRequestReduxAction | ResetPasswordSuccessReduxAction | ResetPasswordFailureReduxAction>) => {
    dispatch(resetPasswordRequest());
    userService.resetPassword(userData, history)
      .then(
        () => {
          Events.send({
            context: EventContext.resetPassword,
            event: ResetPasswordEvents.resetSuccess,
          });
          dispatch(success(userData));
          history.push(Routes.Main);
        },
        error => {
          Events.send({
            context: EventContext.resetPassword,
            event: ResetPasswordEvents.resetError,
          });
          dispatch(failure(error));
        }
      );

    function success(userData: ResetPasswordUserBody): ResetPasswordSuccessReduxAction {
      return {type: UserConstants.RESET_PASSWORD_SUCCESS, payload: userData}
    }

    function failure(error: Error): ResetPasswordFailureReduxAction {
      return {type: UserConstants.RESET_PASSWORD_FAILURE, payload: error}
    }
  }
}

export function resetPasswordRequest(): ResetPasswordRequestReduxAction {
  return {type: UserConstants.RESET_PASSWORD_REQUEST, payload: {}}
}

export function generateDownloadLinks(history: History) {
  return (dispatch: Dispatch<GenerateDownloadLinksRequestReduxAction | GenerateDownloadLinksSuccessReduxAction | GenerateDownloadLinksFailureReduxAction>) => {
    dispatch(request());
    userService.generateAppDownloadLinks(history)
      .then(
        (resp: DownloadLinksType) => {
          dispatch(success(resp));
        },
        error => {
          dispatch(failure(error));
        }
      );

    function request(): GenerateDownloadLinksRequestReduxAction {
      return {type: UserConstants.GENERATE_DOWNLOAD_LINKS_REQUEST, payload: {}}
    }

    function success(links: DownloadLinksType): GenerateDownloadLinksSuccessReduxAction {
      return {type: UserConstants.GENERATE_DOWNLOAD_LINKS_SUCCESS, payload: links}
    }

    function failure(error: Error): GenerateDownloadLinksFailureReduxAction {
      return {type: UserConstants.GENERATE_DOWNLOAD_LINKS_FAILURE, payload: error}
    }
  }
}

export function resetChangePasswordError() {
  return (dispatch: Dispatch<ChangePasswordRequestReduxAction>) => {
    dispatch({type: UserConstants.CHANGE_PASSWORD_REQUEST, payload: {}});
  }
}

export function resetForgotPasswordError() {
  return (dispatch: Dispatch<ForgotPasswordRequestReduxAction>) => {
    dispatch({type: UserConstants.FORGOT_REQUEST, payload: {resend: false}});
  }
}

export function setLoginEmail(email: string) {
  return (dispatch: Dispatch<SetLoginEmailReduxAction>) => {
    dispatch({type: UserConstants.SET_LOGIN_EMAIL, payload: {email}});
  }
}

export function showSendVerificationEmailMessage(): void {
  const verifyEmailBlock = document.getElementById('verify-email');
  const signupForm = document.getElementById('signup-form');
  verifyEmailBlock?.classList.add('appear');
  signupForm?.classList.add('appear');
  Events.send({
    context: EventContext.signup,
    event: SignupEvents.verificationEmailScreen,
  });
}

export function removeUserSessionData(): void {
  const userSessionData = [UserInfo.affidUuid, UserInfo.webcbt, UserInfo.partnerName];

  userSessionData.forEach(userInfoItem => {
    sessionStorage.removeItem(userInfoItem);
  })
}

export function getUserSessionData(dataName = ''): string | null {
  return sessionStorage.getItem(dataName);
}
