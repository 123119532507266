import React, {FunctionComponent} from 'react';
import '../Icons.scss';
import {IconType} from '../../../constants';

type Props = {
  className?: string;
  size?: 'big';
  iconType: IconType;
}

const MembershipIcon = require('../../../../resources/images/icon-membership.svg').default;
const ProfileIcon = require('../../../../resources/images/icon-profile.svg').default;
const LockIcon = require('../../../../resources/images/icon-lock.svg').default;
const DesktopIcon = require('../../../../resources/images/icon-desktop.svg').default;
const MobileIcon = require('../../../../resources/images/icon-mobile.svg').default;
const DeleteIcon = require('../../../../resources/images/icon-delete.svg').default;
const RevertIcon = require('../../../../resources/images/icon-revert.svg').default;
const BellIcon = require('../../../../resources/images/icon-bell.svg').default;
const ShieldIcon = require('../../../../resources/images/icon-shield-outline.svg').default;

const IconsWithBackground: FunctionComponent<Props> = (props: Props) => {
  const {className = '', iconType, size} = props;

  const chooseIcon = (iconType: IconType) => {
    switch (iconType) {
      case IconType.subscription:
        return <MembershipIcon className='icon__membership'/>;
      case IconType.profile:
        return <ProfileIcon/>;
      case IconType.changePassword:
        return <LockIcon/>;
      case IconType.desktop:
        return <DesktopIcon/>;
      case IconType.mobile:
        return <MobileIcon/>;
      case IconType.delete:
        return <DeleteIcon/>;
      case IconType.revert:
        return <RevertIcon/>;
      case IconType.notifications:
        return <BellIcon/>;
      case IconType.privacy:
        return <ShieldIcon/>;
      default:
        break;
    }
  };

  return (
    <div
      data-name={iconType}
      className={`${className} icon__background ${size ? 'icon__background--big' : ''}`}>
      {chooseIcon(iconType)}
    </div>
  );
};

export default IconsWithBackground;
