import {WebCbt, WebCbtFlowNames} from '../../constants';

export const LoginPageConfig: Record<any, any> = {
  [WebCbtFlowNames[WebCbt.clario]]: {
    title: {
      text: 'Hi! Welcome\u00A0back',
      className: 'title--break-words'
    },
    subtitle: '',
  },
  [WebCbtFlowNames[WebCbt.waoo]]: {
    title: {
      text: 'Welcome back!',
      className: ''
    },
    subtitle: 'You\'ve already created Clario account with the subscription provided by %partner_name%.',
  },
  [WebCbtFlowNames[WebCbt.mkClarioMobile]]: {
    title: {
      text: 'Hi! Welcome\u00A0back',
      className: 'title--break-words'
    },
    subtitle: 'You\'ve already created an account. Let\'s log in.',
  },
}
