import {registration} from './registration';
import {login} from './login';
import {changePassword} from './changePassword';
import {forgot} from './forgot';
import {checkVerificationCode} from './checkVerificationCode';
import {resetPassword} from './resetPassword';
import {logout} from './logout';
import {getUserInfo} from './getUserInfo';
import {generateAppDownloadLinks} from './generateAppDownloadLinks';
import {changeConsents} from './changeConsents';
import {changeUserName} from './changeUserName';
import {getAutoLoginParams} from './getAutoLoginParams';

export const userService = {
  registration,
  login,
  changePassword,
  forgot,
  resetPassword,
  getUserInfo,
  checkVerificationCode,
  logout,
  generateAppDownloadLinks,
  changeConsents,
  changeUserName,
  getAutoLoginParams
};






