import {connect} from 'react-redux';
import ModalPopupWrapper from './ModalPopupWrapper';
import {hideModal, openModal, setModalPopupSize} from '../../../store/actions';
import {ModalContentType, ModalPopup, ModalPopupSize} from '../ModalTypes';
import {AppState} from '../../../store/reducers/reducerType';
import {InnerContentPopups} from '../../../constants';
import {Asset} from '../../../services/assets/assetsTypes';

type StateType = {
  needToShow: boolean,
  currentAsset: Asset | null,
  modalPopup: ModalPopup | null;
}

const mapStateToProps = (state: AppState): StateType => ({
  needToShow: state.popups.needToShow,
  currentAsset: state.assets.currentAsset,
  modalPopup: state.popups.modalPopup,
});

const mapDispatchToProps = (dispatch: any) => ({
  setModalPopupSize: (size: ModalPopupSize) => {
    dispatch(setModalPopupSize(size));
  },
  hideModal: (): void => {
    dispatch(hideModal());
  },
  openModal: (contentType: ModalContentType, innerContentPopup: InnerContentPopups): void => {
    dispatch(openModal(contentType, innerContentPopup));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalPopupWrapper);
