import React, {FunctionComponent} from 'react';
import DeviceItem from './DeviceItem';
import './DevicesList.scss'
import {DevicesStatus, EnrichedDevice} from '../../../../services/device/deviceTypes';

type Props = {
  devicesList: Array<EnrichedDevice> | null;
  devicesStatus: DevicesStatus | null;
}

const DevicesList: FunctionComponent<Props> = (props: Props) => {
  const {devicesList, devicesStatus} = props;

  // Handle case when we don`t yet receive devicesList data
  if (devicesList == null || !devicesList.length) {
    return null;
  }

  return (<div className='devices__devices-list devices-list'>
      {devicesList.map((device: EnrichedDevice, index: number) => {
        return (<DeviceItem device={device} key={device.id} devicesStatus={devicesStatus} listNumber={index}/>)
      })}
    </div>
  );
};

export default React.memo(DevicesList);
