import {connect} from 'react-redux';
import LinkExpired from './LinkExpired';
import {sendDownloadLinks} from '../../../../store/actions';
import {AppState, RequestStatus} from '../../../../store/reducers/reducerType';

type StateType = {
  downloadToken: string,
  sendDownloadLinksStatus: RequestStatus,
}

const mapStateToProps = (state: AppState): StateType => ({
  downloadToken: state.partner.downloadToken,
  sendDownloadLinksStatus: state.partner.sendDownloadLinksStatus,
});

const mapDispatchToProps = (dispatch: any) => ({
  sendDownloadLinks: (token: string): void => {
    dispatch(sendDownloadLinks(token));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)((LinkExpired));
