import React, {FunctionComponent, useEffect, useState} from 'react';
import Button from '../../../../../components/Buttons/Button';
import DeviceItemPopup from '../DeviceItemPopup';
import {History} from 'history';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {AppState} from '../../../../../store/reducers/reducerType';
import {Asset} from '../../../../../services/assets/assetsTypes';
import Loader from '../../../../../components/Loader';
import './DisconnectConfirm.scss';
import {Events} from '../../../../../services/events/events';
import {DeviceItemScreenType, DevicesEvents, EventContext, ApiErrors, PopupErrorType} from '../../../../../constants';
import withServerErrorHandlingDeviceItem from '../../../../../hoc/ServerErrorHandlingDeviceItem';
import {DevicesStatus} from '../../../../../services/device/deviceTypes';
import {DisconnectDeviceStatusType} from '../../../../../store/actions/device/deviceActionsTypes';

type Props = {
  setDeviceItemScreenType: (deviceId: string, screenType: DeviceItemScreenType) => void;
  disconnectDevice: (licenseID: string, deviceId: string, history: History) => void;
  getLicenseIdByDevice: (deviceId: string, currentAsset: Asset | null) => string;
  deviceId: string,
  disconnectDeviceStatus: DisconnectDeviceStatusType | null,
  setUpdateDataFromApi: (value: boolean) => void;
  reduceConnectedDevicesQuantity: (devicesStatus: DevicesStatus) => void;
  devicesStatus: DevicesStatus | null,
}

const DisconnectConfirm: FunctionComponent<Props> = (props: Props) => {
  const history = useHistory();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const currentAsset: any = useSelector((state: AppState) => state.assets.currentAsset);
  const [disconnectRequest, setDisconnectRequestStatus] = useState(false);


  useEffect(() => {
    Events.send({
      context: EventContext.devices,
      event: DevicesEvents.detailed_area__disconnect_confirmation_area_shown,
      data: {deviceId: props.deviceId}
    })
  }, []);

  useEffect(() => {
    const {
      disconnectDeviceStatus,
      setDeviceItemScreenType, deviceId, reduceConnectedDevicesQuantity, devicesStatus
    } = props;

    // handle request status by deviceId
    if (!disconnectDeviceStatus || !disconnectDeviceStatus[deviceId]) {
      return;
    }

    // handle if devicesStatus
    if (!devicesStatus) {
      return;
    }

    const {status, error} = disconnectDeviceStatus[deviceId];

    // Handle successful disconnect - update assets List and devices list and show next popup
    if (status) {
      setIsSubmitted(false);
      props.setUpdateDataFromApi(true);
      setDeviceItemScreenType(deviceId, DeviceItemScreenType.disconnectCongratulation);
      reduceConnectedDevicesQuantity(devicesStatus);
      return;
    }

    if (error.length) {
      setIsSubmitted(false);
      if (error === ApiErrors.invalid_license_id || error === ApiErrors.license_not_found) {
        throw new Error(error);
      }
    }
  }, [props.disconnectDeviceStatus]);


  const handleRejectDisconnect = () => {
    const {setDeviceItemScreenType, deviceId} = props;
    setDeviceItemScreenType(deviceId, DeviceItemScreenType.deviceInfo);
    Events.send({
      context: EventContext.devices,
      event: DevicesEvents.detailed_area__disconnect_confirmation_area_no_click,
      data: {deviceId: props.deviceId}
    })
  };

  const handleDisconnect = () => {
    const {disconnectDevice, getLicenseIdByDevice} = props;

    if (isSubmitted) {
      return;
    }

    setIsSubmitted(true);

    const licenseId = getLicenseIdByDevice(props.deviceId, currentAsset);

    if (!licenseId.length) {
      throw new Error(`${PopupErrorType.Disconnect} licenseId not found`);
    }

    disconnectDevice(licenseId, props.deviceId, history);
    setDisconnectRequestStatus(true);

    Events.send({
      context: EventContext.devices,
      event: DevicesEvents.detailed_area__disconnect_confirmation_area_yes_click,
      data: {deviceId: props.deviceId}
    })
  };

  return (
    <DeviceItemPopup>
      {disconnectRequest ? <Loader className='device-item__loader'/> :
        <>
          <h3 className='devices__block-title'>Disconnect this device?</h3>
          <div className='device-item-popup__text-wrapper'>
            <p className='devices__text'>{`We'll no longer be able to secure it. `}
              <span className='devices__subtext'>Are you sure?</span>
            </p>
          </div>
          <div className='button-wrapper disconnect-confirm__button-wrapper'>
            <Button
              type='button'
              className='button--secondary disconnect-confirm__button disconnect-confirm__button--reject'
              action={handleRejectDisconnect}
              title='No'/>
            <Button
              type='button'
              disabled={isSubmitted}
              className='button--secondary disconnect-confirm__button'
              action={handleDisconnect}
              title='Yes'/>
          </div>
        </>}
    </DeviceItemPopup>
  );
};

export default withServerErrorHandlingDeviceItem(React.memo(DisconnectConfirm));
