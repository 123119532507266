import React, {FunctionComponent, ReactNode} from 'react';
import './DeviceItemPopup.scss';

type Props = {
  children: ReactNode,
}
const DeviceItemPopup: FunctionComponent<Props> = (props: Props) => {
  return (
    <div className='device-item-popup'>
      {props.children}
    </div>
  );
};

export default DeviceItemPopup;
