import {connect} from 'react-redux';
import Login from './Login';
import {login, loginRequest} from '../../../store/actions';
import {LoginInfo, LoginUserBody} from '../../../services/user/userTypes';
import {AppState, RequestStatus} from '../../../store/reducers/reducerType';
import {History} from 'history';

type StateType = {
  loginStatus: RequestStatus,
  loginInfo: LoginInfo | null,
  webcbt: string,
}

const mapStateToProps = (state: AppState): StateType => ({
  loginStatus: state.user.loginStatus,
  loginInfo: state.user.loginInfo,
  webcbt: state.partner.webcbt,
});

const mapDispatchToProps = (dispatch: any) => ({
  login: (userData: LoginUserBody, history: History): void => {
    dispatch(login(userData, history));
  },
  resetLoginErrors: () => {
    dispatch(loginRequest());
  },
  clearLoginEmail: () => {
    dispatch(loginRequest());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);
