import {ConsentsType} from './userTypes';
import {History} from 'history';
import fetch from 'node-fetch';
import {ServicesErrors, UserChangeConsentsApi} from '../../constants';
import {determineErrorLevelForSentry, handleCommonErrorResponse, handleResponse} from '../../utils';
import * as Sentry from '@sentry/browser';

export function changeConsents(consents: ConsentsType, history: History): Promise<void> {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(consents)
  };

  return fetch(UserChangeConsentsApi, requestOptions)
    .then(handleResponse)
    .catch(error => {
      const errorLevel = determineErrorLevelForSentry(error);

      Sentry.configureScope((scope) => {
        scope.setLevel(errorLevel);
      })

      Sentry.addBreadcrumb({
        category: 'auth',
        message: 'Endpoint ' + UserChangeConsentsApi,
        data: {
          error: error,
        },
        level: errorLevel,
      });
      Sentry.captureMessage(`${ServicesErrors.user.changeConsents} with ${error.message}`, errorLevel);
      return handleCommonErrorResponse(error, history)
    });
}
