import {iOSDeviceTypes} from '../constants';

export default function determineIOSModel(defaultValue: string): string {
  const concurrenceModels: Array<string> = [];

  if (typeof defaultValue !== 'string') {
    return '';
  }

  Object.entries(iOSDeviceTypes).find((modelName) => {
    if (defaultValue.toLowerCase().includes(modelName[0].toLowerCase())) {
      concurrenceModels.push(modelName[1]);
    }
  });

  if (!concurrenceModels.length) {
    return defaultValue;
  }

  return concurrenceModels[concurrenceModels.length - 1];
};
