import {connect} from 'react-redux';
import ResetPassword from './ResetPassword';
import {resetPassword, resetPasswordRequest} from '../../../store/actions';
import {ResetPasswordData} from '../../../services/user/userTypes';
import {AppState, RequestStatus} from '../../../store/reducers/reducerType';
import {History} from 'history';

type StateType = {
  resetPasswordStatus: RequestStatus,
  verificationCode: string,
}

const mapStateToProps = (state: AppState): StateType => ({
  resetPasswordStatus: state.user.resetPasswordStatus,
  verificationCode: state.user.verificationCode,
});

const mapDispatchToProps = (dispatch: any) => ({
  reset: (userData: ResetPasswordData, history: History): void => {
    dispatch(resetPassword(userData, history));
  },
  resetResetPasswordErrors: () => {
    dispatch(resetPasswordRequest());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResetPassword);
