import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useMemo} from 'react';
import {determineDownloadLink, getAutoLoginParams} from '../store/actions';
import {useHistory} from 'react-router-dom';
import {selectAutoLoginParamsData} from '../store/reducers/user';
import {DownloadSystemLink, MobileDownloadLink} from '../constants';
import getPlatform, {PlatformNames} from '../utils/getPlatform';
import {DesktopDownloadLinkParamsType} from '../services/download/getDesktopDownloadLinks';
import {getDesktopDownloadLinks, selectDesktopDownloadLinksInfo} from '../store/reducers/download';
import {useTrackingParams} from './useTrackingParams';

export interface DownloadLinkParamsInterface {
    affid: string | null;
    altk?: string;
    cbt?: number;
    webCbt?: number;
    bundleId: string | null,
    ga_cid: string | null,
}

export const useDownloadLinks = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const autoLoginParamsData = useSelector(selectAutoLoginParamsData);
    const desktopDownloadLinks = useSelector(selectDesktopDownloadLinksInfo);
    const {getAffid, getBundleId, getGaCid} = useTrackingParams();
    const desktopLink = useMemo(() => {
        if (!desktopDownloadLinks.data) {
            return DownloadSystemLink;
        }
        const platform = getPlatform();
        const {macos = null, windows = null} = desktopDownloadLinks.data;

        if (platform === PlatformNames.MacOS && macos) {
            return macos;
        }

        return windows || DownloadSystemLink;
    }, [desktopDownloadLinks]);

    const downloadLinkParams = useMemo(()  => {
        const defaultParams = {
            affid: getAffid(),
            bundleId: getBundleId(),
            ga_cid: getGaCid(),
        };
        if (autoLoginParamsData) {
            return  {
                ...autoLoginParamsData,
                ...defaultParams,
            }
        }
        return defaultParams;
    }, [autoLoginParamsData])
    
    const desktopDownloadLink = useMemo(() => {
        if (!desktopLink) {
            return DownloadSystemLink;
        }
        return determineDownloadLink<DownloadLinkParamsInterface | null>(desktopLink, downloadLinkParams)
    }, [desktopLink, downloadLinkParams]);
    
    const mobileDownloadLink = useMemo(() => {
        return determineDownloadLink<DownloadLinkParamsInterface | null>(MobileDownloadLink, downloadLinkParams);
    }, [downloadLinkParams]);
    
    useEffect(() => {
        getAutoLoginParam();
        getDesktopDownloadLink();
    },  []);

    const getDesktopDownloadLink = () => {
        const params: DesktopDownloadLinkParamsType = {
            affid: getAffid(),
            bundleId: getBundleId(),
        }
        dispatch(getDesktopDownloadLinks(params));
    }
    
    const getAutoLoginParam = () => {
        dispatch(getAutoLoginParams(history));
    }

    return {
        desktopDownloadLink,
        mobileDownloadLink,
    }
}