import React, {FunctionComponent} from 'react';
import {Device, DevicesStatus} from '../../../../../services/device/deviceTypes';
import {DeviceItemScreenType} from '../../../../../constants';
import DefaultDevice from './DefaultDevice';
import UnknownDevice from './UnknownDevice';

type Props = {
  device: Device;
  devicesStatus: DevicesStatus | null;
  listNumber: number,
  setDeviceItemScreenType: (deviceId: string, screenType: DeviceItemScreenType) => void;
}

const DeviceInfo: FunctionComponent<Props> = (props: Props) => {
  const {device} = props;

  if (!device.soft_version || !device.soft_version) {
    return <UnknownDevice {...props}  />
  }

  return <DefaultDevice {...props} />;
};

export default DeviceInfo;
