export const PageActions = {
  changeClassName: 'CHANGE_CLASS_NAME',
  chatInitialized: 'CHANGE_CHAT_INITIALIZED_STATE',
  updateDataFromApi: 'UPDATE_DATA_FROM_API',
  SET_THANK_YOU_PAGE_CONTENT: 'SET_THANK_YOU_PAGE_CONTENT',
  SET_ZCHAT_LIB_LOADED: 'SET_ZCHAT_LIB_LOADED',
  SET_ERROR_QUERY_PARAM: 'SET_ERROR_QUERY_PARAM',
  SCROLL_TOP_PAGE: 'SCROLL_TOP_PAGE',
};

export enum ThankYouPageContext {
  emailVerification = 'emailVerification'
}

export const UIBreakPoints = {
  mobile: {
    min: 320,
    max: 767,
    landscape: {
      min: 679
    }
  },
  tablet: {
    min: 768,
    max: 1023
  },
  desktop: {
    min: 1024,
    content: 1370,
  }
}
