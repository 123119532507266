import React, {useEffect, useState} from 'react';
import Section from '../../../containers/Section';
import {ActiveStatusFromApi, DeactivatedStatusFromApi, DeviceItemScreenType, HoldStatusFromApi, IconType, InnerContentPopups} from '../../../constants';
import {SectionInfo} from '../Main';
import {Device} from '../../../services/device/deviceTypes';
import Loader from '../../../components/Loader';
import {License} from '../../../services/assets/assetsTypes';
import {useHistory} from 'react-router-dom';
import {ModalContentType} from '../../../popups/Modal/ModalTypes';
import {SectionNames} from '../../../containers/Section/Section';
import {useDispatch, useSelector} from 'react-redux';
import {selectDevicesList, selectDevicesStatus} from '../../../store/reducers/device';
import {selectCurrentAsset} from '../../../store/reducers/assets';
import {openModal, setCurrentAssetDevices, determineDevicesStatus, determineDeviceQuantity} from '../../../store/actions';

const DevicesSection = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const devicesList = useSelector(selectDevicesList);
  const currentAsset = useSelector(selectCurrentAsset);
  const devicesStatus = useSelector(selectDevicesStatus);
  const [deviceSectionInfo, setDeviceSectionInfo] = useState<SectionInfo>();
  const [needToOpenPopup, setNeedToOpenPopup] = useState(false);
  const [needToShow, setNeedToShow] = useState(false);

  useEffect(() => {
    determineNeedToShow();
  }, []);

  useEffect(() => {
    determineNeedToShow();
  }, [currentAsset]);

  // Handle current asset status when need to show device section
  const determineNeedToShow = (): void => {
    if (currentAsset == null || !Object.keys(currentAsset).length) {
      return;
    }

    switch (currentAsset.status) {
      case ActiveStatusFromApi:
      case DeactivatedStatusFromApi:
      case HoldStatusFromApi:
        setNeedToShow(true);
        break;
      default:
    }
  };

  // Handle open popup from direct link
  useEffect(() => {
    const locationState: any = history.location.state;

    if (locationState != null && Object.keys(locationState).length && locationState.modalPopup != null) {
      if (locationState.modalPopup.contentType === ModalContentType.devices) {
        setNeedToOpenPopup(true);
        return;
      }
    }

    setNeedToOpenPopup(false);
  }, [history.location.state]);

  // Handle change devices list
  useEffect(() => {
    dispatch(determineDevicesStatus(currentAsset));
    determineDeviceSectionInfo();
    determineCurrentAssetDevices();
  }, [devicesList]);

  const handleManageDevices = () => {
    dispatch(openModal(ModalContentType.devices, InnerContentPopups.devices));
  };

  const determineCurrentAssetDevices = (): void => {
    let currentAssetDevices: any = [];

    // Data not yet received
    if (devicesList == null || currentAsset == null) {
      dispatch(setCurrentAssetDevices(currentAssetDevices));
      return;
    }

    // No asset
    if (!Object.keys(currentAsset).length) {
      dispatch(setCurrentAssetDevices(currentAssetDevices));
      return;
    }

    // No licenses
    if (currentAsset.licenses == null || !currentAsset.licenses.length) {
      dispatch(setCurrentAssetDevices(currentAssetDevices));
      return;
    }

    // @ts-ignore
    currentAsset.licenses.map((license: License) => {
      if (!license.device_id.length) {
        return;
      }

      let deviceInfo = devicesList.find(device => device.id === license.device_id);


      // Handle case when user has licenses with devices but has no such devices in device list
      if (!deviceInfo) {
        currentAssetDevices.push({id: license.device_id});
        return;
      }

      currentAssetDevices.push(deviceInfo);
    });

    // enriched device info with screen type info
    currentAssetDevices = currentAssetDevices.map((device: Device) => {
      return ({...device, screen_type: DeviceItemScreenType.deviceInfo})
    });

    dispatch(setCurrentAssetDevices(currentAssetDevices));
  };

  const determineDeviceSectionInfo = () => {
    const deviceQuantity = determineDeviceQuantity(currentAsset);

    // No attached devices
    if (!deviceQuantity) {
      setDeviceSectionInfo({text: 'Let\'s connect your first device'});
      return;
    }

    setDeviceSectionInfo({text: 'You\'ve connected', bold: `${deviceQuantity} ${deviceQuantity === 1 ? 'device' : 'devices'}`, boldClassName: 'section__text--lowercase'});
  }

  // Show the loader before receiving all the necessary data to display the view
  if (devicesStatus == null || currentAsset == null || deviceSectionInfo == null) {
    return (
      <section className='section slide__section'>
        <Loader/>
      </section>
    )
  }

  if (!needToShow) {
    return null;
  }

  if (needToOpenPopup) {
    dispatch(openModal(ModalContentType.devices, InnerContentPopups.devices));
  }

  return (
    <Section
      name={SectionNames.devices}
      title='Devices'
      iconType={IconType.desktop}
      status={devicesStatus}
      description={`All the devices you're logged being monitored by Clario.`}
      sectionInfo={deviceSectionInfo}
      button={{title: 'Manage', action: handleManageDevices}}
    />
  );
};

export default DevicesSection;
