import React, {FunctionComponent, ReactNode} from 'react';
import './DownloadLinks.scss';
import withServerErrorHandlingPage from '../../hoc/ServerErrorHandlingPage';
import ContentWrapper from '../../containers/ContentWrapper';
import {DownloadLinkCircleType} from '../../containers/DownloadLinkCircle';

type Props = {
  type: DownloadLinkCircleType,
  children: ReactNode;
}

const DownloadLinksWrapper: FunctionComponent<Props> = (props: Props) => {
  return (
    <ContentWrapper>
      <div className={`download-link-expired download-link-expired__page download-link-expired__page--${props.type}`} id='download-link-expired-page'>
        {props.children}
      </div>
    </ContentWrapper>
  );
};

export default (withServerErrorHandlingPage(DownloadLinksWrapper) as any);
