import React, {ReactNode, useRef} from 'react';
import {Events} from '../../../../services/events/events';
import {EventContext, DevicesEvents} from '../../../../constants';
import {useSelector} from 'react-redux';
import {AppState} from '../../../../store/reducers/reducerType';
import {formDeviceIdArray} from '../../../../utils';
import {FAQNames} from './FAQList';
import {useDevicesContext} from '../Devices';

interface FAQListItemType{
  children: ReactNode;
  name: string
}

const FAQListItem = ({name, children}: FAQListItemType) => {
  const {toggleScrollState} = useDevicesContext();
  const listItemRef = useRef(null);
  const currentAssetDevices = useSelector((state: AppState) => state.device.currentAssetDevices);

  const handleToggle = () => {
    if (listItemRef == null) {
      return;
    }

    const listItem: any = listItemRef.current;

    if (listItem == null) {
      return;
    }

    sendEvent();

    const answerElement: HTMLElement | null | undefined = listItem?.querySelector('.faq__answer');

    if (answerElement == null || toggleScrollState == null) {
      return;
    }

    const isActive = answerElement.classList.contains('active');
    toggleScrollState(false);
    if (!isActive) {
      answerElement.classList.add('active');
      listItem.classList.add('active');
      answerElement.style.height = 'auto';
      listItem.style.paddingBottom = '33px';

      const height = answerElement.clientHeight + 'px';

      answerElement.style.height = '0px';

      setTimeout(function () {
        answerElement.style.height = height;
      }, 0);
    }

    if (isActive) {
      answerElement.style.height = '0px';
      listItem.classList.remove('active');
      answerElement.addEventListener('transitionend', function () {
        answerElement.classList.remove('active');
      }, {
        once: true
      });
    }
  };

  const sendEvent = (): void => {
    let eventName = '';
    switch (name) {
      case FAQNames.connectQuantity:
        eventName = DevicesEvents.detailed_area__how_many_devices_can_connect_tab_click;
        break;
      case FAQNames.deviceTypes:
        eventName = DevicesEvents.detailed_area__which_devices_can_connect_tab_click;
        break;
      case FAQNames.howToConnect:
        eventName = DevicesEvents.detailed_area__how_connect_devices_tab_click;
        break;
      case FAQNames.protectedStatus:
        eventName = DevicesEvents.detailed_area__device_protected_tab_click;
        break;
      case FAQNames.moreDevices:
        eventName = DevicesEvents.detailed_area__connect_more_devices_tab_click;
        break;
      case FAQNames.install:
        eventName = DevicesEvents.detailed_area__install_clario_new_devices_tab_click;
        break;
      default:
    }

    if (!eventName) {
      return;
    }

    Events.send({
      context: EventContext.devices,
      event: eventName,
      data: {deviceId: formDeviceIdArray(currentAssetDevices)}
    });
  };

  return (
    <div className='faq__list-item' ref={listItemRef} key={name} onClick={handleToggle}>
      {children}
    </div>
  );
};

export default FAQListItem;
