import React, {FunctionComponent, useEffect} from 'react';
import {DownloadLinkCircleType} from '../../DownloadLinkCircle';
import {Events} from '../../../../services/events/events';
import {DownloadLinksEvents, EventContext} from '../../../../constants/events';

type Props = {
  handleContactSupport: (from: DownloadLinkCircleType) => void;
}

const RequestTimout: FunctionComponent<Props> = (props: Props) => {
  useEffect(() => {
    Events.send({
      context: EventContext.downloadLinks,
      event: DownloadLinksEvents.email_sent_before_shown,
    });
  }, []);

  return (
    <div className='download-link-circle__content-wrapper download-link-circle__content-wrapper--request-timeout'>
      <div className='download-link-circle__content'>
        <h3 className='download-link-circle__title'>Hold on, we've already emailed you.</h3>
        <p className='download-link-circle__text'>Try double-checking your mailbox, we've already sent you the new download link.</p>
        <footer className='download-link-circle__footer download-link-circle-footer'>
          <div className='download-link-circle-footer__content'>
            <div className='download-link-circle__text'>{`Any problems? `}
              <p className='download-link-circle__text download-link-circle__text--bold download-link-circle__text--support'
                 onClick={() => props.handleContactSupport(DownloadLinkCircleType.requestTimout)}>Let us know.</p>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default RequestTimout;
