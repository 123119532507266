import React, {FunctionComponent, useEffect, useRef} from 'react';
import {ListItemType} from '../../HeaderNavList/HeaderNavList';
import SubMenuItem from './SubMenuItem';
import './SubMenu.scss';
import {isAdaptiveMarkup} from '../../../../utils';

type Props = {
  subMenuListItems: Array<ListItemType>
  subMenuState: boolean,
}

const SubMenuList: FunctionComponent<Props> = ({subMenuListItems, subMenuState}) => {
  const subMenuListRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    animateAdaptiveSubMenu();
  }, [subMenuState]);

  const animateAdaptiveSubMenu = () => {
    const subMenuListEl: HTMLUListElement | null = subMenuListRef.current;

    if (subMenuListEl == null) {
      return;
    }

    // Prevent set inline style for desktop markup.
    // Also handle if markup change - in case tablet rotation - reset inline style (height) to initial state.
    if (!isAdaptiveMarkup()) {
      let inlineHeight = subMenuListEl.style.height;

      if (inlineHeight === '0px') {
        subMenuListEl.style.height = `inherit`;
      }

      return;
    }

    if (subMenuState) {
      let subMenuItems = document.querySelectorAll('.sub-menu__list--open .sub-menu__item');
      let subMenuItemsArray = Array.from(subMenuItems);
      let subMenuItemsHeight = 0;

      if (!subMenuItemsArray.length) {
        return;
      }

      subMenuItemsArray.map((element: Element) => {
          if (!(element instanceof Element)) {
            return;
          }
          subMenuItemsHeight += (element as HTMLElement).offsetHeight;
        }
      )
      subMenuListEl.style.height = `${subMenuItemsHeight}px`;
    }

    if (!subMenuState) {
      subMenuListEl.style.height = `0px`;
    }
  }

  return (
    <>
      {!!subMenuListItems.length && (
        <ul ref={subMenuListRef} className={`sub-menu__list ${subMenuState ? 'sub-menu__list--open' : ''}`}>
          {subMenuListItems.map(subMenuListItem => (
            <SubMenuItem
              key={subMenuListItem.name}
              {...subMenuListItem}
            />
          ))}
        </ul>
      )}
    </>
  );
}

export default SubMenuList;
