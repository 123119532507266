import {connect} from 'react-redux';
import VerificationCode from './VerificationCode';
import {VerificationData} from '../../services/user/userTypes';
import {checkVerificationCode} from '../../store/actions';
import {AppState, RequestStatus} from '../../store/reducers/reducerType';

type StateType = {
  checkCodeStatus: RequestStatus,
}

const mapStateToProps = (state: AppState): StateType => ({
  checkCodeStatus: state.user.checkCodeStatus,
});

const mapDispatchToProps = (dispatch: any) => ({
  checkVerificationCode: (verificationData: VerificationData): void => {
    dispatch(checkVerificationCode(verificationData));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)((VerificationCode as any));
