import fetch from 'node-fetch';
import {ServicesErrors, VerifyDownloadLinkTokenApi} from '../../constants';
import {determineErrorLevelForSentry, handleResponse} from '../../utils';
import * as Sentry from '@sentry/browser';
import {VerifyLinkTokenResponse} from './verifySmartLinkToken';

export function verifyDownloadToken(token: string): Promise<VerifyLinkTokenResponse> {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({token: token})
  };

  return fetch(VerifyDownloadLinkTokenApi, requestOptions)
    .then(handleResponse)
    .catch((error) => {
      const errorLevel = determineErrorLevelForSentry(error);

      Sentry.configureScope((scope) => {
        scope.setLevel(errorLevel);
      })


      Sentry.addBreadcrumb({
        category: 'auth',
        message: 'Endpoint ' + VerifyDownloadLinkTokenApi,
        data: {
          error: error,
          requestOptions,
        },
        level: errorLevel
      });
      Sentry.captureMessage(ServicesErrors.waoo.verifyDownloadToken, errorLevel);
      throw new Error(error.message);
    });
}
