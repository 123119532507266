import React, {FunctionComponent, ReactNode} from 'react';
import Aside from '../../containers/Aside';

type Props = {
  children: ReactNode;
  className?: string,
  aside?: {
    title: string,
    titleClassName?: string,
    subtitle?: string,
    fullHeight?: boolean,
  },
};

const ContentWrapper: FunctionComponent<Props> = (props: Props) => {
  const formSectionClassName = (): string => {
    const className = 'content';

    if (props.aside) {
      return props.aside.fullHeight ? className.concat(' content--aside-full-height') : className.concat(' content--aside-right');
    }

    return className.concat(' content--full-width');
  };

  return (
    <div className={`main container ${props.className || ''}`}>
      {props.aside ? <Aside {...props.aside}/> : null}
      <section className={formSectionClassName()}>
        {props.children}
      </section>
    </div>
  )
};

export default ContentWrapper;
