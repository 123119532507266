export enum Routes {
  Main = '/',
  Login = '/login',
  Signup = '/signup',
  Forgot = '/forgot',
  ResetPassword = '/reset-password',
  SubscriptionPopup = '/subscription',
  DevicesPopup = '/devices',
  ProfilePopup = '/profile',
  DeleteAccountPopup = '/delete-account',
  VerifyEmail = '/verify-email',
  ThankYouPage = '/thank-you',
  VerifyDownloadLinks = '/verify-download-link',
  VerifySmartLink = '/verify-smart-link',
  Download = '/download',
  DownloadInstruction = '/download-instruction',
  SalesForceDeleteFlow = '/delete-account-feedback',
  AuthError = '/auth-error',
  AppleSignIn = '/apple/auth-result'
}

export const UnAuthorizedRoutes = [Routes.Login, Routes.Signup, Routes.Forgot, Routes.ResetPassword, Routes.VerifySmartLink];
