export {};
declare global {
  interface Window {
    dataLayer: {[key: string]: any}[];
  }
}

export default function sendGtmCustomEvent(eventName: string): void {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({event: eventName});
}

