import {Redirect} from 'react-router-dom';
import React from 'react';
import {Routes} from './Routes';
import {InnerContentPopups} from '../constants';
import {ModalContentType} from '../popups/Modal/ModalTypes';

type RedirectRoutePropsType = {
  path: Routes,
  state: {
    modalPopup: {
      contentType: ModalContentType,
      innerContentPopup: InnerContentPopups
    }
  }
};

const RedirectRoute = (props: RedirectRoutePropsType) => {
  return (
    <Redirect
      to={{
        pathname: props.path,
        state: props.state,
      }}
    />
  );
};

export default RedirectRoute;
