import * as React from 'react';
import './Notification.scss';
import {ReactNode} from 'react';

interface Props {
  children: ReactNode,
  className?: string,
}

const Notification: React.FunctionComponent<Props> = ({children, className = ''}) => {
  return (
    <div className={`notification`}>
      <div className={`notification__content ${className}`}>
        {children}
      </div>
    </div>
  );
};

export default Notification;
