import Fingerprint2 from 'fingerprintjs2';
import {deviceService} from '../services/device/device';


export async function generateDeviceId() {
  let deviceId = localStorage.getItem('device-id');

  if (!deviceId) {
    deviceId = await getFingerPrint();

    if (deviceId) {
      localStorage.setItem('device-id', deviceId);
      await deviceService.registration(deviceId);
    }
  }

  return deviceId;
}

export default async function getDeviceId(): Promise<string> {
  return await generateDeviceId();
}

function getFingerPrint(): Promise<string> {
  return new Promise((resolve) => {
    Fingerprint2.get((components) => {
      var values = components.map(function (component) {
        return component.value
      });
      resolve(Fingerprint2.x64hash128(values.join(''), 31));
    });
  })
}
