import {PopupsActions, UserConstants} from '../../../constants';
import {DefaultPopupsState} from './constants';
import {PopupsStateType} from './types';


export default (state = DefaultPopupsState, action: any): PopupsStateType => {
  switch (action.type) {
    case PopupsActions.hideModal:
      return {
        ...state,
        needToShow: false,
      };
    case PopupsActions.openModal:
      return {
        ...state,
        modalPopup: action.payload,
        needToShow: true,
      };
    case PopupsActions.setModalSize:
      return {
        ...state,
        modalPopupSize: action.payload,
      };
    case UserConstants.LOGOUT_SUCCESS:
      return DefaultPopupsState;
    default:
      return state;
  }
}
