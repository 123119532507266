export const ApiErrors = {
  invalid_email: 'invalid_email',
  email_already_exists: 'email_already_exists',
  invalid_password: 'invalid_password',
  password_is_less_6_symbols: 'password_is_less_6_symbols',
  password_does_not_contain_a_letter: 'password_does_not_contain_a_letter',
  password_does_not_contain_a_number: 'password_does_not_contain_a_number',
  password_contains_space: 'password_contains_space',
  invalid_request_body: 'invalid_request_body',
  invalid_device_id: 'invalid_device_id',
  undefined_error: 'undefined_error',
  invalid_email_or_password: 'invalid_email_or_password',
  request_is_already_processing: 'request_is_already_processing',
  invalid_token: 'invalid_token',
  token_expired: 'token_expired',
  not_in_beta: 'not_in_beta',
  previous_password_does_not_match: 'previous_password_does_not_match',
  invalid_verification_code: 'invalid_verification_code',
  invalid_empty_consents: 'invalid_empty_consents',
  invalid_app_statistic_consent: 'invalid_app_statistic_consent',
  invalid_marketing_name_consent: 'invalid_marketing_name_consent',
  invalid_marketing_emails_consent: 'invalid_marketing_emails_consent',
  invalid_user_name: 'invalid_user_name',
  user_name_too_long: 'user_name_too_long',
  invalid_affid: 'invalid_affid',
  email_already_verified: 'email_already_verified',
  invalid_license_id: 'invalid_license_id',
  license_not_found: 'license_not_found',
  sent_download_links_has_max_verify_attempts: 'sent_download_links_has_max_verify_attempts',
  smart_link_used: 'smart_link_used',
  invalid_user_password: 'invalid_user_password',
  inquiry_already_exists: 'inquiry_already_exists',
  incorrect_password: 'incorrect_password',
  appleAuth: 'appleAuth'
};

export enum PopupErrorType {
  Cancel = 'cancel',
  Renew = 'renew',
  ChangePassword = 'changePassword',
  ChangeUserName = 'changeUserName',
  Disconnect = 'disconnect',
  DeleteAccount = 'deleteAccount',
  CancelDeleteAccount = 'cancelDeleteAccount'
}

export const ServicesErrors = {
  assets: {
    cancelSubscriptionFailed: 'Cancel subscription failed',
    checkTrialFailed: 'Check Trial failed',
    getAssetsListFailed: 'Get assets list failed',
    renewSubscriptionFailed: 'Renew subscription failed',
  },
  emailVerification: {
    checkVerificationEmailCodeFailed: 'Check verification email code failed',
    sendEmailVerificationFailed: 'Send verification email failed'
  },
  device: {
    disconnectDeviceFailed: 'Disconnect device failed',
    getDeviceListFailed: 'Get device list failed',
    deviceRegistrationFailed: 'Device registration failed',
  },
  events: {
    sendEventFailed: 'Send event failed',
  },
  salesForce: {
    sendWebToCaseResponse: 'Send WebToCase Response to Sales Force Failed with',
    webToCaseConfig: 'No config found for the specified survey type'
  },
  user: {
    registrationUserFailed: 'Registration user failed',
    loginUserFailed: 'Login user failed',
    changePasswordFailed: 'Change password failed',
    changeUserNameFailed: 'Change user name failed',
    forgotPasswordFailed: 'Forgot password failed',
    changeConsents: 'Change user consents failed',
    checkForgotPasswordVerificationCodeFailed: 'Check forgot password verification code failed',
    resetPasswordFailed: 'Reset password failed',
    logoutUserFailed: 'Logout user failed',
    getUserInfoFailed: 'Get user info failed',
    generateAppDownloadLinks: 'Generate AppDownload Links failed',
    getAutoLoginParams: 'Get auto login params failed',
  },
  ldr: {
    createAffid: 'Create affid failed',
  },
  cde: {
    deleteAccount: 'Delete user account failed with',
    cancelDeleteAccount: 'Cancel delete user account failed with',
    getDeleteAccountStatus: 'Get delete user account status failed with',
  },
  waoo: {
    verifyDownloadToken: 'Waoo verify download token failed',
    sendDownloadLinks: 'Waoo send download links failed',
    verifySmartLinkToken: 'Waoo verify smart link token failed',
    signupUserFailed: 'Waoo signup user failed',
  },
  firebase: {
    shortenUrlFailed: 'Get short url from Firebase failed'
  },
  sso: {
    appleAuthRedirectLink: 'Get Apple Auth redirect link failed',
    google: {
      webRequest: 'Send Google Auth credential failed'
    }
  },
  download: {
    getDesktopDownloadLink: 'Get Desktop Download Link failed',
    getDesktopDownloadLinkEmptyParams: 'Try to get desktop download link without params failed',
  }
}
