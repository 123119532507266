export type ActionType<T, P> = {
  type: T;
  payload: P;
}

export type ErrorType = {
  message: string,
  status: number,
}

export type ErrorsType = Error | string | null;

export enum RequestsStatus {
  Initial = 'Initial',
  Loading = 'Loading',
  Success = 'Success',
  Failed = 'Failed',
}

export interface StoreDataType<D> {
  status: RequestsStatus;
  data: D | null;
  error: ErrorsType;
}

export const DefaultInitialData = {
  status: RequestsStatus.Initial,
  data: null,
  error: null,
};
