import {ApiMethod} from '../../../cypress/support/constants';
import fetch from 'node-fetch';
import {GetDeleteAccountStatusApi, ServicesErrors} from '../../constants';
import {determineErrorLevelForSentry, handleCommonErrorResponse, handleResponse} from '../../utils';
import * as Sentry from '@sentry/browser';
import {History} from 'history';
import {DeleteAccountRequestStatus} from '../../store/actions/cde/cdeTypes';

export function getDeleteAccountStatus(history: History): Promise<{status: DeleteAccountRequestStatus}> {
  const requestOptions = {
    method: ApiMethod.GET,
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  };

  return fetch(GetDeleteAccountStatusApi, requestOptions)
    .then(handleDeleteAccountResponse)
    .catch(error => {
      const errorLevel = determineErrorLevelForSentry(error);

      Sentry.configureScope((scope) => {
        scope.setLevel(errorLevel);
      })

      Sentry.addBreadcrumb({
        category: 'auth',
        message: 'Endpoint GET' + GetDeleteAccountStatusApi,
        data: {
          error: error,
        },
        level: Sentry.Severity.Error
      });
      Sentry.captureMessage(`${ServicesErrors.cde.getDeleteAccountStatus} ${error.message}`, Sentry.Severity.Error);
      return handleCommonErrorResponse(error, history);
    });
}

function handleDeleteAccountResponse(response: any) {
  return response.text().then((text: string) => {
    const data = text && JSON.parse(text);
    
    if (response.status === 404) {
      return {status: data.message}
    }

    if (!response.ok) {
      return Promise.reject({...data, status: response.status});
    }

    return data;
  });
}
