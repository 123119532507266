import {RegisterUserBody} from './userTypes';
import {UserApi, ServicesErrors} from '../../constants';
import fetch from 'node-fetch';
import {handleResponse, determineErrorLevelForSentry, getAffid} from '../../utils';
import * as Sentry from '@sentry/browser';

export async function registration(user: RegisterUserBody): Promise<string> {
  const affid = await getAffid();
  const requestBody = {
    ...user,
    affid: affid || '',
  };
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(requestBody)
  };

  return fetch(UserApi, requestOptions)
    .then(handleResponse)
    .catch(error => {
      const errorLevel = determineErrorLevelForSentry(error);

      Sentry.configureScope((scope) => {
        scope.setLevel(errorLevel);
      })

      Sentry.addBreadcrumb({
        category: 'auth',
        message: 'Endpoint ' + UserApi,
        data: {
          error: error,
          device_id: user.device_id,
        },
        level: errorLevel
      });
      Sentry.captureMessage(`${ServicesErrors.user.registrationUserFailed} ${error.message}`, errorLevel);
      throw new Error(error.message);
    });
}
