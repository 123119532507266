import React, {Component, ErrorInfo} from 'react';
import './ServerErrorHandlingPage.scss';
import * as Sentry from '@sentry/browser';
import Title from '../../components/Title';
import Subtitle from '../../components/Subtitle';
import Button from '../../components/Buttons/Button';
import {withRouter} from 'react-router-dom';
import {Routes} from '../../router/Routes';
import {ServerErrorPageData, ErrorPageMessageMap} from './ServerErrorPageData';
import {AppState} from '../../store/reducers/reducerType';
import {compose} from 'redux';
import {connect} from 'react-redux';
import ContentWrapper from '../../containers/ContentWrapper';
import {changeClassName, resetForgotPasswordError, checkEmailVerificationCodeRequest, scrollTopPage} from '../../store/actions';
import {
  EmailVerificationEvents,
  EmailVerificationInvalidCodeMessage,
  EmailVerificationResendEvents,
  EventContext,
  ApiErrors, ClarioSiteLinks
} from '../../constants';
import {Events} from '../../services/events/events';

type ErrorBoundaryProps = any;

type InnerContentType = {
  title: string,
  description: string,
  button?: {name: string, route: Routes} | null,
  additionalButton?: {name: string, route: Routes} | null,
}

type ErrorBoundaryState = {
  error: string,
  errorInfo: ErrorInfo | null,
  innerContent: InnerContentType,
}

const WithServerErrorHandlingPage = (WrappedComponent: any) => {
  class ServerErrorHandlingPage extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
      super(props);
      this.state = {
        error: '',
        errorInfo: null,
        innerContent: {
          title: 'Something went wrong.',
          description: 'Just get in touch and we\'ll be happy to help you out.',
          button: null,
        },
      };
    }

    determineServerErrorPopupData = (error: string) => {
      if (!error || (error && !error.length)) {
        return ServerErrorPageData.default;
      }

      for (const [key, value] of Object.entries(ServerErrorPageData)) {
        if (error.includes(key)) {
          return value;
        }
      }

      return ServerErrorPageData.default;
    }

    onError = (error: string) => {
      this.props.scrollTopPage();
      this.setState((state) => {
        return {
          ...state,
          error: error,
          errorInfo: (error as any),
          innerContent: this.determineServerErrorPopupData(error),
        }
      });
    };

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
      this.props.changeClassName('page page--error');
      this.props.scrollTopPage();
      // Catch errors in components below and re-render with error message
      this.setState({
        error: error.message,
        errorInfo: errorInfo,
        innerContent: this.determineServerErrorPopupData(error.message),
      })

      Sentry.withScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          // @ts-ignore
          scope.setExtra(key, errorInfo[key]);
        });
        Sentry.captureException(error);
      });
    }

    componentDidUpdate(prevProps: Readonly<ErrorBoundaryProps>, prevState: Readonly<ErrorBoundaryState>, snapshot?: any): void {
      if (prevState.error !== this.state.error) {
        this.sendShowErrorPageEvent();
        this.props.changeClassName('page page--error');
        return;
      }
    }

    startChat = () => {
      window.open(ClarioSiteLinks.contacts, '_blank');
    };

    componentWillUnmount(): void {
      const {innerContent} = this.state;

      if (innerContent.button != null) {
        this.resetErrorState();
      }
    }

    resetErrorState = () => {
      const {error} = this.state;
      const {resetForgotPasswordError, resetCheckEmailVerificationCodeError} = this.props;
      switch (error) {
        case ApiErrors.request_is_already_processing:
          resetForgotPasswordError();
          break;
        case EmailVerificationInvalidCodeMessage:
          this.props.changeClassName('page');
          resetCheckEmailVerificationCodeError();
          break;
        default:
      }
    };

    determineButtonAction = () => {
      const {innerContent} = this.state;
      this.props.history.push(innerContent.button?.route);
      this.props.resetCheckEmailVerificationCodeError();
      this.sendButtonActionEvent();
    };

    findMatchingErrorPageMessage = (): string | undefined => {
      const {error} = this.state;

      return Object.values(ErrorPageMessageMap).find(value => value === error);
    }

    sendShowErrorPageEvent = (): void => {
      switch (this.findMatchingErrorPageMessage()) {
        case ErrorPageMessageMap.emailVerificationInvalidCode:
          Events.send({
            context: EventContext.emailVerification,
            event: EmailVerificationEvents.page_opened__email_verification_unsuccess_error_shown_expired,
          });
          break;
        case ErrorPageMessageMap.sendVerificationEmail:
          Events.send({
            context: EventContext.emailVerification,
            event: EmailVerificationResendEvents.detailed_area__verify_email_resend_unsuccess_error_shown_unexpected,
          });
          break;
        case ErrorPageMessageMap.checkVerificationEmailCodeFailed:
          Events.send({
            context: EventContext.emailVerification,
            event: EmailVerificationEvents.page_opened__email_verification_unsuccess_error_shown_unexpected,
          });
          break;
        default:
      }
    }

    sendButtonActionEvent = () => {
      const {error} = this.state;
      switch (ServerErrorPageData[error]) {
        case ServerErrorPageData.email_verification_invalid_code:
          Events.send({
            context: EventContext.emailVerification,
            event: EmailVerificationEvents.page_opened__email_verification_unsuccess_login_button_click,
          });
          break;
        default:
      }
    }

    determineButton = () => {
      const {innerContent} = this.state;

      if (innerContent.button) {
        return (
          <>
            <Subtitle text={innerContent.description} className='subtitle--error subtitle--error-visible'/>
            <div className='button-wrapper button-wrapper--error button-wrapper--error-visible'>
              <Button
                type='button'
                className='button--primary button--green'
                action={this.determineButtonAction}
                title={innerContent.button.name}/>
            </div>
          </>);
      }

      return (<>
        <Subtitle text={innerContent.description} className='subtitle--error'/>
        <div className='button-wrapper button-wrapper--error'>
          <Button
            type='button'
            className='button--primary button--green'
            action={this.startChat}
            title='Contact us'/>
        </div>
      </>);
    };


    render() {
      const {errorInfo, innerContent} = this.state;
      if (errorInfo) {
        return (
          <ContentWrapper className='container--error-page'>
            <div className='error-page'>
              <Title text={innerContent.title} className='title--form'/>
              {this.determineButton()}
            </div>
          </ContentWrapper>
        );
      }

      // Normally, just render component
      return <WrappedComponent {...this.props} onError={this.onError}/>
    }
  }

  return withRouter(ServerErrorHandlingPage);
};


const mapStateToProps = (state: AppState) => ({
  userInfo: state.user.userInfo,
});

const mapDispatchToProps = (dispatch: any) => ({
  changeClassName: (className: string): void => {
    dispatch(changeClassName(className));
  },
  resetForgotPasswordError: (): void => {
    dispatch(resetForgotPasswordError());
  },
  resetCheckEmailVerificationCodeError: (): void => {
    dispatch(checkEmailVerificationCodeRequest());
  },
  scrollTopPage: (): void => {
    dispatch(scrollTopPage());
  }
});


const withServerErrorHandlingPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  WithServerErrorHandlingPage
);

export default withServerErrorHandlingPage;
