import React, {ReactElement, MouseEvent} from 'react';
import './CheckBox.scss';
import ErrorMessage from '../ErrorMessage';
import {ErrorItem} from '../../utils/formValidationRules';

type CheckBoxProps = {
  name: string,
  label: string,
  errors?: ErrorItem | null,
  clickAction: (ev: MouseEvent<HTMLInputElement>) => void,
  checked?: boolean,
  className?: string

}

const CheckBox = React.forwardRef((props: CheckBoxProps, ref: React.Ref<HTMLInputElement>): ReactElement => {
  const {name, label, errors, className = '', checked = false} = props;
  return (
    <div className={`checkbox-group ${className}`}>
      <label className='checkbox__label-wrapper'>
        <input
          className='checkbox'
          id={name}
          name={name}
          ref={ref}
          type='checkbox'
          tabIndex={2}
          defaultChecked={checked}
          onClick={props.clickAction}
        />
        <span className={`checkbox__label ${props.errors ? 'checkbox__label--errors' : ''}`}/>
        <p className='checkbox__text'>{label}</p>
      </label>
      {errors != null
        ? <ErrorMessage message={(errors as ErrorItem).message} className='checkbox'/>
        : null
      }
    </div>
  );
});

export default CheckBox;
