import React, {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom';
import Title from '../../components/Title';
import '../Forms/Form.scss'
import './SendEmail.scss';
import {EventContext, ForgotPasswordEvents, UserConstants} from '../../constants';
import {Routes} from '../../router/Routes';
import Subtitle from '../../components/Subtitle';
import {Events} from '../../services/events/events';
import {RequestStatus} from '../../store/reducers/reducerType';

type Props = {
  resendEmail: () => void;
  forgotPasswordStatus: RequestStatus;
}
const SendEmail: FunctionComponent<Props> = (props: Props) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const [resend, setResendStatus] = useState(false);

  useEffect(() => {
    let {status, error} = props.forgotPasswordStatus;

    if (status && !error.length && resend) {
      setResendStatus(false);
    }

    if (status && !resend)
      Events.send({
        context: EventContext.forgotPassword,
        event: ForgotPasswordEvents.resetLinkScreen.open,
      });
  }, [props.forgotPasswordStatus]);

  // Clear forgot password status to return next time to forgot password form not send email
  const handleBack = useCallback(
    () => {
      Events.send({
        context: EventContext.forgotPassword,
        event: ForgotPasswordEvents.resetLinkScreen.login,
      });
      history.push(Routes.Login);
      dispatch({type: UserConstants.FORGOT_REQUEST, payload: {resend: false}})
    }, [dispatch]
  );

  const handleResendEmail = () => {
    const {resendEmail} = props;
    if (resend) {
      return;
    }

    Events.send({
      context: EventContext.forgotPassword,
      event: ForgotPasswordEvents.resetLinkScreen.resend,
    });

    setResendStatus(true)
    resendEmail();
  }

  return (
    <div className='send-email'>
      <Title text='Check your inbox' className='title--form'/>
      <Subtitle text="We've just emailed you a link. Follow it to reset your password." className='subtitle--email'/>
      <div className='form__link-wrapper form__link-wrapper--resend'>
        <p className='form__link-description'>{`No reset email from us? No problem. Just hit `}
          <span className='form__link' onClick={handleResendEmail}> resend</span>.
        </p>
      </div>
      <div className='form__link-wrapper form__link-wrapper--send-email-login'>
        <p className='form__link' onClick={handleBack}>Back to Login</p>
      </div>
    </div>
  );
};

export default SendEmail;
