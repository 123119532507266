import {Dispatch} from 'redux';
import {SetWebCbtReduxAction} from './partnerActionsTypes';
import {UserInfo, PartnersActions} from '../../../constants';

export function setWebCbt(webcbt: number | string) {
  return (dispatch: Dispatch<SetWebCbtReduxAction>) => {
    if (webcbt == null) {
      return;
    }
    sessionStorage.setItem(UserInfo.webcbt, webcbt.toString());
    dispatch({type: PartnersActions.SET_WEB_CBT, payload: {webcbt: webcbt.toString()}});
  };
}
