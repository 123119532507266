export const EmailDomain = 'weareclario.com';

export const LogoNameAttribute = 'Clario Logo';

export const FooterNavLinksName = {
  logo: 'logo',
  privacyPolicy: 'privacyPolicy',
  cookie: 'cookie',
  eula: 'eula',
  privacyNotice: 'privacyNotice',
  terms: 'terms',
  refund: 'refund',
  contact: 'contact',
}
