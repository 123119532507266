import React, {FunctionComponent, useEffect, useState} from 'react';
import withServerErrorHandlingPage from '../../hoc/ServerErrorHandlingPage';
import Loader from '../../components/Loader';
import Url from '../../utils/url';
import {RequestStatus} from '../../store/reducers/reducerType';
import TokenExpired from '../DownloadLinks/TokenExpired';
import TokenInvalid from '../DownloadLinks/TokenInvalid';
import {ApiErrors, ServicesErrors} from '../../constants';
import {Redirect} from 'react-router-dom';
import {Routes} from '../../router/Routes';

type Props = {
  verifyDownloadToken: (token: string) => void;
  verifyDownloadLinkStatus: RequestStatus;
  sendDownloadLinksStatus: RequestStatus,
  setWebCbt: (webcbt: number | string) => void;
}

const TokenState: { [key in TokenStateType]: string } = {
  valid: 'valid',
  invalid: 'invalid',
  expired: 'expired'
}

type TokenStateType = 'valid' | 'invalid' | 'expired';

const VerifyDownloadLinks: FunctionComponent<Props> = (props: Props) => {
  const [tokenState, setTokenState] = useState<TokenStateType | null>(null);
  const [resendFailedByTimeout, setResendTimeout] = useState(false);
  const downloadLink = Url.getQueryParamByName(window.location.href, 'link');

  useEffect(() => {
    const queryParams = getQueryParamsFromDownloadLinks();
    if (!queryParams) {
      return;
    }
    const {token, webCbt} = queryParams;

    if (token == null || webCbt == null) {
      return;
    }

    props.verifyDownloadToken(token);
    props.setWebCbt(webCbt);
  }, []);

  useEffect(() => {
    handleSendDownloadLinksStatus();
  }, [props.sendDownloadLinksStatus]);

  const handleSendDownloadLinksStatus = () => {
    const {sendDownloadLinksStatus} = props;
    const {status, error} = sendDownloadLinksStatus;

    if (status) {
      return;
    }

    switch (error) {
      case ApiErrors.sent_download_links_has_max_verify_attempts:
        setResendTimeout(true);
        break;
      case ApiErrors.undefined_error:
        throw new Error(ServicesErrors.waoo.sendDownloadLinks);
      default:

    }
  }

  const getQueryParamsFromDownloadLinks = (): {token: string | null, webCbt: string | null} | null => {
    if (!downloadLink) {
      return null;
    }

    const decodedDownloadLink = decodeURIComponent(downloadLink);
    const queryParams = Url.getAllQueryParams(decodedDownloadLink);
    const tokenQueryParam = queryParams.get('altk');
    const webCbtQueryParam = queryParams.get('web_cbt');

    return {
      token: tokenQueryParam,
      webCbt: webCbtQueryParam
    };

  }

  useEffect(() => {
    const {status} = props.verifyDownloadLinkStatus;

    if (status) {
      setTokenState((TokenState.valid as TokenStateType));
      return;
    }

    determineApiErrors();
  }, [props.verifyDownloadLinkStatus]);

  const determineApiErrors = () => {
    const {error, status} = props.verifyDownloadLinkStatus;

    if (!status && !error.length) {
      return;
    }

    switch (error) {
      case ApiErrors.token_expired:
        setTokenState((TokenState.expired as TokenStateType));
        break;
      case ApiErrors.invalid_token:
        setTokenState((TokenState.invalid as TokenStateType));
        break;
      default:
        throw new Error(ServicesErrors.waoo.verifyDownloadToken);
    }
  };

  const determineView = () => {
    switch (tokenState) {
      case TokenState.valid:
        return (
            // @ts-ignore
            <Redirect to={{
          pathname: Routes.DownloadInstruction,
          state: {downloadLinks: {redirect: downloadLink}}
        }}/>
        );
      case TokenState.expired:
        return <TokenExpired resendLinkFailed={resendFailedByTimeout}/>;
      case TokenState.invalid:
        return <TokenInvalid/>;
      default:
        return <Loader className='loader--page'/>;
    }
  }

  return determineView();
}

export default withServerErrorHandlingPage(VerifyDownloadLinks);
