import {CdeConstants, UserConstants} from '../../../constants';
import {DefaultCdeState} from './constants';
import {CdeStateType} from './types';


export default (state = DefaultCdeState, action: any): CdeStateType => {
  switch (action.type) {
    case CdeConstants.DELETE_ACCOUNT_REQUEST:
      return {
        ...state,
        deleteAccountRequestStatus: {
          status: false,
          error: ''
        },
      };
    case CdeConstants.DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        deleteAccountRequestStatus: {
          status: true,
          error: ''
        },
        deleteAccountStatus: null,
      };
    case CdeConstants.DELETE_ACCOUNT_FAILURE:
      return {
        ...state,
        deleteAccountStatus: null,
        deleteAccountRequestStatus: {
          status: false,
          error: action.payload.message,
        },
      };

    case CdeConstants.CANCEL_DELETE_ACCOUNT_REQUEST:
      return {
        ...state,
        cancelDeleteAccountRequestStatus: {
          status: false,
          error: ''
        }
      };
    case CdeConstants.CANCEL_DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        cancelDeleteAccountRequestStatus: {
          status: true,
          error: ''
        },
        deleteAccountStatus: null,
      };
    case CdeConstants.CANCEL_DELETE_ACCOUNT_FAILURE:
      return {
        ...state,
        deleteAccountStatus: null,
        cancelDeleteAccountRequestStatus: {
          status: false,
          error: action.payload.message,
        },
      };
    case CdeConstants.GET_DELETE_ACCOUNT_STATUS_REQUEST:
      return {
        ...state,
        deleteAccountStatus: null,
        getDeleteAccountStatusRequestStatus: {
          status: false,
          error: ''
        }
      };
    case CdeConstants.GET_DELETE_ACCOUNT_STATUS_SUCCESS:
      return {
        ...state,
        getDeleteAccountStatusRequestStatus: {
          status: true,
          error: ''
        },
        deleteAccountStatus: action.payload.status,
      };
    case CdeConstants.GET_DELETE_ACCOUNT_STATUS_FAILURE:
      return {
        ...state,
        getDeleteAccountStatusRequestStatus: {
          status: false,
          error: action.payload.message,
        },
      };
    case UserConstants.LOGOUT_SUCCESS:
      return DefaultCdeState;
    default:
      return state;
  }
}
