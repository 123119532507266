import {ActionType} from '../actionsTypes';
import {CdeConstants} from '../../../constants/cde';

export enum DeleteAccountRequestStatus {
  newApplication = 'received',
  inProgress = 'in_progress',
  ready = 'ready',
  expired = 'expired',
  done = 'done',
  canceled = 'canceled',
  inQueue = 'in_queue',
  notFound = 'inquiry_not_found',
}

export type DeleteAccountRequestReduxAction = ActionType<typeof CdeConstants.DELETE_ACCOUNT_REQUEST, {}>;
export type DeleteAccountSuccessReduxAction = ActionType<typeof CdeConstants.DELETE_ACCOUNT_SUCCESS, {}>;
export type DeleteAccountFailureReduxAction = ActionType<typeof CdeConstants.DELETE_ACCOUNT_FAILURE, Error>;

export type CancelDeleteAccountRequestReduxAction = ActionType<typeof CdeConstants.CANCEL_DELETE_ACCOUNT_REQUEST, {}>;
export type CancelDeleteAccountSuccessReduxAction = ActionType<typeof CdeConstants.CANCEL_DELETE_ACCOUNT_SUCCESS, {}>;
export type CancelDeleteAccountFailureReduxAction = ActionType<typeof CdeConstants.CANCEL_DELETE_ACCOUNT_FAILURE, Error>;

export type GetDeleteAccountStatusRequestReduxAction = ActionType<typeof CdeConstants.GET_DELETE_ACCOUNT_STATUS_REQUEST, {}>;
export type GetDeleteAccountStatusSuccessReduxAction = ActionType<typeof CdeConstants.GET_DELETE_ACCOUNT_STATUS_SUCCESS, {status: DeleteAccountRequestStatus}>;
export type GetDeleteAccountStatusFailureReduxAction = ActionType<typeof CdeConstants.GET_DELETE_ACCOUNT_STATUS_FAILURE, Error>;
