import React, {Component, ErrorInfo} from 'react';
import * as Sentry from '@sentry/browser';
import './ServerErrorHandlingDeviceItem.scss';
import Button from '../../components/Buttons/Button';
import {AppState} from '../../store/reducers/reducerType';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {formDeviceIdArray} from '../../utils';
import DeviceItemPopup from '../../popups/Modal/Devices/DevicesList/DeviceItemPopup';
import {disconnectDeviceRequest} from '../../store/actions';
import {Events} from '../../services/events/events';
import {ClarioSiteLinks, DevicesEvents, EventContext} from '../../constants';

type ErrorBoundaryProps = any;

type ErrorBoundaryState = {
  error: string,
  errorInfo: ErrorInfo | null,
}

const WithServerErrorHandlingDeviceItem = (WrappedComponent: any) => {
  class ServerErrorHandlingPopup extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
      super(props);
      this.state = {
        error: '',
        errorInfo: null,
      };
    }

    componentDidUpdate(prevProps: Readonly<ErrorBoundaryProps>, prevState: Readonly<ErrorBoundaryState>, snapshot?: any): void {
      if (prevState.error !== this.state.error) {
        this.sendErrorPopupShowEvent();
        return;
      }
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
      // Catch errors in components below and re-render with error message
      this.setState({
        error: error.message,
        errorInfo: errorInfo
      });

      Sentry.withScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          // @ts-ignore
          scope.setExtra(key, errorInfo[key]);
        });
        Sentry.captureException(error);
      });
    }

    componentWillUnmount(): void {
      const {errorInfo} = this.state;

      if (errorInfo !== null) {
        this.setState({error: '', errorInfo: null});
        this.props.resetDisconnectDeviceError(this.props.deviceId);
      }
    }

    sendErrorPopupShowEvent = (): void => {
      Events.send({
        context: EventContext.devices,
        event: DevicesEvents.detailed_area__disconnect_unsuccess_error_shown,
        data: {deviceId: formDeviceIdArray(this.props.currentAssetDevices)}
      })
    };

    sendStartChatEvent = (): void => {
      Events.send({
        context: EventContext.devices,
        event: DevicesEvents.detailed_area__disconnect_unsuccess_error_chat_button_click,
        data: {deviceId: formDeviceIdArray(this.props.currentAssetDevices)}
      })
    };

    startChat = () => {
      this.sendStartChatEvent();
      window.open(ClarioSiteLinks.contacts, '_blank');
    };

    render() {
      if (this.state.errorInfo) {
        return (
          <DeviceItemPopup>
            <h3 className='devices__block-title device-item-error__title'>Uh-oh, we couldn’t disconnect your device...</h3>
            <div className='device-item-popup__text-wrapper device-item-error__text-wrapper'>
              <p className='devices__text'>Just start a chat and we’ll help you out.</p>
            </div>
            <div className='button-wrapper device-item-error__button-wrapper'>
              <Button
                type='button'
                className='button--secondary device-item-error__button'
                action={this.startChat}
                title='Contact us'/>
            </div>
          </DeviceItemPopup>
        );
      }

      // Normally, just render component
      return <WrappedComponent {...this.props} />
    }
  }

  return withRouter(ServerErrorHandlingPopup);
};

const mapStateToProps = (state: AppState) => ({
  userInfo: state.user.userInfo,
  currentAssetDevices: state.device.currentAssetDevices,
});

const mapDispatchToProps = (dispatch: any) => ({
  resetDisconnectDeviceError: (deviceId: string): void => {
    dispatch(disconnectDeviceRequest(deviceId));
  },
});

const withServerErrorHandlingDeviceItem = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  WithServerErrorHandlingDeviceItem
);

export default withServerErrorHandlingDeviceItem;
