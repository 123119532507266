import {History} from 'history';
import {logout} from '../store/actions';
import store from '../store';

type Error = {
  message: string,
  status: number,
}

export default function handleCommonErrorResponse(error: Error, history: History): Promise<string> {
  switch (error.status) {
    case 401:
    case 403:
      let isAuthenticated = store.getState().user.isAuthenticated;

      if (isAuthenticated) {
        store.dispatch((logout(history) as any));
      }
      return Promise.reject({message: error.message});
    default:
      return Promise.reject({message: error.message, status: error.status});
  }
}
