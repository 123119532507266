import React, {useEffect, useState} from 'react';
import {DashboardEvents, EventContext, InnerContentPopups, WebCbt} from '../../../constants';
import DefaultSubscription from './DefaultSubscription';
import WaooSubscription from './WaooSubscription';
import StackCommerceSubscription from './StackCommerceSubscription';
import {Events} from '../../../services/events/events';
import {Asset} from '../../../services/assets/assetsTypes';
import Loader from '../../../components/Loader';
import {useHistory} from 'react-router-dom';
import {Routes} from '../../../router/Routes';
import {ModalContentType} from '../../../popups/Modal/ModalTypes';
import {useDispatch, useSelector} from 'react-redux';
import {selectWebCbt} from '../../../store/reducers/partner';
import {selectCurrentAsset} from '../../../store/reducers/assets';
import {determineBuyNowLink, openModal} from '../../../store/actions';

export type SubscriptionSectionStatus = {
  name: string,
  active: boolean,
};

export type SubscriptionSectionConsumerContext = {
  sendManageSubscriptionEvent: (event: DashboardEvents) => void;
  currentAsset: Asset;
  openAssetModal: (type?: InnerContentPopups) => void;
  redirectToBuyNow: () => void;
  isSubscriptionGifted: () => boolean;
}

export const SubscriptionSectionContext = React.createContext<Partial<SubscriptionSectionConsumerContext>>({});

export function useSubscriptionSectionContext() {
  const context = React.useContext(SubscriptionSectionContext);

  if (!context) {
    throw new Error(
      'Subscription Compound Components must be rendered within the Subscription Consumer',
    )
  }
  return context;
}

const Subscription = () => {
  const history = useHistory();
  const webcbt = useSelector(selectWebCbt);
  const currentAsset = useSelector(selectCurrentAsset);
  const dispatch = useDispatch();
  const [needToOpenPopup, setNeedToOpenPopup] = useState(false);

  const sendManageSubscriptionEvent = (event: DashboardEvents) => {
    Events.send({
      context: EventContext.mainPage,
      event: event,
      data: {subscriptionId: currentAsset?.pg_subscription_id}
    });
  }

  useEffect(() => {
    handleOpenPopupFromDirectLink();
  }, [history.location.state]);

  const handleOpenPopupFromDirectLink = () => {
    const locationState: any = history.location.state;

    if (locationState == null || !Object.keys(locationState).length || locationState.modalPopup == null) {
      return;
    }

    if (currentAsset == null || !Object.keys(currentAsset).length) {
      return;
    }

    // Check correct content type
    if (locationState.modalPopup.contentType !== ModalContentType.subscription) {
      return;
    }

    if (webcbt == WebCbt.clario && isSubscriptionGifted()) {
      return;
    }

    setNeedToOpenPopup(true);
  }

  const redirectToBuyNow = async () => {
    Events.send({context: EventContext.mainPage, event: DashboardEvents.subscription_area__subscribe_button_click_to_buynow});
    const link = determineBuyNowLink(currentAsset);
    window.open(link);
  }

  const redirectTo = (route: Routes) => {
    if (!route) {
      return;
    }
    history.push(route);
  }

  const openAssetModal = (type = InnerContentPopups.asset) => {
    dispatch(openModal(ModalContentType.subscription, type));
  }

  // Handle gifted subscription - in Kbill there will be neither a subscription, nor payment
  // From back will receive asset with  pg_subscription_id - empty string
  const isSubscriptionGifted = (): boolean => {
    if (currentAsset == null || !Object.keys(currentAsset).length) {
      return false;
    }

    return !currentAsset.is_trial && (currentAsset.payment_gateway == null || !currentAsset.payment_gateway.length);
  };

  const determineSubscriptionSectionPartnerType = () => {
    switch (webcbt) {
      case WebCbt.waoo:
        return <WaooSubscription/>
      case WebCbt.stackCommerce:
        return <StackCommerceSubscription/>
      default:
        return <DefaultSubscription/>
    }
  }

  if (needToOpenPopup) {
    openAssetModal();
  }

  if (!currentAsset) {
    return (
      <section className='section slide__section'>
        <Loader/>
      </section>
    )
  }
  return (
    <SubscriptionSectionContext.Provider value={{
      sendManageSubscriptionEvent,
      currentAsset: currentAsset,
      redirectToBuyNow: redirectToBuyNow,
      openAssetModal: openAssetModal,
      isSubscriptionGifted: isSubscriptionGifted,
    }}>
      {determineSubscriptionSectionPartnerType()}
    </SubscriptionSectionContext.Provider>)
};

export default React.memo(Subscription);
