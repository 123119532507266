import React, {FunctionComponent, Suspense} from 'react';
import {InnerContentPopups} from '../../../constants';
import {useModalPopupContext} from '../ModalWrapper/ModalPopupWrapper';
import ModalContentWrapper from '../ModalContentWrapper';

const ChangePasswordForm = React.lazy(() => import('./ChangePasswordForm'));
const ChangeUserNameForm = React.lazy(() => import('./ChangeUserNameForm'));
const Profile = React.lazy(() => import('./Profile'));
const Congratulation = React.lazy(() => import('../Shared/Congratulation'));
const DeleteAccount = React.lazy(() => import('./DeleteAccount/DeleteAccount'));
const DeleteAccountSuccessful = React.lazy(() => import('./DeleteAccount/DeleteAccountSuccessful'));
const CancelDeleteAccount = React.lazy(() => import('./CancelDeleteAccount/CancelDeleteAccount'));

const ProfileConductor: FunctionComponent = () => {
  const {modalPopup} = useModalPopupContext();

  const determinePopup = () => {
    switch (modalPopup?.innerContentPopup) {
      case InnerContentPopups.profile:
        return <Profile/>;
      case InnerContentPopups.changePasswordForm:
        return <ChangePasswordForm/>;
      case InnerContentPopups.changeUserNameForm:
        return <ChangeUserNameForm/>;
      case InnerContentPopups.congratulationChangeUserName:
        return <Congratulation type={InnerContentPopups.congratulationChangeUserName}/>;
      case InnerContentPopups.congratulationChangePassword:
        return <Congratulation type={InnerContentPopups.congratulationChangePassword}/>;
      case InnerContentPopups.deleteAccountForm:
        return <DeleteAccount/>;
      case InnerContentPopups.deleteAccountSuccessful:
        return <DeleteAccountSuccessful/>;
      case InnerContentPopups.cancelDeleteAccountForm:
        return <CancelDeleteAccount/>;
      case InnerContentPopups.congratulationCancelDeleteAccount:
        return <Congratulation type={InnerContentPopups.congratulationCancelDeleteAccount}/>;
      default:
        return null;
    }
  }

  return (
    <ModalContentWrapper>
      <Suspense fallback={null}>
        {determinePopup()}
      </Suspense>
    </ModalContentWrapper>
  )
}

export default ProfileConductor;
