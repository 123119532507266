import {History} from 'history';
import {Dispatch} from 'redux';
import {RenewSubscriptionFailureReduxAction, RenewSubscriptionRequestReduxAction, RenewSubscriptionSuccessReduxAction} from './assetsActionsTypes';
import {assetsService} from '../../../services/assets/assets';
import {AssetsConstants} from '../../../constants';
import {getAssetsList} from './assetsActions';

export function renewSubscription(assetId: string, history: History) {
  return (dispatch: Dispatch<RenewSubscriptionRequestReduxAction | RenewSubscriptionSuccessReduxAction | RenewSubscriptionFailureReduxAction | any>) => {
    dispatch(request());
    return assetsService.renewSubscription(assetId, history)
      .then(
        () => {
          dispatch(success());
          dispatch(getAssetsList(history));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request(): RenewSubscriptionRequestReduxAction {
    return {type: AssetsConstants.RENEW_SUBSCRIPTION_REQUEST, payload: {}}
  }

  function success(): RenewSubscriptionSuccessReduxAction {
    return {type: AssetsConstants.RENEW_SUBSCRIPTION_SUCCESS, payload: {}}
  }

  function failure(error: Error): RenewSubscriptionFailureReduxAction {
    return {type: AssetsConstants.RENEW_SUBSCRIPTION_FAILURE, payload: error}
  }
}

export function resetRenewSubscriptionError() {
  return (dispatch: Dispatch<RenewSubscriptionRequestReduxAction>) => {
    dispatch({type: AssetsConstants.RENEW_SUBSCRIPTION_REQUEST, payload: {}});
  }
}
