import React, {FunctionComponent, ReactNode} from 'react';
import './Circle.scss';

interface Props {
  children: ReactNode;
  active?: boolean,
  className?: string;
  style?: {
    width?: string,
    height?: string,
  }
}

const Circle: FunctionComponent<Props> = ({children, className = '', active = true, style}) => {
  return (
    <div className={`circle__wrapper ${className}`}>
      <div className={`circle ${!active ? 'circle--inactive' : ''}`} style={{...style}}>
        <div className='circle__text'>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Circle;
