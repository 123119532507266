import * as React from 'react';
import './DesktopPlatforms.scss';

const DesktopPlatforms = () => {
    return (
        <div className="device-download__platforms">
            <div className="device-download__platform">
                <img src='/images/platforms/logo-windows.svg' alt='Windows icon'
                     className='device-download__platform-icon' />
                <p className='device-download__text'>Windows</p>
            </div>
            <div className="device-download__platform">
                <img src='/images/platforms/logo-ios-desktop.svg' alt='macOs icon'
                     className='device-download__platform-icon' />
                <p className='device-download__platform-text'>macOS</p>
            </div>
        </div>
    );
}

DesktopPlatforms.displayName = 'DesktopPlatforms';
export default DesktopPlatforms;