import {Dispatch} from 'redux';
import {History} from 'history';
import {
  SendEmailVerificationRequestReduxAction,
  SendEmailVerificationSuccessReduxAction,
  SendEmailVerificationFailureReduxAction,
  CheckVerificationEmailCodeRequestReduxAction,
  CheckVerificationEmailCodeSuccessReduxAction,
  CheckVerificationEmailCodeFailureReduxAction
} from './emailVerificationActionsTypes';
import {emailVerificationService} from '../../../services/emailVerification/emailVerification';
import {EmailVerification} from '../../../constants';

export function sendVerificationEmail(history: History) {
  return (dispatch: Dispatch<SendEmailVerificationRequestReduxAction | SendEmailVerificationSuccessReduxAction | SendEmailVerificationFailureReduxAction>) => {
    dispatch(sendVerificationEmailRequest());
    return emailVerificationService.sendVerificationEmail(history)
      .then(() => {
          dispatch(success());
        },
        (error: Error) => {
          dispatch(failure(error));
        }
      );

    function success(): SendEmailVerificationSuccessReduxAction {
      return {type: EmailVerification.SEND_EMAIL_VERIFICATION_SUCCESS, payload: {}}
    }

    function failure(error: Error): SendEmailVerificationFailureReduxAction {
      return {type: EmailVerification.SEND_EMAIL_VERIFICATION_FAILURE, payload: error}
    }
  }
}

export function sendVerificationEmailRequest(): SendEmailVerificationRequestReduxAction {
  return {type: EmailVerification.SEND_EMAIL_VERIFICATION_REQUEST, payload: {}}
}

export function checkEmailVerificationCode(verificationCode = '', history: History) {
  return (dispatch: Dispatch<CheckVerificationEmailCodeRequestReduxAction | CheckVerificationEmailCodeSuccessReduxAction | CheckVerificationEmailCodeFailureReduxAction>) => {
    dispatch(checkEmailVerificationCodeRequest());

    emailVerificationService.checkVerificationEmailCode(verificationCode, history)
      .then(
        () => {
          dispatch(success());
        },
        error => {
          dispatch(failure(error));
        }
      );

    function success(): CheckVerificationEmailCodeSuccessReduxAction {
      return {type: EmailVerification.CHECK_VERIFICATION_EMAIL_CODE_SUCCESS, payload: {}}
    }

    function failure(error: Error): CheckVerificationEmailCodeFailureReduxAction {
      return {type: EmailVerification.CHECK_VERIFICATION_EMAIL_CODE_FAILURE, payload: error}
    }
  }
}

export function checkEmailVerificationCodeRequest(): CheckVerificationEmailCodeRequestReduxAction {
  return {type: EmailVerification.CHECK_VERIFICATION_EMAIL_CODE_REQUEST, payload: {}}
}
