import React, {FunctionComponent} from 'react';
import {DownloadLinkCircleType} from '../../DownloadLinkCircle';

type Props = {
  handleContactSupport: (from: DownloadLinkCircleType) => void;
}

const InvalidToken: FunctionComponent<Props> = (props: Props) => {
  return (
    <div className='download-link-circle__content-wrapper download-link-circle__content-wrapper--invalid-token'>
      <div className='download-link-circle__content'>
        <h3 className='download-link-circle__title'>Ouch! This link is broken.</h3>
        <p className='download-link-circle__text'>Let's close this page and retry.</p>
        <footer className='download-link-circle__footer download-link-circle-footer'>
          <div className='download-link-circle-footer__content'>
            <div className='download-link-circle__text'>{`Keep following broken links? `}
              <p className='download-link-circle__text download-link-circle__text--bold download-link-circle__text--support'
                 onClick={() => props.handleContactSupport(DownloadLinkCircleType.invalidToken)}>Ask us </p>
              for the right one.
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default InvalidToken;
