import {connect} from 'react-redux';
import VerifyDownloadLinks from './VerifyDownloadLinks';
import {AppState, RequestStatus} from '../../store/reducers/reducerType';
import {verifyDownloadToken, setWebCbt} from '../../store/actions';

type StateType = {
  verifyDownloadLinkStatus: RequestStatus,
  sendDownloadLinksStatus: RequestStatus,
}

const mapStateToProps = (state: AppState): StateType => ({
  verifyDownloadLinkStatus: state.partner.verifyDownloadLinkStatus,
  sendDownloadLinksStatus: state.partner.sendDownloadLinksStatus,
});

const mapDispatchToProps = (dispatch: any) => ({
  verifyDownloadToken: (token: string): void => {
    dispatch(verifyDownloadToken(token));
  },
  setWebCbt: (webcbt: number | string): void => {
    dispatch(setWebCbt(webcbt));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)((VerifyDownloadLinks as any));
