import React, {FunctionComponent, ReactElement, Suspense} from 'react';
import {UserInfo} from '../../../../store/reducers/reducerType';
import {Asset} from '../../../../services/assets/assetsTypes';
import {DeactivatedStatusFromApi, FinishedStatusFromApi} from '../../../../constants';
import {DeleteAccountRequestStatus} from '../../../../store/actions/cde/cdeTypes';
import {EnrichedDevice} from '../../../../services/device/deviceTypes';

type Props = {
  userInfo: UserInfo;
  currentAsset: Asset | null,
  deleteAccountStatus: null | DeleteAccountRequestStatus;
  currentAssetDevices: Array<EnrichedDevice> | null;
}

const UnverifiedEmail = React.lazy(() => import('../UnverifiedEmail'));
const NoSubscription = React.lazy(() => import('../NoSubscription'));
const FullySetup = React.lazy(() => import('../FullySetup'));
const Canceled = React.lazy(() => import('../Canceled'));
const FinishedTrial = React.lazy(() => import('../FinishedTrial'));
const Finished = React.lazy(() => import('../Finished'));
const DeleteAccountInProgress = React.lazy(() => import('../DeleteAccountInProgress'));
const NoDevicesConnected = React.lazy(() => import('../NoDevicesConnected'));

// Greeting priority
// 1. Account removal requested
// 2. No subscription yet
// 3. Trial subscription ended
// 4. Subscription = canceled
// 5. Subscription = finished
// 6. (Not handled yet) Subscription = refunded
// 7. No devices connected
// 8. Email is not verified
// 9. Subscription = active

const GreetingDefault: FunctionComponent<Props> = ({userInfo, currentAsset, deleteAccountStatus, currentAssetDevices}) => {
  const determineGreetingType = (): ReactElement | null => {

    if (currentAsset == null || currentAssetDevices == null) {
      return null;
    }

    // 1. Account removal requested
    if (deleteAccountStatus === DeleteAccountRequestStatus.newApplication || deleteAccountStatus === DeleteAccountRequestStatus.inQueue) {
      return <DeleteAccountInProgress/>
    }

    // 2. No subscription yet
    if (!Object.keys(currentAsset).length) {
      return <NoSubscription/>;
    }

    // 3. Trial subscription ended
    if (currentAsset.status === FinishedStatusFromApi && currentAsset.is_trial) {
      return <FinishedTrial/>;
    }

    // 4. Subscription = canceled
    if (currentAsset.status === DeactivatedStatusFromApi) {
      return <Canceled/>;
    }

    // 5. Subscription = finished
    if (currentAsset.status === FinishedStatusFromApi) {
      return <Finished/>;
    }

    // 7. No devices connected
    if (!currentAssetDevices.length) {
      return <NoDevicesConnected/>;
    }

    // 8. Email is not verified
    if (!userInfo.is_email_verified) {
      return <UnverifiedEmail/>;
    }

    // 9. Subscription = active
    return <FullySetup/>;
  }

  return (
    <Suspense fallback={null}>
      {determineGreetingType()}
    </Suspense>
  )
}
export default React.memo(GreetingDefault);
