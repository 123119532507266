import React, {FunctionComponent} from 'react';
import Copyright from '../../../../components/Copyright';
import {WebCbtFlowNames} from '../../../../constants';

type Props = {
  webcbt: string
}

const OnlyCopyright: FunctionComponent<Props> = ({webcbt}) => {
  return (
    <footer className={`footer footer--${WebCbtFlowNames[webcbt]}`}>
      <div className='footer__container container'>
        <Copyright/>
      </div>
    </footer>
  )
};

export default React.memo(OnlyCopyright);
