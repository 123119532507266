import React, {FunctionComponent} from 'react';
import Section from '../../../../../../containers/Section';
import {AssetsStatusesMap, DashboardEvents, IconType} from '../../../../../../constants';
import {useSubscriptionSectionContext} from '../../../Subscription';
import {SectionNames} from '../../../../../../containers/Section/Section';

const ActiveSubscription: FunctionComponent = () => {
  const {currentAsset, openAssetModal, sendManageSubscriptionEvent} = useSubscriptionSectionContext();

  if (!currentAsset || !openAssetModal || !sendManageSubscriptionEvent) {
    return null;
  }

  const handleManageSubscriptions = () => {
    sendManageSubscriptionEvent(DashboardEvents.subscription_area__manage_button_click_to_popup);
    openAssetModal();
  };
  return (
    <Section
      name={SectionNames.subscription}
      title='Subscription'
      iconType={IconType.subscription}
      status={{name: AssetsStatusesMap.active, active: true}}
      description='Here you can review details about your Clario subscription'
      sectionInfo={{text: 'Your subscription is provided by Waoo'}}
      button={{title: 'Review details', action: handleManageSubscriptions}}
    />
  );
}
export default ActiveSubscription;
