import React, {FunctionComponent} from 'react';
import {ListItemType} from '../../HeaderNavList/HeaderNavList';

const SubMenuItem: FunctionComponent<ListItemType> = ({name, link, ariaLabel, text}) => {
  return (
    <li className='sub-menu__item'>
      <a target='_blank'
         data-name={name}
         href={link}
         className='sub-menu__link'
         aria-label={ariaLabel}
         rel='noopener'>
        {text}
      </a>
    </li>
  );
}

export default SubMenuItem;
