import fetch from 'node-fetch';
import {ServicesErrors, GetAutoLoginParamsApi} from '../../constants';
import {handleResponse, determineErrorLevelForSentry, handleCommonErrorResponse} from '../../utils';
import * as Sentry from '@sentry/browser';
import {History} from 'history';

export interface AutoLoginParamsInterface {
    affid: string | null;
    altk: string;
    cbt: number;
    webCbt?: number;
}

export function getAutoLoginParams(history: History): Promise<AutoLoginParamsInterface> {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
    };
    return fetch(GetAutoLoginParamsApi, requestOptions)
        .then(handleResponse)
        .catch(error => {
            const errorLevel = determineErrorLevelForSentry(error);
            Sentry.configureScope((scope) => {
                scope.setLevel(errorLevel);
            });

            Sentry.addBreadcrumb({
                category: 'user',
                message: 'Endpoint ' + GetAutoLoginParamsApi,
                data: {
                    error: error,
                },
                level: errorLevel,
            });
            Sentry.captureMessage(ServicesErrors.user.getAutoLoginParams, errorLevel);
            return handleCommonErrorResponse(error, history);
        });
}
