import React, {FunctionComponent, SyntheticEvent, useEffect, useState} from 'react';
import './GdprBanner.scss';
import Cookies from '../../utils/cookies';
import {CookiesKeys, CookiesBannerEventsTypes, CookiesBannerEvents, EventContext, GdprBannerKeys, ClarioSiteLinks} from '../../constants';
import {Events} from '../../services/events/events';

const GdprBannerEventsTriggerElements: {[key: string]: CookiesBannerEventsTypes} = {
  cookiePolicy: 'cookiePolicy',
  close: 'close',
  agree: 'agree',
  disagree: 'disagree',
}

const GdprBanner: FunctionComponent = () => {
  const [shouldShow, changeShouldShow] = useState(false);
  // Check if need to hide GdprBannerKeys
  // for local development - to hide banner - just set GdprBannerKeys.hideLocally to local storage with false value
  const preventConsentFromStorage = localStorage.getItem(GdprBannerKeys.hideLocally);
  var closeBannerEvent = new Event('closeGdprBanner');
  useEffect(() => {
    const consentValue = Cookies.getCookie(CookiesKeys.consent_disagree);

    if (consentValue == null) {
      changeShouldShow(true);
    }

  }, []);

  if (!shouldShow || preventConsentFromStorage === 'false') {
    return null;
  }

  const handleClose = (ev: SyntheticEvent) => {
    document.dispatchEvent(closeBannerEvent);
    Cookies.setCookie(CookiesKeys.consent_disagree, false);
    changeShouldShow(false);
    sendCookiesBannerEvents(ev);
  };

  const handleAccept = (ev: SyntheticEvent) => {
    document.dispatchEvent(closeBannerEvent);
    Cookies.setCookie(CookiesKeys.consent_disagree, true);
    changeShouldShow(false);
    sendCookiesBannerEvents(ev);
  };

  const handleReject = (ev: SyntheticEvent) => {
    document.dispatchEvent(closeBannerEvent);
    Cookies.setCookie(CookiesKeys.consent_disagree, true);
    changeShouldShow(false);
    sendCookiesBannerEvents(ev);
  };

  const handleReadCookiePolicy = (ev: SyntheticEvent) => {
    sendCookiesBannerEvents(ev);
  }

  const sendCookiesBannerEvents = (ev: SyntheticEvent) => {
    const element: any = ev.target;
    if (!element) {
      return;
    }
    const eventType: CookiesBannerEventsTypes = element.dataset.name;

    if (!eventType) {
      return;
    }

    if (CookiesBannerEvents[eventType]) {
      Events.send({
        context: EventContext.cookiesBanner,
        event: CookiesBannerEvents[eventType],
      });
    }
  }

  return (
    <div className='gdpr-banner'>
      <form className='gdpr-banner__content'>
        <p className='gdpr-banner__text'>
          Hi there! Clario uses cookies to give you the best online experience. If you're feeling curious and you'd like to know more, please take a look at our
          <a
            data-name={GdprBannerEventsTriggerElements.cookiePolicy}
            href={ClarioSiteLinks.cookiePolicy}
            className='gdpr-banner__link text__link'
            aria-label='Cookie Policy'
            rel='noopener'
            target='_blank'
            onClick={handleReadCookiePolicy}
          > Cookie Policy</a>.
        </p>
        <div className='gdpr-banner__controls'>
          <button data-name={GdprBannerEventsTriggerElements.disagree} className='gdpr-banner__button gdpr-banner__button--transparent' onClick={handleAccept}>Disagree</button>
          <button data-name={GdprBannerEventsTriggerElements.agree} className='gdpr-banner__button' onClick={handleClose}>Agree</button>
        </div>
        <div data-name={GdprBannerEventsTriggerElements.close} className='gdpr-banner__close' onClick={handleClose}/>
      </form>
    </div>
  );
};

export default GdprBanner;
