import React, {FunctionComponent, useEffect} from 'react';
import withServerErrorHandlingPage from '../../hoc/ServerErrorHandlingPage';
import ContentWrapper from '../../containers/ContentWrapper';
import LoginForm from '../../containers/Forms/Login';
import {Events} from '../../services/events/events';
import {EventContext, LoginEvents} from '../../constants/events';
import {LoginPageConfig} from './LoginConfigs';
import {WebCbt, WebCbtFlowNames, UserInfo} from '../../constants';
import {capitalizeFirstLetter, fullFillStringWithPlaceholder} from '../../utils';
import {getUserSessionData} from '../..//store/actions';
import {PartnerName} from '../../services/user/userTypes';
import SSOWrapper from '../../containers/SSOWrapper';

type Props = {
  onError: (error: string) => void;
}

const Login: FunctionComponent<Props> = ({onError}) => {
  let webcbt: string = getUserSessionData(UserInfo.webcbt) || WebCbt.clario;
  let partnerName: string = getUserSessionData(UserInfo.partnerName) || PartnerName.clario;
  let loginPageConfig = LoginPageConfig[WebCbtFlowNames[webcbt]] || LoginPageConfig[WebCbtFlowNames[WebCbt.clario]];

  useEffect(() => {
    Events.send({
      context: EventContext.login,
      event: LoginEvents.open,
    });
  }, []);

  const setErrorMessage = (error: string) => {
    if (error.length) {
      onError(error);
    }
  }

  return (
    <ContentWrapper
      aside={{
        title: loginPageConfig.title.text,
        titleClassName: loginPageConfig.title.className,
        subtitle: fullFillStringWithPlaceholder(loginPageConfig.subtitle, capitalizeFirstLetter(partnerName)),
        fullHeight: true,
      }}>
      <div className='content-form__wrapper'>
        <SSOWrapper/>
        <LoginForm setErrorMessage={setErrorMessage}/>
      </div>
    </ContentWrapper>
  );
}

export default withServerErrorHandlingPage(Login);
