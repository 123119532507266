import {ClarioSiteLinks} from '../../../constants/links';

export const HeaderNavLinksName = {
  logo: 'logo',
  about: 'about',
  product: 'product',
  pricing: 'pricing',
  faq: 'faq',
  blog: 'blog',
  account: 'account',
  mobileSecurity: 'mobile_security',
  logout: 'logout',
}

export const DefaultNavListItems = [HeaderNavLinksName.product, HeaderNavLinksName.pricing, HeaderNavLinksName.about, HeaderNavLinksName.blog, HeaderNavLinksName.account, HeaderNavLinksName.logout];

export const NavListItemsConfig = [
  {
    name: 'product',
    text: 'Product.',
    subMenu: [
      {
        name: 'product_overview',
        link: ClarioSiteLinks.product,
        ariaLabel: 'Product overview',
        text: 'Product overview',
      },
      {
        name: 'mobile_security',
        link: ClarioSiteLinks.mobileSecurity,
        ariaLabel: 'Mobile Security',
        text: 'Mobile security',
      },
      {
        name: 'secure_browsing',
        link: ClarioSiteLinks.secureBrowsing,
        ariaLabel: 'Clario on web',
        text: 'Clario on web',
      },
    ]
  },
  {
    name: 'about',
    text: 'About.',
    subMenu: [
      {
      name: 'about_us',
      link: ClarioSiteLinks.about,
      ariaLabel: 'About us',
      text: 'About us',
    },
      {
        name: 'faq',
        link: ClarioSiteLinks.faq,
        ariaLabel: 'FAQ',
        text: 'FAQ',
      },
      {
        name: 'contact',
        link: ClarioSiteLinks.contacts,
        ariaLabel: 'Contact',
        text: 'Contact',
      },
    ]
  },
  {
    name: 'pricing',
    link: ClarioSiteLinks.pricing,
    ariaLabel: 'Pricing Page',
    text: 'Pricing.',
  },
  {
    name: 'faq',
    link: ClarioSiteLinks.faq,
    ariaLabel: 'FAQ Page',
    text: 'FAQ.',
  },
  {
    name: 'mobile_security',
    link: ClarioSiteLinks.mobileSecurity,
    ariaLabel: 'Mobile Security',
    text: 'Mobile Security.',
  },
  {
    name: 'blog',
    link: ClarioSiteLinks.blog,
    ariaLabel: 'Blog Page',
    text: 'Blog.',
  },
  {
    name: 'account',
    text: ['Sign in.', 'Account.']
  },
  {
    name: 'logout',
    text: 'Log out'
  }
];
