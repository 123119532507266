import fetch from 'node-fetch';
import {ApiErrors, ServicesErrors, VerifySmartLinkTokenApi} from '../../constants';
import {determineErrorLevelForSentry, handleResponse} from '../../utils';
import * as Sentry from '@sentry/browser';

export type VerifyLinkTokenResponse = {
  partner_name: string,
  affid_uuid: string,
}

export function verifySmartLinkToken(token: string): Promise<VerifyLinkTokenResponse> {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    credentials: 'include',
  };

  return fetch(VerifySmartLinkTokenApi, requestOptions)
    .then(handleResponse)
    .catch((error) => {
      const errorLevel = determineErrorLevelForSentry(error);

      if (error.status === 403) {
        return Promise.reject({message: ApiErrors.smart_link_used, ...error});
      }

      Sentry.configureScope((scope) => {
        scope.setLevel(errorLevel);
      });

      Sentry.addBreadcrumb({
        category: 'auth',
        message: 'Endpoint ' + VerifySmartLinkTokenApi,
        data: {
          error: error,
          requestOptions,
        },
        level: errorLevel
      });
      Sentry.captureMessage(ServicesErrors.waoo.verifySmartLinkToken, errorLevel);
      throw new Error(error.message);
    });
}
