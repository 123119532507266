export enum DownloadLinksEvents {
  page_opened = 'page_opened',
  resend_button_click = 'resend_button_click',
  email_sent_shown = 'email_sent_shown',
  contact_chat_click = 'contact_chat_click',
  link_broken_shown = 'link_broken_shown',
  download_instruction_show = 'download_instruction_show',
  email_sent_before_shown = 'email_sent_before_shown',
}

export const ContactSupportFromEventParamsMap = {
  invalidToken: 'LinkBroken',
  requestTimout: 'EmailWasSentBefore',
  tokenExpired: 'DownloadLinkExpired',
  linkSend: 'EmailSent',
}

export const MKClarioDownload = {
  open: 'MKClario_download_page_opened',
  download: 'MKClario_download_button_click'
}
