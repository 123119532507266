import React, {FunctionComponent, useEffect, useState} from 'react';
import {RequestStatus} from '../../store/reducers/reducerType';
import {ForgotPasswordUserBody} from '../../services/user/userTypes';
import withServerErrorHandlingPage from '../../hoc/ServerErrorHandlingPage';
import {useHistory} from 'react-router-dom';
import {History} from 'history';
import ContentWrapper from '../../containers/ContentWrapper';
import ForgotForm from '../../containers/Forms/Forgot';
import SendEmail from '../../containers/SendEmail';
import {Events} from '../../services/events/events';
import {EventContext, ForgotPasswordEvents} from '../../constants/events';

type Props = {
  forgotPasswordStatus: RequestStatus,
  forgotPasswordInfo: ForgotPasswordUserBody | null,
  forgot: (userData: ForgotPasswordUserBody, history: History, resend?: boolean) => void;
  resetForgotPasswordErrors: () => void;
  onError: (error: string) => void;
};

const Forgot: FunctionComponent<Props> = (props: Props) => {
  const history = useHistory();
  const {forgotPasswordStatus, forgotPasswordInfo, forgot} = props;

  useEffect(() => {
    Events.send({
      context: EventContext.forgotPassword,
      event: ForgotPasswordEvents.open,
    })
    return () => {
      props.resetForgotPasswordErrors();
    }
  }, []);

  const resendEmail = () => {
    if (forgotPasswordInfo == null) {
      return;
    }

    forgot(forgotPasswordInfo, history, true);
  };

  const setErrorMessage = (error: string) => {
    if (error.length) {
      props.onError(error);
    }
  }

  return (
    <ContentWrapper
      aside={{
        title: 'Did you forget your password?',
        titleClassName: 'title--break-words-forgot',
        subtitle: 'No worries. We’ll email you a link to reset it.',
        fullHeight: true,
      }}>
      {forgotPasswordStatus.status
        ? <SendEmail resendEmail={resendEmail} forgotPasswordStatus={forgotPasswordStatus}/>
        : <ForgotForm setErrorMessage={setErrorMessage}/>
      }
    </ContentWrapper>
  );
};

export default withServerErrorHandlingPage(Forgot);
