import Url from './url';

export enum TargetsElement {
  Row = 'row',
  Word = 'word',
  TreePartLine = 'treePartLine',
}

const defaultAnimateOptions = {
  className: '.animate',
  targets: TargetsElement.Row,
};

type AnimateOptions = {
  className?: string,
  targets: TargetsElement,
}

// animate for each container that has target class (specified in options) will get all elements
// and prepare for animation through wrapping them into spans with specific class
// it also received different strategies for animation (TargetsElement)
// Allowed TargetsElement:
//   - treePartLine (divide each row into three parts and animate them separately)
//   - word (animate each word in a row)
//   - row (animate whole row)
//
export default function animate(options?: AnimateOptions): void {
  const animateOptions = determineAnimationOptions(options);

  const {className, targets} = animateOptions;

  const animateElements = document.querySelectorAll(className);

  if (!animateElements.length) {
    return;
  }

  // Process Area
  for (let animateElement of animateElements) {
    const dataDelayValue = animateElement.getAttribute('data-delay');
    const delay = dataDelayValue ? Number(dataDelayValue) : 0;
    const words = (animateElement as HTMLElement).innerText.split(' ');
    animateElement.innerHTML = '';

    for (let word of words) {
      animateElement.innerHTML += '<span class="word">' + word + '</span> ';
    }

    const wordNodes = animateElement.querySelectorAll('.word');

    let time = delay + 100;

    chooseAnimationTargets(wordNodes, time, (targets as TargetsElement));
  }
}

function determineAnimationOptions(options?: AnimateOptions) {
  const animationTargetFromUrl = Url.getQueryParamByName(window.location.href, 'animationTarget');

  if (animationTargetFromUrl == null) {
    return {
      ...defaultAnimateOptions,
      ...options
    };
  }

  return {
    ...defaultAnimateOptions,
    targets: animationTargetFromUrl,
  };
}

function prepareArrayOfRows(wordNodes: NodeListOf<Element>) {
  let pos = 0;
  let i = 0;
  let arrayOfRows = [];

  for (let node of wordNodes) {
    if (pos === 0) {
      pos = (node as HTMLElement).offsetTop;
      arrayOfRows.push([node]);
      continue;
    }

    if (pos !== (node as HTMLElement).offsetTop) {
      i++;
      pos = (node as HTMLElement).offsetTop;
      arrayOfRows.push([node]);
      continue;
    }

    if (pos === (node as HTMLElement).offsetTop) {
      arrayOfRows[i].push(node);
    }
  }

  return arrayOfRows;
}

function chooseAnimationTargets(wordNodes: NodeListOf<Element>, time: number, targets: TargetsElement): void {
  const arrayOfRows = prepareArrayOfRows(wordNodes);

  switch (targets) {
    case TargetsElement.Word:
      calculateWordAnimationDelay(arrayOfRows, time);
      break;
    case TargetsElement.TreePartLine:
      calculateTreePartLineAnimationDelay(arrayOfRows, time);
      break;
    default:
      calculateRowAnimationDelay(arrayOfRows, time);
  }
}

function startAnimation(node: Element, time: number) {
  setTimeout(() => {
    node.classList.add('show');
  }, time);
}

function calculateRowAnimationDelay(arrayOfRows: Element[][], time: number) {
  for (let row of arrayOfRows) {
    time += 150;
    row.map(element => startAnimation(element, time));
  }
}

function calculateWordAnimationDelay(arrayOfRows: Element[][], time: number) {
  for (let row of arrayOfRows) {
    row.map(element => {
      time += 150;
      return startAnimation(element, time);
    });
  }
}

function calculateTreePartLineAnimationDelay(arrayOfRows: Element[][], time: number) {
  for (let row of arrayOfRows) {
    let i = 0;
    row.map(element => {
      i++;
      time = tripleDelayDiff(row.length, i);
      return startAnimation(element, time);
    });
  }
}

function tripleDelayDiff(length: number, step: number): number {
  const onePartSize = Math.round(length / 3);
  let delay = 0;

  switch (true) {
    case step > 2 * onePartSize:
      delay = 100 * 4;
      break;
    case step <= 2 * onePartSize && step > onePartSize:
      delay = 100 * 2;
      break;
    case step <= onePartSize:
      delay = 0;
      break;
  }

  return delay;
}

